<div
  class="t-card-2 w-full p-1 pr-2 flex items-center justify-between rounded-lg border-dashed border border-gray-300 bg-white shadow-xs"
  [ngClass]="{ 't-card-2-active': isActivated }"
  (click)="handleClickAddBtn($event)"
>
  <div
    class="image-wrapper grow h-[3.5rem] flex gap-2 items-center pr-3"
    (click)="selectedCard.emit($event)"
  >
    <img class="thumbnail" [src]="imageUrl" alt="Card-2-thumbnail" />
    <span class="title">{{ title }}</span>
  </div>
  <p-button
    #addBtn
    icon="sctr-icon-plus"
    styleClass="p-button-rounded p-button-text"
    (click)="op.toggle($event)"
  ></p-button>
</div>
<p-overlayPanel #op styleClass="center">
  <div
    *ngFor="let day of days"
    class="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-200"
    (click)="handleOnClick.emit(day); op.hide()"
  >
    <div class="w-4 h-4 mr-2 sctr-icon-marker-pin-01"></div>
    <div class="font-Inter text-md not-italic font-medium text-gray-700">
      {{ 'common.term.day' | translateAsync }} {{ day?.no }}
    </div>
    <div *ngIf="isDayAdded(day, placeId)" class="w-4 h-4 ml-2 sctr-icon-check"></div>
  </div>
  <div
    class="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-200"
    (click)="handleOnClick.emit(extraDay); op.hide()"
  >
    <div class="w-4 h-4 mr-2 sctr-icon-plus"></div>
    <div class="font-Inter text-md not-italic font-medium text-gray-700">
      {{ 'common.component.cards.add_day' | translateAsync }}
    </div>
  </div>
</p-overlayPanel>
