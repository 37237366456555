import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ResponseModelTourGroupDetailDTO,
  TourGroupControllerService,
} from '@assistant/angular-tour-ota-service';
import { TranslationService } from '@core/services';
import { environment } from '@env/environment';
import { BranchInfoService } from '@shared/services/branch-info.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, of, Subject, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TourManagementsSidebarService {
  public isSidebarOpen = new BehaviorSubject<boolean>(true);
  public showMenubar = new BehaviorSubject<boolean>(false);
  private currentParams = new Subject<CurrentParams>();
  currentParams$ = this.currentParams.asObservable();
  private branchInfoService = inject(BranchInfoService);
  private messageService = inject(MessageService);
  private translationService = inject(TranslationService);
  private http = inject(HttpClient);
  private tourGroupControllerService = inject(TourGroupControllerService);
  i18nPrefix = 'page.tour_ota.availability.';
  currency = 'USD';
  currencyRate = 1;
  branchTimezone: string = '';

  setCurrentParams({ agencyId, groupId }: CurrentParams) {
    this.tourGroupControllerService
      .getTourGroupDetailById(groupId)
      .pipe(
        take(1),
        switchMap((data: ResponseModelTourGroupDetailDTO) => {
          if (data) {
            this.currency = data?.data?.tour_group?.currency!;
            this.branchTimezone = data?.data?.tour_group?.timezone!;
            const localTimezone = localStorage.getItem('branch-timezone');
            if (!localTimezone || localTimezone !== this.branchTimezone) {
              localStorage.setItem('branch-timezone', this.branchTimezone);
            }
            return this.http
              .get(`${environment.BASE_API_URL}/billing/exchange-rate/USD/${this.currency}`)
              .pipe(take(1));
          }
          return of({
            data: null,
            error: true,
            message: this.translationService.getTranslationAsync(
              `${this.i18nPrefix}get_currency_failed`
            ),
          });
        })
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.currencyRate = res.data;
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err?.status
              ? this.translationService.getTranslationAsync(`common.http_error.${err?.status}`)
              : this.translationService.getTranslationAsync(
                  `${this.i18nPrefix}get_currency_failed`
                ),
          });
        },
      });

    this.currentParams.next({
      agencyId,
      groupId,
    });
  }

  getCurrency() {
    return {
      currency: this.currency,
      currencyRate: this.currencyRate,
    };
  }

  toggleSidebar() {
    this.isSidebarOpen.next(!this.isSidebarOpen.value);
  }

  toggleShowMenubar() {
    this.showMenubar.next(!this.showMenubar.value);
  }
}

type CurrentParams = {
  agencyId: string;
  groupId: string;
};
