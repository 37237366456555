import { Injectable } from '@angular/core';
import { IViewedTour, viewedToursDb } from '@shared/indexeddb-databases/recently-viewed-tours-db';
@Injectable({
  providedIn: 'root',
})
export class ViewedToursService {
  constructor() {}
  async addViewedTour(tourInfo: IViewedTour) {
    // Check existed tour
    const existingRecord = await viewedToursDb.viewedTours.where('id').equals(tourInfo.id).first();

    if (!existingRecord) {
      await viewedToursDb.viewedTours.put(tourInfo); // using put to pretend page detail reload multiple time that calls async func throw error.
    } else {
      // If tour is exist, update viewed time
      await viewedToursDb.viewedTours.where('id').equals(tourInfo.id).modify(tourInfo);
    }

    // Limit saved records
    const count = await viewedToursDb.viewedTours.count();
    const maxRecords = 100;
    if (count > maxRecords) {
      const recordsToDelete = count - maxRecords;
      await this.deleteOldestRecordsByViewedTime(recordsToDelete);
    }
  }
  async getNumberOfViewedTours(quantity: number) {
    const viewedTourList = await viewedToursDb.viewedTours
      .orderBy('viewedTime')
      .reverse()
      .limit(quantity)
      .toArray();
    const totalViewedTours = await viewedToursDb.viewedTours.count();
    return { data: viewedTourList, totalTours: totalViewedTours };
  }
  async getViewedTourById(tourId: string) {
    return await viewedToursDb.viewedTours.get(tourId);
  }
  async deleteOldestRecordsByViewedTime(count: number) {
    const oldestRecords = await viewedToursDb.viewedTours
      .orderBy('viewedTime')
      .limit(count)
      .toArray();
    for (const record of oldestRecords) {
      await viewedToursDb.viewedTours.delete(record.id);
    }
  }
  async deleteViewedTourByTourId(tourId: string) {
    // Check existed tour
    const existingRecord = await viewedToursDb.viewedTours.where('id').equals(tourId).first();
    if (existingRecord) {
      await viewedToursDb.viewedTours.delete(tourId);
    }
  }
  async deleteListViewedTours(tourListId: string[]) {
    tourListId.forEach((tourId) => {
      this.deleteViewedTourByTourId(tourId);
    });
  }
  async resetDb() {
    await viewedToursDb.resetDB();
  }
}
