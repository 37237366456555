import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ISelectedUser {
  session_id: string;
  isAgencySupport: boolean;
  username?: string;
  updated_at?: string;
  message?: string;
  unread?: boolean;
  chat?: {
    otherUser: string;
    currentUser: string;
    updated_at: string;
  };
  last_message?: any;
  partner?: any;
}
export interface IChatRequest {
  agencyId?: string;
  selectedUser?: ISelectedUser;
}
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public chatRequestData = new BehaviorSubject<any>('');
  public pendingSessionChanges = new BehaviorSubject<boolean>(false);

  constructor() {}

  setChatRequestValue(data: IChatRequest) {
    this.chatRequestData.next(data);
  }

  getChatRequestValue() {
    return this.chatRequestData as Observable<IChatRequest>;
  }
}
