import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { TranslationService } from '@core/services';
import { environment } from '@env/environment';
import {
  ConfirmationData,
  ConfirmationDialogService,
} from '@shared/services/confirmation-dialog.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import {
  catchError,
  delay,
  map,
  mergeMap,
  of,
  retryWhen,
  take,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { AppRoutes } from 'src/app/config/routes.config';

export const PagePermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const socialApp = environment.SOCIAL_APP_URL;
  const userProfileService = inject(UserProfileService);
  const translationService = inject(TranslationService);
  const confirmService = inject(ConfirmationDialogService);

  let retries = 0;

  return userProfileService.isLogged.pipe(
    mergeMap((status) => {
      if (status) {
        return of(true);
      } else {
        throw new Error('User is not logged in');
      }
    }),
    retryWhen((errors) =>
      errors.pipe(
        tap(() => retries++),
        delay(1000),
        mergeMap((error) => (retries < 5 ? of(error) : throwError(error)))
      )
    ),
    catchError(() => {
      const confirmationData: ConfirmationData = {
        header: translationService.getTranslationAsync(
          'common.component.auth_can_activate.comfirm_title'
        ),
        message: translationService.getTranslationAsync(
          'common.component.auth_can_activate.comfirm_msg'
        ),
        type: 'btnClose',
        confirmBtnLabel: translationService.getTranslationAsync('common.term.ok'),
        responsiveModal: true,
      };

      confirmService.setConfirmationData(
        confirmationData,
        () => {
          window.location.href = `${socialApp}/${AppRoutes.LOGIN}?r=${window.location.href}`;
        },
        () => {
          window.location.reload();
        }
      );
      return of(false);
    })
  );
};
