import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IFilter } from './filter.service';
import { AppRoutes } from 'src/app/config/routes.config';
import { environment } from '@env/environment';

interface IRouteHistory {
  route: string;
  params: Params;
}

@Injectable({
  providedIn: 'root',
})
export class RouteHistoryService {
  public histories = new BehaviorSubject<IRouteHistory[]>([]);
  public justGoBack: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.add({ route: window.location.pathname, params: params });
    });
  }

  add(history: IRouteHistory) {
    if (this.justGoBack) {
      this.justGoBack = false;
      return;
    }
    if (
      this.check(
        this.filterQueryParams(history.params, history.route),
        this.histories.value[this.histories.value.length - 1]?.params ?? {}
      )
    ) {
      return;
    }

    this.histories.next([
      ...this.histories.value,
      {
        route: history.route,
        params: this.filterQueryParams(history.params, history.route),
      },
    ]);
  }
  back() {
    const length = this.histories.value.length;
    if (length > 1) {
      this.justGoBack = true;
      this.router.navigate([this.histories.value[length - 2].route], {
        queryParams: this.histories.value[length - 2].params,
      });
      this.histories.next(this.histories.value.slice(0, -1));
    } else if (
      !this.histories.value[0].route.includes(`${environment.prefix}/${AppRoutes.TOUR_LIST}`)
    ) {
      this.router.navigate([this.histories.value[0].route], {
        queryParams: this.histories.value[0].params,
      });
      this.histories.next([]);
    } else {
      this.router.navigate(['/']);
      this.histories.next([]);
    }
  }

  filterQueryParams(params: Params, route: string) {
    const disallowParams = route.includes(`${AppRoutes.TOUR}/${AppRoutes.TOUR_LIST}`)
      ? ['openQuickView', 'openFilter', 'id']
      : ['openQuickView', 'openFilter'];
    const keys = Object.keys(params) as any;
    const result = keys
      .filter((key: string) => !disallowParams.includes(key))
      .reduce((obj: Object, key: string) => {
        return Object.assign(obj, {
          [key]: params[key],
        });
      }, {});
    return result;
  }

  check(arg1: Object, arg2: Object) {
    return JSON.stringify(arg1) === JSON.stringify(arg2);
  }
}
