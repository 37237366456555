import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'promotion',
  standalone: true,
})
export class PromotionPipe implements PipeTransform {
  transform(value: any, startDate: number | null, endDate: number | null): number {
    try {
      const currentEpoch = DateTime.now().toSeconds();
      if (endDate === null || endDate === 9999999999) return Math.round(value);
      if ((startDate && currentEpoch < startDate) || currentEpoch > endDate) {
        return 0;
      }
      return Math.round(value);
    } catch (error) {
      return 0;
    }
  }
}
