import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs';
import { quickViewAnimation } from '@modules/tour-list/components/quick-view/quick-view.animations';
import { CardModule } from 'primeng/card';
import {
  PlaceControllerService,
  SearchingPlaceControllerService,
} from '@assistant/angular-map-location-service';
import { IDay } from '../explore/explore.component';
import { environment } from '@env/environment';
import { Card2Component } from '@shared/components/cards/card2/card2.component';
import { TourCreateLangDto } from '@assistant/angular-tour-builder-service';
import { IPlace, IRecommendedPlace, LocationService, SoctripMapService } from '@soctrip-common/map';
import { ResizeDirective } from '@shared/directives/resize.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutes } from 'src/app/config/routes.config';
import { Action } from '@shared/enum/action';
import { SkeletonModule } from 'primeng/skeleton';
import { fadeIn } from '@shared/animations/fade-in';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';

@Component({
  selector: 'app-recommended-places',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    Card2Component,
    ResizeDirective,
    TranslateModule,
    SkeletonModule,
    TranslateAsyncPipe,
  ],
  templateUrl: './recommended-places.component.html',
  styleUrls: ['./recommended-places.component.scss'],
  animations: [quickViewAnimation, fadeIn],
})
export class RecommendedPlacesComponent implements OnInit, OnDestroy {
  @Input() recPlacesVisible: boolean;
  @Input() searchByGoogle: boolean = false;
  @Output() showRecPlaces = new EventEmitter<boolean>();
  destroy$ = new Subject<void>();
  locationID?: string = '';
  places = [];
  days: Array<IDay> = [];
  selectedPlaceId: string | null;
  isDesktopView: boolean = window.innerWidth >= 768;
  @Input() tourId: string;
  @Input() isModal: boolean = false;
  isLoaded: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService,
    private cdr: ChangeDetectorRef,
    private searchingPlaceControllerService: SearchingPlaceControllerService,
    private location: Location,
    private placeControllerService: PlaceControllerService,
    private mapService: SoctripMapService,
    private router: Router
  ) {}

  ngOnInit() {
    this.calcDays();
    if (!this.tourId) {
      this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
        this.recPlacesVisible = params['openViewAll'] === 'true';
        this.showRecPlaces.emit(this.recPlacesVisible);
        this.tourId = params['tourId'];
      });
    }

    this.locationService.tourData$
      .pipe(
        distinctUntilChanged(
          (prev: TourCreateLangDto | undefined, curr: TourCreateLangDto | undefined) => {
            return (prev?.location?.['name'] || '') === (curr?.location?.['name'] || '');
          }
        )
      )
      .subscribe(() => {
        this.getDestination();
      });
    this.locationService.selectedPlaceId$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.selectedPlaceId = val;
    });
  }

  hideRecPlaces() {
    this.showRecPlaces.emit(false);
    if (!this.isModal) {
      this.router.navigate([`${environment.prefix}/${this.tourId}/${Action.UPDATE}`], {
        queryParams: {
          openViewAll: null,
          tourId: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  getDestination() {
    this.locationService.tourData$.subscribe((val: TourCreateLangDto) => {
      const tourDetails = val;
      if (tourDetails?.location) {
        const newLocationID = (tourDetails.location as any)?.[0]?.id || '';
        if (this.locationID !== newLocationID) {
          this.locationID = newLocationID;
          this.cdr.detectChanges();
          this.fetchData();
        }
      }
    });
  }

  calcDays(): void {
    this.locationService.tourData$.subscribe((val: TourCreateLangDto) => {
      const tourDetails = val;
      const totalDays = tourDetails?.total_days;

      this.days = [];
      for (let i = 0; i < totalDays; ++i) {
        this.days.push({
          no: i + 1,
        });
      }
      this.cdr.markForCheck();
    });
  }
  currentLocation?: string;
  fetchData(): void {
    if (this.currentLocation === this.locationID) {
      return;
    }
    this.currentLocation = this.locationID;
    if (this.locationID && this.locationID.trim()) {
      if (localStorage.getItem('recommendPlaces')) {
        const recommendPlaces = JSON.parse(localStorage.getItem('recommendPlaces') || '');
        if (recommendPlaces.desID === this.locationID) {
          this.places = recommendPlaces.places;
          this.cdr.detectChanges();
          return;
        }
      }
      this.searchingPlaceControllerService
        .searchPlace(
          '',
          this.locationID,
          10,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.searchByGoogle
        )
        .pipe(
          debounceTime(300),
          finalize(() => {
            this.isLoaded = true;
            this.cdr.detectChanges();
          })
        )
        .subscribe((res: any) => {
          if (res.success && res.data.data.length > 0) {
            this.places = res.data?.data?.map((place: any) => {
              this.isPlaceAddedToTour(place.id);
              return <IPlace>{
                place_id: place.id,
                imageUrl: `${environment.baseURL}/storage/files/web/${
                  place?.storages?.[0]?.id || ''
                }`,
                name: place.main_text,
                category: place.category,
                main_text: place.main_text,
                secondary_text: place.secd_text,
                address: place.addr,
                storages: place.storages,
                longitude: place.lon,
                latitude: place.lat,
              };
            });
            //save recommend places to local storage to save price search google place
            localStorage.setItem(
              'recommendPlaces',
              JSON.stringify({
                places: this.places,
                desID: this.locationID,
              })
            );
            this.cdr.detectChanges();
          }
        });
    }
  }

  handleGetPlace = (day: IDay, place: IPlace) => {
    const placeData: IRecommendedPlace = {
      place: place,
      day: day,
    };
    this.locationService.setRecommendedPlace(placeData);
  };

  handleShowPlaceModal = (place: IPlace) => {
    this.selectedPlaceId = place.place_id!;
    this.placeControllerService.getPlaceInfo([this.selectedPlaceId!]).subscribe((data) => {
      if (data.data) {
        if (this.isDesktopView) {
          this.mapService.hightLightMarker(data.data[0], this.mapService.getMap()?.getZoom(), true);
        } else {
          this.locationService.setLocationData(data.data[0]);
        }
        this.locationService.setSelectedPlaceId(this.selectedPlaceId);
      }
    });
  };

  onIsDesktopViewChange(isDesktopView: any) {
    this.isDesktopView = isDesktopView;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isPlaceAddedToTour(placeId: string) {
    this.locationService.tourData$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      const itineraries = val?.itineraries;
      itineraries?.forEach((itinerary: any) => {
        if (itinerary.place_visits) {
          const placeAdded = itinerary.place_visits.some(
            (place: any) => placeId === place.place_id
          );

          if (placeAdded) {
            const dayNo = itinerary.day_no;
            const foundDay = this.days.find((d: IDay) => d.no === dayNo);

            if (foundDay) {
              foundDay.addedPlaces = foundDay.addedPlaces || [];

              if (!foundDay.addedPlaces.includes(placeId)) {
                foundDay.addedPlaces.push(placeId);
              }
            }
          }
        }
      });
    });
    this.cdr.detectChanges();
  }
}
