export enum TimeFormat {
  TIME_SIMPLE = 'TIME_SIMPLE', // 1:30 PM
  TIME_WITH_SECONDS = 'TIME_WITH_SECONDS', //	1:30:23 PM
  TIME_WITH_SHORT_OFFSET = 'TIME_WITH_SHORT_OFFSET', // 1:30:23 PM EDT
  TIME_WITH_LONG_OFFSET = 'TIME_WITH_LONG_OFFSET', //	1:30:23 PM Eastern Daylight Time
  TIME_24_SIMPLE = 'TIME_24_SIMPLE', // 13:30
  TIME_24_WITH_SECONDS = 'TIME_24_WITH_SECONDS', //	13:30:23
  TIME_24_WITH_SHORT_OFFSET = 'TIME_24_WITH_SHORT_OFFSET', //	13:30:23 EDT
  TIME_24_WITH_LONG_OFFSET = 'TIME_24_WITH_LONG_OFFSET', // 13:30:23 Eastern Daylight Time
}
