<div
  *ngIf="filterVisible"
  class="t-quick-view t-accrordion t-scroll-custom t-advance-filter"
  overlay-scrollbars
  [options]="{ scrollbars: { autoHide: 'leave', autoHideDelay: 100 } }"
>
  <p-card styleClass="t-card-body w-full rounded-none">
    <ng-template pTemplate="header">
      <div class="flex items-center justify-between pt-5 pl-4 mr-12">
        <span class="text-xl font-semibold text-gray-900">
          {{ 'page.tour_list.filter.filter' | translateAsync }}
        </span>
        <span
          class="text-md font-semibold"
          (click)="!disableBtn && resetFilter()"
          [ngClass]="
            checkHasFilterValue()
              ? 'hover:text-error-600 text-error-700 cursor-pointer'
              : 'text-error-300 cursor-default'
          "
        >
          {{ 'page.tour_list.filter.reset_filter' | translateAsync }}
        </span>
      </div>
      <button class="t-close-btn p-2" (click)="hideFilter()">
        <i class="sctr-icon-x t-close-icon"></i>
      </button>
    </ng-template>
    <ng-template pTemplate="body" #body>
      <div class="border-t border-gray-200 w-full">
        <p-accordion [multiple]="true" [activeIndex]="[0]">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex flex-row items-center justify-between w-full">
                <span class="text-lg font-semibold text-gray-900">
                  {{ 'page.tour_list.filter.tour_types' | translateAsync }}
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <!-- <div class="mt-3 flex flex-col gap-4">
                <div *ngFor="let type of types" class="">
                  <p-radioButton [inputId]="type.key" name="category" [value]="type" [(ngModel)]="selectedType"
                    (onFocus)="changeType()"></p-radioButton>
                  <label [for]="type.key" class="text-lg font-medium text-gray-900 ml-3">{{ type.name }}</label>
                </div>
              </div> -->
              <div *ngFor="let tourType of tourTypes; let i = index" class="t-checkbox pt-3 flex">
                <p-checkbox
                  [value]="tourType.name"
                  [inputId]="tourType.name"
                  (onChange)="changeTourType(tourType.name)"
                  [(ngModel)]="selectedPointTourType"
                ></p-checkbox>
                <label [for]="tourType" class="gap-4 pl-3 text-lg font-medium text-gray-700">
                  {{ 'page.tour_list.filter.' + (tourType.name | lowercase) | translateAsync }}
                </label>
                <!-- <p class="gap-4 pl-3 text-lg font-medium text-gray-900" > {{ tourType.key !== 0 ? '(' + tourType.key + ')' : '' }}</p> -->
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>

      <!-- <div class="w-full">
        <div class="pt-3 flex flex-col">
          <span class="text-lg font-semibold text-gray-900 mb-2">Trip periods</span>
          <p-inputNumber
            inputId="tripPeriods"
            inputStyleClass="t-input"
            [style]="{ display: 'contents' }"
            placeholder="days"
            [(ngModel)]="tripPeriods"
            (onInput)="changeTripPriod()"
          >
          </p-inputNumber>
        </div>
      </div> -->

      <div class="w-full">
        <div class="pt-3 flex flex-col">
          <span class="text-lg font-semibold text-gray-900 mb-2">
            {{ 'page.tour_list.filter.date' | translateAsync }}
          </span>
          <div
            class="border border-gray-200 rounded-lg flex items-center gap-2 py-1 min-w[200px] xl:p-0 t-inputtext"
          >
            <p-calendar
              #searchCalendar
              id="searchCalendar"
              (onSelect)="changeDate()"
              (onClear)="clearDate()"
              [(ngModel)]="date"
              view="month"
              dateFormat="mm/yy"
              [readonlyInput]="true"
              styleClass="t-datetime w-full flex-row-reverse text-gray-700 xl:pl-2 xl:py-0.5 "
              [showIcon]="true"
              icon="t-date-picker-icon sctr-icon-calendar-heart-02"
              [placeholder]="'page.tour_list.filter.when' | translateAsync"
              inputStyleClass="px-2 py-3 text-md w-full"
              [panelStyle]="{ top: '68px', left: '0px' }"
              [minDate]="minDate"
              [showClear]="true"
            >
              <ng-template pTemplate="previousicon">
                <i class="t-year-icon sctr-icon-chevron-left"></i>
              </ng-template>
              <ng-template pTemplate="nexticon">
                <i class="t-year-icon sctr-icon-chevron-right"></i>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <i class="t-clear-icon sctr-icon-x-close text-lg/4"></i>
              </ng-template>
            </p-calendar>
          </div>
        </div>
      </div>

      <p-accordion [multiple]="true" [activeIndex]="[0]">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="flex flex-row items-center justify-between w-full">
              <span class="text-lg font-semibold text-gray-900">
                {{ 'page.tour_list.filter.adventure_styles' | translateAsync }}
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <div
              *ngFor="let style of topAdventureStyle; let i = index"
              class="t-checkbox pt-3 flex"
            >
              <p-checkbox
                name="group"
                [value]="style.name"
                [inputId]="style?.name!"
                [(ngModel)]="selectedAdventureStyles"
                (onChange)="changeAdventureStyle()"
              ></p-checkbox>
              <label [for]="style" class="gap-4 pl-3 text-lg font-medium text-gray-700">
                {{ style.localized_name! || style.name! | localizedLanguageString: lang }}
                <!-- {{ 'common.term.adventure_styles.' + style.id | translateAsync }} -->
              </label>
              <!-- <p class="gap-4 pl-3 text-lg font-medium text-gray-900"> {{ style.total_tour !== 0 ? '(' + style?.total_tour! + ')' : '' }}</p> -->
            </div>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
      <div class="w-full pt-3">
        <span class="text-lg font-semibold text-gray-900">
          {{ 'page.tour_list.filter.budget' | translateAsync }}
        </span>
        <div class="flex gap-3 mt-1">
          <div class="flex flex-col gap-1.5 grow">
            <p class="text-md font-medium text-gray-700">
              {{ 'page.tour_list.filter.from' | translateAsync }}
            </p>
            <p-inputNumber
              inputId="fromPrice"
              inputStyleClass="t-input"
              [(ngModel)]="fromPrice"
              [ngClass]="{ 'ng-invalid ng-dirty': invalidPriceRange === true }"
              (onBlur)="checkInputPriceInvalid()"
              (onInput)="changePrice()"
              [min]="0"
            >
            </p-inputNumber>
          </div>
          <div class="flex flex-col gap-1.5 grow">
            <p class="text-md font-medium text-gray-700">
              {{ 'page.tour_list.filter.to' | translateAsync }}
            </p>
            <p-inputNumber
              class="w-full"
              inputId="toPrice"
              [(ngModel)]="toPrice"
              inputStyleClass=" t-input"
              [ngClass]="{ 'ng-invalid ng-dirty': invalidPriceRange === true }"
              (onBlur)="checkInputPriceInvalid()"
              (onInput)="changePrice()"
              [min]="0"
            >
            </p-inputNumber>
          </div>
        </div>
        <div *ngIf="invalidPriceRange" class="mt-1.5">
          <span class="text-error-500 text-md font-normal">{{ invalidPriceRangeMessage }}</span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex gap-3 justify-end">
        <button
          class="px-[1.125rem] py-[0.625rem] text-lg font-semibold text-gray-700 rounded-lg border border-gray-300"
          (click)="handleCancel()"
        >
          {{ 'common.term.cancel' | translateAsync }}
        </button>
        <button
          [disabled]="invalidPriceRange || !isDirty"
          [ngClass]="invalidPriceRange || !isDirty ? 'bg-primary-200 ' : 'bg-primary-600 '"
          class="px-[1.125rem] py-[0.625rem] text-lg font-semibold text-white rounded-lg"
          (click)="handleApply()"
        >
          {{ 'common.term.apply' | translateAsync }}
        </button>
      </div>
    </ng-template>
  </p-card>
</div>
