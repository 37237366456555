import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { BehaviorSubject, of } from 'rxjs';
import { UserService } from '../user/user.service';
import { UserInfo } from '@soctrip-common/comment/lib/models/comment.models';
import { APP_PERMISSION } from '@core/constants/permissions';
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userRoles = new BehaviorSubject<any>(this.userService.getUserInfoFromLocalStorage());
  public userInfo = new BehaviorSubject<UserInfo>({} as UserInfo);
  private userPermissionSubject = new BehaviorSubject<string | null>(null);
  userPermission$ = this.userPermissionSubject.asObservable();
  private userRoleOfTourGrpSubject = new BehaviorSubject<string | null>(null);
  userRoleOfTourGrp$ = this.userRoleOfTourGrpSubject.asObservable();

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private userService: UserService
  ) {}
  nextPermissions(per: string) {
    this.userPermissionSubject.next(per);
  }
  checkPermission(requiredPermission: string[], permissions: string) {
    const tokenId = this.tokenStorageService.getToken(); // || get token from cookie
    const isTokenValid = this.authService.isTokenExpired();

    if (tokenId && !isTokenValid) {
      const hasPermission = requiredPermission.some((per) => permissions.includes(per));
      return hasPermission;
    }
    return false;
  }

  nextUserRoleOfTourGrp(role: string) {
    this.userRoleOfTourGrpSubject.next(role);
  }
}
