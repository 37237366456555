import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserInfo } from '@shared/models/user';
import { Observable, ReplaySubject, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  basePath = environment.baseURL;
  currentId: string;
  private _userInfo: UserInfo | null;

  constructor(private http: HttpClient) {}
  getUserInfo(id: string) {
    return this.http.get(`${this.basePath}/user/users/` + id);
  }

  saveUserInfoToLocalStorage(userRole: string[]) {
    localStorage.setItem(environment.USER_ROLE_KEY, JSON.stringify(userRole));
  }

  setAdminRoleToLocalStorage(adminRole: string) {
    localStorage.setItem(environment.ADMIN_ROLE_KEY, JSON.stringify(adminRole));
  }

  getAdminRoleFromLocalStorage() {
    const adminRoleString = localStorage.getItem(environment.ADMIN_ROLE_KEY);
    if (adminRoleString && this.isValidJSON(adminRoleString)) return JSON.parse(adminRoleString);
  }

  getUserInfoFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.USER_PROFILE_KEY);
    if (userInfoString && this.isValidJSON(userInfoString)) return JSON.parse(userInfoString);
  }
  clearUserLocalStorage() {
    localStorage.removeItem(environment.USER_ROLE_KEY);
  }
  isValidJSON(str: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  getUserData(userId: string): Observable<any> {
    if (this._userInfo && this.currentId === userId) {
      return of({
        success: true,
        data: this._userInfo,
        error: null,
      });
    }
    return this.getUserInfo(userId).pipe(
      tap((res) => {
        if (res['success']) {
          this._userInfo = res['data'] as UserInfo;
          this.currentId = userId;
        }
      })
    );
  }

  getUserLanguage(): string {
    return String(localStorage.getItem('lang')).toLowerCase() || 'en';
  }
}
