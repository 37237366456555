import { Injectable } from '@angular/core';
import { UserService } from '@core/services';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private userService: UserService) {}
  private profile$ = new BehaviorSubject<any>(this.userService.getUserInfoFromLocalStorage());

  getData() {
    return this.profile$.asObservable();
  }

  setData(data: any) {
    this.profile$.next(data);
  }

  get isLogged(): Observable<boolean> {
    return this.getData().pipe(map((profile) => !!profile?.id));
  }
}
