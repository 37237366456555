<app-modal
  [showDialog]="isShowReport"
  (emitOnCloseDialog)="handleCloseModal()"
  [dialogConfig]="dialogConfig"
>
  <ng-container modal-header>
    <div class="text-xl font-semibold text-gray-700">
      {{ 'common.term.report' | translateAsync }}
    </div>
  </ng-container>

  <ng-container modal-content>
    <div class="flex flex-col">
      <span class="text-md font-medium text-gray-600">
        {{ 'common.component.report_dialog.report_reason' | translateAsync }}
      </span>
      <div class="flex pt-2 pb-4 gap-4" *ngIf="contentReport">
        <div class="flex gap-2 flex-wrap">
          <div class="flex" *ngFor="let item of contentReport; let i = index">
            <p-chip [class.selected]="isChipSelected(item.name)" (click)="toggleChip(item.name)">
              <div class="py-1.5 text-md cursor-pointer apply-color">
                {{ 'common.component.report_dialog.' + item.id | translateAsync }}
              </div>
            </p-chip>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1.5">
        <span class="text-md font-medium text-gray-700">
          {{ 'common.term.other' | translateAsync }}
        </span>
        <textarea
          class="h-full rounded-lg mb-1 text-md"
          [placeholder]="'common.component.report_dialog.enter_description' | translateAsync"
          rows="5"
          cols="30"
          pInputTextarea
          [(ngModel)]="textareaValue"
          (ngModelChange)="checkAllFields()"
        ></textarea>
      </div>
    </div>
  </ng-container>

  <ng-container modal-footer>
    <div class="flex flex-row gap-3 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit"
        (click)="handleCloseModal()"
      >
        <p class="text-base font-semibold text-gray-700">
          {{ 'common.term.cancel' | translateAsync }}
        </p>
      </button>
      <button
        class="rounded-lg h-10 px-4 text-white"
        type="submit"
        [ngClass]="{
          '!bg-primary-200 border-primary-200': !isDisabled,
          '!bg-primary-600': isDisabled
        }"
        [disabled]="!isDisabled"
        (click)="handleConfirmReport()"
      >
        {{ 'common.term.confirm' | translateAsync }}
      </button>
    </div>
  </ng-container>
</app-modal>
