import { Pipe, PipeTransform } from '@angular/core';
import { TranslateHubService } from '@shared/services/translate-hub.service';
import { generateHash, normalizeText } from '@shared/utils/normalize-text';

@Pipe({
  name: 'autoTranslate',
  standalone: true,
})
export class AutoTranslatePipe implements PipeTransform {
  translatedText: string | undefined;
  constructor(private translateHubService: TranslateHubService) {}

  transform(text: string, isChange: number, isKeepOriginal: boolean = false) {
    if (isKeepOriginal) {
      return text;
    }
    if (!this.translatedText) {
      this.translateHubService.addTextList(text);
    }

    if (!text || !text.length) {
      return text;
    }
    if (isChange !== 0) {
      const translationCache = this.translateHubService.getTranslationList();
      this.translatedText = translationCache.find(
        (t) => generateHash(normalizeText(t.original)) === generateHash(normalizeText(text))
      )?.translated;
      if (this.translatedText) return this.translatedText;
    }
    return text;
  }
}
