<div @fadeIn class="flex h-full" *ngIf="tour$ | async as tour; else skeletonTourPreview">
  <ng-container>
    <div
      *ngIf="isHasInvalidPlaceId"
      class="flex items-center justify-start fixed md:mt-5 md:right-8 h-5 z-[999]"
    >
      <p-messages [(value)]="messages!" [enableService]="false" [closable]="false" />
    </div>
    <div
      overlay-scrollbars
      [options]="{ scrollbars: { autoHide: 'leave', autoHideDelay: 100 } }"
      class="hidden md:block h-full flex-shrink-0 md:w-[460px] bg-white border-r border-gray-200 md:pr-3 pt-0"
    >
      <app-tab-view
        class="hidden md:block"
        [datas]="itineraries!"
        [views]="'day'"
        [pinnedHeader]="true"
        [isQuickView]="true"
        [isTourGuide]="true"
        [isPreviewMode]="isPreviewMode"
        [isConvertPrice]="isConvertPrice"
        [showCost]="!isCommercialTour"
      ></app-tab-view>
    </div>
    <div #parent class="relative flex-1 w-full h-full overflow-hidden">
      <div
        id="media-player"
        class="absolute z-[999] flex md:w-[410px] md:h-[310px] w-[320px] h-[250px]"
        [ngClass]="[
          isDesktopView ? 'left-4 top-4 opacity-0' : 'media-mobile opacity-1',
          hasFooterInModal ? 'bottom' : ''
        ]"
        cdkDrag
        [cdkDragDisabled]="resizing || media.isFullScreen || !isDesktopView"
        (cdkDragStarted)="dragging = true"
        (cdkDragMoved)="dragMove($event)"
        (cdkDragEnded)="dragEnd($event)"
        [cdkDragBoundary]="parent"
      >
        <div
          class="relative w-full h-full overflow-hidden"
          [ngStyle]="style"
          mwlResizable
          [validateResize]="validate"
          [enableGhostResize]="true"
          (resizeEnd)="onResizeEnd($event)"
          (resizeStart)="resizing = true"
          (resizing)="onResizing($event)"
          [ghostElementPositioning]="'absolute'"
        >
          <div
            *ngIf="isPinnedMedia"
            class="absolute w-fit text-primary-700 bg-primary-50 rounded-b-lg top-0 left-0 right-0 mx-auto text-xs py-0.5 px-2 font-semibold z-10"
          >
            <i class="sctr-icon-solid-pin-02"></i>
          </div>
          <div
            class="resize-handle-top-right"
            mwlResizeHandle
            [resizeEdges]="{ top: true, right: true }"
          ></div>
          <div
            class="resize-handle-bottom-right"
            mwlResizeHandle
            [resizeEdges]="{ bottom: true, right: true }"
          ></div>
          <app-media-preview
            class="overflow-hidden rounded-[10px] flex-shrink-0 w-full h-full"
            #mediaTemplate
            [place]="currentPlace"
            [isFirst]="placeIndex === 0"
            [isLast]="placeIndex === places.length - 1"
            [play]="play"
            [isLastPlace]="this.placeIndex === this.places.length - 1"
            (slideEnded)="handleSlideEnded($event)"
            (toggleScreen)="toggleScreen(parent, $event)"
            (skipMedia)="handleSkipMedia($event)"
          ></app-media-preview>
        </div>
      </div>
      <app-map-tour
        [currentCoordinate]="centerPlace"
        [ngClass]="[media.isFullScreen ? 'mini-map' : 'full-map']"
        #map
        (mapLayer)="initMapLayer($event)"
      ></app-map-tour>
    </div>
  </ng-container>
</div>
<ng-template #skeletonTourPreview>
  <div @fadeIn class="flex w-full h-full overflow-hidden gap-x-4">
    <div class="hidden md:flex md:w-[460px] md:flex-col gap-y-3 overflow-hidden">
      <div class="flex gap-x-3">
        <p-skeleton width="5rem" height="2.5rem"></p-skeleton>
        <p-skeleton width="5rem" height="2.5rem"></p-skeleton>
        <p-skeleton width="5rem" height="2.5rem"></p-skeleton>
        <p-skeleton width="5rem" height="2.5rem"></p-skeleton>
        <p-skeleton width="5rem" height="2.5rem"></p-skeleton>
      </div>
      <hr />
      <div class="flex flex-col w-full gap-y-3">
        <p-skeleton height="1.5rem"></p-skeleton>
        <p-skeleton height="20rem"></p-skeleton>
        <p-skeleton height="20rem"></p-skeleton>
        <p-skeleton height="20rem"></p-skeleton>
      </div>
    </div>
    <div class="flex-1 h-full">
      <p-skeleton height="100%" styleClass="w-full h-full"></p-skeleton>
    </div>
  </div>
</ng-template>
