import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapTourServices {
  public categoriesPlace: any[] = [
    {
      name: 'Food and drink',
      key: 'food_and_drink',
      img: 'assets/images/markers/food_and_drink.svg',
    },
    { name: 'Shopping', key: 'shopping', img: 'assets/images/markers/shopping.svg' },
    { name: 'Services', key: 'services', img: 'assets/images/markers/services.svg' },
    {
      name: 'Hotel and Lodging',
      key: 'hotel_and_lodging',
      img: 'assets/images/markers/hotel_and_lodging.svg',
    },
    { name: 'Religion', key: 'religion', img: 'assets/images/markers/religion.svg' },
    {
      name: 'Office and industrial',
      key: 'office_and_industrial',
      img: 'assets/images/markers/office_and_industrial.svg',
    },
    { name: 'Education', key: 'education', img: 'assets/images/markers/education.svg' },
    { name: 'Tourism', key: 'tourism', img: 'assets/images/markers/tourism.svg' },
    {
      name: 'Transportation terminal',
      key: 'transportation_terminal',
      img: 'assets/images/markers/transportation_terminal.svg',
    },
    { name: 'Residential', key: 'residential', img: 'assets/images/markers/residential.svg' },
    { name: 'Other', key: 'other', img: 'assets/images/markers/start_marker.svg' },
  ];
  private selectedCategoriesPlace = new BehaviorSubject<any[]>([]);
  private selectedCategoriesPlace$ = this.selectedCategoriesPlace.asObservable();
  constructor(private http: HttpClient) {}

  public getCategoriesPlace() {
    return this.categoriesPlace;
  }
  public getCategoriesPlaceSelected() {
    return this.selectedCategoriesPlace.getValue();
  }
  public setCategoriesPlaceSelected(categories: any[]) {
    this.selectedCategoriesPlace.next(categories);
  }
  public getCategoriesPlaceSelected$(): Observable<any[]> {
    return this.selectedCategoriesPlace$;
  }
}
