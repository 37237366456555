import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListSuggestionService {
  public reviewSubject = new BehaviorSubject<number>(0);
  public resetFilter = new BehaviorSubject(false);
  public checkBookmark = new BehaviorSubject(false);
  constructor() {}
}
