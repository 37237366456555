import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export interface IReportDTO {
  object_id: string;
  object_parent_id: string;
  category_type: string;
  object_type: string;
  object_parent_type: string;
  report_reason: string;
  owner_id: string;
  key_search: string;
  url_detail: string;
  city_code: string;
}
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  REPORT_API_URL: string = `${environment.BASE_API_URL}/report`;
  REPORT_CATEGORY_API_URL: string = `${environment.BASE_API_URL}/report-category`;

  constructor(private httpClient: HttpClient) {}
  createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }

  getAllReportCategory(): Observable<any> {
    const options = { headers: this.createHeader() };
    return this.httpClient.get(`${this.REPORT_CATEGORY_API_URL}/get-all`, options);
  }

  postReport(body: IReportDTO): Observable<any> {
    const options = { headers: this.createHeader() };
    return this.httpClient.post(`${this.REPORT_API_URL}/report`, body, options);
  }
}
