import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { TabView, TabViewModule } from 'primeng/tabview';
import { TabMenu, TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';

import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { ItineraryDto } from '@assistant/angular-tour-builder-service';
import { MediaService } from '@modules/tour-guide/services/media.service';
import { AppRoutes } from 'src/app/config/routes.config';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { TranslationService } from '@core/services';

@Component({
  selector: 'app-tab-view',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TabViewModule,
    TabMenuModule,
    TimelineComponent,
    TranslateAsyncPipe,
  ],
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() views: string;
  @Input() showCost: boolean = true;
  @Input() headers: string[];
  @Input() datas: ItineraryDto[] = [];
  @Input() activeIndex: number = 0;
  @Input() isPreviewMode: boolean;
  @Input() action: string;
  @Input() pinnedHeader: boolean = false;
  @ViewChild('tab') tab: TabView;
  @ViewChild('tabView') tabView: TabView;
  @ViewChild('tab') tabMenu: TabMenu;
  @Input() activeIndexTab: number = 0;
  @Output() handleTabChange = new EventEmitter<Event>();
  @Input() resetIndexAfterChange: boolean = true;
  @Input() updateMenuItems: boolean = false;
  @Input() showPreviewButton: boolean = false;
  @Input() isQuickView: boolean = false;
  @Input() headerActiveIndex: number = 0;
  @Input() isConvertPrice: boolean = false;
  @Output() emitOnPlayPreview = new EventEmitter();
  isScrollable: boolean = false;
  menuItems: any[] = [];
  activeItem: MenuItem;
  currentActiveItem: any;
  itemIndex: number;
  @Input() isTourGuide: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  translateAllDays = this.translationService.getTranslationAsync(
    'common.component.tab_view.all_days'
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private location: Location,
    private mediaService: MediaService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.datas = this.datas?.sort((a, b) => Number(a.day_no) - Number(b.day_no));
    this.generateMenuItems();
    if (this.location.path().includes(AppRoutes.TOUR_GUIDE)) this.isTourGuide = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resetIndexAfterChange) {
      this.activeIndex = 0;
      this.generateMenuItems();
    }
    if (this.updateMenuItems) {
      this.generateMenuItems();
      if (!this.showPreviewButton) {
        this.menuItems.shift();
      }
      this.currentActiveItem = this.activeItem;
      this.itemIndex = this.menuItems.findIndex(
        (item) => item.label === this.currentActiveItem.label
      );
      if (this.itemIndex === -1) {
        this.activeItem = this.menuItems[0];
        this.currentActiveItem = this.activeItem;
      } else {
        this.activeItem = this.menuItems[this.itemIndex];
      }
      this.tabMenu.updateButtonState();
    }
    if (this.activeItem?.label === this.translateAllDays) {
      this.tabChangeToAllDays();
    }
    this.headerActiveIndex = this.activeIndexTab;
  }

  ngAfterViewInit(): void {
    if (this.tab) {
      this.tab.updateButtonState();
      this.tab.updateInkBar();
      // this.tab.updateScrollBar(this.activeIndex);
      this.tab.cd.detectChanges();
    }
    if (this.tabView) {
      this.tabView.updateButtonState();
      this.tabView.updateInkBar();
      // this.tabView.updateScrollBar(this.activeIndexTab);
      this.tabView.cd.detectChanges();
    }
    if (this.isTourGuide) {
      this.mediaService.currentTabIndex$.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
        this.activeIndex = val?.day_no! - 1;
        this.activeItem = this.menuItems[this.activeIndex];
        this.handleTabChange.emit(this.menuItems[this.activeIndex]);
      });
    }
    if (this.datas.length >= 8) {
      this.tab.forwardIsDisabled = false;
    }
    if (this.isQuickView) {
      const allDaysEvent = new Event('tabChange');
      allDaysEvent['label'] = this.translationService.getTranslationAsync(
        'common.component.tab_view.all_days'
      );
      this.handleTabChange.emit(allDaysEvent);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showItineraryTimeline(status: boolean) {
    const tabmenuNavContent = document.querySelectorAll<HTMLElement>('.p-tabmenu-nav-content')!;
    if (status) {
      tabmenuNavContent[0].style.maxWidth = '94%';
    }
    this.showPreviewButton = !status;
  }

  tabChangeToAllDays() {
    const allDaysEvent = new Event('tabChange');
    allDaysEvent['label'] = this.translateAllDays;
    this.handleTabChange.emit(allDaysEvent);
  }

  backToPreviousPage() {
    this.location.back();
  }

  generateMenuItems() {
    if (this.datas?.length >= 2 && !this.isTourGuide) {
      this.menuItems = [
        {
          label: this.translationService.getTranslationAsync('common.component.tab_view.all_days'),
          class: 't-background-day0',
        },
        ...this.datas?.map((day) => ({
          label:
            this.translationService.getTranslationAsync('common.component.tab_view.day') +
            ' ' +
            day.day_no,
          class:
            day.day_no && day.day_no > 10
              ? 't-background-day' + Math.floor(Math.random() * 11)
              : 't-background-day' + day.day_no,
        })),
      ];
    } else {
      this.menuItems = [
        ...this.datas?.map((day) => ({
          label:
            this.translationService.getTranslationAsync('common.component.tab_view.day') +
            ' ' +
            day.day_no,
          class:
            day.day_no && day.day_no > 10
              ? 't-background-day' + Math.floor(Math.random() * 11)
              : 't-background-day' + day.day_no,
        })),
      ];
    }

    this.activeItem = this.menuItems[0];
    this.isScrollable = this.datas?.length > 1;
    this.cdr.detectChanges();
  }

  onTabChange(event: any) {
    this.activeIndex = this.menuItems.indexOf(event);
    this.activeItem = event;
    this.handleTabChange.emit(event);
  }
}
