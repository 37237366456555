import { Subject, debounceTime, take, takeUntil, map } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  TemplateRef,
  HostListener,
  AfterViewInit,
  AfterContentInit,
  AfterViewChecked,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { GalleryComponent } from '@shared/components/gallery/gallery.component';
import { BadgeComponent } from '@shared/components/badge/badge.component';
import { TabViewComponent } from '@shared/components/tab-view/tab-view.component';
import { ContentPanelComponent } from '@shared/components/content-panel/content-panel.component';
import { AddButtonComponent } from '@shared/components/add-button/add-button';
import {
  Review,
  ReviewPanelComponent,
} from '@shared/components/review-panel/review-panel.component';
import { ViewMorePanelComponent } from '@shared/components/view-more-panel/view-more-panel.component';
import { IExpenseItem } from '@shared/components/expense-item/expense-item.component';
import { ExpenseComponent } from '@modules/tour-detail/components/expense/expense.component';
import { ShareTourComponent } from '@shared/components/share-tour/share-tour.component';
import { QuickviewSkeletonComponent } from '@shared/components/skeleton/quickview-skeleton/quickview-skeleton.component';
import { ReportComponent } from '@shared/components/report-dialog/report.component';
import { ListSuggestionService } from '@modules/tour-list/tabs/list-suggestion/list-suggestion.service';
import { Map } from 'leaflet';
import {
  TourControllerService,
  ItineraryDto,
  TourDetailLangDto,
  PlaceVisitDto,
  ResponseModelBloomFilterDetailDTO,
  Media,
} from '@assistant/angular-tour-builder-service';
import { LocationService, SoctripMapService } from '@soctrip-common/map';
import { BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { ReportControllerService } from '@assistant/angular-tour-builder-service';

import { AppRoutes } from 'src/app/config/routes.config';
import { BloomFilter } from '@soctrip-common/soctrip-algorithm/dist/api';
import { ItineraryService } from '@shared/services/itinerary.service';
import { FilterService } from '@shared/services/filter.service';
import { ViewedToursService } from '@shared/services/viewed-tours.service';
import { IViewedTour } from '@shared/indexeddb-databases/recently-viewed-tours-db';
import { OpenMapService } from '@modules/create-tour/services/open-map.service';
import { GetBloomBookmarkService } from '@shared/services/get-bloom-bookmark.service';
import { getValues } from '@shared/models/list-object';
import { getMediaPath } from '@shared/utils/getMediaPath';
import { ToastService, TranslationService, UserService } from '@core/services';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  IExtendPlaceVisit,
  TourGuideComponent,
} from '@modules/tour-guide/components/tour-guide/tour-guide.component';
import { DialogConfig } from '@shared/models/dialog';
import { HostViewService } from '@core/services/modal/host-view.service';
import {
  CommentContentComponent,
  CommentFormComponent,
  CommentLibModule,
} from '@soctrip-common/comment';
import {
  MediaComment,
  MessageRanges,
  UserInfo,
  Comment,
} from '@soctrip-common/comment/lib/models/comment.models';
import { GetApiService } from '@shared/services/get-api.service';
import { ChildCommentComponent } from '@modules/tour-detail/components/customer-review/child-comment/child-comment.component';
import { environment } from '@env/environment';
import { IReportDTO, ReportService } from '@shared/services/report.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import {
  AdventureStylesService,
  IAdventureStyleRelatedTourDto,
} from '@shared/services/adventure-styles.service';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { CurrencySymbolPipe } from 'src/common/pipe/currency-symbol.pipe';
import { UserProfileService } from '@shared/services/user-profile.service';
import { DateTime } from 'luxon';
import L, { latLngBounds } from 'leaflet';
import { PromotionPipe } from '@shared/pipes/promotion.pipe';
import { TourTitlePipe } from '@shared/pipes/tour-title.pipe';
import { LocalizedLanguageStringPipe } from '@shared/pipes/localized-language-string.pipe';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    /* @angular */
    CommonModule,
    ToastModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    /* primeng */
    OverlayPanelModule,
    TooltipModule,
    /* component */
    GalleryComponent,
    BadgeComponent,
    TabViewComponent,
    ContentPanelComponent,
    AddButtonComponent,
    ReviewPanelComponent,
    ViewMorePanelComponent,
    ExpenseComponent,
    ReportComponent,
    ShareTourComponent,
    QuickviewSkeletonComponent,
    ModalComponent,
    TourGuideComponent,
    ChildCommentComponent,
    /* pipe */
    CommentLibModule,
    TranslateAsyncPipe,
    CurrencyConversionPipe,
    CurrencySymbolPipe,
    PromotionPipe,
    TourTitlePipe,
  ],
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, LocalizedLanguageStringPipe],
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  /**
   * Tour name
   */
  title: string;
  /**
   * Tour categories
   */
  @Input() categories: string[];
  /**
   * Tour hashtags
   */
  hashtags: string[];
  /**
   * Tour images
   */
  images: Media[] = [];
  /**
   * Show/hide add to favorite button
   */
  @Input() favoriteButton: boolean = true;
  /**
   * Duration badge
   */
  @Input() durationBadge: string = '3N2D';
  /**
   * Rating badge
   */
  @Input() ratingBadge: number = 9.2;
  /**
   * Tour price
   */
  promotion: number;
  bookable_start: number | null;
  bookable_end: number | null;
  price: number;
  oldPrice: number;
  /**
   * Tour highlight
   */
  highlights: string;
  /**
   * Tour itinerary
   */
  itineraries: ItineraryDto[];
  /**
   * Tour total days
   */
  total_days: number = 3;
  durationUnit: string = 'day';
  /**
   * Tour rating
   */
  rating: number = 9;
  /**
   * Tour total reviews
   */
  total_reviews: number;
  /**
   * Tour reviews
   */
  reviews: Review[];
  /**
   * Tour id
   */
  tourId: string;
  isBookmarked: boolean = false;
  /**
   * Active index for tab view tour body
   */
  currentMap: any; //current leaflet map
  centerPlace: L.LatLng;
  activeBodyIndex: number = 0;
  activeHeaderIndex: number = 0;
  tourType: string;
  isCommercialTour: boolean = false;
  @Input() isPreviewMode: boolean = false;
  tabViewData: Array<string>;
  private destroy$ = new Subject();
  expenses: IExpenseItem[] = [];
  @Output() closeOverview = new EventEmitter();
  @Output() openModal = new EventEmitter<boolean>();
  @Output() handleCloseModal = new EventEmitter<boolean>();
  @Input() action: string;
  @ViewChild('view') view: ElementRef;
  tourDetails: TourDetailLangDto;
  destination: string;
  destinationId: string;
  resetIndexAfterChange: boolean = true;
  updateMenuItems: boolean = false;
  expenseList: number[] = [];
  totalExpense: number;
  expense: number | undefined;
  reviewData: Review[];
  disableButtonViewMore: boolean = false;
  bloomResponse: ResponseModelBloomFilterDetailDTO;
  isOpenReportDialog: boolean = false;
  reportCategories: any[] = [];
  targedReview: Review;
  targedReviewId: string | null = null;
  tourPreviewDialogConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 't-tour-preview-modal',
  };
  userInfo: UserInfo = {} as UserInfo;
  friendInfo: UserInfo[] = [];
  replyTarget: { id: string; full_name: string } = { id: '', full_name: '' };
  commentContent: Comment[];
  displayComment: Comment[];
  totalComment: number[] = [];
  pageComment: number = 0;
  date: Date;
  apiHost: string = `${environment.BASE_API_URL}`;
  otaId: string;
  reviewTourOTA: any[] = [];
  currentTab: MenuItem = {
    label: this.translationService.getTranslationAsync('common.component.tab_view.all_days'),
  };
  adventureStyles: IAdventureStyleRelatedTourDto[];
  //@ViewChildren('contentComment') contentComment: QueryList<CommentFormComponent>;
  @ViewChild('contentComment') contentComment: CommentFormComponent;
  @ViewChildren('commentData') commentData: QueryList<CommentContentComponent>;
  @ViewChildren('contentChildComment') contentChildComment: QueryList<CommentFormComponent>;
  @ViewChildren('contentComment1') contentComment1: QueryList<any>;
  isScrollUp: boolean = true;
  @Input() isModal: boolean = false;
  userCurrency: string;
  currencyRate: number;
  expenseCurrency: string = 'USD';
  tabChange$: Subject<any> = new Subject();
  resultTrans: string = '';
  translateAllDays = this.translationService.getTranslationAsync(
    'common.component.tab_view.all_days'
  );
  previousLabelTabChange: string;
  environment = environment;
  TAB_OVERVIEW: any;
  TAB_REVIEWS: any;
  TAB_COMMENTS: any;
  lang: string = 'en';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tourBuilder: TourControllerService,
    private filterService: FilterService,
    private listSuggestionService: ListSuggestionService,
    private locationService: LocationService,
    private cdr: ChangeDetectorRef,
    public itineraryService: ItineraryService,
    private viewedToursService: ViewedToursService,
    public openMapService: OpenMapService,
    private bookmarkControllerService: BookmarkControllerService,
    private getBloomBookmarkService: GetBloomBookmarkService,
    private reportControllerService: ReportControllerService,
    private reportService: ReportService,
    private toastService: ToastService,
    public hostView: HostViewService,
    private getApiService: GetApiService,
    private userService: UserService,
    private mapService: SoctripMapService,
    private translationService: TranslationService,
    private adventureStylesService: AdventureStylesService,
    private userProfileService: UserProfileService,
    private localizedLanguagePipe: LocalizedLanguageStringPipe
  ) {}
  ngAfterViewInit(): void {
    this.contentComment1.changes.subscribe((value) => {
      const attributes = value?.last?.textareaEl?.nativeElement?.attributes;
      const placeholder: any = Object.values(attributes)?.find(
        (i: any) => i?.value?.includes('COMMENT.')
      );
      if (placeholder) {
        placeholder.value = this.translationService.getTranslationAsync('COMMENT.ADD_COMMENT');
      }
    });
    this.commentData.changes.subscribe((value) => {
      for (let i = 0; i <= value._results.length; i++) {
        if (value._results[i].el.nativeElement) {
          let emoji =
            value._results[i].el.nativeElement.children[0].children[1].children[1].children[0]
              .textContent;
          let reply =
            value._results[i].el.nativeElement.children[0].children[1].children[1].children[3]
              .textContent;
          if (emoji.includes('POST.')) {
            value._results[
              i
            ].el.nativeElement.children[0].children[1].children[1].children[0].textContent =
              this.translationService.getTranslationAsync('POST.LOVE');
          }
          if (reply.includes('COMMENT.')) {
            value._results[
              i
            ].el.nativeElement.children[0].children[1].children[1].children[3].textContent =
              this.translationService.getTranslationAsync('COMMENT.REPLY');
          }
        }
      }
    });
  }
  ngAfterViewChecked(): void {
    this.commentData.changes.subscribe((value) => {
      for (let i = 0; i <= value._results.length; i++) {
        value._results[i].emojiList.forEach((data) => {
          if (data.label.includes('EMOJI.')) {
            data.label = this.translateComment(
              data.label,
              data.label.substring(0, data.label.indexOf('.') + 1)
            );
          }
        });
      }
    });
  }
  ngOnInit() {
    // this.userProfileService
    //   .getData()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: (profile) => {
    //       this.userCurrency = profile.currency;
    //     },
    //   });
    const currencyData = JSON.parse(localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA)!);
    this.currencyRate = currencyData?.rate;
    this.userCurrency = currencyData?.currency || 'USD';
    this.TAB_OVERVIEW = this.translationService.getTranslationAsync('page.tour_list.overview');
    this.TAB_REVIEWS = this.translationService.getTranslationAsync('page.tour_list.reviews');
    this.TAB_COMMENTS = this.translationService.getTranslationAsync('page.tour_list.comments');

    this.listSuggestionService.reviewSubject.subscribe((value) => {
      this.activeHeaderIndex = value;
    });
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params['lang']) {
        this.lang = params['lang'];
      }
      if (this.isPreviewMode) {
        this.tabViewData = [this.TAB_OVERVIEW];
        this.getPreviewData();
        this.resetIndexAfterChange = false;
        this.updateMenuItems = true;
      } else {
        if (!params['previewMode'] && params['openQuickView']) {
          if (params['id'] === this.tourId) return;
          this.tourId = params['id'];
          this.getTourid(this.tourId);
          this.destination = params['destination'];
          this.destinationId = params['destinationId'];
          // Populate tour details data
          if (params['id'] && !this.isPreviewMode) {
            this.lang = this.userService.getUserLanguage();
            this.handleGetTourInfo(true);
            // this.getTourReview(this.tourId);
            this.setBookmark(this.tourId);
          }
          // Set bookmark
          this.getBloomBookmarkService.reloadBookmark.subscribe((value) => {
            if (value) {
              this.setBookmark(this.tourId);
            }
          });
        }
      }
    });
    this.getReportCategories();
    // this.cdr.detectChanges();
    this.adventureStyles = this.adventureStylesService.getAdventureStyles();
    this.handleTabChangedAsync();
  }

  handleGetTourInfo(first?: boolean) {
    this.tourBuilder.getTourById(this.tourId, this.lang).subscribe({
      next: async (response) => {
        if (response.success) {
          if (response.data) {
            if (
              (!response?.data?.title || !response?.data?.language?.includes(this.lang)) &&
              first
            ) {
              if (response?.data?.language?.includes('en')) {
                this.lang = 'en';
              } else {
                this.lang = response?.data?.language[0] || 'en';
              }

              return this.handleGetTourInfo();
            }

            this.tourDetails = response.data as TourDetailLangDto;
            this.title = this.tourDetails.title as string;
            this.categories = getValues(this.tourDetails.adventure_styles as any);
            this.hashtags = getValues(this.tourDetails.hashtags as any);
            this.images = this.getAllGalleries(this.tourDetails);
            this.highlights = this.tourDetails.highlight as any;
            this.itineraries = this.tourDetails.itineraries?.sort((a, b) => {
              return Number(a.day_no) - Number(b.day_no);
            }) as ItineraryDto[];
            await this.itineraryService.showMarkers(
              this.itineraries,
              true,
              true,
              undefined,
              this.itineraryService.iShowQuickView
            );
            this.itineraryService.getLatLngList(this.itineraries, true);

            this.total_days = this.tourDetails.ota
              ? this.tourDetails.ota.duration
              : (this.tourDetails.total_days as number);
            this.tourType = this.tourDetails?.tour_type as string;
            this.isCommercialTour = this.tourType === 'Commercial' ? true : false;
            if (this.tourType === 'Commercial' && this.tourDetails?.ota?.id!) {
              this.durationUnit = this.tourDetails.ota.duration_unit;
              this.rating = this.tourDetails.rating!;
              this.total_reviews = this.tourDetails.total_review!;
              this.disableButtonViewMore = false;
              this.tourBuilder.getReviewByTourId(this.tourDetails?.ota?.id!).subscribe((value) => {
                if (value?.data?.totalElement > value?.data?.data?.length!) {
                  this.tourBuilder
                    .getReviewByTourId(
                      this.tourDetails?.ota?.id!,
                      undefined,
                      undefined,
                      undefined,
                      value?.data?.totalElement
                    )
                    .subscribe((value) => {
                      this.reviewTourOTA = value?.data?.data!;
                      if (this.reviewTourOTA && this.reviewTourOTA.length > 0) {
                        this.tabViewData = [this.TAB_OVERVIEW, this.TAB_REVIEWS];
                        if (this.reviewTourOTA?.length > 3) {
                          this.reviews = this.reviewTourOTA.slice(0, 3);
                        } else {
                          this.reviews = this.reviewTourOTA;
                          this.disableButtonViewMore = true;
                        }
                      } else {
                        this.tabViewData = [this.TAB_OVERVIEW];
                      }
                    });
                } else {
                  this.reviewTourOTA = value?.data?.data!;
                  if (this.reviewTourOTA && this.reviewTourOTA.length > 0) {
                    this.tabViewData = [this.TAB_OVERVIEW, this.TAB_REVIEWS];
                    if (this.reviewTourOTA?.length > 3) {
                      this.reviews = this.reviewTourOTA.slice(0, 3);
                    } else {
                      this.reviews = this.reviewTourOTA;
                      this.disableButtonViewMore = true;
                    }
                  } else {
                    this.tabViewData = [this.TAB_OVERVIEW];
                  }
                }
              });
            } else if (this.tourType !== 'Commercial') {
              this.tabViewData = [this.TAB_OVERVIEW, this.TAB_COMMENTS];
              this.getUserInfo();
              this.getListFriend();
              this.countTotalComment();
              this.getMyReactions();
            } else {
              this.tabViewData = [this.TAB_OVERVIEW];
            }
            this.price = this.tourDetails.price as number;
            this.oldPrice = this.tourDetails.original_price as number;
            this.promotion = this.tourDetails?.promotion || 0;
            this.bookable_start = this.tourDetails?.bookable_start_date || null;
            this.bookable_end = this.tourDetails?.bookable_end_date || null;
            if (response.data.id) {
              const viewedTourInfo: IViewedTour = {
                id: response.data.id,
                viewedTime: new Date().getTime(),
              };
              this.viewedToursService.addViewedTour(viewedTourInfo);
            }
          }
        }
      },
      error: (error) => console.log(error),
      complete: () => {},
    });
  }

  ngOnDestroy(): void {
    this.tabChange$.next([]);
    this.tabChange$.complete();
    // Remove the itinerary markers of the previous tour
    if (!this.isPreviewMode) {
      this.itineraryService.clearAllMarkers();
    }
    // this.getBloomBookmarkService.reloadBookmark.unsubscribe();
    this.destroy$.next(true);
    this.itineraryService.previousItineraries = [];
  }
  translateComment(value: string, part: string): string {
    if (value.includes(part + 'LOVE')) {
      this.resultTrans = this.translationService.getTranslationAsync(value);
    } else if (value.includes(part + 'HAHA')) {
      this.resultTrans = this.translationService.getTranslationAsync(value);
    } else if (value.includes(part + 'WOW')) {
      this.resultTrans = this.translationService.getTranslationAsync(value);
    } else if (value.includes(part + 'ANGRY')) {
      this.resultTrans = this.translationService.getTranslationAsync(value);
    } else if (value.includes(part + 'SAD')) {
      this.resultTrans = this.translationService.getTranslationAsync(value);
    }
    return this.resultTrans;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.isScrollUp = scrollTop <= 0;
  }

  getAdventureStyleLabel(name: string): string {
    if (!this.adventureStyles) {
      this.adventureStyles = this.adventureStylesService.getAdventureStyles();
    }
    const adventureStyle = this.adventureStyles?.find((item) => item.name === name)!;
    return (
      this.localizedLanguagePipe.transform(
        adventureStyle?.localized_name! || adventureStyle?.name!,
        this.lang
      ) || name
    );
  }

  // Comment
  checkUser(data: UserInfo): boolean {
    if (Object.keys(this.userInfo).length !== 0) {
      return true;
    } else {
      return false;
    }
  }

  getUserInfo() {
    if (localStorage.getItem(environment.USER_PROFILE_KEY)) {
      this.userService
        .getUserInfo(JSON.parse(localStorage?.getItem(environment.USER_PROFILE_KEY)!).id)
        .subscribe((value: any) => {
          if (value) {
            this.userInfo = {
              id: value?.data?.id!,
              first_name: value?.data?.first_name!,
              last_name: value?.data?.last_name!,
              full_name: value?.data?.full_name!,
              phone: value?.data?.phone!,
              email: value?.data?.email!,
              invited_by_code: value?.data?.invited_by_code!,
              invite_code: value?.data?.invite_code!,
              status: value?.data?.status!,
              username_id: value?.data?.username_id!,
              area_code: value?.data?.area_code!,
              register_date: value?.data?.register_date!,
              avatar_thumbnail_url: value?.data?.avatar_thumbnail_url!,
              frame_url: value?.data?.frame_url!,
              roles: value?.data?.roles!,
            };
          }
        });
    } else {
      // this.permissionService.userInfo.subscribe((value: UserInfo) => {
      //   if (value) {
      //     console.log(value);
      //     this.userInfo = {
      //       id: value.id,
      //       first_name: value?.first_name!,
      //       last_name: value?.last_name!,
      //       full_name: value?.full_name!,
      //       phone: value?.phone!,
      //       email: value?.email!,
      //       invited_by_code: value?.invited_by_code!,
      //       invite_code: value?.invite_code!,
      //       status: value?.status!,
      //       username_id: value?.username_id!,
      //       area_code: value?.area_code!,
      //       register_date: value?.register_date!,
      //       avatar_thumbnail_url: value?.avatar_thumbnail_url!,
      //       frame_url: value?.frame_url!,
      //       roles: value?.roles!,
      //     };
      //   }
      // });
    }
  }

  getListFriend() {
    this.getApiService.getListFriend('', 0).subscribe((value) => {
      if (value.data) {
        Array.from(value?.data?.content!).forEach((val: any) => {
          this.userService.getUserInfo(val?.id!).subscribe((res: any) => {
            this.friendInfo?.push({
              id: res?.data?.id!,
              first_name: res?.data?.first_name!,
              last_name: res?.data?.last_name!,
              full_name: res?.data?.full_name!,
              phone: res?.data?.phone!,
              email: res?.data?.email!,
              invited_by_code: res?.data?.invited_by_code!,
              invite_code: res?.data?.invite_code!,
              status: res?.data?.status!,
              username_id: res?.data?.username_id!,
              area_code: res?.data?.area_code!,
              register_date: res?.data?.register_date!,
              avatar_thumbnail_url: res?.data?.avatar_thumbnail_url!,
              frame_url: res?.data?.frame_url!,
              roles: res?.data?.roles!,
            });
          });
        });
      }
    });
  }

  sendComment(reply: boolean, commentId?: string, numberComment?: number) {
    let messRange: MessageRanges[] = [];
    let media: MediaComment[] = [];
    let body: any;
    const lang =
      JSON.parse(localStorage.getItem(environment.USER_PROFILE_KEY) || '')?.language || 'EN';
    if (reply === false) {
      body = {
        object_id: this.tourId,
        object_type: 'TOUR',
        parent_id: this.tourId,
        content: this.contentComment.textareaEl.nativeElement.textContent,
        message_ranges: this.contentComment.messageRanges,
        medias: this.contentComment.medias,
        language: lang,
        preview_data: null,
      };
    } else {
      body = {
        object_id: this.tourId,
        object_type: 'COMMENT',
        parent_id: commentId as string,
        content:
          this.contentChildComment.toArray()[numberComment as number].textareaEl.nativeElement
            .textContent,
        message_ranges: this.contentChildComment.toArray()[numberComment as number].messageRanges,
        medias: this.contentChildComment.toArray()[numberComment as number].medias,
        language: lang,
        preview_data: null,
      };
    }
    let addComment: Comment | undefined;
    this.getApiService.postComment(body).subscribe({
      next: (value) => {
        if (value?.success) {
          addComment = value?.data!;
        } else {
          this.toastService.toast(
            'error',
            this.translationService.getTranslationAsync('COMMENT.POST_COMMENT_FAILED', {
              message: value?.error?.message,
            })
          );
        }
      },
      error: (value) => {
        console.error(value);
      },
      complete: () => {
        if (addComment) {
          if (reply === false) {
            if (
              this.displayComment.length > 3 &&
              this.displayComment.length < this.totalComment.length
            ) {
              this.displayComment = [...[addComment], ...this.displayComment].slice(
                0,
                this.displayComment.length
              );
            } else if (this.displayComment.length === 3) {
              this.displayComment = [...[addComment], ...this.displayComment].slice(0, 3);
            } else if (this.displayComment.length === this.totalComment.length) {
              this.displayComment = [...[addComment], ...this.displayComment];
            }
            this.totalComment.push(this.totalComment.length + 1);
          } else {
            this.getApiService.commentChildata.next(addComment);
          }
        }
      },
    });
  }

  deleteComment(id: string) {
    this.getApiService.deleteComment(id).subscribe({
      error: (value) => {
        console.error(value);
      },
      complete: () => {
        if (this.displayComment.length === this.totalComment.length) {
          this.displayComment = this.displayComment.filter((item) => item.id !== id);
        } else if (
          this.displayComment.length > 3 &&
          this.displayComment.length < this.totalComment.length
        ) {
          this.getComment(0, this.displayComment.length);
        } else if (this.displayComment.length === 3) {
          this.getComment();
        }
        this.totalComment.shift();
      },
    });
  }

  getComment(page: number = 0, pageSize: number = 3) {
    this.getApiService.getComment(this.tourId, page, pageSize, 'TIME_DESC').subscribe((res) => {
      if (res) {
        this.displayComment = res?.data!;
      }
    });
  }

  countTotalComment() {
    this.getApiService.countCommenById(this.tourId).subscribe((res: any) => {
      if (res) {
        this.totalComment = Array.from(Array(res.data).keys());
        this.getComment();
      }
    });
  }

  getMyReactions() {
    this.getApiService.getReaction().subscribe((res) => {
      res = res.data;
      const tomorrow = DateTime.now().plus({ days: 1 }).toFormat('YYYY-MM-DDTHH:mm:ss').toString();
      if (res.length > 0) {
        res.sort((a, b) => (a.updated_at < b.updated_at ? -1 : 1));
      }
      const myReactionsInfo = {
        expiry: tomorrow, // expiry in 1 day
        oldestTime: res.length > 0 ? res[0].updated_at : '',
        reactionList: res,
      };
      localStorage.setItem('myReactions', JSON.stringify(myReactionsInfo));
    });
  }

  handleReply(id: string, ids: number, idTarget?: string, nameTarget?: string, $event?) {
    this.commentData.forEach((value) => {
      if (value.commentData.id === id) {
        document.getElementById('comment' + ids)?.classList.remove('hidden');
        document.getElementById('comment' + ids)?.classList.add('visibility-visible');
        if (idTarget && nameTarget) {
          this.getReplyTarget(idTarget!, nameTarget!);
        } else {
          this.getReplyTarget($event?.id!, $event?.created_by?.full_name!);
        }
      }
    });
  }

  handleUnReply(id: string, ids: number) {
    this.commentData.forEach((value) => {
      if (value.commentData.id === id) {
        document.getElementById('comment' + ids)?.classList.remove('visibility-visible');
        document.getElementById('comment' + ids)?.classList.add('hidden');
      }
    });
  }

  getReplyTarget(id: string, name: string) {
    this.replyTarget = { id: id, full_name: name };
  }

  loadMoreComment() {
    this.pageComment++;
    if (this.pageComment <= Number((this.totalComment.length / 3).toFixed(1).slice(0, 1))) {
      this.getApiService
        .getComment(this.tourId, this.pageComment, 3, 'TIME_DESC')
        .subscribe((res) => {
          this.displayComment = [...this.displayComment, ...res?.data!];
        });
    }
  }

  getHeaderIndex() {
    return this.activeHeaderIndex;
  }

  setBookmark(params: string) {
    let bloomBookmarkString = localStorage?.getItem('bloomBookmarkString')!;
    if (bloomBookmarkString) {
      let bloomString = BloomFilter.load(bloomBookmarkString);
      if (bloomString.has(params)) {
        this.bookmarkControllerService.bookmarksObjectIdIsExistedGet(params).subscribe((value) => {
          if (value.data?.is_existed) {
            this.isBookmarked = true;
          } else {
            this.isBookmarked = false;
          }
        });
      } else {
        this.isBookmarked = false;
      }
    }
  }

  loadMoreReviews() {
    let newReviews = this.reviews?.length + 3;
    if (newReviews > this.reviewTourOTA?.length) {
      newReviews = this.reviewTourOTA?.length;
      this.disableButtonViewMore = true;
    }
    this.reviews = this.reviewTourOTA.slice(0, newReviews);
  }

  convertArray(data: string[]) {
    return Array.from(data);
  }

  getPreviewData() {
    this.locationService.tourData$.pipe(debounceTime(200)).subscribe((val) => {
      this.tourDetails = val as TourDetailLangDto;
      this.title = this.tourDetails.title as string;
      this.categories = getValues(this.tourDetails.adventure_styles as any);
      this.hashtags = getValues(this.tourDetails.hashtags as any);
      this.highlights = this.tourDetails.highlight!;
      this.itineraries = this.tourDetails.itineraries?.sort((a, b) => {
        return Number(a.day_no) - Number(b.day_no);
      }) as ItineraryDto[];
      this.total_days = this.tourDetails.total_days as number;
      this.tourType = this.tourDetails.tour_type as string;
      this.isCommercialTour = this.tourType === 'Commercial' ? true : false;
      this.images = this.getAllGalleries(this.tourDetails);
      this.price = this.calculateTotalAmount(this.tourDetails);
      this.tourType = this.tourDetails?.tour_type as string;
      this.cdr.detectChanges();
    });
  }

  getAllGalleries(formData: TourDetailLangDto): Media[] {
    const galleries: Media[] = [];
    if (formData.galleries) {
      formData.galleries?.forEach((gallery, index) => {
        if (gallery.id) {
          galleries.push(gallery);
        }
      });
    }
    if (formData.itineraries) {
      formData.itineraries
        ?.sort((a, b) => Number(a.day_no) - Number(b.day_no))
        .forEach((itinerary) => {
          itinerary.place_visits
            ?.sort((a, b) => Number(a.order) - Number(b.order))
            ?.forEach((place) => {
              if (place.galleries) {
                place.galleries.forEach((img) => {
                  if (img.id) {
                    galleries.push(img);
                  }
                });
              }
            });
        });
    }
    return galleries;
  }

  getAllGalleriesPath(medias: Media[]): string[] {
    return medias.map((item: Media) =>
      item.type?.includes('video')
        ? getMediaPath(item.id as string, 'video')
        : getMediaPath(item.id as string, 'image')
    );
  }

  calculateTotalAmount(formData: TourDetailLangDto): number {
    let total = 0;
    this.expenses = [];

    if (formData && formData.itineraries) {
      let startDate = new Date(formData.start_date || Date.now());
      formData.itineraries.forEach((itinerary) => {
        itinerary.place_visits?.forEach((place) => {
          if (place.expense && place.expense.amount) {
            if (place.expense?.currency) {
              this.expenseCurrency = place.expense.currency;
            }
            let date = this.addDaysToDate(startDate, (itinerary.day_no as number) - 1);
            total += place.expense.amount;
            this.expenses.push(<IExpenseItem>{
              name: place?.name || 'Name missing',
              amount: place.expense.amount || 0,
              currency: place?.expense.currency || 'USD',
              date: date || new Date(),
              category: place?.category || [],
              price_usd: place?.expense.price_usd || 0,
            });
          }
        });
      });
    }
    return total;
  }

  addDaysToDate(startDate: Date, numberOfDays: number): Date {
    const newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + numberOfDays);
    return newDate;
  }

  showViewDetailPage() {
    this.router.navigate([`${environment.prefix}/${AppRoutes.TOUR_DETAIL}`], {
      queryParams: { id: this.tourId },
    });
  }

  @ViewChild(ShareTourComponent) shareTourComponent: ShareTourComponent;
  shareTourLink: string;
  isShowShareDialog: boolean;

  handleButtonTour(tourType: string, tourId: string) {
    if (tourType === 'Commercial') {
      this.router.navigate([`${environment.prefix}/${AppRoutes.TOUR_DETAIL}`], {
        queryParams: {
          id: tourId,
        },
      });
    } else {
      this.shareTourLink =
        window.location.protocol +
        '//' +
        window.location.host +
        '/' +
        `${environment.prefix}/${AppRoutes.TOUR_DETAIL}/${tourId}`;
      this.shareTourComponent.isShowDialog = true;
      this.isShowShareDialog = true;
    }
  }

  getCurrentAdvs(adventure_style: string) {
    const currentAdvs = this.filterService.filterSubject.value.adventure_styles ?? [];
    if (currentAdvs?.includes(adventure_style)) {
      return [...currentAdvs].toString().replaceAll(',', ';');
    }
    return [...currentAdvs, adventure_style].toString().replaceAll(',', ';');
  }
  // handleFilterByAdventureStyle(adventure_style: string) {
  //   const currentAdvs = this.filterService.filterSubject.value.adventure_styles ?? [];
  //   if (currentAdvs?.includes(adventure_style)) return;
  //   this.router.navigate([], {
  //     relativeTo: this.activatedRoute,
  //     queryParams: {
  //       [FilterTypes.ADVENTURE_STYLE]: [...currentAdvs, adventure_style]
  //         .toString()
  //         .replaceAll(',', ';'),
  //       // quickViewFilter: true,
  //     },
  //     queryParamsHandling: 'merge',
  //   });
  // }

  // handleFilterByHashtag(hashtag: string) {
  //   this.router.navigate([], {
  //     relativeTo: this.activatedRoute,
  //     queryParams: {
  //       [FilterTypes.HASHTAG]: hashtag,
  //       [FilterTypes.LOCATION]: null,
  //       destinationId: null,
  //       [FilterTypes.PLACE]: null,
  //       [FilterTypes.ADVENTURE_STYLE]: null,
  //       [FilterTypes.SPOTLIGHT]: null,
  //       [FilterTypes.PRICE]: null,
  //       [FilterTypes.TOTAL_DAY]: null,
  //       [FilterTypes.TOUR_TYPE]: null,
  //       [FilterTypes.START_DATE]: null,
  //     },
  //     queryParamsHandling: 'merge',
  //   });
  // }

  // Handle view more
  isExtend: boolean = false;
  toggleViewMore() {
    this.isExtend = !this.isExtend;
  }

  getTourid(tourId: string) {
    this.tourId = tourId;
  }

  handleFavouriteTour() {
    this.bookmarkControllerService.bookmarksObjectIdIsExistedGet(this.tourId).subscribe((value) => {
      if (value?.data?.is_existed!) {
        this.isBookmarked = false;
        this.getBloomBookmarkService.handleUnBookmark('TOURS', this.tourId);
      } else {
        this.isBookmarked = true;
        this.getBloomBookmarkService.handleBookmark('TOURS', this.tourId);
      }
    });
  }

  handleLocationModal() {
    this.locationService.setLocationData(null);
  }

  getReportCategories() {
    this.reportControllerService.getListCategory().subscribe({
      next: (data) => {
        this.reportCategories = data.data;
      },
      error: (error) => {
        this.reportCategories = [];
        throw new Error(error);
      },
    });
  }

  openReportDialog(review: Review) {
    this.targedReview = review;
    this.targedReviewId = review.id;
    this.isOpenReportDialog = true;
  }

  closeReportDialog() {
    this.isOpenReportDialog = false;
    this.targedReviewId = null;
  }

  confirmReport(event: any) {
    const body: IReportDTO = {
      object_id: this.targedReviewId ?? '',
      object_parent_id: this.tourId,
      category_type: event.category_type,
      object_type: 'TOURRATING',
      object_parent_type: 'TOUR',
      report_reason: event.report_reason || '',
      owner_id: this.targedReview?.created_by?.id,
      key_search: this.targedReview?.created_by?.username,
      url_detail: '',
      city_code: this.targedReview?.created_by?.city_code,
    };

    this.reportService.postReport(body).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastService.toast(
            'success',
            this.translationService.getTranslationAsync(
              'page.tour_detail.customer_review.report_review_successfully'
            )
          );
          this.closeReportDialog();
        } else
          this.toastService.toast(
            'error',
            this.translationService.getTranslationAsync(
              'page.tour_detail.customer_review.report_review_failed'
            )
          );
      },
      error: (error) => {
        this.toastService.toast(
          'error',
          this.translationService.getTranslationAsync(
            'page.tour_detail.customer_review.report_review_failed'
          )
        );
        throw new Error(error);
      },
    });
  }

  getReviewTourOTA(id: string) {
    this.otaId = id;
  }

  onTabChanged(event: MenuItem | any) {
    if (this.currentTab?.label !== event?.label) {
      this.previousLabelTabChange = this.currentTab?.label ?? '';
      this.currentTab = event;
      this.tabChange$.next(this.currentTab);
    }
  }

  handleTabChangedAsync() {
    this.tabChange$.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((value) => {
      if (
        value.label !== this.translateAllDays &&
        (this.itineraryService.markersSubject.getValue().length === 0 ||
          this.previousLabelTabChange === this.translateAllDays)
      ) {
        this.previousLabelTabChange = value?.label ?? '';
        this.tabChange$.next(this.currentTab);
      } else {
        this.onShowMarkersByCurrentTab(this.currentTab);
        this.cdr.detectChanges();
      }
    });
  }
  typeMap: 'google' | 'osm' = 'osm';
  onShowDialogPreview() {
    this.typeMap = this.mapService.typeMap;
    // // this.mapService.setMap(undefined);
    this.mapService.typeMap = 'osm';
    this.currentMap = this.mapService.getMap();
    this.centerPlace = this.getCenterPlaceOnMap(this.itineraries);
  }

  getCenterPlaceOnMap(itineraries: ItineraryDto[]): L.LatLng {
    const places = itineraries
      ?.sort((a, b) => (a.day_no! < b.day_no! ? -1 : 1))
      .map((item) => {
        const sortedPlaces = item.place_visits?.sort((a, b) => (a.order! < b.order! ? -1 : 1))!;
        return sortedPlaces.map((place): IExtendPlaceVisit => ({ ...place, day_no: item.day_no }));
      })
      .flat();
    const lstLatLng = places
      .map((x) => new L.LatLng(x.latitude!, x.longitude!))
      .filter((x) => !(x.lat === 0 && x.lng === 0));
    return latLngBounds(lstLatLng).getCenter();
  }

  onCloseDialogPreview() {
    this.mapService.typeMap = this.typeMap;
    this.mapService.setMap(this.currentMap);
  }

  onCloseTourGuide() {
    setTimeout(() => {
      // this.onShowMarkersByCurrentTab(this.currentTab);
      this.cdr.detectChanges();
    }, 200);
  }

  async onShowMarkersByCurrentTab(currentTab: MenuItem) {
    this.handleLocationModal();
    this.itineraryService.clearAllMarkers();
    this.itineraryService.isShowMarkersCalled = false;
    this.itineraryService.onRouting = false;
    const label = currentTab.label!;
    const numberPattern = /\d+/;
    const match = label!.match(numberPattern);
    const currentHlMarker = this.mapService.getCurHlMarker();
    if (currentHlMarker) {
      this.mapService.removeMarker(currentHlMarker!);
    }
    if (match) {
      const number = parseInt(match[0], 10);
      await this.itineraryService.showMarkers(
        [this.itineraries[number - 1]],
        true,
        true,
        undefined,
        this.itineraryService.iShowQuickView
      );
      this.itineraryService.getLatLngList([this.itineraries[number - 1]], true);
    } else {
      await this.itineraryService.showMarkers(
        this.itineraries,
        true,
        true,
        undefined,
        this.itineraryService.iShowQuickView
      );
      this.itineraryService.getLatLngList(this.itineraries, true);
    }
  }

  handlePlayPreview(template: TemplateRef<HTMLElement>) {
    this.onShowDialogPreview();
    setTimeout(() => {
      this.hostView.open(template);
      this.itineraryService.setPlaceSaved(false);
    }, 100);
  }
}
