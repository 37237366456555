import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TOAST_SEVERITY } from '@shared/enum';
import { MessageService } from 'primeng/api';
import { TranslationService } from '@core/services';

import { EnsureLoadedOnce } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ToastService extends EnsureLoadedOnce {
  constructor(
    @Optional() @SkipSelf() parent: ToastService,
    public messageService: MessageService,
    private translationService: TranslationService
  ) {
    super(parent);
  }

  default(detail: string, life?: number, clickCb?: Function, summary?: string): void {
    this.toast(TOAST_SEVERITY.DEFAULT, summary, detail, life, clickCb);
  }

  primary(detail: string, life?: number, clickCb?: Function, summary?: string): void {
    this.toast(TOAST_SEVERITY.PRIMARY, summary, detail, life, clickCb);
  }

  success(
    detail: string,
    life?: number,
    clickCb?: Function,
    summary?: string,
    icon?: string
  ): void {
    this.toast(TOAST_SEVERITY.SUCCESS, summary, detail, life, clickCb, icon);
  }

  info(detail: string, life?: number, clickCb?: Function, summary?: string): void {
    this.toast(TOAST_SEVERITY.INFO, summary, detail, life, clickCb);
  }

  warn(detail: string, life?: number, clickCb?: Function, summary?: string): void {
    this.toast(TOAST_SEVERITY.WARN, summary, detail, life, clickCb);
  }

  error(detail: string, life?: number, clickCb?: Function, summary?: string): void {
    this.toast(TOAST_SEVERITY.ERROR, summary, detail, life, clickCb);
  }

  handleErrResponseMsg(err) {
    if (!err.status)
      this.error(this.translationService.getTranslation('common.error.api_error_message'));
    switch (err.status) {
      case 400:
        this.error(this.translationService.getTranslation('common.error.api_error_message'));
        break;
      case 401:
        this.error(this.translationService.getTranslation('common.error.api_error_message'));
        break;
      case 403:
        this.error(
          this.translationService.getTranslation('common.error.api_error_forbidden_message')
        );
        break;
      case 404:
        this.error(this.translationService.getTranslation('common.error.api_error_message'));
        break;
      default:
        this.error(err.message);
    }
  }

  toast(
    severity: string,
    summary?: string,
    detail?: string,
    life: number = 3000,
    clickCb?: Function,
    icon?: string
  ) {
    return this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: life,
      data: { clickCb },
      closeIcon: 'sctr-icon-x-close',
      icon: icon,
    });
  }
}
