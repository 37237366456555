<div class="t-review-panel">
  <div class="flex items-center">
    <strong class="total-rating">{{ getRatingString(rating) }}</strong>
    <span class="total-reviews">
      {{ getTranslationAsync('pluralization.total_reviews', { count: total_reviews || 0 }) }}
    </span>
  </div>
  <ng-container *ngIf="total_reviews; else noReviews">
    <div class="w-full flex flex-col gap-3">
      <ng-container *ngFor="let review of reviews">
        <div class="border border-solid border-gray-200 rounded-lg">
          <app-review-item
            (emitOpenReportDialog)="emitOpenReportDialog.emit(review)"
            [review]="review"
            [appproval]="true"
          ></app-review-item>
        </div>
      </ng-container>
    </div>
    <button *ngIf="!disable" class="view-more-btn" (click)="handleLoadMoreReviews.emit($event)">
      {{ 'common.component.review_panel.view_more' | translateAsync }}
    </button>
  </ng-container>
</div>

<ng-template #noReviews>
  <p class="font-Inter text-md text-gray-700">
    {{ 'common.component.review_panel.not_have_reviews_yet' | translateAsync }}
  </p>
</ng-template>
