import { Injectable } from '@angular/core';
import { PlaceDetailDTO } from '@assistant/angular-map-location-service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TourListService {
  private searchLocation: ReplaySubject<PlaceDetailDTO[]> = new ReplaySubject<PlaceDetailDTO[]>(15);
  public openBookmark = new BehaviorSubject<{
    open: boolean;
    type: string;
    id: string;
    name: string;
  }>({ open: false, type: '', id: '', name: '' });
  public openToastBookmark = new BehaviorSubject<{
    open: boolean;
    type: string;
    summary: string;
    change: boolean;
    bookmarkType: string;
    id: string;
  }>({ open: false, type: '', summary: '', change: false, bookmarkType: '', id: '' });
  public handleModalShareTour = new BehaviorSubject(false);
  constructor() {}
  get placeSearchData$() {
    return this.searchLocation.asObservable();
  }
  emit(data: PlaceDetailDTO[]) {
    this.searchLocation.next(data);
  }
}
