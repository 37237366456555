import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-quickview-skeleton',
  standalone: true,
  imports: [CommonModule, SkeletonModule],
  templateUrl: './quickview-skeleton.component.html',
  styleUrls: ['./quickview-skeleton.component.scss'],
})
export class QuickviewSkeletonComponent {}
