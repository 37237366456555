import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@core/services';

@Pipe({
  name: 'translateAsync',
  standalone: true,
  pure: false,
})
export class TranslateAsyncPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}
  transform(key: string, interpolateParams?: Object): string {
    return this.translationService.getTranslationAsync(key, interpolateParams);
  }
  // transform(key: string, interpolateParams?: Object): string {
  //   // Gọi service để lấy bản dịch
  //   this.translationService.getTranslationAsyncOb(key, interpolateParams);

  //   // Trả về giá trị dịch từ BehaviorSubject
  //   return this.translationService.getTranslationOB().getValue();
  // }
}
