<div id="media" class="w-full h-full overflow-hidden rounded-lg" (mouseup)="handleClick($event)">
  <audio #music [src]="'/assets/audios/' + selectedMusic" loop></audio>
  <gallery
    #gallery
    class="h-full"
    [items]="galleries"
    [playerInterval]="transitionTime[slideSpeed]"
    [nav]="false"
    [loop]="false"
    [dots]="false"
    [thumb]="false"
    [counter]="false"
    [imageSize]="'cover'"
    [slidingDisabled]="false"
    (indexChange)="handleIndexChange($event)"
  >
    <ng-container *galleryItemDef="let item; let type = type; let isLast = last">
      <ng-container *ngIf="type === 'custom-video'">
        <video
          #video
          [muted]="mute"
          class="video"
          controls
          disablePictureInPicture
          disableRemotePlayback
          controlsList="nofullscreen nodownload"
          (ended)="handleVideoEnd(isLast!, $event)"
          (pause)="handlePauseVideo()"
          (play)="_play = true; isPaused = false"
          (mouseover)="_play && isVideoShow && (shiftDescUp = true)"
          (mouseout)="_play && isVideoShow && (shiftDescUp = false)"
        >
          <source [src]="item?.src" />
        </video>
      </ng-container>
    </ng-container>
  </gallery>
  <button
    type="button"
    *ngIf="!_play"
    class="absolute z-20 flex items-center justify-center -translate-x-1/2 -translate-y-1/2 cursor-pointer top-1/2 left-1/2"
  >
    <i class="block sctr-icon-solid-play-circle text-5xl/none"></i>
  </button>
  <div
    class="absolute z-20 flex"
    [ngClass]="[isFullScreen ? 'top-6 right-6 gap-x-3' : 'top-2 right-3 gap-x-1']"
  >
    <div
      (mouseup)="toggleMusicVolume(!mute, $event)"
      class="flex items-center justify-center text-white rounded-full w-7 h-7 text-md bg-[#10182866] hover:bg-[#10182890]"
    >
      <i *ngIf="!mute">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
          />
        </svg>
      </i>
      <i *ngIf="mute">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
          />
        </svg>
      </i>
    </div>
    <p-toggleButton
      (mouseup)="$event.stopPropagation()"
      styleClass="!text-white justify-center rounded-full w-7 h-7 p-1.5 whitespace-nowrap text-md/none cus:shadow-none"
      offLabel="1x"
      [onLabel]="'2x'"
      (onChange)="handleToggleSlideSpeedBtn($event)"
    ></p-toggleButton>
    <div
      (mouseup)="toggleScreen.emit((isFullScreen = !isFullScreen)); $event.stopPropagation()"
      class="flex items-center justify-center w-7 h-7 bg-[#10182866] rounded-full hover:bg-[#10182890] cursor-pointer"
    >
      <i
        class="block text-white"
        [ngClass]="isFullScreen ? 'sctr-icon-minimize-01' : 'sctr-icon-maximize-01'"
      ></i>
    </div>
  </div>
  <ng-container *ngIf="place">
    <div
      class="absolute text-white z-10 px-1.5 py-1 bg-[#10182866] flex gap-1 rounded-lg items-center"
      [ngClass]="[
        isFullScreen
          ? 'top-6 left-6 text-[1.5rem] leading-8 font-semibold max-w-[55%]'
          : 'top-2 left-3 font-medium text-base max-w-[60%]'
      ]"
    >
      <i class="sctr-icon-marker-pin-01"></i>
      <span>{{ place!.name }}</span>
    </div>
    <div
      class="absolute text-white z-10 px-1.5 py-1 bg-[#10182866] rounded-lg overflow-y-hidden"
      [ngClass]="[
        isFullScreen
          ? 'bottom-[17rem] left-[20%] right-[20%] text-xl font-semibold h-28'
          : 'bottom-2 left-3 right-3 font-medium text-md h-20',
        browserName === 'firefox'
          ? '-translate-y-8'
          : (isVideoShow && !_play) || shiftDescUp
          ? '-translate-y-16'
          : 'translate-y-0',
        'transition-transform'
      ]"
      (mouseover)="_play && isVideoShow && (shiftDescUp = true)"
      (mouseout)="_play && isVideoShow && (shiftDescUp = false)"
    >
      <p
        [@scrollTextFromBottom]="scrollTextState"
        class="font-normal text-center"
        [innerHTML]="place!.description"
      ></p>
    </div>

    <i
      *ngIf="!isLast"
      (mouseup)="handleSkipPlace(SKIP_MEDIA.NEXT, $event)"
      aria-label="Next"
      role="button"
      class="g-nav-next absolute right-4 top-[50%]"
      ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"
        ></path>
      </svg>
    </i>
    <i
      *ngIf="!isFirst"
      (mouseup)="handleSkipPlace(SKIP_MEDIA.PREVIOUS, $event)"
      aria-label="Previous"
      role="button"
      class="g-nav-prev absolute left-4 top-[50%]"
      ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"
        ></path>
      </svg>
    </i>
  </ng-container>
</div>
