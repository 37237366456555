<!-- <p-toast class="t-toast"></p-toast> -->
<p-dialog
  [(visible)]="visible"
  [style]="{ width: '708px', 'max-height': '708px' }"
  [position]="position"
  [modal]="true"
  class="t-custom-dialog"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onDialogHide()"
  (onShow)="onShowDialog()"
>
  <ng-template pTemplate="header" class="relative">
    <div class="w-full flex items-center gap-[18px]">
      <span class="text-xl font-semibold text-gray-900">
        {{ 'common.component.post_dialog.create_post' | translateAsync | titlecase }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <form [formGroup]="postForm" (ngSubmit)="onSubmit()" id="postForm">
      <div class="rounded-lg border p-4 bg-white">
        <div class="gap-2 flex justify-between w-full">
          <!-- Avatar -->
          <div
            class="md:w-10 md:h-10 w-6 h-6 rounded-full border border-[#FF953A] p-0.25 flex justify-center align-items-center shrink-0"
          >
            <div class="p-0.5 relative">
              <div class="absolute top-0 right-0.5 p-[1px] bg-white rounded-full">
                <img src="assets/svg/crown_icon.svg" />
              </div>
              <img [src]="avatar" class="rounded-full w-full h-full bg-cover" />
            </div>
          </div>
          <textarea
            class="font-inter text-md w-full focus:outline-none self-start h-24 resize-none ml-2 bg-white"
            formControlName="content"
            id="content"
            [placeholder]="'common.component.post_dialog.textarea_placeholder' | translateAsync"
          ></textarea>
        </div>
        <div class="relative flex justify-end">
          <i
            class="w-fit sctr-icon-face-smile flex justify-end hover:text-primary-400 cursor-pointer"
            (click)="onOffEmoji = !onOffEmoji"
          ></i>
          <emoji-mart
            *ngIf="onOffEmoji"
            set="twitter"
            class="t-emoji"
            (emojiSelect)="addEmoji($event)"
          ></emoji-mart>
        </div>
      </div>
      <div *ngIf="tourData$" class="mt-4 flex align-items-center border rounded-md p-4 bg-white">
        <div class="flex flex-col gap-3 w-full">
          <div class="t-header flex flex-row gap-3 items-center">
            <div
              class="md:w-10 md:h-10 w-6 h-6 rounded-full border border-[#FF953A] p-0.25 flex justify-center align-items-center shrink-0"
            >
              <div class="p-0.5 w-full h-full">
                <img [src]="imageList[0]" class="rounded-full w-full h-full bg-cover" />
              </div>
            </div>
            <div class="flex flex-col gap-1">
              <h3 class="text-gray-700 font-semibold line-clamp-1">
                {{ title }}
              </h3>
              <p class="text-md text-gray-700">
                {{ location }}
              </p>
            </div>
          </div>
          <div
            class="text-gray-500 text-md font-medium hidden md:line-clamp-3"
            [innerHTML]="highlight"
          ></div>
          <div class="rounded-lg overflow-hidden">
            <limit-image-box [images]="imageList" [limit]="3"></limit-image-box>
          </div>
          <div
            class="flex flex-row justify-end items-center text-orange-600"
            *ngIf="tourData$.price"
          >
            <!-- <span class="text-sm">{{ 'USD' | currencySymbol }}</span> -->
            <!-- <span class="text-xl font-medium">{{ tourData$.price | currencyConversion }}</span> -->
            <span
                  *ngIf="
                    tourData$.tour_type === 'Commercial' || tourData$.currency !== profileCurrency;
                    else estimatedPriceTempl
                  "
                >
                  {{ tourData$.price | currencyConversion }}
                </span>
                <ng-template #estimatedPriceTempl>
                  <span> {{ tourData$.price_canal || 0 | currency: tourData$.currency }}</span>
                </ng-template>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 align-items-center justify-end">
      <p-dropdown
        [options]="myOptions"
        optionLabel="label"
        class="t-custom-dropdown-post"
        (onChange)="onChangePriority($event.value.value)"
      >
        <ng-template let-option pTemplate="selectedItem">
          <div class="flex items-center text-md p-0 gap-2">
            <i [ngClass]="option.image" class="text-lg"></i>
            <span class="font-inter font-medium">
              {{ option.label }}
            </span>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div class="flex items-center text-md gap-2">
            <i [ngClass]="option.image" class="text-lg"></i>
            <span class="font-inter">
              {{ option.label }}
            </span>
          </div>
        </ng-template>
      </p-dropdown>
      <button
        class="h-10 !mr-1 text-white font-medium py-2 px-8 rounded-lg {{
          shouldDisableButton()
            ? '!bg-blue-200 cursor-not-allowed'
            : 'bg-blue-600 hover:bg-blue-700'
        }}"
        [disabled]="shouldDisableButton()"
        type="submit"
        form="postForm"
      >
        {{ 'common.component.post_dialog.post' | translateAsync }}
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast class="t-toast"></p-toast>
