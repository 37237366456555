import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CtaSigninService {
  private isShowCTASubject = new BehaviorSubject<boolean | null>(null);
  private isFirstCheckToken = new BehaviorSubject<boolean>(true);
  isShowCTA$: Observable<boolean | null> = this.isShowCTASubject.asObservable();
  private isLogin = new BehaviorSubject<boolean | null>(null);
  isLogin$: Observable<boolean | null> = this.isLogin.asObservable();
  constructor() {}

  hideCTA(): void {
    this.isShowCTASubject.next(false);
  }

  showCTA(): void {
    this.isShowCTASubject.next(true);
  }

  setCTAByAccessToken() {
    let token = localStorage.getItem(environment.TOKEN_KEY);
    if (token && token !== null && token !== undefined) {
      this.isShowCTASubject.next(false);
      this.isLogin.next(true);
    } else if (
      !token &&
      this.isShowCTASubject.getValue() === null &&
      this.isFirstCheckToken.getValue()
    ) {
      this.isFirstCheckToken.next(false);
      return;
    } else {
      this.isShowCTASubject.next(true);
      this.isLogin.next(false);
    }
  }

  navigateToSignInPage() {
    if (!this.isLogin.value) {
      window.location.href = `${environment.SOCIAL_APP_URL}/login?r=${window.location.origin}`;
      return true;
    }
    return false;
  }
}
