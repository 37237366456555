import { Pipe, PipeTransform } from '@angular/core';

export const categoryIconClassMapper = {
  restaurant: 'sctr-icon-trophy-02',
  bakery: 'sctr-icon-trophy-02',
  bar: 'sctr-icon-trophy-02',
  'butcher shop': 'sctr-icon-trophy-02',
  'coffee shop': 'sctr-icon-trophy-02',
  farm: 'sctr-icon-trophy-02',
  'ice cream store': 'sctr-icon-trophy-02',
  'night club': 'sctr-icon-trophy-02',
  'soup kitchen': 'sctr-icon-trophy-02',
  store: 'sctr-icon-shopping-bag-01',
  'auto parts store': 'sctr-icon-shopping-bag-01',
  'book store': 'sctr-icon-shopping-bag-01',
  'building materials store': 'sctr-icon-shopping-bag-01',
  'care dealer': 'sctr-icon-shopping-bag-01',
  'cell phone store': 'sctr-icon-shopping-bag-01',
  'clothing store': 'sctr-icon-shopping-bag-01',
  'computer store': 'sctr-icon-shopping-bag-01',
  'cosmetics store': 'sctr-icon-shopping-bag-01',
  'electronics store': 'sctr-icon-shopping-bag-01',
  florist: 'sctr-icon-shopping-bag-01',
  'furniture store': 'sctr-icon-shopping-bag-01',
  'general store': 'sctr-icon-shopping-bag-01',
  'gift store': 'sctr-icon-shopping-bag-01',
  'grocery store': 'sctr-icon-shopping-bag-01',
  'hardware store': 'sctr-icon-shopping-bag-01',
  'home goods store': 'sctr-icon-shopping-bag-01',
  'jewelry store': 'sctr-icon-shopping-bag-01',
  market: 'sctr-icon-shopping-bag-01',
  pharmacy: 'sctr-icon-shopping-bag-01',
  'shoe store': 'sctr-icon-shopping-bag-01',
  'shopping mall': 'sctr-icon-shopping-bag-01',
  supermarket: 'sctr-icon-shopping-bag-01',
  'tire shop': 'sctr-icon-shopping-bag-01',
  'tobacco shop': 'sctr-icon-shopping-bag-01',
  "women's clothing store": 'sctr-icon-shopping-bag-01',
  'medical services': 'sctr-icon-medical-cross',
  atm: 'sctr-icon-medical-cross',
  'autor repair shop': 'sctr-icon-medical-cross',
  bank: 'sctr-icon-medical-cross',
  'barber shop': 'sctr-icon-medical-cross',
  'beauty salon': 'sctr-icon-medical-cross',
  'car rental agency': 'sctr-icon-medical-cross',
  'car wash': 'sctr-icon-medical-cross',
  'electric vehicle charging station': 'sctr-icon-medical-cross',
  'gas station': 'sctr-icon-medical-cross',
  'hair salon': 'sctr-icon-medical-cross',
  'home builder': 'sctr-icon-medical-cross',
  'insurance agency': 'sctr-icon-medical-cross',
  'internet café': 'sctr-icon-medical-cross',
  'laundry service': 'sctr-icon-medical-cross',
  'mobile phone repair shop': 'sctr-icon-medical-cross',
  'motorcycle repair shop': 'sctr-icon-medical-cross',
  'nail salon': 'sctr-icon-medical-cross',
  'parking slot': 'sctr-icon-medical-cross',
  'photography studio': 'sctr-icon-medical-cross',
  'post office': 'sctr-icon-medical-cross',
  'public bathroom': 'sctr-icon-medical-cross',
  'real estate agency': 'sctr-icon-medical-cross',
  tailor: 'sctr-icon-medical-cross',
  'transportation service': 'sctr-icon-medical-cross',
  'travel agency': 'sctr-icon-medical-cross',
  hotel: 'sctr-icon-building-01',
  'bed & breakfast': 'sctr-icon-building-01',
  campground: 'sctr-icon-building-01',
  'camping cabin': 'sctr-icon-building-01',
  'holiday apartment rental': 'sctr-icon-building-01',
  homestay: 'sctr-icon-building-01',
  hostel: 'sctr-icon-building-01',
  motel: 'sctr-icon-building-01',
  villa: 'sctr-icon-building-01',
  'place of worship': 'sctr-icon-activity-heart',
  "baha'I house of worship": 'sctr-icon-activity-heart',
  'buddhist temple': 'sctr-icon-activity-heart',
  church: 'sctr-icon-activity-heart',
  gurudwara: 'sctr-icon-activity-heart',
  'hindu temple': 'sctr-icon-activity-heart',
  'jain temple': 'sctr-icon-activity-heart',
  mosque: 'sctr-icon-activity-heart',
  pagoda: 'sctr-icon-activity-heart',
  'parsi temple': 'sctr-icon-activity-heart',
  'religious destination': 'sctr-icon-activity-heart',
  'shinto shrine': 'sctr-icon-activity-heart',
  shrine: 'sctr-icon-activity-heart',
  'spiritist center': 'sctr-icon-activity-heart',
  synagogue: 'sctr-icon-activity-heart',
  'association or organization': 'sctr-icon-briefcase-01',
  'community center': 'sctr-icon-briefcase-01',
  'construction company': 'sctr-icon-briefcase-01',
  'corporate office': 'sctr-icon-briefcase-01',
  'government office': 'sctr-icon-briefcase-01',
  manufacture: 'sctr-icon-briefcase-01',
  'non-profit organization': 'sctr-icon-briefcase-01',
  warehouse: 'sctr-icon-briefcase-01',
  wholesaler: 'sctr-icon-briefcase-01',
  'apartment building': 'sctr-icon-building-07',
  'apartment complex': 'sctr-icon-building-07',
  'condominium complex': 'sctr-icon-building-07',
  'housing complex': 'sctr-icon-building-07',
  'housing development': 'sctr-icon-building-07',
  'housing society': 'sctr-icon-building-07',
  college: 'sctr-icon-book-open-01',
  'driving school': 'sctr-icon-book-open-01',
  'education center': 'sctr-icon-book-open-01',
  'elementary school': 'sctr-icon-book-open-01',
  'high school': 'sctr-icon-book-open-01',
  kindergarten: 'sctr-icon-book-open-01',
  'middle school': 'sctr-icon-book-open-01',
  preschool: 'sctr-icon-book-open-01',
  'primary school': 'sctr-icon-book-open-01',
  school: 'sctr-icon-book-open-01',
  university: 'sctr-icon-book-open-01',
};

@Pipe({
  name: 'categoryToIcon',
  standalone: true,
})
export class CategoryToIconPipe implements PipeTransform {
  transform(category: string) {
    if (!category) return 'sctr-icon-marker-pin-01';
    let cat = category.toLowerCase();
    return (
      categoryIconClassMapper[cat as keyof typeof categoryIconClassMapper] ||
      'sctr-icon-marker-pin-01'
    );
  }
}
