import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpenMapService {
  constructor() {}
  open: boolean = false;
  isVisible: boolean = true;
  public scrollMap = new BehaviorSubject(false);
  show() {
    this.open = true;
    this.isVisible = true;
    this.scrollMap.next(true);
  }
  hide() {
    this.open = false;
    this.isVisible = false;
    this.scrollMap.next(false);
  }
}
