import { Injectable } from '@angular/core';
import { IAutoTranslate, autoTranslateDb } from '@shared/indexeddb-databases/translate-db';
import { postTranslate } from './get-api.service';
import { TranslateHubService } from './translate-hub.service';
@Injectable({
  providedIn: 'root',
})
export class AutoTranslateService {
  lang: string = localStorage.getItem('lang') || 'vi';

  constructor() {}

  async addtranslated(translateInfo: IAutoTranslate[], modify: boolean = false) {
    await autoTranslateDb[this.lang].bulkPut(translateInfo);
  }

  getTranslatedByKey(key: string) {
    return autoTranslateDb[this.lang].get(key);
  }
  async getTranslationNotIncludeDB(textList: postTranslate[]) {
    const records = await autoTranslateDb[this.lang].toArray();

    const newRecords = textList.filter((obj1) => !records.some((obj2) => obj2.original === obj1.q));
    return newRecords;
  }

  async resetDb() {
    await autoTranslateDb.resetDB();
  }
}
