import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router) {}

  public initialize() {
    // dynamically add analytics scripts to document head
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.META_CODE}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.META_CODE}')`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  // track visited routes
  // private onRouteChange() {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       gtag('config', environment.META_CODE, {
  //         page_path: event.urlAfterRedirects,
  //       });
  //     }
  //   });
  // }

  // use gtag.js to send Google Analytics Events
  // public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
  //   gtag('event', action, {
  //     ...(eventCategory && { event_category: eventCategory }),
  //     ...(eventLabel && { event_label: eventLabel }),
  //     ...(value && { value: value }),
  //   });
  // }
  public pageViewEvent(contenId: string, contentType: string) {
    gtag('event', 'page_view', {
      page_location: window.location.href,
      page_title: document.title,
      page_path: window.location.pathname,
      content_id: contenId, // Replace with your dynamic ID variable
      content_type: contentType, // Replace with your dynamic content type variable
    });
  }

  public viewItemEvent(
    productId: string,
    productname: string,
    price: number,
    category: string,
    quantity = 1
  ) {
    gtag('event', 'view_item', {
      item_id: productId,
      item_name: productname,
      price: price,
      item_category: category,
      quantity: quantity,
    });
  }

  public searchEvent(searchTerm: string, searchCategory = '') {
    gtag('event', 'search', {
      search_term: searchTerm,
      search_category: searchCategory,
    });
  }

  public viewItemListEvent(
    itemListid: string,
    itemListName: string,
    listItems: { item_id: string; item_name: string; price: number; quantity: number }[]
  ) {
    gtag('event', 'view_item_list', {
      item_list_id: itemListid,
      item_list_name: itemListName,
      items: listItems,
    });
  }

  public purchaseEvent(
    transactionId: string,
    affiliation: string,
    value: number,
    currency: string,
    tax: number,
    coupon: string,
    itemId: string,
    ItemName: string,
    quantity: number
  ) {
    gtag('event', 'purchase', {
      transaction_id: transactionId,
      value: value,
      currency: currency,
      tax: tax,
      coupon: coupon,
      item_id: itemId,
      item_name: ItemName,
      quantity: quantity,
    });
  }
}
