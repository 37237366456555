import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TestingGuard {
  canActivate() {
    if (environment.TESTING_PERMISSION) return true;
    return false;
  }
}
