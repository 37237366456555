import { Injectable } from '@angular/core';
import {
  AdventureStyleControllerService,
  AdventureStyleRelatedTourDto,
} from '@assistant/angular-tour-builder-service';
import { TranslationService } from '@core/services';
import { LOCALSTORAGE_ITEMS } from '@shared/enum';

export interface IAdventureStyleRelatedTourDto extends AdventureStyleRelatedTourDto {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdventureStylesService {
  private adventureStyles: IAdventureStyleRelatedTourDto[];

  constructor(
    private adventureStyleControllerService: AdventureStyleControllerService,
    private translationService: TranslationService
  ) {}

  getAdventureStyles() {
    if (localStorage.getItem(LOCALSTORAGE_ITEMS.ADVENTURE_STYLES)) {
      this.adventureStyles = JSON.parse(localStorage.getItem(LOCALSTORAGE_ITEMS.ADVENTURE_STYLES)!);
    } else {
      this.adventureStyleControllerService.getTopAdventureStyleWithMostRelatedTour(15).subscribe({
        next: (value) => {
          this.adventureStyles = (value.data?.data as IAdventureStyleRelatedTourDto[])?.map(
            (data) => ({
              id: data?.id,
              name: data?.name!
            })
          );
          localStorage.setItem(
            LOCALSTORAGE_ITEMS.ADVENTURE_STYLES,
            JSON.stringify(this.adventureStyles)
          );
        },
        error: () => {
          this.adventureStyles = [];
        },
        complete: () => {
          return this.adventureStyles;
        },
      });
    }
    return this.adventureStyles;
  }
}
