import { MenuItem } from 'primeng/api';
import { AppRoutes } from './routes.config';
import { environment } from '@env/environment';
export const TOP_BAR_MENU: Array<MenuItem> = [
  {
    label: 'Home',
    icon: 'sctr-icon-home-line',
    url: `${environment.SOCIAL_APP_URL}`,
    target: '_self',
  },
  {
    label: 'Booking',
    icon: 'sctr-icon-calendar',
    target: '_self',
    items: [
      {
        icon: 'sctr-icon-umbrella-03 text-palette-purple-600',
        label: 'Tours',
        url: `${environment.TRAVEL_ASSIST_URL}`,
        target: '_self',
      },
      {
        icon: 'sctr-icon-plane text-palette-blue-700',
        label: 'Flights',
        url: `${environment.FLIGHTS_URL}`,
        target: '_self',
      },
      {
        icon: 'sctr-icon-building-01 text-palette-pink-600',
        label: 'Hotel',
        url: `${environment.BOOKING_URL}`,
        target: '_self',
      },
      {
        icon: 'sctr-icon-car text-palette-orange-500',
        label: 'Car rentals',
        url: `${environment.CAR_RENTAL_URL}`,
        target: '_self',
      },
    ],
  },
  {
    label: 'Food',
    icon: 'sctr-icon-shopping-cart-03',
    url: `${environment.ECOMMERCE_FOOD_URL}`,
    target: '_self',
  },
  {
    label: 'Shopping',
    icon: 'sctr-icon-shopping-bag-03',
    url: `${environment.ECOMMERCE_URL}`,
    target: '_self',
  },
];
