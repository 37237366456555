export enum DateTimeFormat {
  DATETIME_SHORT = 'DATETIME_SHORT', // 10/14/1983, 1:30 PM
  DATETIME_SHORT__24H = 'DATETIME_SHORT__24H', // 10/14/1983, 13:30
  DATETIME_MED = 'DATETIME_MED', //	Oct 14, 1983, 1:30 PM
  DATETIME_MED__24H = 'DATETIME_MED__24H', //	Oct 14, 1983, 13:30
  DATETIME_FULL = 'DATETIME_FULL', // October 14, 1983 at 1:30 PM EDT
  DATETIME_FULL__24H = 'DATETIME_FULL__24H', // October 14, 1983 at 13:30 EDT
  DATETIME_HUGE = 'DATETIME_HUGE', // Friday, October 14, 1983 at 1:30 PM Eastern Daylight Time
  DATETIME_SHORT_WITH_SECONDS = 'DATETIME_SHORT_WITH_SECONDS', // 10/14/1983, 1:30:23 PM
  DATETIME_MED_WITH_SECONDS = 'DATETIME_MED_WITH_SECONDS', // Oct 14, 1983, 1:30:23 PM
  DATETIME_FULL_WITH_SECONDS = 'DATETIME_FULL_WITH_SECONDS', //	October 14, 1983 at 1:30:23 PM EDT
  DATETIME_HUGE_WITH_SECONDS = 'DATETIME_HUGE_WITH_SECONDS', // Friday, October 14, 1983 at 1:30:23 PM Eastern Daylight Time,
  OFFSET = 'OFFSET', // DateTime (UTC+07:00)
  OFFSET_AND_ABB = 'OFFSET_AND_ABB', // DateTime (UTC+07:00 GMT+7)
}
