import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { UnexpectedResponseException } from 'pdfjs-dist/types/src/shared/util';
import { Observable } from 'rxjs';

export interface ITimezone {
  id: string;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  tzData = require('@shared/mock/timezones.json');
  tzArr: ITimezone[] = [];
  private timezoneMappingJson = require('@shared/mock/mapping-timezone.json');

  constructor(private http: HttpClient) {}

  getTimezoneData(isOffsetOnly: boolean = false) {
    this.tzArr = [];
    this.tzData.data.forEach((element) => {
      this.tzArr.push({
        id: element.name,
        name: isOffsetOnly
          ? `(${this.generateOffsetByTz(element.name)})`
          : `(${this.generateOffsetByTz(element.name)}) ${element.name}`,
      });
    });
    return this.tzArr;
  }

  generateOffsetByTime(offset: number): string {
    const sign = offset >= 0 ? '+' : '-';
    const absoluteOffset = Math.abs(offset);
    const hours = Math.floor(absoluteOffset / 60);
    return `${sign}${this.pad(hours)}:00`;
  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  getTimezoneById(id: string, isOffsetOnly: boolean = false) {
    if (this.tzArr.length === 0) {
      this.getTimezoneData(isOffsetOnly);
    }
    return this.tzArr.find((tz) => tz.id === id);
  }

  generateOffsetByTz(timezone: string) {
    const offsetMinutes = DateTime.now().setZone(timezone).offset;
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetRemainingMinutes = Math.abs(offsetMinutes) % 60;
    const sign = offsetMinutes < 0 ? '-' : '+';
    const offsetString: string = `UTC ${sign}${offsetHours
      .toString()
      .padStart(2, '0')}:${offsetRemainingMinutes.toString().padStart(2, '0')}`;
    return offsetString;
  }

  getTimezoneMapping(key: string) {
    return this.timezoneMappingJson[key] ?? this.timezoneMappingJson['US'];
  }
}
