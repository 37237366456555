import { Injectable, ViewContainerRef, TemplateRef } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class HostViewService {
  hostView: ViewContainerRef; //value from HostViewDirective
  constructor() {}
  open(template: TemplateRef<HTMLElement>) {
    this.hostView.clear();
    this.hostView.createEmbeddedView(template);
  }
}
