import Dexie, { Table } from 'dexie';
export interface IAutoTranslate {
  original: string;
  translated: string;
  to: string;
}

export class AppAutoTranslateDB extends Dexie {
  vi!: Table<IAutoTranslate>;
  en!: Table<IAutoTranslate>;
  constructor() {
    super('autoTranslateQuery');
    this.version(1).stores({
      en: 'original, translated',
      vi: 'original, translated',
    });
  }
  async resetDB() {
    await autoTranslateDb.transaction('rw', 'vi', () => {
      this.vi.clear();
    });
    await autoTranslateDb.transaction('rw', 'en', () => {
      this.en.clear();
    });
  }
}
export const autoTranslateDb = new AppAutoTranslateDB();
