import Dexie, { Table } from 'dexie';
export interface IViewedTour {
  id: string;
  viewedTime: number;
}

export class AppViewedTourDB extends Dexie {
  viewedTours!: Table<IViewedTour>;
  constructor() {
    super('viewedTourLiveQuery');
    this.version(1).stores({
      viewedTours: 'id, viewedTime',
    });
  }
  async resetDB() {
    await viewedToursDb.transaction('rw', 'viewedTours', () => {
      this.viewedTours.clear();
    });
  }
}
export const viewedToursDb = new AppViewedTourDB();
