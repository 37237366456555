<!--comment i18n for production-->
<div class="hidden">
  {{ 'POST.LOVE' | translateAsync }}
  {{ 'COMMENT.REPLY' | translateAsync }}
  {{ 'COMMENT.ADD_COMMENT' | translateAsync }}
  {{ 'EMOJI.LOVE' | translateAsync }}
  {{ 'EMOJI.HAHA' | translateAsync }}
  {{ 'EMOJI.SAD' | translateAsync }}
  {{ 'EMOJI.ANGRY' | translateAsync }}
  {{ 'EMOJI.WOW' | translateAsync }}
  {{ 'COMMON.SAVE' | translateAsync }}
  {{ 'COMMON.CANCEL' | translateAsync }}
</div>
<!----------------------->
<div *ngIf="tourDetails; else loadingTemplate" id="overview" class="t-overview">
  <div
    class="flex justify-center items-center bg-gradient-to-r from-[#FEAD34] to-[#FF7041] font-semibold p-2 text-white md:sticky md:top-1 z-10 mb-2 rounded-lg md:w-full fixed w-[calc(100%_-_2.05rem)]"
    [ngClass]="{ 'top-36': isScrollUp && !isModal, 'top-20': !isScrollUp && !isModal }"
    *ngIf="isPreviewMode"
  >
    <span>{{ 'page.tour_list.preview_mode' | translateAsync }}</span>
    <i
      class="sctr-icon-x-close absolute right-2.5 cursor-pointer"
      (click)="closeOverview.emit()"
    ></i>
  </div>
  <h3
    class="t-title"
    [ngClass]="{ 'mt-14 md:mt-0': isPreviewMode, 'mt-10': isModal }"
    [pTooltip]="title"
  >
    {{ title }}
  </h3>
  <div class="t-badges">
    <div class="t-caterories" *ngIf="categories && categories.length > 0">
      <ng-container *ngFor="let category of categories.slice(0, 5)">
        <a
          [routerLink]="[]"
          [queryParams]="{ adventure_styles: getCurrentAdvs(category) }"
          queryParamsHandling="merge"
        >
          <app-badge [label]="getAdventureStyleLabel(category)" [color]="'rose'"></app-badge>
        </a>
      </ng-container>
      <ng-container *ngIf="categories.length > 5">
        <app-badge
          label="{{ '+' + (categories.length - 5) }}"
          [color]="'rose'"
          (click)="moreCategories.toggle($event)"
        ></app-badge>
        <p-overlayPanel #moreCategories styleClass="t-more-categories-panel">
          <ng-template pTemplate="content" *ngFor="let category of categories?.slice(5)">
            <a
              [routerLink]="[]"
              [queryParams]="{ adventure_styles: getCurrentAdvs(category) }"
              queryParamsHandling="merge"
            >
              <app-badge [label]="getAdventureStyleLabel(category)" [color]="'rose'"></app-badge>
            </a>
          </ng-template>
        </p-overlayPanel>
      </ng-container>
    </div>
    <div class="t-hashtags" *ngIf="hashtags">
      <ng-container *ngFor="let hashtag of hashtags">
        <a
          [routerLink]="[]"
          [queryParams]="{
            hashtags: hashtag,
            location: null,
            destinationId: null,
            places: null,
            adventure_styles: null,
            spotlights: null,
            price: null,
            total_days: null,
            tour_type: null,
            a_dates: null
          }"
          queryParamsHandling="merge"
        >
          <app-badge [type]="'hashtag'" [label]="hashtag" [color]="'primary'"></app-badge>
        </a>
      </ng-container>
    </div>
  </div>
  <app-gallery
    [isPreviewMode]="isPreviewMode"
    [images]="getAllGalleriesPath(images)"
    [durationBadge]="total_days"
    [durationUnit]="durationUnit"
    [ratingBadge]="isCommercialTour ? rating : 0"
    [favoriteButton]="favoriteButton"
    (handleAddToFavorite)="handleFavouriteTour()"
    [isBookmarked]="isBookmarked"
    [numVisible]="5"
  ></app-gallery>
  <div *ngIf="!isPreviewMode" class="t-information">
    <div class="t-price-wrapper">
      <div class="flex flex-col justify-start">
        <p class="t-leading-text">
          {{
            (isCommercialTour ? 'page.tour_list.starting_from' : 'page.tour_list.estimated')
              | translateAsync
          }}
        </p>
        <div *ngIf="isCommercialTour" class="t-supporting-text">
          {{ 'page.tour_list.includes_taxes_and_charges' | translateAsync }}
        </div>
      </div>
      <div class="flex flex-col items-end mb-2">
        <p class="t-price">
          {{
            ((promotion | promotion: bookable_start : bookable_end) ? price : oldPrice)
              | currencyConversion
          }}
        </p>
        <p *ngIf="promotion | promotion: bookable_start : bookable_end" class="t-old-price">
          {{ oldPrice | currencyConversion }}
        </p>
      </div>
    </div>
    <div class="t-actions">
      <a class="t-view-detail-button" [routerLink]="['tour-detail']" [queryParams]="{ id: tourId }">
        {{ 'page.tour_list.view_detail' | translateAsync }}
      </a>
      <div>
        <app-add-button
          *ngIf="isCommercialTour"
          type="btnAdd"
          [label]="'page.tour_list.book_tour' | translateAsync"
          size="size-sm"
          (handleClick)="handleButtonTour(tourType, tourId)"
        ></app-add-button>
        <button
          *ngIf="!isCommercialTour"
          class="flex items-center gap-2 px-4 py-2 font-semibold bg-white border rounded-lg t-share-button border-primary-600 font-Inter"
          (click)="handleButtonTour(tourType, tourId); openModal.emit(true)"
        >
          <i class="t-share-tour-icon sctr-icon-share-06"></i>
          <span class="font-semibold text-primary-600 text-md">
            {{ 'page.tour_list.share_itinerary' | translateAsync }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isPreviewMode && !isCommercialTour" class="t-information">
    <div class="t-price-wrapper">
      <p class="t-leading-text">{{ 'page.tour_list.expense' | translateAsync }}</p>
      <div *ngIf="isPreviewMode; else defaultCurrency" class="flex gap-0.5">
        <span class="flex items-center font-bold md:text-base text-orange-dark-500">
          {{ userCurrency | currencySymbol }}
        </span>
        <p class="t-price">{{ price | number: '1.2-2' }}</p>
      </div>
      <ng-template #defaultCurrency
        ><p class="t-price">{{ price | currencyConversion }}</p></ng-template
      >
    </div>
  </div>
  <div id="tab-view" class="t-tabs" #view>
    <app-tab-view
      [views]="'view'"
      [headers]="tabViewData"
      [activeIndex]="activeBodyIndex"
      [headerActiveIndex]="getHeaderIndex()"
      [showPreviewButton]="false"
      [isQuickView]="true"
      (emitOnPlayPreview)="hostView.open(tourPreviewModal); onShowDialogPreview()"
    >
      <div body1 class="t-overview-container">
        <app-view-more-panel
          *ngIf="highlights"
          [data]="highlights"
          [header]="'page.tour_list.highlight' | translateAsync"
          [size]="'small'"
        ></app-view-more-panel>
        <div class="t-itinerary-wrapper">
          <div class="flex items-center justify-between gap-2 t-itinerary-header">
            <h4 class="t-heading-text">{{ 'page.tour_list.itinerary' | translateAsync }}</h4>
            <div
              class="flex sticky items-center justify-center top-10 right-1 md:max-w-[7.5rem] md:min-w-fit pl-5 bg-white self-stretch"
            >
              <button
                class="flex items-center gap-1 px-3 py-1 font-semibold rounded-lg bg-primary-50 text-primary-700 text-md"
                (click)="handlePlayPreview(tourPreviewModal); onShowDialogPreview()"
              >
                <span>{{ 'page.tour_list.preview' | translateAsync }}</span>
                <i class="text-lg sctr-icon-solid-play"></i>
              </button>
            </div>
          </div>
          <app-tab-view
            *ngIf="itineraries?.length; else emptyItinerary"
            [showCost]="tourType === 'Community'"
            [views]="'day'"
            [datas]="itineraries"
            [isPreviewMode]="isPreviewMode"
            [action]="action"
            [pinnedHeader]="true"
            [resetIndexAfterChange]="resetIndexAfterChange"
            [updateMenuItems]="updateMenuItems"
            (handleTabChange)="onTabChanged($event)"
            [showPreviewButton]="true"
            [isQuickView]="true"
            (emitOnPlayPreview)="hostView.open(tourPreviewModal); onShowDialogPreview()"
          ></app-tab-view>
        </div>
        <app-expense
          *ngIf="isPreviewMode && price && !isCommercialTour"
          [isPreviewMode]="isPreviewMode"
          [expenses]="expenses"
          [profileCurrency]="userCurrency"
          [expenseCurrency]="expenseCurrency"
        ></app-expense>
      </div>
      <div *ngIf="tourType === 'Commercial'" body2 class="t-review-wrapper">
        <app-review-panel
          [reviews]="reviews"
          [rating]="rating"
          [total_reviews]="total_reviews"
          [disable]="disableButtonViewMore"
          (handleLoadMoreReviews)="loadMoreReviews()"
          (emitOpenReportDialog)="openReportDialog($event)"
        ></app-review-panel>
      </div>
      <div
        *ngIf="tourType === 'Community' && userInfo && friendInfo"
        body2
        class="t-review-wrapper"
      >
        <lib-comment-form
          #contentComment
          #contentComment1
          [hidden]="!checkUser(userInfo)"
          [apiHost]="apiHost"
          [userProfile]="userInfo"
          [friendList]="friendInfo"
          (valueComment)="sendComment(false)"
        ></lib-comment-form>
        <div *ngIf="displayComment" class="t-comment-panel">
          <div *ngFor="let comment of displayComment; let i = index" class="pt-3">
            <div class="">
              <lib-comment-content
                #commentData
                [apiHost]="apiHost"
                [commentData]="comment"
                [positionFixed]="true"
                (deletedCommentId)="deleteComment(comment?.id!)"
                [userProfile]="userInfo"
                [postOwnerId]="comment?.created_by?.id!"
                (onReplyClick)="
                  handleReply(comment?.id!, i, comment?.id!, comment?.created_by?.full_name!)
                "
              ></lib-comment-content>
            </div>
            <div id="comment{{ i }}" class="relative hidden pt-3 pl-12">
              <lib-comment-form
                #contentChildComment
                [apiHost]="apiHost"
                [userProfile]="userInfo"
                [friendList]="friendInfo"
                [isReplyForm]="true"
                [replyTarget]="replyTarget"
                (valueComment)="sendComment(true, comment?.id!, i)"
              ></lib-comment-form>
              <i
                class="sctr-icon-x-circle absolute top-[15px] right-[6px] text-gray-400 hover:text-primary-500 cursor-pointer"
                (click)="handleUnReply(comment?.id!, i)"
              ></i>
            </div>
            <div *ngIf="comment?.total_replies! > 0" class="pt-3">
              <app-child-comment
                [id]="comment?.id!"
                [position]="true"
                [userInfo]="userInfo"
                [friendInfo]="friendInfo"
                (childCommentData)="handleReply(comment?.id!, i, undefined, undefined, $event)"
              ></app-child-comment>
            </div>
          </div>
        </div>
        <button
          *ngIf="
            displayComment &&
            totalComment.length > 3 &&
            displayComment.length !== totalComment.length
          "
          class="pt-3 font-semibold text-md text-primary-700 hover:underline"
          (click)="loadMoreComment()"
        >
          {{ 'page.tour_list.view_more_comments' | translateAsync }}
        </button>
      </div>
    </app-tab-view>
  </div>
  <div *ngIf="isPreviewMode" class="justify-end hidden mt-4">
    <button
      class="px-4 py-2 font-semibold text-gray-700 bg-white border border-gray-300 border-solid rounded-lg hover:bg-gray-50 text-md"
      type="button"
      (click)="closeOverview.emit()"
    >
      {{ 'common.term.close' | translateAsync }}
    </button>
  </div>
  <!-- Toggle map button on mobile -->
  <div
    *ngIf="!isPreviewMode"
    class="t-main-panel-top fixed left-[50%] -translate-x-[50%] z-20 font-medium bottom-6 md:hidden opacity-70"
  >
    <button
      (click)="openMapService.show(); itineraryService.showMarkers(itineraries)"
      type="button"
      class="flex items-center justify-center gap-2 px-3 py-1 not-italic text-center border rounded-md bg-primary-50 border-primary-600"
    >
      <span class="sctr-icon-map-01 text-primary-700"></span>
      <span class="not-italic font-semibold text-primary-700 text-md">
        {{ 'page.tour_list.view_map' | translateAsync }}
      </span>
    </button>
  </div>
</div>

<ng-template #itemTmpl let-item>
  <li class="text-gray-700 text-md font-Inter">{{ item }}</li>
</ng-template>
<ng-template #emptyItinerary>
  <p class="text-gray-700 font-Inter text-md">
    {{ 'page.tour_list.not_have_itinerary_yet' | translateAsync }}
  </p>
</ng-template>
<ng-template #loadingTemplate>
  <app-quickview-skeleton></app-quickview-skeleton>
</ng-template>

<div [hidden]="!isShowShareDialog">
  <app-share-tour
    (closeModal)="handleCloseModal.emit($event)"
    [linkShare]="shareTourLink"
    [tourId]="tourId"
  ></app-share-tour>
</div>

<app-report-dialog
  [isShowReport]="isOpenReportDialog"
  (closeModal)="closeReportDialog()"
  (postReport)="confirmReport($event)"
  [contentReport]="reportCategories"
></app-report-dialog>

<ng-template #tourPreviewModal>
  <app-modal
    #modal
    [showDialog]="true"
    (emitOnCloseDialog)="modal.showDialog = false; onCloseDialogPreview()"
    [dialogConfig]="tourPreviewDialogConfig"
  >
    <ng-container modal-header>
      <span class="pr-8 font-semibold text-gray-800 md:text-xl line-clamp-2">
        {{ 'page.tour_list.preview_tour_title' | translateAsync: { param: tourDetails.title } }}
      </span>
    </ng-container>
    <ng-container modal-content *ngIf="modal.showDialog">
      <div class="relative overflow-hidden rounded w-full h-[82vh] md:w-[94vw] md:h-[88vh]">
        <app-tour-guide
          [tourId]="tourDetails.id!"
          [centerPlace]="centerPlace"
          [tourDetails]="tourDetails"
          [action]="action"
          [isPreviewMode]="isPreviewMode"
          [isCommercialTour]="isCommercialTour"
          (emitOnCloseTourGuide)="onCloseTourGuide()"
        ></app-tour-guide>
      </div>
    </ng-container>
  </app-modal>
</ng-template>
