<div class="flex flex-col gap-4 pt-4">
  <div class="w-full flex flex-col gap-2 mr-9">
    <p-skeleton width="85%" height="28px" styleClass="mb-1"></p-skeleton>
    <p-skeleton width="70%" height="20px"></p-skeleton>
    <p-skeleton width="70%" height="20px"></p-skeleton>
  </div>
  <div class="w-full flex flex-col gap-1 mr-9">
    <div class="w-full aspect-[3/2]">
      <p-skeleton width="100%" height="100%"></p-skeleton>
    </div>
    <div class="w-full flex justify-content-between gap-1 aspect-[5]">
      <div *ngFor="let item of [].constructor(5)" class="flex-1">
        <p-skeleton width="100%" height="100%"></p-skeleton>
      </div>
    </div>
  </div>
  <p-skeleton width="100%" height="120px"></p-skeleton>
  <div class="w-full flex gap-2">
    <p-skeleton width="80px" height="28px"></p-skeleton>
    <p-skeleton width="80px" height="28px"></p-skeleton>
  </div>
  <div class="w-full flex flex-col gap-2">
    <p-skeleton width="30%" height="20px" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="100%" height="18px"></p-skeleton>
    <p-skeleton width="90%" height="18px"></p-skeleton>
  </div>
  <div class="w-full flex flex-col gap-2">
    <p-skeleton width="30%" height="20px" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="100%" height="18px"></p-skeleton>
    <p-skeleton width="100%" height="18px"></p-skeleton>
    <p-skeleton width="90%" height="18px"></p-skeleton>
  </div>
</div>
