import { Injectable } from '@angular/core';
import { TourGroupControllerService } from '@assistant/angular-tour-ota-service';
import { Observable, catchError, of, switchMap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class BranchInfoService {
  constructor(private tourGroupControllerService: TourGroupControllerService) {}
  getBranchCurrency(id: string): Observable<any> {
    return this.tourGroupControllerService.getTourGroupDetailById(id).pipe(
      switchMap((value) => {
        return of(value.data?.tour_group?.currency).pipe(catchError((error) => of(error)));
      })
    );
  }
}
