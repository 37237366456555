import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';

import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { AddButtonComponent } from '@shared/components/add-button/add-button';

import {
  AdventureStyleControllerService,
  AdventureStyleRelatedTourDto,
} from '@assistant/angular-tour-builder-service';
import { TourControllerService } from '@assistant/angular-travel-assistant-searching-service';

import { FilterTypes } from '@shared/services/filter.service';
import { AutoUnsubscribe } from '@shared/decorators/auto-unsubscribe.decorator';
import { AppRoutes } from 'src/app/config/routes.config';

import { TranslationService } from '@core/services/translation/translation.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { environment } from '@env/environment';
import { LocalizedLanguageStringPipe } from '@shared/pipes/localized-language-string.pipe';

interface IAdventureStyleRelatedTourDto extends AdventureStyleRelatedTourDto {
  id: string;
}
@AutoUnsubscribe
@Component({
  selector: 'app-advanced-filter',
  standalone: true,
  imports: [
    /* angular */
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    TranslateModule,
    /* primeng */
    CheckboxModule,
    CardModule,
    InputNumberModule,
    AccordionModule,
    RadioButtonModule,
    OverlayscrollbarsModule,
    /* component */
    AddButtonComponent,
    /* pipe */
    TranslateAsyncPipe,
    LocalizedLanguageStringPipe,
  ],
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdvancedFilterComponent implements OnInit {
  @Output() showFilter = new EventEmitter<boolean>();
  tourTypes: any[] = [
    { name: 'Commercial', key: '' },
    { name: 'Community', key: '' },
  ];
  adventureStyles: string[];
  location: string | null = null;
  spotlight: string | null = null;
  hashtag: string | null = null;
  destinationId: string | null = null;
  selectedPointTourType: any[] = [];
  currentTourType: string[];
  selectedAdventureStyles: string[] = [];
  tripPeriods: number | null;

  date: Date | null;
  today = new Date();
  numberDaysOfCurrentMonth: number = new Date(
    this.today.getFullYear(),
    this.today.getMonth() + 1,
    0
  ).getDate();
  minDate: Date = new Date(
    this.today.getFullYear(),
    this.today.getDate() >= this.numberDaysOfCurrentMonth
      ? this.today.getMonth() + 1
      : this.today.getMonth(),
    1
  );
  formatTimestamp: number | null;
  formatTimestampString: string;

  fromPrice: number | null;
  toPrice: number | null;
  priceRange: string | null;
  filterVisible: boolean = false;
  invalidPriceRange: boolean = false;
  invalidPriceRangeMessage: string;
  disableBtn: boolean = false;
  destinationChange: string | undefined;
  topAdventureStyle: IAdventureStyleRelatedTourDto[] | undefined;
  styles: Array<{ id: string; name: string; tour: number }> = [];
  resetType: 'location' | 'style';
  isDirty: boolean;
  types: any[] = [
    {
      name: this.translationService.getTranslationAsync('page.tour_list.filter.commercial'),
      key: 'Commercial',
    },
    {
      name: this.translationService.getTranslationAsync('page.tour_list.filter.community'),
      key: 'Community',
    },
    // { name: 'Both of them', key: null },
  ];
  lang = localStorage.getItem('lang') ?? 'en';
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private adventureStyleControllerService: AdventureStyleControllerService,
    private tourControllerService: TourControllerService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isDirty = false;
      this.invalidPriceRange = false;
      this.invalidPriceRangeMessage = '';
      if (params['destinationId']) {
        this.location = params[FilterTypes.LOCATION] ?? null;
        this.destinationId = params['destinationId'];
        this.hashtag = null;
        this.spotlight = params[FilterTypes.SPOTLIGHT] ?? null;
      }
      if (params[FilterTypes.HASHTAG]) {
        this.hashtag = params[FilterTypes.HASHTAG];
        this.location = null;
        this.destinationId = null;
        this.spotlight = null;
      }
      if (params[FilterTypes.START_DATE]) {
        const monthYear = params[FilterTypes.START_DATE].toString().split('@~')[1].split(':')[0];
        this.date = DateTime.fromSeconds(monthYear / 1000).toJSDate();
      } else {
        this.date = null;
        this.formatTimestampString = '';
      }
      this.filterVisible = params['openFilter'] === 'true';
      this.showFilter.emit(this.filterVisible);
      this.selectedAdventureStyles = params[FilterTypes.ADVENTURE_STYLE]
        ? params[FilterTypes.ADVENTURE_STYLE]?.split(';')
        : [];
      this.selectedPointTourType = params[FilterTypes.TOUR_TYPE]
        ? [params[FilterTypes.TOUR_TYPE]]
        : [];
      this.currentTourType = this.selectedPointTourType;
      this.tripPeriods = params[FilterTypes.TOTAL_DAY] ?? null;

      const priceValue = params[FilterTypes.PRICE] ?? null;
      const formatPriceValue = priceValue?.match(/\d+/g);
      if (priceValue && formatPriceValue) {
        switch (priceValue.charAt(0)) {
          case '@': {
            this.fromPrice = Number(formatPriceValue[0]);
            this.toPrice = Number(formatPriceValue[1]);
            break;
          }
          case '>': {
            this.fromPrice = Number(formatPriceValue[0]);
            break;
          }
          case '<': {
            this.fromPrice = 0;
            this.toPrice = Number(formatPriceValue[0]);
            break;
          }
          default: {
            return;
          }
        }
      } else {
        this.fromPrice = null;
        this.toPrice = null;
      }
    });
    this.getAdventureStyles();
  }

  checkInputPriceInvalid() {
    if (this.toPrice !== null && this.fromPrice !== null) {
      this.invalidPriceRange = this.toPrice < this.fromPrice;
      this.invalidPriceRangeMessage = this.translationService.getTranslationAsync(
        'page.tour_list.filter.invalid_budget_input_value'
      );
    } else {
      this.invalidPriceRange = false;
      this.invalidPriceRangeMessage = '';
    }
  }

  hideFilter() {
    this.showFilter.emit(false);
    this.invalidPriceRange = false;
    this.invalidPriceRangeMessage = '';
    this.router.navigate([`${environment.prefix}/${AppRoutes.TOUR_LIST}`], {
      queryParams: { openFilter: null },
      queryParamsHandling: 'merge',
    });
  }

  getAdventureStyles() {
    if (localStorage.getItem('adventure_styles')) {
      const advantureStyleList = JSON.parse(localStorage.getItem('adventure_styles')!);
      this.adventureStyles = advantureStyleList;
    } else {
      this.adventureStyleControllerService.getAll().subscribe((value) => {
        if (value?.data) {
          this.adventureStyles = value.data;
          localStorage.setItem('adventure_styles', JSON.stringify(this.adventureStyles));
        }
      });
    }
    // this.adventureStyleControllerService.getAll().subscribe((value) => {
    //   this.adventureStyles = value.data;
    // });
    this.tourControllerService.countByTourType().subscribe((value) => {
      this.tourTypes[0].key = value.data?.Commercial;
      this.tourTypes[1].key = value.data?.Community;
    });

    this.adventureStyleControllerService
      .getTopAdventureStyleWithMostRelatedTour(15)
      .subscribe((value) => {
        this.topAdventureStyle = value.data?.data as IAdventureStyleRelatedTourDto[];
        this.topAdventureStyle?.forEach((data) => {
          if (this.adventureStyles.some((item) => item === data.name)) {
            this.styles.push({ id: data?.id, name: data?.name!, tour: data?.total_tour! });
          }
        });
      });
  }

  changeTourType(tourType: string) {
    this.changePrice();
    if (this.currentTourType[0] === tourType) {
      this.selectedPointTourType = [];
      this.currentTourType = [];
      return;
    }
    if (this.currentTourType[0] !== tourType) {
      this.selectedPointTourType = [tourType];
      this.currentTourType = [tourType];
      return;
    }
  }

  changeTripPriod() {
    this.changePrice();
  }

  changeAdventureStyle() {
    this.changePrice();
  }

  changeDate() {
    if (!this.isDirty) {
      this.isDirty = true;
    }
    if (this.date) {
      this.formatTimestamp = new Date(this.date.getFullYear(), this.date.getMonth() + 1).getTime();

      const lastDayofMonth = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      ).getTime();

      this.formatTimestampString = `@~${this.formatTimestamp}:${lastDayofMonth}`;
    } else {
      this.formatTimestamp = null;
      this.formatTimestampString = '';
    }
  }

  formatDateToNum() {}
  clearDate() {
    if (!this.isDirty) {
      this.isDirty = true;
    }
    this.formatTimestamp = null;
    this.formatTimestampString = '';
  }

  changePrice() {
    if (!this.isDirty) {
      this.isDirty = true;
    }
    if (this.fromPrice && this.toPrice && this.fromPrice > this.toPrice) {
      return (this.priceRange = null);
    }
    if (!this.fromPrice && !this.toPrice) {
      return (this.priceRange = null);
    }
    if (!this.fromPrice) {
      return (this.priceRange = `<=${this.toPrice}`);
    }
    if (!this.toPrice) {
      return (this.priceRange = `>=${this.fromPrice}`);
    }
    return (this.priceRange = `@~${this.fromPrice}:${this.toPrice}`);
  }

  handleApply() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [FilterTypes.PRICE]: this.priceRange,
        [FilterTypes.ADVENTURE_STYLE]:
          this.selectedAdventureStyles.length > 0
            ? this.selectedAdventureStyles.toString().replaceAll(',', ';')
            : null,
        [FilterTypes.TOTAL_DAY]: this.tripPeriods ?? null,
        [FilterTypes.START_DATE]: this.formatTimestampString ?? null,
        [FilterTypes.TOUR_TYPE]: this.selectedPointTourType[0] ?? null,
      },
      queryParamsHandling: 'merge',
    });
  }

  handleCancel() {
    this.invalidPriceRange = false;
    this.invalidPriceRangeMessage = '';
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        openFilter: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  resetFilter() {
    this.selectedAdventureStyles = [];
    this.selectedPointTourType = [];
    this.tripPeriods = null;
    this.fromPrice = null;
    this.date = null;
    this.toPrice = null;
    this.priceRange = null;
    this.isDirty = false;
    this.invalidPriceRange = false;
    this.invalidPriceRangeMessage = '';
    this.formatTimestampString = '';
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [FilterTypes.LOCATION]: this.location,
        destinationId: this.destinationId,
        [FilterTypes.SPOTLIGHT]: this.spotlight,
        [FilterTypes.HASHTAG]: this.hashtag,
        [FilterTypes.PRICE]: null,
        [FilterTypes.START_DATE]: null,
        [FilterTypes.ADVENTURE_STYLE]: null,
        [FilterTypes.TOTAL_DAY]: null,
        [FilterTypes.TOUR_TYPE]: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  checkHasFilterValue(): boolean {
    if (
      this.selectedAdventureStyles?.length === 0 &&
      !this.date &&
      this.selectedPointTourType?.length === 0 &&
      (this.fromPrice === null || this.fromPrice === undefined) &&
      (this.toPrice === null || this.toPrice === undefined) &&
      (this.tripPeriods === null || this.tripPeriods === undefined)
    ) {
      this.disableBtn = true;
      return false;
    } else {
      this.disableBtn = false;
      return true;
    }
  }
}
