<div
  class="t-tab-view-component"
  [ngClass]="{ 't-pinned-header': !navTop, 't-unpin-header': navTop,'t-preview-mode': isPreviewMode }"
>
  <p-tabView
    class="t-tab-view"
    *ngIf="views === 'view'"
    [(activeIndex)]="headerActiveIndex"
    #tabView
  >
    <p-tabPanel *ngFor="let header of headers; let index = index" header="{{ header }}">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      <ng-template #contentTemplate>
        <div [ngSwitch]="index">
          <ng-content *ngSwitchCase="'0'" select="[body1]"></ng-content>
          <ng-content *ngSwitchCase="'1'" select="[body2]"></ng-content>
          <ng-content *ngSwitchCase="'2'" select="[body3]"></ng-content>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>

  <p-tabView
    class="t-tab-day"
    *ngIf="views === 'day' && !isQuickView"
    [(activeIndex)]="activeIndex"
    [scrollable]="datas.length > 3"
    #tab
    (onChange)="handleTabChange.emit($event)"
  >
    <!-- All days tab -->
    <p-tabPanel
      *ngIf="datas.length >= 2"
      [header]="'common.component.tab_view.all_days' | translateAsync"
    >
      <div *ngFor="let data of datas; let i = index" class="t-all-day-tab-item">
        <div *ngIf="data.title; else noTitle" class="t-tab-title">
          {{ ('common.term.day' | translateAsync) + ' ' + data.day_no + ': ' + data.title }}
        </div>
        <ng-template #noTitle>
          <div class="t-tab-title">
            {{ ('common.term.day' | translateAsync) + ' ' + data.day_no }}
          </div>
        </ng-template>
        <app-timeline
          [action]="action"
          [isPreviewMode]="isPreviewMode"
          [showCost]="showCost"
          *ngIf="data.place_visits"
          [events]="data.place_visits"
          [activeDay]="activeIndex"
          [isQuickView]="isQuickView"
          [isItineraryTimeline]="showPreviewButton"
          [isShowPlaceOnTop]="true"
          [isConvertPrice]="isConvertPrice"
        ></app-timeline>
      </div>
    </p-tabPanel>

    <p-tabPanel
      *ngFor="let data of datas; let i = index"
      [header]="('common.term.day' | translateAsync) + ' ' + data.day_no"
    >
      <ng-container
        *ngTemplateOutlet="timelineTemplate; context: { $implicit: data.title }"
      ></ng-container>
      <ng-template #timelineTemplate let-item>
        <div
          *ngIf="data.title"
          class="flex items-center mb-3 font-semibold text-gray-500 font-Inter text-md"
        >
          {{ data.title }}
        </div>
        <app-timeline
          [action]="action"
          [isPreviewMode]="isPreviewMode"
          [showCost]="showCost"
          *ngIf="data.place_visits"
          [events]="data.place_visits"
          [activeDay]="activeIndex"
          [isQuickView]="isQuickView"
          [isItineraryTimeline]="showPreviewButton"
          [isShowPlaceOnTop]="true"
          [isConvertPrice]="isConvertPrice"
        ></app-timeline>
      </ng-template>
    </p-tabPanel>
  </p-tabView>

  <div *ngIf="isQuickView">
    <div
      class="flex items-center sticky top-[40px] z-[2] justify-between py-2 rounded-none border-b border-solid border-b-gray-200 bg-white"
      [ngClass]="isPreviewMode && !isTourGuide ? 'md:top-10' : 'md:top-0'"
    >
      <p-tabMenu
        class="w-full t-tabmenu-day"
        [model]="menuItems"
        [activeItem]="activeItem"
        (activeItemChange)="onTabChange($event)"
        [scrollable]="isScrollable"
        #tab
      >
        <ng-template let-item pTemplate="item">
          <div [class]="'t-tab-day ' + item.class">
            {{ item.label }}
          </div>
        </ng-template>
      </p-tabMenu>
      <div *ngIf="isTourGuide">
        <div
          *ngIf="showPreviewButton"
          class="flex sticky items-center justify-center top-10 right-1 md:max-w-[7.5rem] md:min-w-fit bg-white self-stretch"
        >
          <button
            class="flex items-center gap-1 px-3 py-1 font-semibold rounded-lg bg-primary-50 text-primary-700 text-md"
            (click)="showItineraryTimeline(false)"
          >
            <span class="hidden md:block">
              {{ 'common.component.tab_view.preview' | translateAsync }}
            </span>
            <i class="text-lg sctr-icon-solid-play"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- All days tab content -->
    <div *ngIf="activeItem?.label === ('common.component.tab_view.all_days' | translateAsync)">
      <div
        *ngFor="let data of datas; let i = index"
        class="flex flex-col items-start gap-3 mt-4 font-semibold text-gray-500 text-md"
      >
        <div *ngIf="data.title; else noTitle" class="t-tab-title">
          {{ ('common.term.day' | translateAsync) + ' ' + data.day_no + ': ' + data.title }}
        </div>
        <ng-template #noTitle>
          <div class="t-tab-title">
            {{ ('common.term.day' | translateAsync) + ' ' + data.day_no }}
          </div>
        </ng-template>
        <app-timeline
          class="w-full"
          [action]="action"
          [isPreviewMode]="isPreviewMode"
          [showCost]="showCost"
          *ngIf="data.place_visits"
          [events]="data.place_visits"
          [activeDay]="activeIndex"
          [isQuickView]="isQuickView"
          [isItineraryTimeline]="showPreviewButton"
          (emitOnPlayPreview)="emitOnPlayPreview.emit($event)"
          [isShowPlaceOnTop]="true"
          [isConvertPrice]="isConvertPrice"
        ></app-timeline>
      </div>
    </div>

    <!-- Days tab content -->
    <div *ngIf="activeItem?.label !== ('common.component.tab_view.all_days' | translateAsync)">
      <div *ngFor="let data of datas; let i = index" class="mt-4 mb-3">
        <div *ngIf="activeItem?.label === ('common.term.day' | translateAsync) + ' ' + data.day_no">
          <ng-container
            *ngTemplateOutlet="timelineTemplate; context: { $implicit: data.title }"
          ></ng-container>
          <ng-template #timelineTemplate let-item>
            <div
              *ngIf="data.title"
              class="flex items-center mb-3 font-semibold text-gray-500 font-Inter text-md"
            >
              {{ data.title }}
            </div>
            <app-timeline
              class="w-full"
              [action]="action"
              [isPreviewMode]="isPreviewMode"
              [showCost]="showCost"
              *ngIf="data.place_visits"
              [events]="data.place_visits"
              [activeDay]="activeIndex"
              [isQuickView]="isQuickView"
              [isItineraryTimeline]="showPreviewButton"
              [isTourGuide]="isTourGuide"
              [isShowPlaceOnTop]="true"
              [isConvertPrice]="isConvertPrice"
              (emitOnPlayPreview)="emitOnPlayPreview.emit($event)"
            ></app-timeline>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
