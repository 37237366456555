import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@core/services';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [CommonModule, ToastModule, ConfirmDialogModule, TranslateModule],
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  header: string = '';
  message: string = '';
  type: 'btnCancel' | 'btnLanguage' | 'btnDeletePlace' | 'btnDeleteDay' | 'btnClose';
  cancelBtnLabel: string;
  confirmBtnLabel: string;
  isDisableConfirmBtn: boolean = false;
  responsiveModal: boolean;
  isLoading: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public confirmationService: ConfirmationService,
    public confirmationDialogService: ConfirmationDialogService,
    private translationService: TranslationService
  ) {}

  get visible(): boolean {
    return this.confirmationDialogService.confirmationDataSubject.value !== null;
  }

  ngOnInit(): void {
    this.confirmationDialogService.confirmationData$.subscribe((data) => {
      if (data) {
        this.header = data.header;
        this.message = data.message;
        this.type = data.type;
        this.cancelBtnLabel =
          data.cancelBtnLabel || this.translationService.getTranslationAsync('common.term.cancel');
        this.confirmBtnLabel =
          data.confirmBtnLabel ||
          this.translationService.getTranslationAsync('common.term.confirm');
        this.responsiveModal = data.responsiveModal || false;
        this.isDisableConfirmBtn = data.disableConfirmBtn || false;
      }
    });
    this.confirmationDialogService.isLoadingConfirm
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isLoading = value;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
