import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ResponseModelBloomFilterDetailDTO,
  TourControllerService,
} from '@assistant/angular-tour-builder-service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GetBloomHelpfulService {
  BLOOM_HELPFUL_STRING_KEY: string = 'bloomHelpfulString';
  bloomResponse: ResponseModelBloomFilterDetailDTO;

  constructor(private tourControllerService: TourControllerService) {}

  // Get bloom filter for Helpful
  async getBloomlFilterString() {
    if (localStorage.getItem(environment.TOKEN_KEY)) {
      const getBloom$ = this.tourControllerService.getBloomFilters();
      this.bloomResponse = await lastValueFrom(getBloom$);
      localStorage.setItem(this.BLOOM_HELPFUL_STRING_KEY, this.bloomResponse.data?.bloom_filter!);
    }
  }

  // Get bloom helpful string from local storage
  getBloomHelpful() {
    return localStorage.getItem(this.BLOOM_HELPFUL_STRING_KEY);
  }
}
