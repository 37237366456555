import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Toast, ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TourListService } from '@shared/services/tour-list.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';

@Component({
  selector: 'app-toast-modal-bookmark',
  standalone: true,
  imports: [CommonModule, ToastModule, TranslateModule, TranslateAsyncPipe],
  templateUrl: './toast-modal-bookmark.component.html',
  styleUrls: ['./toast-modal-bookmark.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastModalBookmarkComponent implements OnInit, OnDestroy {
  visible: boolean = false;
  changeVisible: boolean = false;
  id: string;
  type: string;
  @ViewChild('toast') toast: Toast;
  private destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private tourListService: TourListService
  ) {}

  ngOnInit(): void {
    this.tourListService.openToastBookmark.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value.open) {
        this.onReject();
        this.showConfirm(value.type, value.summary);
        this.getData(value.change, value.id, value.bookmarkType);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showConfirm(severity: string, summary: string) {
    if (!this.visible) {
      this.messageService.add({ key: 'confirm', severity: severity, summary: summary, life: 3000 });
      this.visible = true;
    }
  }

  getData(visible: boolean, id: string, type: string) {
    this.changeVisible = visible;
    this.id = id;
    this.type = type;
  }

  onChange() {
    this.tourListService.openBookmark.next({
      open: true,
      type: this.type,
      id: this.id,
      name: '',
    });
  }

  onReject() {
    this.messageService.clear('confirm');
    this.visible = false;
  }
}
