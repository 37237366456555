import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryToIconPipe } from '@shared/pipes/category-to-icon.pipe';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { CurrencySymbolPipe } from 'src/common/pipe/currency-symbol.pipe';

export interface IExpenseItem {
  name: string;
  date: Date;
  currency: string;
  activity?: string;
  amount: number | null;
  category: string[];
  price_usd?: number | null;
}

@Component({
  selector: 'app-expense-item',
  standalone: true,
  imports: [
    CommonModule,
    CurrencySymbolPipe,
    CategoryToIconPipe,
    TranslateAsyncPipe,
    TranslateModule,
    CurrencyConversionPipe,
  ],
  templateUrl: './expense-item.component.html',
  styleUrls: ['./expense-item.component.scss'],
})
export class ExpenseItemComponent {
  @Input() expense: IExpenseItem;
  @Input() isDefaultCurrency: boolean = true;
}
