import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { ToastModule } from 'primeng/toast';
import { TourListService } from '@shared/services/tour-list.service';
import {
  BookmarkBasicDTO,
  BookmarkControllerService,
  BookmarkGroupBasicDTO,
  BookmarkGroupControllerService,
} from '@soctrip/angular-bookmarks-service';
import { GetBloomBookmarkService } from '@shared/services/get-bloom-bookmark.service';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject, mergeMap, of, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { TranslationService } from '@core/services';
@Component({
  selector: 'app-bookmark-modal',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    CheckboxModule,
    FormsModule,
    DividerModule,
    ToastModule,
    OverlayPanelModule,
    /* translate */
    TranslateModule,
    /* pipe */
    TranslateAsyncPipe,
  ],
  templateUrl: './bookmark-modal.component.html',
  styleUrls: ['./bookmark-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookmarkModalComponent implements OnInit, OnDestroy {
  inputValue: string = '';
  id: string = '';
  name: string = '';
  @Input() visible: boolean = false;
  listSave: Array<any> = [];
  selectedSave: any[] = [];
  previousSave: any[] = [];
  newSave: Array<{ name: string; id: string }> = [];
  openInput: boolean = false;
  bookmarkType: string = '';
  idAddBookmark: string = '';
  titelBookmarkItem: string = '';
  overlayName: boolean = false;
  bodyCreateBookmark: BookmarkBasicDTO;
  bodyGroupCreateBookmark: BookmarkGroupBasicDTO;
  spaceValid: boolean = false;
  emptyValid: boolean = false;
  @Output() submitBtn: any = new EventEmitter<any>();
  @Output() cancelBtn: any = new EventEmitter<any>();
  @ViewChild('label') label: ElementRef<any>;
  @ViewChild('fullCollectionName') fullCollectionName: OverlayPanel;
  private destroy$ = new Subject<void>();

  constructor(
    private tourListService: TourListService,
    private bookmarkGroupControllerService: BookmarkGroupControllerService,
    private bookmarkControllerService: BookmarkControllerService,
    private getBloomBookmarkService: GetBloomBookmarkService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.tourListService.openBookmark.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.selectedSave = [];
        this.visible = value.open;
        this.getGroupBookmark(value.type);
        this.idAddBookmark = value.id;
        this.getIdAndTitleBookmarkItem(value.id, value.name);
        //  let body = document.getElementsByTagName('body')[0];
        //  body.classList.add('overflow-auto');
      }
    });
    this.newSave = [
      {
        name: this.translationService.getTranslationAsync(
          'common.component.bookmark_modal.new_collection'
        ),
        id: 'new collection',
      },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showDialog() {
    this.visible = true;
  }

  onChange(value: string, value1: string) {
    this.id = value;
    this.name = value1;
    const select = this.selectedSave[this.selectedSave.length - 1];
    this.selectedSave.length = 0;
    this.selectedSave.push(select);
    if (value === 'new collection' && this.selectedSave[0] !== undefined) {
      this.openInput = true;
    } else if (value !== 'new collection' || this.selectedSave[0] === undefined) {
      this.openInput = false;
    }
  }
  checkValidinput(data: string) {
    if (/\s$/.test(data) && !/\w/.test(data)) {
      this.spaceValid = true;
      this.emptyValid = false;
    } else if (data === '') {
      this.spaceValid = false;
      this.emptyValid = true;
    } else if (data !== '' && !/\s$/.test(data) && /\w/.test(data)) {
      this.spaceValid = false;
      this.emptyValid = false;
    }
  }
  blurInput(data: string) {
    if (data === '') {
      this.emptyValid = true;
    }
  }
  onClose() {
    this.visible = false;
    this.openInput = false;
    this.inputValue = '';
    //  let body = document.getElementsByTagName('body')[0];
    //  body.classList.remove('overflow-auto');
  }

  getGroupBookmark(type: string) {
    this.bookmarkGroupControllerService
      .bookmarksGroupsSearchTabGet(0, 1000, type)
      .subscribe((value) => {
        this.listSave = value?.data?.data!;
      });
    this.bookmarkType = type;
  }

  getIdAndTitleBookmarkItem(id: string, title: string) {
    this.idAddBookmark = id;
    this.titelBookmarkItem = title;
    this.checkItemInGroup();
  }

  objectBookmark(id: string, dag: string, gid: string, name: string): BookmarkBasicDTO {
    return {
      object_id: id,
      dag_object: dag,
      group_id: gid,
      group_name: name,
    };
  }

  objectGroupBookmark(
    name: string,
    cate: number,
    subCate: Array<number>,
    img: string
  ): BookmarkGroupBasicDTO {
    return {
      name: name,
      object_category: cate,
      object_sub_categories: subCate,
      image: img,
    };
  }

  checkItemInGroup() {
    switch (this.bookmarkType) {
      case 'TOURS': {
        this.bookmarkControllerService
          .bookmarksSearchGet(0, 1000, 'object_category==3')
          .subscribe((value) => {
            value?.data?.data!.forEach((element) => {
              if (this.idAddBookmark === element.object_id) {
                this.getGroupBookmarkById(element.group_id);
              }
            });
          });
        break;
      }
      case 'HOTELS': {
        this.bookmarkControllerService
          .bookmarksSearchGet(0, 1000, 'object_category==2')
          .subscribe((value) => {
            value?.data?.data!.forEach((element) => {
              if (this.idAddBookmark === element.object_id) {
                this.getGroupBookmarkById(element.group_id);
              }
            });
          });
        break;
      }
      case 'FOOD': {
        this.bookmarkControllerService
          .bookmarksSearchGet(0, 1000, 'object_category==4')
          .subscribe((value) => {
            value?.data?.data!.forEach((element) => {
              if (this.idAddBookmark === element.object_id) {
                this.getGroupBookmarkById(element.group_id);
              }
            });
          });
        break;
      }
      case 'SOCIAL': {
        this.bookmarkControllerService
          .bookmarksSearchGet(0, 1000, 'object_category==1')
          .subscribe((value) => {
            value?.data?.data!.forEach((element) => {
              if (this.idAddBookmark === element.object_id) {
                this.getGroupBookmarkById(element.group_id);
              }
            });
          });
        break;
      }
    }
  }

  getGroupBookmarkById(id: string) {
    this.bookmarkGroupControllerService.bookmarksGroupsIdGet(id).subscribe((value) => {
      this.selectedSave = [
        {
          name: value?.data?.name,
          object_category: value?.data?.object_category,
          object_sub_categories: value?.data?.object_sub_categories,
          image: value?.data?.image,
          id: value?.data?.id,
          is_default: value?.data?.is_default,
        },
      ];
      this.previousSave = this.selectedSave;
    });
  }

  addToBookmarkGroup() {
    //  if (this.selectedSave[0] !== undefined && this.selectedSave[0].id !== 'new collection') {
    // switch (this.bookmarkType) {
    //   case 'TOURS': {
    //     this.bodyCreateBookmark = this.objectBookmark(
    //       this.idAddBookmark,
    //       'TOUR_BOOKMARK',
    //       this.id,
    //       this.name
    //     );
    //     break;
    //   }
    //   case 'HOTELS': {
    //     this.bodyCreateBookmark = this.objectBookmark(
    //       this.idAddBookmark,
    //       'HOTEL_BOOKMARK',
    //       this.id,
    //       this.name
    //     );
    //     break;
    //   }
    //   case 'FOOD': {
    //     this.bodyCreateBookmark = this.objectBookmark(
    //       this.idAddBookmark,
    //       'RESTAURANT_BOOKMARK',
    //       this.id,
    //       this.name
    //     );
    //     break;
    //   }
    //   case 'SOCIAL': {
    //     this.bodyCreateBookmark = this.objectBookmark(
    //       this.idAddBookmark,
    //       'SOCIAL_BOOKMARK',
    //       this.id,
    //       this.name
    //     );
    //     break;
    //   }
    // }
    // this.bookmarkControllerService.bookmarksPost(this.bodyCreateBookmark).subscribe({
    //   next: (value) => {},
    //   complete: () => {
    //     this.getBloomBookmarkService.getBloomlFilterString();

    //   },
    // });
    //  } else if (this.selectedSave[0] !== undefined && this.selectedSave[0].id === 'new collection') {
    switch (this.bookmarkType) {
      case 'TOURS': {
        this.bodyGroupCreateBookmark = this.objectGroupBookmark(this.inputValue, 3, [31, 32], '');
        break;
      }
      case 'HOTELS': {
        this.bodyGroupCreateBookmark = this.objectGroupBookmark(this.inputValue, 2, [21], '');
        break;
      }
      case 'FOOD': {
        this.bodyGroupCreateBookmark = this.objectGroupBookmark(this.inputValue, 4, [43, 44], '');
        break;
      }
      case 'SOCIAL': {
        break;
      }
    }
    this.bookmarkGroupControllerService
      .bookmarksGroupsPost(this.bodyGroupCreateBookmark)
      .pipe(
        mergeMap((value) => {
          if (value.success) {
          }
          return of(value);
        })
      )
      .subscribe((value) => {
        this.bookmarkControllerService
          .bookmarksObjectIdGroupGroupIdPut(this.idAddBookmark, value?.data?.id)
          .subscribe({
            next: (value) => {},
            error: () => {
              this.tourListService.openToastBookmark.next({
                open: true,
                type: 'error',
                summary: this.translationService.getTranslationAsync(
                  'common.component.bookmark_modal.save_tour_failed',
                  { param: this.inputValue }
                ),
                change: false,
                bookmarkType: '',
                id: '',
              });
            },
            complete: () => {
              this.getBloomBookmarkService.getBloomlFilterString();
              this.tourListService.openToastBookmark.next({
                open: true,
                type: 'success',
                summary: this.translationService.getTranslationAsync(
                  'common.component.bookmark_modal.save_tour_success',
                  { param: this.inputValue }
                ),
                change: false,
                bookmarkType: '',
                id: '',
              });
              this.onClose();
            },
          });
      });
    //    }
  }

  updateBookmark() {
    this.bookmarkControllerService
      .bookmarksObjectIdGroupGroupIdPut(this.idAddBookmark, this.id)
      .subscribe({
        next: (value) => {},
        error: () => {
          this.tourListService.openToastBookmark.next({
            open: true,
            type: 'error',
            summary: this.translationService.getTranslationAsync(
              'common.component.bookmark_modal.save_tour_failed',
              { param: this.selectedSave[0].name }
            ),
            change: false,
            bookmarkType: '',
            id: '',
          });
        },
        complete: () => {
          this.tourListService.openToastBookmark.next({
            open: true,
            type: 'success',
            summary: this.translationService.getTranslationAsync(
              'common.component.bookmark_modal.save_tour_success',
              { param: this.selectedSave[0].name }
            ),
            change: false,
            bookmarkType: '',
            id: '',
          });
          this.onClose();
        },
      });
  }

  checkUpdateOrAddNew() {
    this.bookmarkControllerService
      .bookmarksObjectIdIsExistedGet(this.idAddBookmark)
      .subscribe((value) => {
        if (value?.data?.is_existed! && this.selectedSave[0].id !== 'new collection') {
          this.updateBookmark();
        } else if (value?.data?.is_existed! && this.selectedSave[0].id === 'new collection') {
          this.addToBookmarkGroup();
        }
      });
  }

  getTranslationAsync(key: string, interpolateParams?: Object): string {
    return this.translationService.getTranslationAsync(key, interpolateParams);
  }
}
