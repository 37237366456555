import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslationService } from '@core/services';
interface dataDropdown {
  name: string;
  code: string;
}
@Component({
  selector: 'app-add-button',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule],
  templateUrl: './add-button.html',
  styleUrls: ['./add-button.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddButtonComponent implements OnInit {
  @Input() type: 'btnDropdown' | 'btnAdd' | 'btnSave' | 'btnCancel' | 'btnPreview';
  @Input() label: string;
  @Input() stringClass: string;
  @Input() size: 'size-lg' | 'size-sm' = 'size-lg';
  @Input() disabled: boolean = false;
  @Input() btnType: string = 'button';
  state: boolean = false;
  dropdownData: dataDropdown[];
  dropdownValue: dataDropdown;
  @Output() handleClick = new EventEmitter<Event>();
  @Output() handleClickDropdown = new EventEmitter<Object>();

  changeState() {
    this.state = !this.state;
  }

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.dropdownData = [
      {
        name: this.translationService.getTranslationAsync('common.term.save_as_draft'),
        code: 'Save as Draft',
      },
      {
        name: this.translationService.getTranslationAsync('common.term.preview'),
        code: 'Preview',
      },
    ];
  }
}
