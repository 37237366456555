<div
  *ngIf="quickViewVisible"
  class="t-quick-view t-scroll-custom {{ class }}"
  [ngClass]="[isModal ? '!h-[88vh]' : '']"
  @quickPanelAnimation
  overlay-scrollbars
  [options]="{
    scrollbars: {
      autoHide: 'leave',
      autoHideDelay: 100,
      visibility: isEnableScroll ? 'hidden' : 'visible'
    }
  }"
>
  <p-card styleClass="w-full rounded-none ">
    <ng-template pTemplate="header">
      <button class="p-2 t-close-btn" (click)="hidePanel()" *ngIf="!isPreviewMode">
        <i class="sctr-icon-x t-close-icon"></i>
      </button>
    </ng-template>
    <app-overview
      [lang]="lang"
      [isPreviewMode]="isPreviewMode"
      [action]="action"
      (closeOverview)="hidePanel()"
      (handleCloseModal)="handleCloseModal($event)"
      (openModal)="handleOpenModal($event)"
      [isModal]="isModal"
    ></app-overview>
  </p-card>
  <p-scrollTop
    styleClass="t-scroll-top-button"
    target="parent"
    [threshold]="50"
    behavior="auto"
  ></p-scrollTop>
</div>
