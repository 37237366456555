<div [ngSwitch]="type">
  <p-dropdown
    *ngSwitchCase="'btnDropdown'"
    [options]="dropdownData"
    [(ngModel)]="dropdownValue"
    optionLabel="name"
    class="t-btnDropdown"
    styleClass="rounded-lg py-2.5 px-4 md:w-[9.375rem] w-[10.344rem] h-10 flex items-center gap-2 border border-gray-300 bg-gray-50"
    (onChange)="handleClickDropdown.emit($event)"
  >
  </p-dropdown>
  <button
    *ngSwitchDefault
    class="t-{{type}} t-{{size}} font-Inter font-semibold text-base py-2.5 px-[18px] border disabled:opacity-50 disabled:bg-primary-500 disabled:cursor-not-allowed"
    [disabled]="disabled"
    (click)="changeState();handleClick.emit($event)"
    [class.click]="state"
    [type]="btnType"
  >
    {{label}}
  </button>
</div>
