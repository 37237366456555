<div class="flex flex-col gap-3 font-Inter">
  <h4 class="text-lg font-semibold text-gray-800 md:text-xl">
    {{ 'page.tour_detail.expense.estimated' | translateAsync }}
  </h4>
  <div class="flex flex-col">
    <ng-container *ngIf="totalCost; else noCostAdded">
      <ng-container *ngFor="let expense of expenses">
        <app-expense-item
          [isDefaultCurrency]="expenseCurrency === profileCurrency"
          *ngIf="expense.amount"
          [expense]="expense"
        ></app-expense-item>
      </ng-container>
      <div class="flex flex-col items-end mt-3">
        <div class="flex gap-1 items-center font-semibold text-primary-700 opacity-90">
          <span class="text-lg font-normal text-gray-500">
            {{ 'common.term.total' | translateAsync }}:
          </span>
          <div
            *ngIf="expenseCurrency === profileCurrency; else notDefaultCurrencyTempl"
            class="flex gap-0.5 items-center font-semibold"
          >
            <span>{{ expenses[0].currency || profileCurrency | currencySymbol }}</span>
            <span>
              {{ totalCost | number: '1.0-2' }}
            </span>
          </div>
          <ng-template #notDefaultCurrencyTempl>{{ totalCost | currencyConversion }}</ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noCostAdded>
  <div class="text-lg text-gray-700">
    {{ 'page.tour_detail.expense.no_cost_added' | translateAsync }}
  </div>
</ng-template>
