<div
  *ngIf="recPlacesVisible"
  class="border-t-2 t-recommended-places w-max-full t-accrordion"
  [ngClass]="[isModal ? '!h-[88vh]' : '']"
  @quickPanelAnimation
  appResize
  (isDesktopViewChange)="onIsDesktopViewChange($event)"
>
  <div @fadeIn *ngIf="isLoaded">
    <p-card styleClass="p-0">
      <ng-template pTemplate="header">
        <div class="relative flex items-center self-stretch justify-between px-6 py-4 border-b-2">
          <span class="self-stretch text-lg not-italic font-semibold text-gray-900 font-Inter">{{
            'page.create_tour.recommended_places.recommended_places' | translateAsync
          }}</span>
          <button class="t-close-btn" (click)="hideRecPlaces()">
            <i class="sctr-icon-x t-close-icon"></i>
          </button>
        </div>
      </ng-template>
      <div
        class="t-recommended-content t-scroll-custom"
        [ngClass]="[isModal ? '!h-[calc(88vh_-_7.5rem)]' : '']"
      >
        <span class="flex flex-col gap-1.5" *ngIf="places && places.length > 0; else noPlaces">
          <div *ngFor="let place of places">
            <div class="cursor-pointer">
              <app-card2
                [imageUrl]="place['imageUrl']"
                [title]="place['name']"
                [days]="days"
                (handleOnClick)="handleGetPlace($event, place)"
                [isActivated]="place['place_id'] === selectedPlaceId"
                (selectedCard)="handleShowPlaceModal(place)"
                [placeId]="place['place_id']"
              ></app-card2>
            </div>
          </div>
        </span>
        <ng-template #noPlaces
          ><span class="px-4 text-base font-medium">{{
            'page.create_tour.recommended_places.no_places_found' | translateAsync
          }}</span></ng-template
        >
      </div>
    </p-card>
    <div class="flex justify-end px-6 py-4 border-t-2">
      <button
        class="px-4 py-2 text-lg font-semibold text-gray-700 bg-white border border-gray-300 border-solid rounded-lg hover:bg-gray-50"
        type="button"
        (click)="hideRecPlaces()"
      >
        {{ 'common.term.close' | translateAsync }}
      </button>
    </div>
  </div>

  <div @fadeIn *ngIf="!isLoaded" class="relative flex-1 w-full h-full overflow-hidden">
    <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
  </div>
</div>

<ng-template #skeletonTemplate>
  <div class="flex items-center self-stretch justify-between px-6 py-4 border-b-2">
    <div class="flex-1">
      <p-skeleton width="90%" height="1.5rem"></p-skeleton>
    </div>
    <p-skeleton size="1.5rem" shape="circle"></p-skeleton>
  </div>
  <div class="md:h-[calc(100vh_-_212px)] h-[calc(100vh_-_202px)] p-3 overflow-hidden">
    <div class="gap-1.5 flex flex-col mb-1.5">
      <div
        *ngFor="let card of [].constructor(20)"
        class="flex items-center justify-between w-full p-1 pr-2 bg-white border border-gray-300 border-dashed rounded-lg shadow-xs"
      >
        <p-skeleton size="3.5rem" styleClass="mr-3"></p-skeleton>
        <div class="flex-1 mr-3">
          <p-skeleton width="100%" height="2.5rem"></p-skeleton>
        </div>
        <p-skeleton size="1rem" shape="circle"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" height="100%"></p-skeleton>
  </div>
  <div class="flex justify-end px-6 py-4 border-t-2">
    <p-skeleton height="2.75rem" width="5rem"></p-skeleton></div
></ng-template>
