<div class="t-clipboard flex flex-row border border-gray-200 rounded-lg">
  <input
    readonly="'true'"
    type="text"
    pInputText
    [(ngModel)]="inputLink"
    class="grow focus:shadow-none border-none text-gray-800 h-11"
  />
  <p-button
    (click)="onClipboard()"
    icon="sctr-icon-copy-06"
    [label]="title"
    class="t-right-button bg-primary-600 text-white text-lg leading-none font-semibold border-none rounded-none rounded-r-lg w-full md:w-[unset]"
    styleClass="h-11"
    [pTooltip]="'common.component.clipboard.copied' | translateAsync"
    [life]="1000"
    tooltipEvent="focus"
    pTootipEvent="focus"
  ></p-button>
</div>
