<div class="t-review">
  <div class="flex justify-between items-center gap-2">
    <div *ngIf="review.created_by" class="flex items-center gap-3">
      <img
        class="h-12 w-12 rounded-full object-cover"
        src="{{
          review.created_by.avatar_thumbnail_url
            ? (review.created_by.avatar_thumbnail_url | ImagePath: 'thumbnail')
            : 'assets/images/user.jpg'
        }} "
        alt="user"
        onerror="this.src='assets/images/user.jpg'"
      />
      <div class="flex flex-col flex-1">
        <span class="t-review-name">
          {{
            review.created_by.full_name ? review.created_by && review.created_by.full_name : 'user'
          }}
        </span>
        <span class="t-review-created-at">
          {{ review.created_at | customDate }}
        </span>
      </div>
    </div>

    <div *ngIf="!review.created_by" class="flex items-center gap-3">
      <img
        class="h-12 w-12 rounded-full object-cover"
        src="assets/images/user.jpg"
        alt="user"
        onerror="this.src='assets/images/user.jpg'"
      />
      <div class="flex flex-col flex-1">
        <span class="t-review-name">user</span>
        <span class="t-review-created-at">
          {{ review.created_at | customDate }}
        </span>
      </div>
    </div>
    <div *ngIf="tourType === 'Commercial'" class="flex gap-2 min-w-fit">
      <p-chip styleClass="t-review-rating">{{ review.rating | number: '1.1-1' }}</p-chip>
      <strong class="t-review-evaluation">{{ pointCheck }}</strong>
    </div>
  </div>
  <p #content class="t-review-desc" [ngClass]="{ 'line-clamp-3': !isExtend && canViewMore }">
    {{ (review.content ? review.content : 'review content') | autoTranslate: isAutoTranslateTrigger}}
  </p>

  <button
    *ngIf="canViewMore"
    #viewMoreBtn
    class="text-md text-primary-700 font-semibold w-fit"
    (click)="handleViewMore()"
  >
    {{ (isExtend ? 'common.term.view_less' : 'common.term.view_more') | translateAsync }}
  </button>

  <div *ngIf="review.medias" class="flex gap-1">
    <div
      *ngFor="let media of review.medias"
      class="w-[80px] aspect-square rounded-lg overflow-hidden"
    >
      <p-image
        *ngIf="media && media.type?.includes('image')"
        imageClass="w-full rounded-md object-cover aspect-square"
        styleClass="rounded-md t-feedback-image"
        [src]="media.id | ImagePath"
        [preview]="true"
        (onShow)="hideMasterMenu()"
        (onHide)="showMasterMenu()"
      ></p-image>
      <div
        class="w-full h-full"
        (click)="handlePreviewVideo(media)"
        *ngIf="media && media.type?.includes('video')"
      >
        <app-media-player
          [url]="basePath + 'storage/files/stream-video/' + media.id + '.webm'"
          [isCover]="true"
          [showToolbar]="false"
          [isPreviewMode]="true"
          ngClass="t-feedback-video"
        ></app-media-player>
      </div>
      <div
        class="z-50 bg-black fixed top-0 left-0 w-full h-full flex items-center justify-center"
        *ngIf="isOpenVideoModal && media && media.type.includes('video')"
      >
        <div class="w-5/12 h-3/4">
          <app-media-player
            [url]="basePath + 'storage/files/stream-video/' + previewItem.id + '.webm'"
          ></app-media-player>
        </div>
        <button
          class="t-remove-button absolute top-1.5 right-1.5 flex items-center justify-center w-12 h-12"
          (click)="closePreviewVideo($event)"
        >
          <i class="sctr-icon-x text-4xl/9 text-white"></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="appproval" class="flex gap-3">
    <button
      class="t-review-helpful-btn"
      [ngClass]="{ 't-active-btn': isHelpful }"
      (click)="handleClickHelpfulButton(review.id)"
    >
      <i [class]="isHelpful ? 'sctr-icon-solid-thumbs-up' : 'sctr-icon-thumbs-up'"></i>
      {{ 'common.term.helpful' | translateAsync }}
      <span *ngIf="helpfulCount > 0">{{ helpfulCount }}</span>
    </button>
    <button class="t-review-report-btn" (click)="emitOpenReportDialog.emit(review.id)">
      <i class="sctr-icon-flag-01"></i>
      {{ 'common.term.report' | translateAsync }}
    </button>
  </div>
</div>
