import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IRecommendedPlace } from '@soctrip-common/map';

@Injectable({
  providedIn: 'root',
})
export class PlaceService {
  private placeData: Subject<IRecommendedPlace> = new Subject<IRecommendedPlace>();
  private isCreatePlace: Subject<object> = new Subject<object>();

  isPlaceCreateData: object;

  get data$() {
    return this.placeData.asObservable();
  }

  emit(data: IRecommendedPlace) {
    this.placeData.next(data);
  }

  get isCreatePlace$() {
    return this.isCreatePlace.asObservable();
  }

  setIsCreatePlace(data: boolean, action: string) {
    this.isPlaceCreateData = { data, action };
    this.isCreatePlace.next(this.isPlaceCreateData);
  }

  getIsCreatePlaceData() {
    return this.isPlaceCreateData;
  }

  scrollPlaceIntoViewByIndex(dayIndex: number, placeIndex: number) {
    const placeHTMLItem = document.getElementById(
      'place_' + String(dayIndex) + '_' + String(placeIndex)
    );
    const placeRect = placeHTMLItem?.getBoundingClientRect();
    if (placeRect) {
      if (placeRect.top < 0 || placeRect.bottom > window.innerHeight) {
        setTimeout(() => {
          placeHTMLItem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
      }
    }
  }
}
