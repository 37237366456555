import { environment } from '@env/environment';

export const IMAGE_EXTENSION = ['jpg', 'jpeg', 'png', 'webp'];
export const VIDEO_EXTENSION = ['mp4', 'mov', 'avi', 'webm'];

export const getMediaPath = (
  id: string,
  type: 'image' | 'video',
  option: 'web' | 'thumbnail' = 'web'
) => {
  if (type === 'image') return `${environment.baseURL}/storage/files/${option}/${id}.webp`;
  else return `${environment.baseURL}/storage/files/stream-video/${id}.webm`;
};
