import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  markAllReadNotification(baseUrl: string) {
    return this.http.post<any>(`${baseUrl}/notification/notifications/all/read`, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getNotifications(baseUrl: string, pageNum: number, pageSize: number) {
    return this.http
      .get<any>(
        baseUrl + '/notification/notifications/me?pageNum=' + pageNum + '&pageSize=' + pageSize
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }

  toggleReadNotification(baseUrl: string, id: any) {
    return this.http.put<any>(`${baseUrl}/notification/notifications/${id}/read/toggle`, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  deleteNotification(baseUrl: string, id: any) {
    return this.http.delete<any>(`${baseUrl}/notification/notifications/${id}`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  callToActionNotification(baseUrl: string, url: any) {
    return this.http.put<any>(baseUrl + url, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  completeCTANotification(baseUrl: string, id: any) {
    return this.http
      .put<any>(`${baseUrl}/notification/notifications/${id}/cta/complete`, null)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
