import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'ImagePath',
  standalone: true,
})
export class ImagePathPipe implements PipeTransform {
  transform(file: string, option: 'thumbnail' | 'avatar' | 'web' | 'mobile' = 'web'): string {
    if (option === 'avatar' || file?.includes('/')) {
      return `${environment.baseURL}${file}`;
    }
    return `${environment.baseURL}/storage/files/${option}/${file}.webp`;
  }
}
