import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@core/services';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
// import { MessageService } from 'primeng/api';
// import { ToastModule } from 'primeng/toast';

export interface imageUploadInfor {
  imageUrl: string;
  fileName: string;
}
@Component({
  selector: 'app-multi-file-uploader',
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateAsyncPipe],
  templateUrl: './multi-file-uploader.component.html',
  styleUrls: ['./multi-file-uploader.component.scss'],
})
export class MultiFileUploaderComponent {
  @Input() isReset: boolean;
  @Input() acceptedFileTypes: string[] = [];
  @Input() acceptedFileTypesText: string = 'Any File Type';
  @Input() maxFileSize: number = 5000000;
  @Input() listImageUrl: imageUploadInfor[] = [];
  @Input() action: 'create' | 'edit';
  @Output() clearFileSelected = new EventEmitter<imageUploadInfor>();
  @Output() filesSelected = new EventEmitter<File[]>();
  @ViewChild('inputFile') inputFile!: ElementRef<HTMLInputElement>;
  // @Input() listFile :File[] = [];
  imageUrl: string = '';
  isExist = false;
  errorMessage: string = '';
  listUploadFile: File[] = [];
  selectedFiles: File[] = [];
  constructor(
    private cdRef: ChangeDetectorRef, // private messageService: MessageService
    private translationService: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isReset']) {
      this.listUploadFile = [];
      this.listImageUrl = [];
      this.selectedFiles = [];
      this.isExist = false;
      this.errorMessage = '';
    }
    if (changes['listUploadFile']) {
    }
  }

  onUploadFile(event: any) {
    for (const image of event.target.files) {
      if (!this.validateImage(image)) {
        break;
      }
      this.validateAndLoadFile(image);
    }
    this.inputFile.nativeElement.value = '';
  }

  validateImage(image: File): boolean {
    if (!image.type.includes('image/')) {
      this.errorMessage = this.translationService.getTranslationAsync(
        'common.error.only_image_are_suported'
      );
      return false;
    } else if (image.size >= this.maxFileSize) {
      this.errorMessage = this.translationService.getTranslationAsync(
        'common.error.reach_maximum_file_size',
        {
          param: this.maxFileSize / 1000000,
        }
      );
      setTimeout(() => {
        this.errorMessage = '';
      }, 3000);
      return false;
    }
    this.errorMessage = '';
    return true;
  }

  selectFile(): void {
    this.inputFile.nativeElement.click();
  }

  clearFile(image: imageUploadInfor): void {
    this.inputFile.nativeElement.value = '';
    this.imageUrl = '';
    this.listUploadFile = this.listUploadFile.filter((file) => file.name !== image.fileName);
    this.listImageUrl = this.listImageUrl.filter((file) => file.fileName !== image.fileName);
    if (this.listImageUrl.length === 0 || this.listUploadFile.length === 0) {
      this.isExist = false;
      this.errorMessage = '';
    }
    this.filesSelected.emit(this.listUploadFile);
    if (this.action === 'edit') {
      this.clearFileSelected.emit(image);
    }
  }

  public validateAndLoadFile(file: File): void {
    this.isExist = false;
    const reader = new FileReader();
    reader.onload = (data: ProgressEvent<FileReader>) => {
      this.imageUrl = data.target?.result as string;
      this.listImageUrl.forEach((image) => {
        if (image.fileName === file.name) {
          this.isExist = true;
        }
      });
      if (!this.isExist) {
        this.listImageUrl.push({ imageUrl: this.imageUrl, fileName: file.name });
        this.cdRef.detectChanges();
        this.listUploadFile.push(file);
        this.filesSelected.emit(this.listUploadFile);
      }
    };
    reader.readAsDataURL(file);
  }

  formatAcceptedFileTypes(): string {
    return this.acceptedFileTypes.reduce((acc, curr, index) => {
      if (index === 0) {
        return acc + '.' + curr;
      }
      return acc + ', .' + curr;
    }, '');
  }
}
