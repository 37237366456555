import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentContentComponent, CommentLibModule } from '@soctrip-common/comment';
import { UserInfo, Comment } from '@soctrip-common/comment/lib/models/comment.models';
import { GetApiService } from '@shared/services/get-api.service';
import { environment } from '@env/environment';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from '@core/services';
@Component({
  selector: 'app-child-comment',
  standalone: true,
  imports: [CommonModule, CommentLibModule, TranslateAsyncPipe],
  templateUrl: './child-comment.component.html',
  styleUrls: ['./child-comment.component.scss'],
})
export class ChildCommentComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() position: boolean = false;
  @Input() id: string;
  @Input() commentData: Comment;
  childComment: Comment[] = [];
  displayChildComment: Comment[] = [];
  totalCommentChild: number[] = [];
  resultTrans: string = '';
  @Input() userInfo: UserInfo;
  @Input() friendInfo: UserInfo[];
  @Input() hideReply: boolean;
  @Output() childCommentData = new EventEmitter<Comment>();
  @ViewChildren('commentData') comments: QueryList<CommentContentComponent>;
  apiHost: string = environment.BASE_API_URL;
  private destroy$ = new Subject();
  pageComment: number = 0;
  constructor(
    private getApiService: GetApiService,
    private translationService: TranslationService
  ) {}
  ngOnInit(): void {
    this.getApiService.commentChildata.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value && value.parent_id === this.id) {
        //       this.displayChildComment.unshift(value);
        if (
          this.displayChildComment.length > 3 &&
          this.displayChildComment.length < this.totalCommentChild.length
        ) {
          this.displayChildComment = [...[value], ...this.displayChildComment].slice(
            0,
            this.displayChildComment.length
          );
        } else if (this.displayChildComment.length === 3) {
          this.displayChildComment = [...[value], ...this.displayChildComment].slice(0, 3);
        } else if (this.displayChildComment.length === this.totalCommentChild.length) {
          this.displayChildComment = [...[value], ...this.displayChildComment];
        }
        this.totalCommentChild.push(this.totalCommentChild.length + 1);
      }
    });
    this.getChildComment();
  }
  ngAfterViewInit(): void {
    this.comments.changes.subscribe((value) => {
      for (let i = 0; i <= value._results.length; i++) {
        if (value._results[i]?.el?.nativeElement) {
          let emoji =
            value._results[i].el.nativeElement.children[0].children[1].children[1].children[0]
              ?.textContent!;
          let reply =
            value._results[i].el.nativeElement.children[0].children[1].children[1].children[3]
              ?.textContent!;
          if (emoji?.includes('POST.')) {
            value._results[
              i
            ].el.nativeElement.children[0].children[1].children[1].children[0].textContent =
              this.translationService.getTranslationAsync('POST.LOVE');
          }
          if (reply?.includes('COMMENT.')) {
            value._results[
              i
            ].el.nativeElement.children[0].children[1].children[1].children[3].textContent =
              this.translationService.getTranslationAsync('COMMENT.REPLY');
          }
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  translateComment(value: string, part: string): string {
    let result: string = '';
    if (value === part + 'LOVE') {
      result = this.translationService.getTranslationAsync(part + 'LOVE');
    } else if (value === part + 'HAHA') {
      result = this.translationService.getTranslationAsync(part + 'HAHA');
    } else if (value === part + 'WOW') {
      result = this.translationService.getTranslationAsync(part + 'WOW');
    } else if (value === part + 'ANGRY') {
      result = this.translationService.getTranslationAsync(part + 'ANGRY');
    } else if (value === part + 'SAD') {
      result = this.translationService.getTranslationAsync(part + 'SAD');
    }
    return result;
  }
  getChildComment(page: number = 0, pageSize: number = 3) {
    this.getApiService.getReplyComment(this.id, page, pageSize, 'TIME_DESC').subscribe((res) => {
      if (res) {
        this.totalCommentChild = Array.from(Array(res?.data?.totalElement!).keys());
        this.displayChildComment = res?.data?.data!;
      }
    });
  }
  deleteComment(id: string) {
    this.getApiService.deleteComment(id).subscribe({
      error: (value) => {
        console.error(value);
      },
      complete: () => {
        if (this.displayChildComment.length === this.totalCommentChild.length) {
          this.displayChildComment = this.displayChildComment.filter((item) => item.id !== id);
        } else if (
          this.displayChildComment.length > 3 &&
          this.displayChildComment.length < this.totalCommentChild.length
        ) {
          this.getChildComment(0, this.displayChildComment.length);
        } else if (this.displayChildComment.length === 3) {
          this.getChildComment();
        }
        this.totalCommentChild.shift();
      },
    });
  }
  loadMoreComment() {
    this.pageComment++;
    if (this.pageComment <= Number((this.totalCommentChild.length / 3).toFixed(1).slice(0, 1))) {
      this.getApiService
        .getReplyComment(this.id, this.pageComment, 3, 'TIME_DESC')
        .subscribe((res) => {
          this.displayChildComment = [...this.displayChildComment, ...res?.data?.data!];
        });
    }
  }
}
