<div class="flex items-center gap-2 py-2 border-b border-gray-200 border-solid">
  <div class="w-6 h-6 p-1 bg-gray-200 rounded-full text-gray-500">
    <span [ngClass]="(expense.category.length ? expense.category[0] : '') | categoryToIcon"></span>
  </div>
  <div class="flex flex-col items-start w-full">
    <h4 class="items-center font-medium text-primary-700 text-md">{{ expense.name }}</h4>
    <span class="mt-1 text-sm font-medium text-gray-600" *ngIf="expense.category.length">
      <span *ngFor="let cat of expense.category">
        {{ 'common.term.place_category.' + cat | translateAsync }}
      </span>
    </span>
  </div>
  <div class="flex gap-0.5 items-center text-gray-800 break-keep">
    <div *ngIf="isDefaultCurrency; else notDefaultCurrencyTempl">
      <span class="font-bold text-md">{{ expense.currency || 'USD' | currencySymbol }}</span>
      <span class="font-semibold text-md">{{ expense.amount | number: '1.0-2' }}</span>
    </div>
    <ng-template #notDefaultCurrencyTempl>
      <span class="font-semibold text-md">
        {{ expense.price_usd || expense.amount || 0 | currencyConversion }}
      </span>
    </ng-template>
  </div>
</div>
