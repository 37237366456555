import { inject, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeFormat } from '@shared/enum/time-format.enum';
import { CustomDateTimeService } from '@shared/services/custom-date-time.service';

@Pipe({
  name: 'customTimeFormat',
  standalone: true,
})
export class CustomTimeFormatPipe implements PipeTransform {
  private customDateTimeService = inject(CustomDateTimeService);
  transform(value: string, format?: TimeFormat): string {
    if (/\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4}/.test(value)) {
      const datePipe = new DatePipe('en-US');
      const formattedDate = datePipe.transform(value, 'shortTime');
      return formattedDate || '';
    } else {
      const timeValue = this.customDateTimeService.convertToDateTime(value, 'HH:mm:ss');
      return this.customDateTimeService.formatTime(timeValue, format);
    }
  }
}
