<div class="t-share-tour">
  <app-modal
    [showDialog]="isShowDialog"
    (emitOnCloseDialog)="closeModal.emit(false); onCloseDialog()"
    [dialogConfig]="dialogConfig"
  >
    <ng-container modal-header>
      <div class="flex flex-col gap-3">
        <div class="text-xl font-semibold font-Inter text-gray-900">
          {{ 'common.term.share' | translateAsync }}
        </div>
        <div class="list-container" *ngIf="shareMode === 'Share'">
          <div class="list">
            <div class="w-16">
              <img
                class="w-10 h-10 rounded-full mx-auto"
                src="assets/images/favicon.svg"
                (click)="shareToFeed()"
              />
              <span class="line-clamp-2 text-sm text-center">
                {{ 'common.term.new_feed' | translateAsync }}
              </span>
            </div>
          </div>
        </div>
        <app-clipboard
          [title]="'common.term.copy' | translateAsync"
          [inputLink]="linkShare"
        ></app-clipboard>
      </div>
    </ng-container>
  </app-modal>
</div>
<soctrip-post-dialog [tourID]="shareId"></soctrip-post-dialog>
