import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appResize]',
  standalone: true,
})
export class ResizeDirective {
  isDesktopView: boolean = window.innerWidth >= 768;
  @Output() isDesktopViewChange = new EventEmitter<boolean>();

  constructor() {
    this.emitIsDesktopView();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isDesktopView = event.target.innerWidth >= 768;
    this.emitIsDesktopView();
  }

  emitIsDesktopView(): void {
    this.isDesktopViewChange.emit(this.isDesktopView);
  }
}
