import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
})
export class ProgressSpinnerComponent {
  @Input() header: string;
  @Input() description: string;
  @Input() isShowSpinnerOnly: boolean = false;
}
