import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CurrentLocationService } from './current-location.service';

export interface FilterObject {
  type: string;
  value: string;
}

export enum FilterTypes {
  LOCATION = 'location',
  STATUS = 'status',
  PLACE = 'places',
  ADVENTURE_STYLE = 'adventure_styles',
  TOUR_TYPE = 'tour_type',
  PRICE = 'price',
  TOTAL_DAY = 'total_days',
  HASHTAG = 'hashtags',
  SPOTLIGHT = 'spotlights',
  START_DATE = 'a_dates',
  TITLE = 'title',
  SUPPORT_ENGLISH = 'support_english',
  COUNTRY_CODE = 'country_code',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  PLACEORLOCATION = 'places|location',
  TITLE_PLACE_LOCATION = 'title|places|location',
}

export interface IFilter {
  [FilterTypes.LOCATION]?: string;
  [FilterTypes.PLACE]?: string;
  [FilterTypes.SPOTLIGHT]?: string;
  [FilterTypes.HASHTAG]?: string;
  [FilterTypes.ADVENTURE_STYLE]?: string[];
  [FilterTypes.PRICE]?: string;
  [FilterTypes.TOTAL_DAY]?: string;
  [FilterTypes.TOUR_TYPE]?: 'Commercial' | 'Community';
  [FilterTypes.START_DATE]?: number;
  [FilterTypes.TITLE]?: string;
  [FilterTypes.SUPPORT_ENGLISH]?: string;
  [FilterTypes.COUNTRY_CODE]?: string;
  [FilterTypes.LATITUDE]?: number;
  [FilterTypes.LONGITUDE]?: number;
  [FilterTypes.PLACEORLOCATION]?: string;
  [FilterTypes.TITLE_PLACE_LOCATION]?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public defaultFilter: FilterObject[] = [
    {
      type: FilterTypes.STATUS,
      value: 'Active|Published',
    },
  ];
  public filterSubject = new BehaviorSubject<IFilter>({});
  public filterList = new BehaviorSubject<FilterObject[]>([]);
  private currentLocationId: string = '';
  private countryCode: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currentLocationService: CurrentLocationService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.currentLocationId = params['destinationId'];
    });
  }

  updateFilterSubject(filters: IFilter) {
    this.filterSubject.next(filters);
  }

  getQuery(filterObject: IFilter) {
    this.countryCode = this.currentLocationService.getCurrentCountryCode();
    const queryArray: string[] = [];
    Object.keys(filterObject).forEach((key) => {
      if (filterObject[key]) {
        switch (key) {
          case FilterTypes.LOCATION:
            // queryArray.push(`location==${this.currentLocationId}|places==${filterObject[key]}`);
            queryArray.push(`location==${this.currentLocationId}`);
            break;
          case FilterTypes.LATITUDE:
            queryArray.push(`latitude==${filterObject[key]}`);
            break;
          case FilterTypes.ADVENTURE_STYLE:
            queryArray.push(`${key}==${filterObject[key]?.join(';')}`);
            break;
          case FilterTypes.TITLE:
            queryArray.push(`${key}@=${filterObject[key]}`);
            break;
          case FilterTypes.START_DATE:
            queryArray.push(`${key}${filterObject[key]}`);
            break;
          case FilterTypes.PRICE:
            queryArray.push(`${key}${filterObject[key]}`);
            break;
          case FilterTypes.SUPPORT_ENGLISH:
            if (`${filterObject[key]}` === 'true') queryArray.push('tour_guide_language==en');
            break;
          default:
            queryArray.push(`${key}==${filterObject[key]}`);
            break;
        }
      }
    });
    queryArray.push(`${FilterTypes.COUNTRY_CODE}==${this.countryCode}|WL`);
    return queryArray.join(',');
  }

  applyFilterToRoute() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.filterSubject.value,
      queryParamsHandling: 'merge',
    });
  }
}
