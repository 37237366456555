import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { IExtendPlaceVisit } from '../components/tour-guide/tour-guide.component';

@Injectable({ providedIn: 'root' })
export class MediaService {
  public currentPlace$: BehaviorSubject<IExtendPlaceVisit | null> =
    new BehaviorSubject<IExtendPlaceVisit | null>(null);
  public currentTabIndex$ = new BehaviorSubject<{ day_no: number; order: number } | null>(null);
  public endMedia$ = new Subject<boolean>();
}
