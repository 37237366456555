import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ModalComponent } from '@shared/components/modal/modal.component';
import { ClipboardComponent } from '@shared/components/clipboard/clipboard.component';
import { PostDialogComponent } from '@shared/components/create-post-dialog/post-dialog/post-dialog.component';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';

export type ShareMode = 'Copy' | 'Share';
@Component({
  selector: 'app-share-tour',
  templateUrl: './share-tour.component.html',
  styleUrls: ['./share-tour.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ModalComponent,
    ClipboardComponent,
    PostDialogComponent,
    TranslateAsyncPipe,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ShareTourComponent {
  isShowDialog = false;
  dialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'w-full md:max-w-[600px] max-h-[90vh]',
  };
  // tourId: string = '';
  @Input() shareMode: ShareMode = 'Share';
  @Input() linkShare: string = 'https://primeng.org/inputtext';
  @Input() tourId = '';
  @ViewChild(PostDialogComponent) postDialog: any;
  @Output() closeModal = new EventEmitter<boolean>();
  shareId: string;
  constructor() {}

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['linkShare']) {
  //     this.setTourID(this.linkShare);
  //   }
  // }
  // setTourID(linkShare: string) {
  //   if (linkShare && linkShare.concat('id=')) {
  //     this.tourId = linkShare.slice(linkShare.indexOf('id=') + 3);
  //   }
  // }

  onCloseDialog() {
    this.isShowDialog = false;
  }

  onShowDialog() {
    this.isShowDialog = true;
  }

  shareToFeed() {
    this.shareId = this.tourId;
    if (this.postDialog && this.shareId) this.postDialog.openDialog();
  }
}
