<p-overlay
  *ngIf="overlayVisible"
  class="absolute right-80 top-15 max-md:hidden"
  [(visible)]="overlayVisible"
  [contentStyleClass]="'shadow-2 border-round'"
  [styleClass]="'z-10 min-w-0 left-0 custom-noti'"
  >
  <ng-container *ngTemplateOutlet="notifications"></ng-container>
</p-overlay>

<div
  *ngIf="mobileOverlayVisible"
  class="md:hidden fixed top-0 left-0 w-full h-full overflow-y-auto bg-white z-10 custom-noti-mobile"
>
  <ng-container *ngTemplateOutlet="notifications"></ng-container>
</div>

<ng-template #notifications>
  <div class="w-96 bg-white p-0 rounded-lg shadow-lg max-md:w-full h-full">
    <div class="flex flex-col p-4 pb-3">
      <div #targetElement class="relative flex justify-between w-full">
        <div class="flex flex-row gap-2 justify-start items-center w-full text-gray-700">
          <p>{{ "HEADER.NOTIFICATIONS" | translateAsync }}</p>
          <span
            class="flex items-center justify-center w-[34px] h-[34px] rounded-[50%] text-[12px] bg-error-500 text-white"
            >{{ badgeCount }}</span
          >
        </div>
        <div class="gap-1 flex flex-row">
          <span
            class="flex sctr-icon-dots-vertical text-lg cursor-pointer text-gray-700"
            (click)="op1.toggle($event)"
          ></span>
          <span
            class="flex sctr-icon-x-close text-lg cursor-pointer md:hidden"
            (click)="closeDialog()"
          ></span>
        </div>
      </div>
      <p-overlayPanel
        #op1
        [appendTo]="targetElement"
        [styleClass]="
          'overlayPanel-custom !left-auto right-0 !top-full mt-0 before:hidden after:hidden mt-[1px] border-none shadow-lg'
        "
      >
        <button
          class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200"
          (click)="markAllReadNotification($event); op1.hide()"
        >
          <i class="sctr-icon-book-open-01 text-base"></i>
          <span class="text-gray-700 text-sm font-medium">{{
            "HEADER.MARK_ALL_AS_READ" | translateAsync
          }}</span>
        </button>
        <a
          class="cursor-pointer flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200"
          [href]="navigateToAccountSettings()"
        >
          <i class="sctr-icon-settings-02 text-base"></i>
          <span class="text-gray-700 text-sm font-medium">{{
            "HEADER.SETTINGS" | translateAsync
          }}</span>
        </a>
      </p-overlayPanel>
    </div>
    <div class="p-2 space-y-2 max-h-[calc(100%_-_90px)] md:max-h-[588px] scroll-auto overflow-x-hidden t-notification">
      <ng-container *ngFor="let item of notificationList; let i = index">
        <div
          class="space-x-3 w-full flex flex-col flex-nowrap {{
            !item._read ? 'bg-primary-50' : null
          }} p-2 rounded-md"
          (mouseenter)="onMouseEnter(i)"
          (mouseleave)="onMouseLeave()"
        >
          <div class="flex justify-start items-start gap-x-4">
            <div class="relative max-w-fit w-full h-full">
              <img
                class="w-11 h-11 rounded-full cursor-pointer"
                onerror="this.src='assets/images/default_user_avatar.png'"
                [src]="getAvatar(item.sender_avatar)"
              />
              <div
                class="absolute bottom-[-1px] right-[-8px] cursor-pointer w-6 h-6 {{
                  getColorIcon(item.icon_color).background
                }} rounded-full flex items-center justify-center"
              >
                <span
                  class="{{ item.icon_image }} text-xs {{
                    getColorIcon(item.icon_color).color
                  }} text-center"
                ></span>
              </div>
            </div>

            <div
              class="flex flex-col text-sm text-gray-700 font-semibold cursor-pointer"
              (click)="item.web_link && navigationUrl(item, $event)"
            >
              <div class="font-normal text-sm">
                <div>
                  <div class="font-semibold max-w-[10rem] inline-flex">
                    <span class="truncate">{{ item.sender_full_name }}</span>
                  </div>
                  <span>{{ item.sender_full_name ? ' ' : '' }}</span>
                  <span
                    [innerHTML]="
                      item.body !== item.styled_body
                        ? transformBoldStyledBody(item.styled_body)
                        : item.body
                    "
                  ></span>
                </div>
              </div>

              <span class="text-xs font-normal text-gray-500">
                {{ this.calculateFromNow(item.send_time) }}
              </span>
              <ng-container *ngIf="item?.cta_accept">
                <div
                  class="font-normal text-xs"
                  *ngIf="item?.cta_accept === friendRequest.ACCEPTED"
                >
                  {{ "HEADER.REQUEST_ACCEPTED" | translateAsync }}
                </div>
                <div
                  class="font-normal text-xs"
                  *ngIf="item?.cta_accept === friendRequest.DECLINED"
                >
                  {{ "HEADER.REQUEST_DECLINED" | translateAsync }}
                </div>
              </ng-container>
            </div>
            <button
              class="relative sctr-icon-dots-horizontal text-lg text-gray-700 last-of-type:ml-auto transition ease-in-out {{
                showButtonIndex === i ? ' cursor-pointer' : 'text-transparent'
              }} "
              (click)="overlayPanelElement.toggle($event); indexOverlay = i"
            ></button>
            <div></div>
          </div>
          <p-overlayPanel
            #overlayPanelElement
            [appendTo]="'body'"
            [styleClass]="
              'overlayPanel-notifications-custom right-0 mt-0 p-0 before:hidden w-max after:hidden mt-[1px] border-none'
            "
          >
            <button
              class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200"
              (click)="
                toggleNotification(item.id, $event);
                overlayPanelElement.toggle($event);
                indexOverlay = i
              "
            >
              <i class="sctr-icon-book-open-01 text-base"></i>
              <span class="text-gray-700 w-max text-sm font-medium">{{
                item._read
                  ? ("HEADER.UNREAD" | translateAsync)
                  : ("HEADER.READ" | translateAsync)
              }}</span>
            </button>
            <button
              class="flex text-error-500 gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200"
              (click)="
                deleteNotification(item.id, $event, item._read);
                overlayPanelElement.toggle($event);
                indexOverlay = i
              "
            >
              <i class="sctr-icon-trash-03 text-base"></i>
              <span class="text-error-500 w-max text-sm font-medium">{{
                "HEADER.REMOVE" | translateAsync
              }}</span>
            </button>
          </p-overlayPanel>
          <div
            *ngIf="!item._cta_complete && item.cta_action"
            class="flex justify-end gap-3 py-3 pb-1"
          >
            <ng-container *ngFor="let action of item?.cta_action">
              <p-button
                (onClick)="
                  processNotificationAction(
                    action?.post_link,
                    item.id,
                    i,
                    false
                  )
                "
                *ngIf="
                  action?.label === 'Decline' || action?.label === 'Reject'
                "
                styleClass="border-gray-300 bg-white text-gray-700 px-3 py-1 rounded-md cursor-pointer p-button flex gap-1.5"
                [loading]="currentActiveItem === i ? loadingReject : false"
                [disabled]="currentActiveItem === i ? loadingAccept : false"
              >
                <span
                  class="{{
                    action?.icon
                  }} text-gray-700  text-lg cursor-pointer"
                ></span>
                <span>{{ action?.label }}</span>
              </p-button>
              <p-button
                *ngIf="action?.label === 'Accept'"
                (onClick)="
                  processNotificationAction(action?.post_link, item.id, i, true)
                "
                styleClass="border-primary-600 bg-primary-600 text-white px-3 py-1 rounded-md cursor-pointer p-button flex gap-1.5"
                [loading]="currentActiveItem === i ? loadingAccept : false"
                [disabled]="currentActiveItem === i ? loadingReject : false"
              >
                <span
                  class="{{ action?.icon }} text-white text-lg cursor-pointer"
                ></span>
                <span>{{ action?.label }}</span>
              </p-button>
              
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
