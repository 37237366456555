export function normalizeText(text: string) {
  try {
    return text
      .toLowerCase() // Chuyển về chữ thường
      .replace(/['’`]/g, '') // Loại bỏ dấu nháy đơn (cả loại tiêu chuẩn và curly)
      .replace(/[.,!?]/g, '') // Loại bỏ các dấu câu thông thường
      .replace(/\s+/g, ' ') // Loại bỏ khoảng trắng thừa
      .trim(); // Xóa khoảng trắng ở đầu và cuối
  } catch (error) {
    return text;
  }
}
export function generateHash(text: string) {
  try {
    const normalizedText = normalizeText(text);
    let hash = 0;

    // Tạo mã hash đơn giản từ chuỗi normalized
    for (let i = 0; i < normalizedText.length; i++) {
      const char = normalizedText.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Đảm bảo mã hash là số nguyên 32-bit
    }
    return hash;
  } catch (error) {
    return null;
  }
}
