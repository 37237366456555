import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CtaSigninService } from '@shared/services/cta-signin.service';
import { environment } from '@env/environment';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-popup-cta',
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateAsyncPipe],
  templateUrl: './popup-cta.component.html',
  styleUrls: ['./popup-cta.component.scss'],
})
export class PopupCtaComponent {
  pageTitle = environment.PAGE_TITLE;
  isApprovalPage: boolean = false;

  constructor(
    public CtaSigninService: CtaSigninService,
    private route: ActivatedRoute
  ) {
    this.route.url.subscribe((segments) => {
      const url = segments.join('/');
      this.isApprovalPage = url.startsWith('approval');
    });
  }

  handleClickClose(): void {
    this.CtaSigninService.hideCTA();
  }

  handleClickSignInOrSignUp(): void {
    this.CtaSigninService.navigateToSignInPage();
  }
}
