import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MapTourComponent } from '@shared/components/map-tour/map-tour.component';
import {
  MediaPreviewComponent,
  SKIP_MEDIA,
  skipMedia,
} from '../media-preview/media-preview.component';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  lastValueFrom,
  of,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import {
  ItineraryDto,
  PlaceVisitDto,
  TourControllerService,
  TourDetailDto,
  TourDetailLangDto,
} from '@assistant/angular-tour-builder-service';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { fadeIn } from '@shared/animations';
import { TabViewComponent } from '@shared/components/tab-view/tab-view.component';
import { ItineraryService } from '@shared/services/itinerary.service';
import { LocationService, SoctripMapModule, SoctripMapService } from '@soctrip-common/map';
import L from 'leaflet';
import { CdkDragMove, DragDropModule, Point } from '@angular/cdk/drag-drop';
import { ResizableModule, ResizeEvent } from 'angular-resizable-element';
import { _isNumberValue } from '@angular/cdk/coercion';
import { MediaService } from '@modules/tour-guide/services/media.service';
import { SkeletonModule } from 'primeng/skeleton';
import { BreakpointObserver, BreakpointState, LayoutModule } from '@angular/cdk/layout';
import { AnimationMoveMarker } from '@soctrip-common/map/lib/models/map.model';
import { Action } from '@shared/enum/action';
import { HttpClient } from '@angular/common/http';
import {
  CategoryControllerService,
  PlaceControllerService,
} from '@assistant/angular-map-location-service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService, UserService } from '@core/services';
import { MessagesModule } from 'primeng/messages';
import { Message } from 'primeng/api';

export interface ITourPreview {
  tourId: string;
  tourTitle: string;
}
class IShadowCanvas {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  placePoint: Point;
  width: number;
  height: number;
}

class mediaPlayer {
  point: Point;
  shadow: shadow;
  el: HTMLElement;
  width: number;
  height: number;
  distanceFromPlace: Point;
  delta: Point;
  isFullScreen: boolean;
}
interface shadow {
  start: Point;
  moveTo: Array<Point>;
  end: Point;
  gradientTo: Point;
  cp: Array<Point>;
}
export interface IExtendPlaceVisit extends PlaceVisitDto {
  day_no?: number;
}

type MapType = 'osm' | 'google';
@Component({
  selector: 'app-tour-guide',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MapTourComponent,
    MediaPreviewComponent,
    OverlayscrollbarsModule,
    TabViewComponent,
    DragDropModule,
    ResizableModule,
    SkeletonModule,
    LayoutModule,
    SoctripMapModule,
    MessagesModule,
  ],
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn],
})
export class TourGuideComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Tour id
   */
  @Input() tourId: string;
  @Input() lang: string = 'en';
  @Input() action: string;
  @Input() isSetTimer: boolean = false;
  @Input() tourDetails: TourDetailLangDto;
  @Input() centerPlace: L.LatLng;
  @Input() isPreviewMode: boolean;
  @Input() isConvertPrice: boolean = false;
  @Input() isCommercialTour: boolean = false;
  @Input() hasFooterInModal: boolean = false;
  @Output() emitOnCloseTourGuide = new EventEmitter<void>();
  @ViewChild(MapTourComponent) map: MapTourComponent;
  @ViewChild(MediaPreviewComponent) mediaTemplate: MediaPreviewComponent;
  currentPlace: IExtendPlaceVisit;
  tour$: Observable<TourDetailDto>;
  play: boolean = false;
  places: IExtendPlaceVisit[];
  itineraries: ItineraryDto[];
  lstLatLng: [number, number][];
  private canvas: IShadowCanvas = new IShadowCanvas();
  media: mediaPlayer = new mediaPlayer();
  isPinnedMedia: boolean = false;
  placePoint: Point;
  shadowPoint: { [key: string]: shadow };
  style: { position: string; left: string; top: string; width: string; height: string };
  allMarkers: any[] = [];
  currentMarker: any;
  resizing: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  isSlideEnded: boolean = false;
  currentNode: any;
  placeIndex: number;
  dragging: boolean;
  className: string;
  resizedClassName: string;
  dragMoveLastPoint: CdkDragMove;
  isDesktopView: boolean;
  cancelTimer$: Subject<void> = new Subject();
  moveAMarker: any;
  movingMarker: AnimationMoveMarker;
  defaultId = '00000000-0000-0000-0000-000000000000';
  isHasInvalidPlaceId: boolean = false;
  messages: Message[] | undefined = [
    {
      severity: 'warn',
      detail: this.translate.getTranslationAsync('common.component.tour_guide_video.hidden_places'),
    },
  ];

  constructor(
    private tourService: TourControllerService,
    private mediaService: MediaService,
    private cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    private http: HttpClient,
    private categoryService: CategoryControllerService,
    private locationService: LocationService,
    private translateService: TranslateService,
    private placeControllerService: PlaceControllerService,
    private userService: UserService,
    private translate: TranslationService
  ) {}
  newMapService: SoctripMapService = new SoctripMapService(
    this.categoryService,
    this.locationService,
    this.translateService
  );
  newItineraryService: ItineraryService = new ItineraryService(
    this.newMapService,
    this.placeControllerService,
    this.locationService
  );

  initMapLayer(map: any) {
    this.newMapService.setMap(map);
    this.newMapService.typeMap = 'osm';
  }

  ngOnInit(): void {
    // get type map from local storage
    this.newMapService.typeMap = 'osm';

    this.lang = this.userService.getUserLanguage();
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isDesktopView = true;
        } else {
          this.isDesktopView = false;
        }
      });
    this.newItineraryService.markersSubject
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((markers) => {
        if (markers?.length > 0) {
          this.allMarkers = markers;
          this.newMapService?.listMarkers?.length > 0 &&
            this.newMapService?.onMoveMap(() => {
              if (
                this.currentPlace &&
                this.currentPlace.place_id !== this.defaultId &&
                this.currentPlace.place_id !== null &&
                this.currentPlace.place_id !== ''
              ) {
                if (this.currentMarker === undefined) {
                  this.currentMarker = this.allMarkers.find(
                    (marker) =>
                      this.newMapService.getLatLangMarker(marker)?.lat ===
                        this.currentPlace?.latitude &&
                      this.newMapService.getLatLangMarker(marker)?.lng ===
                        this.currentPlace?.longitude
                  )!;
                }
                const point = this.newMapService?.getPointScreenMarker(this.currentMarker!);
                if (this.newMapService.typeMap === 'osm') {
                  this.getCanVas(point);
                } else {
                  this.getCanvas(point as google.maps.Point);
                }

                if (!this.media.isFullScreen) {
                  setTimeout(() => {
                    this.transformPositionMedia(point);
                  }, 500);
                }
              }
            });
        }
      });
    this.tour$ = this.getTourById(this.tourId, true);
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    const timer = this.isSetTimer ? 1000 : 0;
    setTimeout(() => {
      lastValueFrom(this.tour$).then(() => {
        this.mediaService.currentPlace$.pipe(takeUntil(this.unsubscribe$)).subscribe((place) => {
          if (place) {
            if (this.moveAMarker) {
              this.removeMarker(this.moveAMarker);
            }
            this.cancelTimer$.next();
            this.placeIndex = this.places.findIndex((item) => item.id === place.id);
            if (!place.id) {
              this.placeIndex = this.places.findIndex((item) => item.place_id === place.place_id);
            }
            this.currentPlace = this.places[this.placeIndex];
            this.mediaService.currentTabIndex$.next({
              day_no: this.currentPlace.day_no!,
              order: this.currentPlace.order!,
            });
            this.handleShowMarker();
            this.play = true;
          } else {
            this.mediaService.currentPlace$.next(this.places[0]);
          }
          this.cdr.detectChanges();
        });
      });
    }, timer);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.cancelTimer$.next();
    this.cancelTimer$.complete();
    this.mediaService.currentPlace$.next(null);
    this.mediaService.currentTabIndex$.next(null);
    if (this.movingMarker) {
      this.removeMarker(this.movingMarker);
    }
    this.emitOnCloseTourGuide.emit();
  }

  getTourById(tourId: string, first: boolean = false, lang = this.lang): Observable<TourDetailDto> {
    this.newMapService.removeAllCurvePolyline();
    this.tourId = tourId;

    return this.tourService.getTourById(tourId, this.lang).pipe(
      switchMap((res) => {
        if ((!res?.data?.title || !res?.data?.language?.includes(this.lang)) && first) {
          if (res?.data?.language?.includes('en')) {
            this.lang = 'en';
          } else {
            this.lang = res?.data?.language[0] || 'en';
          }

          return this.getTourById(tourId);
        }

        if (!res?.data?.title || !res?.data?.language?.includes(lang)) {
          return of(this.tourDetails as TourDetailDto);
        }

        let filteredTourData = res.data;
        this.onFilteredInvalidPlaceId(filteredTourData);
        if (this.tourDetails) this.onFilteredInvalidPlaceId(this.tourDetails as TourDetailDto);

        this.itineraries = filteredTourData.itineraries as ItineraryDto[];
        const isDataSaved = this.itineraries?.every((item) => {
          const placeOfTourDetails = this.tourDetails?.itineraries?.find(
            (place) => place.id === item.id
          );
          return item.place_visits?.length === placeOfTourDetails?.place_visits?.length;
        });
        if (this.action === Action.UPDATE && this.tourDetails?.itineraries) {
          this.itineraries = this.tourDetails.itineraries as ItineraryDto[];
        }
        this.places = this.itineraries
          ?.sort((a, b) => (a.day_no! < b.day_no! ? -1 : 1))
          .map((item) => {
            const sortedPlaces = item.place_visits?.sort((a, b) => (a.order! < b.order! ? -1 : 1))!;
            return sortedPlaces.map(
              (place): IExtendPlaceVisit => ({ ...place, day_no: item.day_no })
            );
          })
          .flat() as ItineraryDto[];
        this.mediaService.currentPlace$.next(this.places[0]);
        this.lstLatLng = this.places.map((place) => [place.latitude!, place.longitude!]);
        this.newMapService.birdDirection(this.lstLatLng);
        this.newItineraryService.isShowMarkersCalled = false;
        this.newItineraryService.showMarkers(this.itineraries, true, false, this.tourId);
        this.isSlideEnded = false;
        this.cdr.detectChanges();
        if (!isDataSaved && this.tourDetails) {
          this.onFilteredInvalidPlaceId(this.tourDetails as TourDetailDto);
          return of(this.tourDetails as TourDetailDto);
        } else {
          this.onFilteredInvalidPlaceId(res.data);
          return of(res.data as TourDetailDto);
        }
      })
    );
  }
  getCanVas(point: Point) {
    const map = this.map['map'];
    if (map.getPanes().overlayPane.childNodes[1]) {
      map.getPanes().overlayPane.removeChild(map.getPanes().overlayPane.childNodes[1]);
    }
    if (!this.canvas.canvas) {
      this.canvas.canvas = L.DomUtil.create('canvas') as HTMLCanvasElement;
      const mapSize = this.newMapService.getSizeMap();

      this.canvas.canvas.width = mapSize.x;
      this.canvas.canvas.height = mapSize.y;
    }
    this.canvas.canvas.style.top = -map.getPanes().mapPane['_leaflet_pos'].y + 'px';
    this.canvas.canvas.style.left = -map.getPanes().mapPane['_leaflet_pos'].x + 'px';
    this.canvas.ctx = this.canvas.canvas.getContext('2d')!;
    map.getPanes().overlayPane.appendChild(this.canvas.canvas);
    this.canvas.placePoint = point;
  }
  getCanvas(point: google.maps.Point) {
    const map = this.map['map'];
    const overlay = new google.maps.OverlayView();
    overlay.onAdd = () => {
      const panes = overlay.getPanes();
      if (panes?.overlayLayer.childNodes[1]) {
        panes.overlayLayer.removeChild(panes.overlayLayer.childNodes[1]);
      }

      if (!this.canvas.canvas) {
        this.canvas.canvas = document.createElement('canvas') as HTMLCanvasElement;
        const mapDiv = map.getDiv();
        const mapSize = {
          x: mapDiv.offsetWidth,
          y: mapDiv.offsetHeight,
        };

        this.canvas.canvas.width = mapSize.x;
        this.canvas.canvas.height = mapSize.y;
      }

      this.canvas.canvas.style.position = 'absolute';
      this.canvas.canvas.style.top = point.y + 'px';
      this.canvas.canvas.style.left = point.x + 'px';
      this.canvas.ctx = this.canvas.canvas.getContext('2d')!;
      panes?.overlayLayer.appendChild(this.canvas.canvas);
      this.canvas.placePoint = { x: point.x, y: point.y };
    };

    overlay.draw = () => {};
    overlay.setMap(map);
  }

  drawShadow() {
    const point = this.canvas.placePoint;
    const shadow = this.media.shadow;
    this.canvas.ctx.clearRect(0, 0, window.screen.width, window.screen.height);
    // Cubic Bézier curve from place point to media start point
    this.canvas.ctx.beginPath();
    this.canvas.ctx.moveTo(shadow.start.x, shadow.start.y);
    this.canvas.ctx.bezierCurveTo(
      shadow.cp[0].x,
      shadow.cp[0].y,
      shadow.cp[1].x,
      shadow.cp[1].y,
      shadow.moveTo[0].x,
      shadow.moveTo[0].y
    );
    //line from media start point to
    this.canvas.ctx.lineTo(shadow.moveTo[1].x, shadow.moveTo[1].y);
    // Cubic Bézier curve from media end point to place point
    this.canvas.ctx.bezierCurveTo(
      shadow.cp[2].x,
      shadow.cp[2].y,
      shadow.cp[3].x,
      shadow.cp[3].y,
      shadow.end.x,
      shadow.end.y
    );
    const grd = this.canvas.ctx.createLinearGradient(
      point.x,
      point.y,
      shadow.gradientTo?.x,
      shadow.gradientTo?.y
    );
    grd.addColorStop(0, 'rgba(16, 24, 40, 0.04)');
    grd.addColorStop(1, 'rgba(16, 24, 40, 0.20)');
    this.canvas.ctx.strokeStyle = grd;
    this.canvas.ctx.stroke();
    this.canvas.ctx.fillStyle = grd;
    this.canvas.ctx.fill();
  }

  handleShowMarker() {
    this.currentMarker = this.allMarkers.find(
      (marker) =>
        this.newMapService.getLatLangMarker(marker)?.lat === this.currentPlace?.latitude &&
        this.newMapService.getLatLangMarker(marker)?.lng === this.currentPlace?.longitude
    )!;
    if (this.currentMarker) {
      const point = this.newMapService.getPointScreenMarker(this.currentMarker);
      // if (this.map) {
      if (this.newMapService.typeMap === 'osm') {
        this.getCanVas(point);
      } else {
        this.getCanvas(point as google.maps.Point);
      }

      // }
      if (!this.media.isFullScreen && this.media) {
        setTimeout(() => {
          this.transformPositionMedia(point!);
        }, 500);
      }
    }
  }

  async handleMovingMarker(timeInSecond: number = 5) {
    await this.onShowMovingMarkers(
      [this.lstLatLng[this.placeIndex][0], this.lstLatLng[this.placeIndex][1]],
      [this.lstLatLng[this.placeIndex + 1][0], this.lstLatLng[this.placeIndex + 1][1]],
      4,
      '#175CD3'
    );
    timer(timeInSecond * 1000)
      .pipe(takeUntil(this.cancelTimer$))
      .subscribe(() => {
        this.currentPlace = this.places[this.placeIndex + 1];
        this.mediaService.currentPlace$.next(this.currentPlace);
        this.handleShowMarker();
        this.cdr.detectChanges();
      });
  }

  transformPositionMedia(point: Point) {
    if (!this.isDesktopView) return;
    const bestPoint = this.isPinnedMedia ? this.media.point : this.calcBestMediaPosition(point);
    this.media.distanceFromPlace = { x: bestPoint.x - point.x, y: bestPoint.y - point.y };

    if (!this.media.el) {
      this.media.el = document.getElementById('media-player')!;
      this.media.width = this.media.el.clientWidth;
      this.media.height = this.media.el.clientHeight;
      this.media.point = {
        x: point.x + this.media.distanceFromPlace.x,
        y: point.y + this.media.distanceFromPlace.y,
      };
      this.media.el.style.left = this.media.point.x + 'px';
      this.media.el.style.top = this.media.point.y + 'px';
    } else {
      this.media.point = {
        x: point.x! + this.media.distanceFromPlace.x,
        y: point.y! + this.media.distanceFromPlace.y,
      };
    }

    // handle media player move out of the map.
    const map = this.map['map'];
    const mapSize = this.newMapService.getSizeMap();
    if (this.media.point.y < 0) {
      this.media.distanceFromPlace.y -= this.media.point.y;
      this.media.point.y = 0;
    } else if (this.media.point.y + this.media.height > mapSize.y) {
      this.media.distanceFromPlace.y = mapSize.y - this.media.height - this.canvas.placePoint.y;
      this.media.point.y = mapSize.y - this.media.height;
    }
    if (this.media.point.x < 0) {
      this.media.distanceFromPlace.x -= this.media.point.x;
      this.media.point.x = 0;
    } else if (this.media.point.x + this.media.width > mapSize.x) {
      this.media.distanceFromPlace.x = mapSize.x - this.media.width - this.canvas.placePoint.x;
      this.media.point.x = mapSize.x - this.media.width;
    }
    this.media.el.style.left = point.x + 'px';
    this.media.el.style.top = point.y + 'px';

    this.media.el.style.transform = `translate3d(${this.media.distanceFromPlace.x}px, ${this.media.distanceFromPlace.y}px, 0px)`;
    this.media.el.style.opacity = '1';

    //Add effect that media-player content zoom in and out in when appeating to notice user attention
    this.media.el.style.overflow = 'hidden';
    const galleryEl = this.media.el.querySelector('#gallery, gallery') as HTMLElement | null;
    if (galleryEl) {
      galleryEl.style.transition = 'transform 1s ease';
      galleryEl.style.transform = 'scale(1.2)';
      setTimeout(() => {
        galleryEl.style.transition = 'transform 1s ease';
        galleryEl.style.transform = 'scale(1)';
      }, 1000);
    }

    this.checkDelta(this.media.point);
  }
  checkDelta(
    mediaPoint: Point,
    width = this.media.width,
    height = this.media.height,
    distance = this.media.distanceFromPlace
  ) {
    let delta: Point = { x: 0, y: 0 };
    if (mediaPoint.x < this.canvas.placePoint.x) {
      delta.x = mediaPoint.x + width > this.canvas.placePoint.x ? 0 : -1;
    } else {
      delta.x = 1;
    }
    if (mediaPoint.y < this.canvas.placePoint.y) {
      delta.y = mediaPoint.y + height > this.canvas.placePoint.y ? 0 : -1;
    } else {
      delta.y = 1;
    }
    if (delta.x === 0 && delta.y === 0) {
      this.canvas.ctx.clearRect(0, 0, window.screen.width, window.screen.height);
      return;
    }
    this.media.delta = delta;
    this.calcPoint(mediaPoint, width, height, distance);
  }
  calcPoint(p: Point, w: number, h: number, d = this.media.distanceFromPlace) {
    let space: number = 10;
    let s = this.canvas.placePoint;
    let b = 4; //border
    const percent = (percent: number, total: number) => {
      return (percent / 100) * total;
    };
    this.shadowPoint = {
      '{"x":1,"y":0}': {
        //Start point of shadow from place
        start: { x: s.x, y: s.y - space },
        //End point of shadow from place
        end: { x: s.x, y: s.y + space },
        //From start point draw a bezierCurveTo moveTo[0] then draw a line to moveTo[1] and draw a bezierCurveTo end point
        moveTo: [
          { x: p.x + space, y: p.y },
          { x: p.x + space, y: p.y + h },
        ],
        //cp[0] cp[1] of bezierCurve from start point | cp[2] cp[3] of bezierCurve to end point,
        cp: [
          { x: p.x - percent(60, d.x), y: p.y + percent(40, h) },
          { x: p.x - percent(40, d.x), y: p.y },
          { x: p.x - percent(40, d.x), y: p.y + h },
          { x: p.x - percent(60, d.x), y: p.y + percent(60, h) },
        ],
        //Gradient of the shadow
        gradientTo: { x: p.x, y: p.y + h / 2 },
      },
      '{"x":1,"y":1}': {
        start: { x: s.x, y: s.y - space },
        end: { x: s.x, y: s.y + space },
        moveTo: [
          { x: p.x + w - b, y: p.y },
          { x: p.x + space, y: p.y + h },
        ],
        cp: [
          { x: p.x - percent(60, d.x), y: p.y },
          {
            x: p.x + percent(40, d.x),
            y: p.y - percent(60, d.y),
          },
          { x: p.x - percent(40, d.x), y: p.y + h },
          { x: p.x - percent(60, d.x), y: p.y + percent(60, h) },
        ],
        gradientTo: { x: p.x, y: p.y + h / 2 },
      },
      '{"x":0,"y":1}': {
        start: { x: s.x + space, y: s.y },
        end: { x: s.x - space, y: s.y },
        moveTo: [
          { x: p.x + w, y: p.y + space },
          { x: p.x, y: p.y + space },
        ],
        cp: [
          { x: p.x + percent(60, w), y: p.y - percent(60, d.y) },
          { x: p.x + w, y: p.y - percent(40, d.y) },
          { x: p.x, y: p.y - percent(40, d.y) },
          { x: p.x + percent(40, w), y: p.y - percent(60, d.y) },
        ],
        gradientTo: { x: p.x + w / 2, y: p.y },
      },
      '{"x":-1,"y":1}': {
        start: { x: s.x - space, y: s.y + space },
        end: { x: s.x - space, y: s.y - space },
        moveTo: [
          { x: p.x + w, y: p.y + h - b },
          { x: p.x, y: p.y + space },
        ],
        cp: [
          { x: p.x + w, y: p.y - percent(60, d.y) },
          { x: p.x + w + percent(40, d.y), y: p.y + percent(40, h) },
          { x: p.x, y: p.y - percent(40, d.y) },
          { x: p.x + percent(40, w), y: p.y - percent(60, d.y) },
        ],
        gradientTo: { x: p.x + w / 2, y: p.y + h / 2 },
      },
      '{"x":-1,"y":0}': {
        start: { x: s.x - space, y: s.y + space },
        end: { x: s.x - space, y: s.y - space },
        moveTo: [
          { x: p.x + w - space, y: p.y + h },
          { x: p.x + w - space, y: p.y },
        ],
        cp: [
          { x: p.x + w + percent(1, s.x - (d.x + w) * 40), y: p.y + percent(60, h) },
          {
            x: p.x + w + percent(1, s.x - (d.x + w) * 60),
            y: p.y + h,
          },
          {
            x: p.x + w + percent(1, s.x - (d.x + w) * 60),
            y: p.y,
          },
          { x: p.x + w + percent(1, s.x - (d.x + w) * 40), y: p.y + percent(40, h) },
        ],
        gradientTo: { x: p.x + w, y: p.y + h / 2 },
      },
      '{"x":-1,"y":-1}': {
        start: { x: s.x - space, y: s.y + space },
        end: { x: s.x - space, y: s.y - space },
        moveTo: [
          { x: p.x, y: p.y + h - b },
          { x: p.x + w - b, y: p.y },
        ],
        cp: [
          { x: p.x + w + percent(1, s.x - (d.x + w) * 40), y: p.y + h },
          {
            x: p.x + w - percent(1, s.x - (d.x + w) * 60),
            y: p.y + h + percent(1, s.y - (d.y + h) * 60),
          },
          {
            x: p.x + w + percent(1, s.x - (d.x + w) * 60),
            y: p.y,
          },
          { x: p.x + w + percent(1, s.x - (d.x + w) * 40), y: p.y + percent(40, h) },
        ],
        gradientTo: { x: p.x + w / 2, y: p.y + h / 2 },
      },
      '{"x":0,"y":-1}': {
        start: { x: s.x - space, y: s.y - space },
        end: { x: s.x + space, y: s.y - space },
        moveTo: [
          { x: p.x, y: p.y + h - space },
          { x: p.x + w, y: p.y + h - space },
        ],
        cp: [
          { x: p.x + percent(40, w), y: p.y + h + percent(1, s.y - (d.y + h) * 60) },
          { x: p.x, y: p.y + h + percent(1, s.y - (d.y + h) * 40) },
          { x: p.x + w, y: p.y + h + percent(1, s.y - (d.y + h) * 40) },
          { x: p.x + w - percent(40, w), y: p.y + h + percent(1, s.y - (d.y + h) * 60) },
        ],
        gradientTo: { x: p.x + w / 2, y: p.y + h },
      },
      '{"x":1,"y":-1}': {
        start: { x: s.x, y: s.y - space },
        end: { x: s.x, y: s.y + space },
        moveTo: [
          { x: p.x + b, y: p.y },
          { x: p.x + w, y: p.y + h - b },
        ],
        cp: [
          { x: p.x, y: p.y + h + percent(1, s.y - (d.y + h) * 60) },
          { x: p.x + percent(1, s.x - d.x * 40), y: p.y + h - percent(1, s.y - (d.y + h) * 40) },
          { x: p.x + w, y: p.y + h + percent(1, s.y - (d.y + h) * 40) },
          { x: p.x + w - percent(40, w), y: p.y + h + percent(1, s.y - (d.y + h) * 60) },
        ],
        gradientTo: { x: p.x + w / 2, y: p.y + h / 2 },
      },
    };
    this.media.shadow = this.shadowPoint[JSON.stringify(this.media.delta)];
    if (this.newMapService.typeMap === 'osm') {
      this.drawShadow();
    }
  }

  calcBestMediaPosition(point: Point) {
    const map = this.map['map'];
    const mapSize = this.newMapService.getSizeMap();

    const mediaWidth = this.media.width;
    const mediaHeight = this.media.height;

    const widhtDivider = Math.round(mapSize.x / mediaWidth) * 2;
    const heightDvider = Math.round(mapSize.y / mediaHeight) * 2;

    const possibleMediaPoints: Point[] = [];

    for (let w = 0; w < widhtDivider; w++) {
      for (let h = 0; h < heightDvider; h++) {
        possibleMediaPoints.push({
          x:
            (w * mediaWidth) / 2 <= mapSize.x - mediaWidth
              ? (w * mediaWidth) / 2 + 10 //10px for padding
              : mapSize.x - mediaWidth - 10,
          y:
            (h * mediaHeight) / 2 <= mapSize.y - mediaHeight
              ? (h * mediaHeight) / 2 + 10 //10px for padding
              : mapSize.y - mediaHeight - 10,
        });
      }
    }

    const handledPoints = this.handleMediaPoints(
      possibleMediaPoints,
      mediaWidth,
      mediaHeight,
      point
    );
    const bestPoints = this.findClosestPoint(handledPoints, point);

    // { x: 10, y: 10 } default position at the coner top-left
    return { x: bestPoints ? bestPoints.x : 10, y: bestPoints ? bestPoints.y : 10 };
  }
  handleMediaPoints(
    possiblePoints: Point[],
    mediaWidth: number,
    mediaHeight: number,
    currnetMarker: Point
  ) {
    const positionMarkers = this.allMarkers.map((m) => this.newMapService.getPointScreenMarker(m));

    let handledPoints = possiblePoints
      .map((p) => {
        let isOverlay = false;
        positionMarkers.forEach((m) => {
          if (p.x <= m.x && m.x <= p.x + mediaWidth && p.y <= m.y && m.y <= m.y + mediaHeight) {
            isOverlay = true;
          }
        });

        return { ...p, isOverlay };
      })
      ?.filter((p) => !p.isOverlay);

    if (handledPoints.length < 1) {
      handledPoints = possiblePoints
        .map((p) => {
          let isOverlay = false;
          if (
            p.x <= currnetMarker.x &&
            currnetMarker.x <= p.x + mediaWidth &&
            p.y <= currnetMarker.y &&
            currnetMarker.y <= p.y + mediaHeight
          ) {
            isOverlay = true;
          }

          return { ...p, isOverlay };
        })
        ?.filter((p) => !p.isOverlay);
    }

    return handledPoints;
  }
  findClosestPoint(points: Point[], marker: Point) {
    if (!points || points.length < 1) return null;

    let closestPoint = points[0];
    let closestDistance = this.calcDistance(closestPoint, marker);

    points.forEach((p) => {
      const distance = this.calcDistance(p, marker);
      if (distance < closestDistance) {
        closestPoint = p;
        closestDistance = distance;
      }
    });

    return closestPoint;
  }
  calcDistance(point: Point, marker: Point) {
    const deltaX = point.x - marker.x;
    const deltaY = point.y - marker.y;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }

  dragMove(e: CdkDragMove) {
    this.dragMoveLastPoint = e;
    this.media.distanceFromPlace.x = this.media.point.x + e.distance.x - this.canvas.placePoint.x;
    this.media.distanceFromPlace.y = this.media.point.y + e.distance.y - this.canvas.placePoint.y;
    this.media.el.style.transform = `translate3d(${this.media.distanceFromPlace.x}px, ${this.media.distanceFromPlace.y}px, 0px)`;
    this.checkDelta({ x: this.media.point.x + e.distance.x, y: this.media.point.y + e.distance.y });
  }

  dragEnd(e) {
    e.event.stopPropagation();
    e.event.preventDefault();
    this.dragging = false;
    this.media.point = {
      x: this.media.point.x + this.dragMoveLastPoint.distance.x,
      y: this.media.point.y + this.dragMoveLastPoint.distance.y,
    };
    this.media.distanceFromPlace = {
      x: this.media.point.x - this.canvas.placePoint.x,
      y: this.media.point.y - this.canvas.placePoint.y,
    };
    this.isPinnedMedia = true;
  }
  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 250;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX || event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }
  onResizeEnd(e: ResizeEvent): void {
    this.resizing = false;
    this.style = {
      position: 'absolute',
      left: `${e.rectangle.left}px`,
      top: `${e.rectangle.top}px`,
      width: `${e.rectangle.width}px`,
      height: `${e.rectangle.height}px`,
    };
    //calc media point
    this.media.width += e.edges.right as number;
    if (e.edges.bottom) {
      //resize at bottom right corner
      this.media.height += e.edges.bottom as number;
    } else if (e.edges.top) {
      //resize at top right corner
      const top = e.edges.top as number;
      this.media.point.y += top;
      this.media.height -= top;
      this.media.distanceFromPlace.y += top;
    }
  }
  onResizing(e: ResizeEvent) {
    let width = this.media.width,
      height = this.media.height,
      mediaPoint: Point = { x: this.media.point.x, y: this.media.point.y },
      distance: Point = { x: this.media.distanceFromPlace.x, y: this.media.distanceFromPlace.y };
    width += e.edges.right as number;
    if (e.edges.bottom) {
      //resize at bottom right corner
      height += e.edges.bottom as number;
    } else if (e.edges.top) {
      //resize at top right corner
      const top = e.edges.top as number;
      mediaPoint.y += top;
      height -= top;
      distance.y += top;
    }
    this.checkDelta(mediaPoint, width, height, distance);
  }

  handleSlideEnded(event: boolean) {
    this.isSlideEnded = event;
    if (this.placeIndex < this.places.length - 1) {
      timer(1000)
        .pipe(takeUntil(this.cancelTimer$))
        .subscribe(() => {
          this.handleMovingMarker();
        });
      this.mediaService.endMedia$.next(false);
    } else {
      this.play = false;
      this.mediaService.endMedia$.next(true);
    }
  }

  @HostListener('fullscreenchange', ['$event'])
  handle() {
    if (!document.fullscreenElement) {
      this.mediaTemplate.isFullScreen = false;
      this.media.isFullScreen = false;
      this.media.el.className = this.className;
      const child = this.media.el.firstElementChild!;
      child.className = child.className.replace('!w-full !h-full', 'w-full h-full');
      this.mediaTemplate.detectChanges();
    }
  }
  toggleScreen(element: HTMLElement, isFullScreen: boolean) {
    if (!this.media.isFullScreen && !this.media.el) {
      this.media.el = document.getElementById('media-player')!;
    }
    this.media.isFullScreen = isFullScreen;
    const style = 'top-0 left-0 w-full h-full !transform-none';
    const child = this.media.el.firstElementChild!;
    const currentMapZoomLevel = this.newMapService.getZoomMap();
    const gapZoomLevel = 2; //Adjust the level between map full-screen and mini-map
    if (isFullScreen) {
      this.className = this.media.el.className;
      this.media.el.className = style;
      child.className = child.className.replace('w-full h-full', '!w-full !h-full');
      this.openFullscreen(element);
      this.newMapService.getMap()?.setZoom(currentMapZoomLevel - gapZoomLevel);
    } else {
      this.media.isFullScreen = isFullScreen;
      this.media.el.className = this.className;
      child.className = child.className.replace('!w-full !h-full', 'w-full h-full');
      this.closeFullscreen();
      this.newMapService.getMap()?.setZoom(currentMapZoomLevel + gapZoomLevel);
    }
  }
  openFullscreen(elem: HTMLElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  handleSkipMedia(e: skipMedia) {
    if (e === SKIP_MEDIA.NEXT) {
      this.placeIndex += 1;
    } else if (e === SKIP_MEDIA.PREVIOUS) {
      this.placeIndex -= 1;
    }
    this.mediaService.currentPlace$.next(this.places[this.placeIndex]);
  }

  async onShowMovingMarkers(
    latLngFrom: [number, number],
    latLngTo: [number, number],
    time?: number,
    color?: string
  ) {
    // if (!latLngList[0] || !latLngList[1]) return;
    const point = this.newMapService.createCurvePoints(
      [latLngFrom[0], latLngFrom[1]],
      [latLngTo[0], latLngTo[1]]
    );

    if (point) {
      this.movingMarker = this.newMapService.onAnimationMoveMarker(
        point,
        time!,
        undefined,
        color!
      ) as AnimationMoveMarker; // Add type assertion here
    }
  }
  removeMarker(currentAMarker: AnimationMoveMarker) {
    this.newMapService.removeAnimationMoveMarker(currentAMarker);
  }

  onFilteredInvalidPlaceId(data: TourDetailDto) {
    data?.itineraries?.forEach((itinerary: ItineraryDto) => {
      itinerary.place_visits =
        itinerary.place_visits?.sort((a, b) => (a.order || 0) - (b.order || 0)) || [];
      itinerary.place_visits = itinerary.place_visits.filter((place: PlaceVisitDto) => {
        const isValid =
          place.place_id && place.place_id !== this.defaultId && place.place_id !== '';
        if (!isValid) this.isHasInvalidPlaceId = true;
        return isValid;
      });

      itinerary.place_visits.forEach((place, index) => {
        place.order = index + 1;
      });
    });
  }
}
