import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@core/services';

@Pipe({
  name: 'translateAsync',
  standalone: true,
})
export class TranslateAsyncPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}
  transform(key: string, interpolateParams?: Object): string {
    return this.translationService.getTranslationAsync(key, interpolateParams);
  }
}
