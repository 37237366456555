import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpandHeaderService {
  private isExpandHeader = new BehaviorSubject<boolean | null>(null);
  isExpandHeader$: Observable<boolean | null> = this.isExpandHeader.asObservable();

  setExpandHeader(value: boolean): void {
    this.isExpandHeader.next(value);
  }
}
