import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ExpenseItemComponent,
  IExpenseItem,
} from '@shared/components/expense-item/expense-item.component';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { CurrencySymbolPipe } from 'src/common/pipe/currency-symbol.pipe';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss'],
  standalone: true,
  imports: [
    ExpenseItemComponent,
    CommonModule,
    CurrencySymbolPipe,
    TranslateModule,
    TranslateAsyncPipe,
    CurrencyConversionPipe,
  ],
})
export class ExpenseComponent implements OnInit, OnChanges {
  @Input() expenses: IExpenseItem[] = [];
  @Input() isPreviewMode: boolean;
  @Input() expenseCurrency: string;
  @Input() profileCurrency: string = 'USD';
  totalCost: number = 0;
  constructor() {}

  ngOnInit() {
    let isDefaultCurrency = this.profileCurrency === this.expenseCurrency;
    this.totalCost = this.expenses.reduce((accumulator, object) => {
      return accumulator + (isDefaultCurrency ? object.amount || 0 : object?.price_usd || 0);
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expenses'] || changes['profileCurrency']) {
      let isDefaultCurrency = this.profileCurrency === this.expenseCurrency;
      this.totalCost = this.expenses.reduce((accumulator, object) => {
        return accumulator + (isDefaultCurrency ? object.amount || 0 : object?.price_usd || 0);
      }, 0);
    }
  }
}
