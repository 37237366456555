<div *ngIf="displayChildComment.length > 0">
  <div *ngFor="let comment of displayChildComment; let i = index">
    <div class="pl-12 pt-3">
      <lib-comment-content
        #commentData
        [apiHost]="apiHost"
        [commentData]="comment"
        [positionFixed]="position"
        [isChild]="true"
        [userProfile]="userInfo"
        [postOwnerId]="comment?.created_by?.id!"
        (onReplyClick)="childCommentData.emit(comment)"
        (deletedCommentId)="deleteComment(comment.id)"
        [isLivestream]="hideReply"
      ></lib-comment-content>
    </div>
  </div>
  <button
    *ngIf="
      displayChildComment &&
      totalCommentChild.length > 3 &&
      displayChildComment.length !== totalCommentChild.length
    "
    class="text-md font-semibold text-primary-700 hover:underline pt-3"
    (click)="loadMoreComment()"
  >
    {{ 'page.tour_detail.customer_review.view_more_comments' | translateAsync }}
  </button>
</div>
