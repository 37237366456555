<div class="t-rich-text flex flex-col gap-3 font-Inter">
  <h4 class="font-semibold text-gray-800" [ngClass]="size === 'medium' ? 'text-xl' : 'text-lg'">
    {{ header }}
  </h4>
  <div class="text-gray-700">
    <div
      #content
      [class]="'text-md ql-editor text-left ' + seoClass"
      [ngClass]="{ 'line-clamp-3': !isExtend && canViewMore }"
      [innerHTML]="data | safeHtml"
    ></div>
  </div>
  <button
    *ngIf="canViewMore"
    class="w-fit font-semibold text-primary-700"
    [ngClass]="size === 'medium' ? 'text-lg' : 'text-md'"
    (click)="toggleViewMore()"
  >
    {{ getTranslationAsync(isExtend ? 'common.term.view_less' : 'common.term.view_more') }}
  </button>
</div>
