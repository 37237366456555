import { Injectable } from '@angular/core';
import { ResponseModelBloomFilterDetailDTO } from '@assistant/angular-tour-builder-service';
import { BehaviorSubject, lastValueFrom, of, switchMap } from 'rxjs';
import { BookmarkBasicDTO, BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { BookmarkGroupControllerService } from '@soctrip/angular-bookmarks-service';
import { TourListService } from './tour-list.service';
@Injectable({
  providedIn: 'root',
})
export class GetBloomBookmarkService {
  public reloadBookmark = new BehaviorSubject(false);
  isBookmarked: boolean;
  bloomResponse: ResponseModelBloomFilterDetailDTO;
  bodyCreateBookmark: BookmarkBasicDTO;
  constructor(
    private bookmarkDataService: BookmarkControllerService,
    private bookmarkGroupControllerService: BookmarkGroupControllerService,
    private tourListService: TourListService
  ) {}
  // Get bloom filter for bookmark
  async getBloomlFilterString() {
    const getBloom$ = this.bookmarkDataService.bookmarksBloomFilterGet();
    this.bloomResponse = await lastValueFrom(getBloom$);
    localStorage.setItem('bloomBookmarkString', this.bloomResponse.data?.bloom_filter!);
    this.reloadBookmark.next(true);
  }
  handleBookmark(type: string, objectId: string) {
    let group = { group_name: '', group_id: '' };
    let typeBookmark = '';
    this.bookmarkGroupControllerService
      .bookmarksGroupsSearchTabGet(0, 1000, type)
      .pipe(
        switchMap((data) => {
          if (data) {
            data?.data?.data!.forEach((value) => {
              if (value.is_default) {
                group = { group_name: value.name, group_id: value.id };
              }
            });
          }
          return of(group);
        })
      )
      .subscribe((value) => {
        if (type === 'FOOD') {
          typeBookmark = 'RESTAURANT';
        } else if (type === 'TOURS') {
          typeBookmark = 'TOUR';
        } else if (type === 'HOTELS') {
          typeBookmark = 'HOTEL';
        }
        let bodyCreate: BookmarkBasicDTO = {
          object_id: objectId,
          dag_object: typeBookmark + '_BOOKMARK',
          group_id: value.group_id,
          group_name: value.group_name,
        };
        this.bookmarkDataService.bookmarksPost(bodyCreate).subscribe({
          next: (value) => {},
          error: (error) => {
            let message: string;
            if (error?.status === 403) {
              message = 'Please login before bookmarking';
            } else {
              message =
                'Failed to saved this ' +
                typeBookmark.toLowerCase() +
                ' to “' +
                value.group_name +
                '”';
            }
            this.tourListService.openToastBookmark.next({
              open: true,
              type: 'error',
              summary: message,
              change: false,
              bookmarkType: type,
              id: '',
            });
          },
          complete: () => {
            this.getBloomlFilterString();
            this.tourListService.openToastBookmark.next({
              open: true,
              type: 'success',
              summary:
                'This ' +
                typeBookmark.toLowerCase() +
                ' was saved successfully to “' +
                value.group_name +
                '”',
              change: true,
              bookmarkType: type,
              id: objectId,
            });
          },
        });
      });
  }
  handleUnBookmark(type: string, objectId: string) {
    let typeBookmark = '';
    if (type === 'FOOD') {
      typeBookmark = 'RESTAURANT';
    } else if (type === 'TOURS') {
      typeBookmark = 'TOUR';
    } else if (type === 'HOTELS') {
      typeBookmark = 'HOTEL';
    }
    this.bookmarkDataService.bookmarksObjectIdDelete(objectId).subscribe({
      next: (value) => {},
      error: (error) => {
        let message: string;
        if (error?.status === 403) {
          message = 'Please login before bookmarking';
        } else {
          message = 'Failed to unsaved this ' + typeBookmark.toLowerCase();
        }
        this.tourListService.openToastBookmark.next({
          open: true,
          type: 'error',
          summary: message,
          change: false,
          bookmarkType: '',
          id: '',
        });
      },
      complete: () => {
        this.getBloomlFilterString();
        this.tourListService.openToastBookmark.next({
          open: true,
          type: 'success',
          summary: 'Unsaved ' + typeBookmark.toLowerCase() + ' successfully',
          change: false,
          bookmarkType: '',
          id: '',
        });
      },
    });
  }
}
