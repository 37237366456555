import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { TranslationService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';

@Component({
  selector: 'app-view-more-panel',
  templateUrl: './view-more-panel.component.html',
  styleUrls: ['./view-more-panel.component.scss'],
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe, TranslateModule],
})
export class ViewMorePanelComponent implements AfterViewInit {
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() data: string;
  @Input() header: string;
  @Input() seoClass: string = '';
  @ViewChild('content') content: ElementRef<HTMLElement>;
  LINE_HEIGHT: number = 20;
  LIMIT_LINE: number = 3;
  isExtend: boolean = false;
  canViewMore: boolean = false;

  private translationService = inject(TranslationService);

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.content?.nativeElement.clientHeight <= this.LINE_HEIGHT * this.LIMIT_LINE &&
        !!this.content?.nativeElement.clientHeight
      ) {
        this.canViewMore = false;
      } else {
        this.canViewMore = true;
        this.isExtend = false;
      }
    }, 0);
  }

  toggleViewMore() {
    this.isExtend = !this.isExtend;
  }

  getTranslationAsync(key: string, interpolateParams?: Object): string {
    return this.translationService.getTranslationAsync(key, interpolateParams);
  }
}
