export interface IListObject {
  [key: string]: string;
}

export const getValues = (obj: IListObject[] | IListObject) => {
  try {
    if (Array.isArray(obj)) {
      return obj.map((item) => item['name']);
    } else if (obj && Object.keys(obj)) {
      return Object.values(obj);
    }
    return [];
  } catch (error) {
    return [];
  }
};
