import { trigger, state, style, transition, animate } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
      visibility: 'hidden',
    })
  ),
  transition('void <=> *', [
    animate(
      '0.25s ease-in',
      style({
        visibility: 'visible',
        opacity: 1,
      })
    ),
  ]),
]);
