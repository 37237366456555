import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';

import { TourControllerService } from '@assistant/angular-tour-builder-service';
import { GetBloomHelpfulService } from '@shared/services/get-bloom-helpful.service';

import { BloomFilter } from '@soctrip-common/soctrip-algorithm/dist/api';
import { CommentLibModule } from '@soctrip-common/comment';
import { TranslationService } from '@core/services';

import { ImagePathPipe } from '@shared/pipes/image-path.pipe';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { CustomDatePipe } from '@shared/pipes/custom-date.pipe';
import { MediaPlayerComponent } from '@shared/components/media-player/media-player.component';
import { environment } from '@env/environment';
import { AutoTranslatePipe } from '@shared/pipes/auto-translate.pipe';

interface IMediaUploadInfo {
  id: string;
  name: string;
  type: string;
  imageUrl?: string;
}

@Component({
  selector: 'app-review-item',
  standalone: true,
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    /* primeng */
    ChipModule,
    ImageModule,
    ButtonModule,
    CarouselModule,
    CommentLibModule,
    /* pipe */
    ImagePathPipe,
    TranslateAsyncPipe,
    CustomDatePipe,
    MediaPlayerComponent,
    AutoTranslatePipe,
  ],
})
export class ReviewComponent implements OnInit, AfterViewInit {
  @Input() review: any;
  @Input() appproval: boolean;
  @Input() tourType: string;
  @Input() isAutoTranslateTrigger: number;
  @Output() emitOpenReportDialog = new EventEmitter<string>();
  responsiveOptions: any[] = [];
  @ViewChild('content') content: ElementRef<HTMLElement>;
  @ViewChild('viewMoreBtn') btnShow: ElementRef<HTMLElement>;
  isExtend: boolean = false;
  canViewMore: boolean = false;
  image: string = 'https://primefaces.org/cdn/primeng/images/demo/avatar/onyamalimba.png';
  pointCheck: string;
  https: any;
  isHelpful: boolean;
  helpfulCount: number = 0;
  currentHelpfulCount: number = 0;
  masterMenu: any;
  isOpenVideoModal: boolean = false;
  previewItem: IMediaUploadInfo;
  basePath = `${environment.baseURL}/`;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private getBloomHelpfulService: GetBloomHelpfulService,
    private tourControllerService: TourControllerService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.pointRange(this.review.rating as number);
    this.masterMenu = document.getElementsByTagName('app-master-header')[0];
  }

  ngAfterViewInit(): void {
    this.handleCheckIsHelpful(this.review.id);
    this.checkViewmoreContent();
    this.changeDetectorRef.detectChanges();
  }

  checkViewmoreContent() {
    if (this.content?.nativeElement.clientHeight <= 60) {
      this.canViewMore = false;
    } else {
      this.canViewMore = true;
      this.isExtend = false;
    }
  }

  handleViewMore() {
    this.isExtend = !this.isExtend;
  }

  hideMasterMenu() {
    this.masterMenu.classList.add('!z-0');
  }

  showMasterMenu() {
    this.masterMenu.classList.remove('!z-0');
  }

  pointRange(point: number) {
    if (point >= 6 && point < 7) {
      this.pointCheck = this.translationService.getTranslationAsync(
        'common.component.review.pleasant'
      );
    } else if (point >= 7 && point < 8) {
      this.pointCheck = this.translationService.getTranslationAsync(
        'common.component.review.very_good'
      );
    } else if (point >= 8 && point < 9) {
      this.pointCheck = this.translationService.getTranslationAsync(
        'common.component.review.excellent'
      );
    } else if (point >= 9) {
      this.pointCheck = this.translationService.getTranslationAsync(
        'common.component.review.superb'
      );
    } else if (point < 6) {
      this.pointCheck = this.translationService.getTranslationAsync(
        'common.component.review.uncomfortable'
      );
    }
  }

  handleCheckIsHelpful(reviewId: string) {
    let bloomHelpfulString = this.getBloomHelpfulService.getBloomHelpful();
    if (bloomHelpfulString) {
      let bloomString = BloomFilter.load(bloomHelpfulString);
      if (bloomString.has(reviewId)) {
        this.isHelpful = true;
      } else this.isHelpful = false;
      this.getHelpfulCount(this.review);
    }
  }

  handleClickHelpfulButton(reviewId: string) {
    if (this.isHelpful) {
      this.isHelpful = false;
      if (this.helpfulCount > 0) this.helpfulCount -= 1;
      this.tourControllerService.handleUnVoteHelpfulByLoggedInUser(reviewId).subscribe({
        next: (response) => {
          if (!response.success) this.failedToUnVoteHelpful();
        },
        error: () => {
          this.failedToUnVoteHelpful();
        },
      });
    } else {
      this.isHelpful = true;
      this.helpfulCount += 1;
      this.tourControllerService.handleVoteHelpfulByLoggedInUser(reviewId).subscribe({
        next: (response) => {
          if (!response.success) this.failedToVoteHelpful();
        },
        error: () => {
          this.failedToVoteHelpful();
        },
      });
    }
  }

  failedToUnVoteHelpful() {
    this.isHelpful = true;
    this.helpfulCount = this.currentHelpfulCount;
  }

  failedToVoteHelpful() {
    this.isHelpful = false;
    if (this.helpfulCount > 0) this.helpfulCount = this.currentHelpfulCount;
  }

  getHelpfulCount(review: any) {
    this.helpfulCount = review?.reactions[0]?.count;
    this.currentHelpfulCount = this.helpfulCount;
  }

  handlePreviewVideo(previewItem: IMediaUploadInfo) {
    this.isOpenVideoModal = true;
    this.previewItem = previewItem;
    this.hideMasterMenu();
  }

  closePreviewVideo(event: Event) {
    this.isOpenVideoModal = false;
    this.showMasterMenu();
  }
}
