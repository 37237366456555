import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, of } from 'rxjs';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';

import { TruncateStringPipe } from 'src/common/pipe/truncate-string.pipe';
import { CurrencySymbolPipe } from 'src/common/pipe/currency-symbol.pipe';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';

import {
  Media,
  ResponseModel,
  TourControllerService,
  TourDetailLangDto,
} from '@assistant/angular-tour-builder-service';
import { AvatarService } from '@shared/services/avatar.service';
import { ToastService, TranslationService } from '@core/services';

import { TourCardComponent } from '@shared/components/tour-card/tour-card.component';
import { LimitImageBoxComponent } from '@shared/components/limit-image-box/limit-image-box.component';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutes } from 'src/app/config/routes.config';
import { getMediaPath } from '@shared/utils/getMediaPath';
import { environment } from '@env/environment';

interface FileWithPreview {
  thumbnail: string;
  original: string;
  file?: any;
}

interface DropdownOption {
  label: string;
  value: any;
  image: string;
}

@Component({
  selector: 'soctrip-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss'],
  providers: [MessageService],
  standalone: true,
  imports: [
    /* Primeng */
    CommonModule,
    ToastModule,
    DropdownModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextareaModule,
    ButtonModule,
    TourCardComponent,
    TruncateStringPipe,
    CurrencySymbolPipe,
    LimitImageBoxComponent,
    AvatarModule,
    PickerModule,
    /* Translate */
    TranslateModule,
    TranslateAsyncPipe,
    CurrencyConversionPipe,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PostDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tourID!: string;
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter(false);
  postForm: FormGroup;
  myOptions: DropdownOption[] = [
    {
      label: this.translationService.getTranslationAsync('common.component.post_dialog.public'),
      value: 'PUBLIC',
      image: 'sctr-icon-globe-04',
    },
    {
      label: this.translationService.getTranslationAsync('common.component.post_dialog.friends'),
      value: 'FRIENDS',
      image: 'sctr-icon-users-01',
    },
    {
      label: this.translationService.getTranslationAsync('common.component.post_dialog.private'),
      value: 'PRIVATE',
      image: 'sctr-icon-user-01',
    },
    // {
    //   label: this.translationService.getTranslationAsync('common.component.post_dialog.friend_except'),
    //   value: 'FRIEND_EXCEPT',
    //   image: 'sctr-icon-solid-settings-02',
    // },
  ];
  selectedOption: any = 'PUBLIC';
  id: string = '';
  tourData$: TourDetailLangDto;
  title: string = '';
  location: string = '';
  highlight: string = '';
  imageList: string[] = [];
  position: string;
  onOffEmoji: boolean = false;
  apiUrl = `${environment.baseURL}/posts/posts/link`;
  avatar = '';
  profileCurrency: string = 'USD';

  constructor(
    private formBuilder: FormBuilder,
    private TourService: TourControllerService,
    private httpClient: HttpClient,
    public avatarService: AvatarService,
    private toastServive: ToastService,
    private translationService: TranslationService,
    public currencyConversionPipe: CurrencyConversionPipe
  ) {
    this.postForm = this.formBuilder.group({
      content: [''],
    });
  }

  ngOnInit() {
    this.profileCurrency = this.currencyConversionPipe.getData()?.currency;
    this.setDialogStyle();
    window.addEventListener('resize', this.onResize.bind(this));
    this.avatarService.getData().subscribe((data) => {
      if (data.includes('.webp')) {
        this.avatar = data;
      } else {
        this.avatar = `${data}.webp`;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tourID']) {
      if (this.tourID) this.id = this.tourID;
      this.fetchData();
    }
  }

  addEmoji(event: any) {
    this.postForm
      .get('content')
      ?.setValue(
        (this.postForm.get('content')?.value === null ? '' : this.postForm.get('content')?.value) +
          event.emoji.native
      );
  }

  fetchData() {
    if (this.id) {
      this.TourService.getTourById(this.id)
        .pipe(catchError((error) => of({})))
        .subscribe((data: ResponseModel) => {
          if (data.data) {
            this.tourData$ = data.data;
            this.title = this.tourData$.title ? this.tourData$.title : '';
            const locationId = this.tourData$.location
              ? Object.keys(this.tourData$.location)[0]
              : '';
            this.location = this.tourData$.location ? this.tourData$.location[locationId] : '';
            this.highlight = this.tourData$.highlight ? this.tourData$.highlight : '';
            this.imageList = this.getAllImages(this.tourData$);
          }
        });
    }
  }

  onDialogHide(): void {
    this.visibleChange.emit(false);
  }

  openDialog() {
    this.postForm.reset();
    this.visible = true; // Set the value to true to open the dialog.
  }

  closeDialog() {
    this.visible = false; // Set the value to false to open the dialog.
  }

  onShowDialog() {
    this.postForm.get('content')?.addValidators(Validators.required);
  }

  onSubmit() {
    const post = {
      content: this.postForm.value.content,
      post_type: 'USER',
      post_privacy: this.selectedOption,
      friend_tags: [],
      share_link: this.getLinkShare(this.id),
      share_link_type: 'TOUR',
      dag_object_name: 'TOUR_SHARE_OBJECT',
      dag_object_id: this.id,
    };

    this.httpClient.post<any>(this.apiUrl, post).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastServive.success(
            this.translationService.getTranslationAsync(
              'common.component.post_dialog.share_tour_to_feed_successfully'
            )
          );
        } else {
          this.toastServive.error(
            this.translationService.getTranslationAsync(
              'common.component.post_dialog.share_tour_to_feed_failed'
            )
          );
        }
      },
      error: (error) => {
        this.toastServive.error(
          this.translationService.getTranslationAsync(
            'common.component.post_dialog.share_tour_to_feed_failed'
          ) +
            '\n' +
            error.error.error
        );
        console.log(error);
      },
    });
    this.closeDialog();
  }

  onChangePriority(value: any) {
    this.selectedOption = value;
  }

  shouldDisableButton(): boolean {
    let check = false;

    if (
      this.postForm.get('content')?.value === '' ||
      this.postForm.get('content')?.value === null
    ) {
      check = true;
    }

    return check;
  }

  getAllImages(tourDetails: TourDetailLangDto): string[] {
    let allImgsPath: string[] = [];
    const images = this.getAllGalleries(tourDetails);
    if (images.length > 0) {
      allImgsPath = this.getAllGalleriesPath(images);
    }
    return allImgsPath;
  }

  getAllGalleries(formData: TourDetailLangDto): Media[] {
    const galleries: Media[] = [];
    if (formData.galleries) {
      formData.galleries?.forEach((gallery) => {
        if (gallery.id) {
          galleries.push(gallery);
        }
      });
    }
    if (formData.tour_type === 'Commercial') {
      const index = galleries.findIndex((item) => item.id === formData.avatar);
      if (index !== -1) {
        const item = galleries[index];
        galleries.splice(index, 1);
        galleries.unshift(item);
      }
    }
    if (formData.itineraries) {
      formData.itineraries
        ?.sort((a, b) => Number(a.day_no) - Number(b.day_no))
        .forEach((itinerary) => {
          itinerary.place_visits
            ?.sort((a, b) => Number(a.order) - Number(b.order))
            ?.forEach((place) => {
              if (place.galleries) {
                place.galleries.forEach((img) => {
                  if (img.id) {
                    galleries.push(img);
                  }
                });
              }
            });
        });
    }
    return galleries;
  }

  getAllGalleriesPath(medias: Media[]): string[] {
    return medias.map((item: Media) =>
      item.type?.includes('video')
        ? getMediaPath(item.id as string, 'video')
        : getMediaPath(item.id as string, 'image', 'thumbnail')
    );
  }

  getLinkShare(id: string): string {
    const params = new HttpParams().set('id', id);
    const shareTourListLink = `${environment.TRAVEL_ASSIST_URL}/${
      AppRoutes.TOUR_DETAIL
    }?${params.toString()}`;
    return shareTourListLink;
  }

  setDialogStyle() {
    if (window.innerWidth >= 768) {
      this.position = 'center';
    } else {
      this.position = 'bottom';
    }
  }

  onResize(event: any) {
    this.setDialogStyle();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }
}
