import { Component, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderComponent, IHeaderConfig } from '@layout/header/header.component';
import { TOP_BAR_MENU } from 'src/app/config/topbar-menu.config';
import { MenuItem } from 'primeng/api';
import { AppRoutes } from 'src/app/config/routes.config';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { environment } from '@env/environment';
import { RouteHistoryService } from '@shared/services/route-history.service';
import { MasterHeaderComponent } from '@layout/master-header/master-header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    MasterHeaderComponent,
  ],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnDestroy {
  menu: Array<MenuItem> = structuredClone(TOP_BAR_MENU);
  headerConfig: IHeaderConfig;
  isHomePage: boolean;
  unsubscribe$: Subject<void> = new Subject();
  private readonly routeHistory = inject(RouteHistoryService);
  routerEvent$ = inject(Router).events.pipe(
    filter((event) => event instanceof NavigationEnd),
    distinctUntilChanged(),
    takeUntil(this.unsubscribe$)
  );
  pageTitle = environment.PAGE_TITLE;
  constructor() {
    this.routerEvent$.subscribe((event) => {
      if (event['url'] === '/' || event['url'].includes(AppRoutes.HOME)) {
        this.isHomePage = true;
        this.headerConfig = {
          menuItem: this.menu.map((item) => {
            item.styleClass = 'text-white font-medium rounded-md hover:bg-[#00000030]';
            return item;
          }),
          logo: `assets/svg/home_${this.pageTitle.toLowerCase()}_logo.svg`,
          backgroundStyleClass: 'backdrop-blur-sm bg-[#00000032]',
          textStyleClass: 'text-white',
          iconStyleClass: 'text-white hover:bg-[#00000030]',
          dropdownStyleClass: 't-header-home-dropdown',
          mobileMenuStyleClass: '!bg-[#00000032]',
        };
      } else {
        this.isHomePage = false;
        this.menu = this.menu.map((item) => {
          item.styleClass = 'text-gray-500 font-medium hover:bg-gray-200 rounded-md';
          return item;
        });
        this.headerConfig = {
          menuItem: this.menu,
          logo: `assets/svg/${this.pageTitle.toLowerCase()}_logo.svg`,
          backgroundStyleClass: 'bg-white',
          iconStyleClass: 'text-gray-500 hover:bg-gray-200',
          textStyleClass: 'text-gray-500',
          dropdownStyleClass: 't-header-dropdown',
          mobileMenuStyleClass: 'bg-white',
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
