<div *ngIf="isLoadingBrowseAccount; else display">
  <app-progress-spinner [isShowSpinnerOnly]="true"> </app-progress-spinner>
</div>

<ng-template #display>
  <router-outlet></router-outlet>
  <app-confirmation></app-confirmation>
  <app-bookmark-modal></app-bookmark-modal>
  <app-toast-modal-bookmark></app-toast-modal-bookmark>
  <app-popup-cta></app-popup-cta>
  <lib-chat-popup
    class="t-chat-popup"
    *ngIf="environment.SOCCHAT_VISIBLE && isEnableChat && userProfile"
    [baseUrl]="environment.baseURL"
    [userInfo]="userProfile"
    [module]="environment.CHAT_MODULE"
    [isEnableChatBot]="true"
    [isDisableCloseDialogFromOutside]="true"
  ></lib-chat-popup>

  <!-- <iframe
  style="display:none;"
  id="social-app"
  [src]="sanitizer.bypassSecurityTrustResourceUrl(this.socialAppConnectUrl)"
  title="Social App"
  (load)="connect()"
></iframe> -->
  <p-toast
    #toast
    [position]="screenWidth >= BREAKPOINT.MD ? 'top-right' : 'bottom-center'"
    [baseZIndex]="9999"
    [breakpoints]="{
      '768px': { width: '90%', bottom: '1.375rem' }
    }"
  >
    <ng-template let-message pTemplate="message">
      <div
        class="flex items-center self-stretch w-full gap-3 py-4 pl-4 pr-3"
        (click)="handleToastItemClick(message)"
        [ngClass]="{
          'cursor-pointer': message.data?.clickCb,
          'text-gray-700': message.severity === TOAST_SEVERITY.DEFAULT,
          'text-primary-600': message.severity === TOAST_SEVERITY.PRIMARY,
          'text-error-600': message.severity === TOAST_SEVERITY.ERROR,
          'text-warning-600': message.severity === TOAST_SEVERITY.WARN,
          'text-success-600': message.severity === TOAST_SEVERITY.SUCCESS,
          'text-cyan-600': message.severity === TOAST_SEVERITY.INFO
        }"
      >
        <i
          class="block text-2xl/none self-baseline"
          [ngClass]="
            message.icon
              ? message.icon
              : {
                  'sctr-icon-info-circle':
                    message.severity === TOAST_SEVERITY.DEFAULT ||
                    message.severity === TOAST_SEVERITY.PRIMARY ||
                    message.severity === TOAST_SEVERITY.INFO,
                  'sctr-icon-alert-circle': message.severity === TOAST_SEVERITY.ERROR,
                  'sctr-icon-check-circle': message.severity === TOAST_SEVERITY.SUCCESS,
                  'sctr-icon-alert-triangle': message.severity === TOAST_SEVERITY.WARN
                }
          "
        ></i>
        <div class="flex flex-col items-start w-full gap-1 text-md">
          <p *ngIf="message.summary" class="font-semibold" [innerHTML]="message.summary"></p>
          <p
            *ngIf="message.detail"
            class="font-medium whitespace-pre-line"
            [innerHTML]="message.detail"
          ></p>
        </div>
      </div>
    </ng-template>
  </p-toast>
  <ng-template hostView></ng-template>

  <div class="t-auth-loader w-full" *ngIf="this.autoNavigateGuard.loader">
    <div class="w-full h-[31.25rem] flex items-center justify-center flex-col gap-2">
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px', width: '13rem' }"
      ></p-progressBar>
      <span *ngIf="this.autoNavigateGuard.loader === 'WAITING_TOKEN'" class="font-Inter text-md font-medium">{{
        'common.term.getting-user-info' | translate
      }}</span>
      <span *ngIf="this.autoNavigateGuard.loader === 'NAVIGATING'" class="font-Inter text-md font-medium">{{
        'common.term.navigating' | translate
      }}</span>
    </div>
  </div>
</ng-template>
