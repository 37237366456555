<div class="t-map-routing-container">
  <p-sidebar
    class="t-routing-sidebar"
    [(visible)]="sidebarVisible"
    position="right"
    [dismissible]="false"
    [showCloseIcon]="true"
    [modal]="false"
    (onHide)="onHide()"
  >
    <ng-template pTemplate="header">
      <div class="text-gray-700 text-md md:text-xl font-semibold font-Inter text-center">
        {{ 'common.component.map_routing.direction' | translateAsync }}
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="t-direction">
        <div class="mt-1 w-full">
          <div class="font-semibold text-gray-800 text-lg">
            {{ 'common.component.map_routing.from' | translateAsync }}
          </div>
          <div class="w-full h-12 mt-1">
            <app-map-search
              placeholder="Choose starting point"
              [isShowSeachBox]="true"
              [selectedItem]="selectedItemFrom"
              (selectItemCallback)="onSelectItemFrom($event)"
              (clear)="clearFrom()"
            ></app-map-search>
          </div>
        </div>
        <div class="mt-1 w-full">
          <div class="font-semibold text-gray-800 text-lg">
            {{ 'common.component.map_routing.to' | translateAsync }}
          </div>
          <div class="w-full h-12 mt-1">
            <app-map-search
              placeholder="Choose destination"
              [isShowSeachBox]="true"
              [selectedItem]="selectedItemTo"
              (selectItemCallback)="onSelectItemTo($event)"
              (clear)="clearTo()"
            ></app-map-search>
          </div>
        </div>
        <div class="justify-end flex">
          <button
            class="text-white font-bold py-2 px-4 rounded-lg mt-2"
            [ngClass]="isDisableClear() ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-700'"
            (click)="ClearDirection()"
            [disabled]="isDisableClear()"
          >
            {{ 'common.component.map_routing.clear' | translateAsync }}
          </button>
        </div>
      </div>

      <div class="t-direction-details" *ngIf="routeData?.routes" [style.max-height]="maxHeight">
        <div class="text-gray-700 text-md md:text-xl font-semibold font-Inter mb-2">
          {{ 'common.component.map_routing.direction_details' | translateAsync }}
        </div>
        <div class="border-b-4 border-t-gray-900 border-t-solid">
          <div class="w-full block font-semibold">
            {{ 'common.component.map_routing.summary' | translateAsync }}
          </div>
          <div class="w-full block">
            {{ 'common.component.map_routing.distance' | translateAsync }}
            {{
              routeData?.routes[0]?.summary.distance
                ? (routeData?.routes[0]?.summary.distance / 1000 | number: '1.2-2')
                : 0
            }}
            km
          </div>
          <div class="w-full block">
            {{ 'common.component.map_routing.duration' | translateAsync }}
            {{
              routeData?.routes[0]?.summary.duration
                ? formatDuration(routeData?.routes[0]?.summary.duration)
                : '0 seconds'
            }}
          </div>
        </div>
        <div>
          <ng-container *ngFor="let item of routeData.routes[0].segments[0].steps; let i = index">
            <div class="border-b-2 border-t-gray-700 border-t-solid mt-2 pb-2">
              <div class="w-full block font-semibold">
                {{ 'common.component.map_routing.step' | translateAsync }} {{ i + 1 }}
              </div>
              <div class="w-full block">
                {{ 'common.component.map_routing.on' | translateAsync }} {{ item.name }}
              </div>
              <div class="w-full block">
                <span *ngIf="item.type === 0" class="sctr-icon-corner-up-left"></span>
                <span *ngIf="item.type === 1" class="sctr-icon-corner-up-right"></span>
                <span
                  *ngIf="item.type === 2 || item.type === 4"
                  class="sctr-icon-arrow-narrow-up-left"
                ></span>
                <span
                  *ngIf="item.type === 3 || item.type === 5"
                  class="sctr-icon-arrow-narrow-up-right"
                ></span>
                <span *ngIf="item.type === 6" class="sctr-icon-arrow-up"></span>
                <!-- <div *ngIf="item.type === 7" class="sctr-icon-arrow-circle-up"></div>
              <div *ngIf="item.type === 8" class="sctr-icon-arrow-circle-up"></div> -->
                <!-- <div *ngIf="item.type === 9" class="sctr-icon-arrow-circle-up"></div> -->
                <span *ngIf="item.type === 10" class="sctr-icon-marker-pin-04"></span>
                <span *ngIf="item.type === 11" class="sctr-icon-marker-pin-01"></span>
                <span *ngIf="item.type === 12" class="sctr-icon-arrow-narrow-left"></span>
                <span *ngIf="item.type === 13" class="sctr-icon-arrow-narrow-right"></span>
                {{ item.instruction }}
              </div>
              <span class="w-full block sctr-icon-route">
                {{ item.distance ?? 'N/A' }}
                {{ 'common.component.map_routing.meter' | translateAsync }}</span
              >
              <span class="w-full block sctr-icon-clock1">
                {{ item.duration ?? 'N/A' }}
                {{ 'common.component.map_routing.seconds' | translateAsync }}</span
              >
            </div>
          </ng-container>
        </div>
      </div>
      <div class="t-direction-details" *ngIf="routeData?.error">
        <div class="text-gray-700 text-md md:text-xl font-semibold font-Inter mb-2">
          {{ 'common.component.map_routing.direction_error' | translateAsync }}
        </div>
        <div class="border-b-4 border-t-gray-900 border-t-solid">
          <div class="w-full block font-semibold">{{ routeData?.error.error.message }}</div>
        </div>
      </div>
    </ng-template>
    <!-- <ng-template pTemplate="footer">
      <div class="h-4">

      </div>
    </ng-template> -->
  </p-sidebar>
</div>
