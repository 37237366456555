import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomVideoComponent } from '@modules/review-tour/components/custom-video/custom-video.component';
import { Media, PlaceVisitDto } from '@assistant/angular-tour-builder-service';
import { getMediaPath } from '@shared/utils/getMediaPath';
import { GalleryItem, GalleryModule, GalleryRef } from 'ng-gallery';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { MiniMapComponent } from '@shared/components/mini-map/mini-map.component';
import { scrollTextFromBottom } from '@shared/animations';
import { Subject, takeUntil, timer } from 'rxjs';

const SIZE = {
  small: {
    width: '450px',
    height: '338px',
  },
  medium: {
    width: '450px',
    height: '338px',
  },
  large: {
    width: '450px',
    height: '338px',
  },
} as const;

export enum SKIP_MEDIA {
  NEXT = 'next',
  PREVIOUS = 'previous',
}
export type skipMedia = 'next' | 'previous';

@Component({
  selector: 'app-media-preview',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CustomVideoComponent,
    GalleryModule,
    ToggleButtonModule,
    MiniMapComponent,
  ],
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scrollTextFromBottom],
})
export class MediaPreviewComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  /**
   * component play
   */
  @Input() play: boolean = false;
  /**
   * itineraries data
   */
  @Input() place: PlaceVisitDto;
  /**
   * is first place
   */
  @Input() isFirst: boolean;
  /**
   * is last place
   */
  @Input() isLast: boolean;
  /**
   * emit slide end
   */
  @Output() slideEnded = new EventEmitter<boolean>();
  /**
   * toggle screen
   */
  @Output() toggleScreen = new EventEmitter<boolean>();
  /**
   * next, previous
   */
  @Output() skipMedia = new EventEmitter<skipMedia>();

  @Input() isFullScreen: boolean;

  @Input() isLastPlace: boolean;

  galleries: GalleryItem[] = [];
  slideSpeed = '1x';
  @ViewChild('gallery') gallery: GalleryRef;
  @ViewChild('music') music: ElementRef<HTMLAudioElement>;
  @ViewChildren('video') video: QueryList<ElementRef<HTMLVideoElement>>;
  shiftDescUp: boolean;
  isVideoShow: boolean;
  _play: boolean;
  browserName: string;
  currVideoIndex = 0;
  musicArr = ['RiverFlowsInYou.mp3', 'KissTheRain.mp3', 'WaitThere.mp3', 'WhenTheLoveFalls.mp3'];
  selectedMusic: string;
  transitionTime = { '1x': 5000, '2x': 2500 };
  slideTimeOutFn;
  sideEnd: boolean;
  SKIP_MEDIA = SKIP_MEDIA;
  mute: boolean;
  videoText: string = 'custom-video';
  imgText: string = 'image';

  scrollTextState: 'play' | 'pause' | 'reset';
  cancelTimer$ = new Subject<void>();
  isPaused: boolean = false;
  isSkippedPlace: boolean = false;

  constructor(public cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.cancelTimer$.next();
    this.cancelTimer$.complete();
  }

  ngOnInit() {
    this._play = this.play;
    this.browserName = this.getBrowserName();
    this.selectedMusic = this.musicArr[Math.floor(Math.random() * this.musicArr.length)];
  }

  ngAfterViewInit(): void {
    this._play = true;
    this.scrollTextState = 'play';
    if (this.galleries[0]?.type! === this.imgText) {
      this.music.nativeElement.play();
      this.gallery.play();
    } else {
      this.music.nativeElement.pause();
      this.gallery.stop();
    }
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['place']?.currentValue) {
      this.slideTimeOutFn && clearTimeout(this.slideTimeOutFn);
      this.galleries = [];
      if (this.place.description) {
        this.scrollTextState = 'reset';
        this.cdr.detectChanges();
      }
      if (this.video?.length > 0) {
        for (let item of this.video.toArray()) {
          item.nativeElement.pause();
          item.nativeElement.removeAttribute('src');
          item.nativeElement.load();
        }
      }
      this.galleries =
        (this.place?.galleries?.map((item: Media) => {
          if (item.type?.includes('video')) {
            return {
              type: 'custom-video',
              data: {
                src: getMediaPath(item.id as string, 'video'),
              },
            };
          } else {
            return {
              type: 'image',
              data: {
                src: getMediaPath(item.id as string, 'image'),
                thumb: getMediaPath(item.id as string, 'image', 'thumbnail'),
              },
            };
          }
        }) as GalleryItem[]) ?? [];
      if (this.galleries.length === 0) {
        this.galleries = [
          {
            type: 'image',
            data: {
              src: 'assets/images/video-thumbnail.jpg',
            },
          },
        ];
      }
      this.gallery?.reset();
      if (this.galleries[0].type === this.videoText) {
        this.shiftDescUp = true;
        this.isVideoShow = true;
        this._play = true;
        timer(3000)
          .pipe(takeUntil(this.cancelTimer$))
          .subscribe(() => {
            this.shiftDescUp = false;
            this.gallery?.stop();
            this.music?.nativeElement.pause();
            this.scrollTextState = 'play';
            this.video.toArray()[0].nativeElement.play();
          });
      } else {
        if (!this.isPaused || this.isSkippedPlace) {
          this.isSkippedPlace = false;
          this._play = true;
          this.scrollTextState = 'play';
          this.gallery?.play();
          this.music?.nativeElement.play();
          if (this.galleries.length === 1) {
            timer(3000)
              .pipe(takeUntil(this.cancelTimer$))
              .subscribe(() => {
                this.sideEnd = true;
                if (this._play) this.handleSlideEnd();
              });
          }
        }
      }
    }
  }

  handleClick(e) {
    this._play = !this._play;
    if (!this._play) {
      this.isPaused = true;
      this.scrollTextState = 'pause';
      this.isVideoShow && this.video.toArray()[this.currVideoIndex].nativeElement.pause();
      this.gallery.stop();
      this.music.nativeElement.pause();
      if (this.slideTimeOutFn) {
        clearTimeout(this.slideTimeOutFn);
      }
    } else {
      this.scrollTextState = 'play';
      this.isPaused = false;
      if (this.isVideoShow) this.video.toArray()[this.currVideoIndex].nativeElement.play();
      else {
        this.gallery.play();
        this.music.nativeElement.play();
        if (this.sideEnd) {
          this.handleSlideEnd();
        }
      }
    }
  }

  handleIndexChange(event: any) {
    const isVideoNext = this.isVideoNext(event.items[event.currIndex].data.src);
    const currentType = event.items[event.currIndex].type;
    if (currentType === this.videoText) {
      this.isVideoShow = true;
      this.gallery.stop();
      this.music.nativeElement.pause();
      let videoList = event.items.filter((item) => item.type === this.videoText);
      this.currVideoIndex = (videoList as Array<any>).findIndex(
        (item) => item === event.items[event.currIndex]
      );
      if (this.currVideoIndex !== -1) {
        this.shiftDescUp = true;
        timer(3000)
          .pipe(takeUntil(this.cancelTimer$))
          .subscribe(() => {
            this.shiftDescUp = false;
            this.video.toArray()[this.currVideoIndex].nativeElement.play();
          });
      }
    } else if (currentType === this.imgText && isVideoNext) {
      this.handleVolumeDown();
    } else {
      this.isVideoShow = false;
      this.shiftDescUp = false;
      if (event.currIndex === event.items.length - 1) {
        this.sideEnd = true;
        this.handleSlideEnd();
      }
    }
    this.detectChanges();
  }

  handleSlideEnd() {
    this.slideTimeOutFn = setTimeout(() => {
      if (this.isLast) {
        this.handleVolumeDown();
        setTimeout(() => {
          this._play = false;
          this.scrollTextState = 'pause';
          this.music.nativeElement.pause();
        }, this.transitionTime[this.slideSpeed]);
      }
      this.slideEnded.emit(true);
      this.sideEnd = false;
    }, this.transitionTime[this.slideSpeed]);
  }

  handleVideoEnd(isLast: boolean, event: Event) {
    const currentSrc = (event.target as HTMLVideoElement).currentSrc;
    const currentIndex = this.galleries.findIndex((x) => x.data?.src === currentSrc);
    const nextItemType = this.galleries[currentIndex + 1]?.type;
    if (!isLast && this._play && nextItemType !== this.videoText) {
      this.scrollTextState = 'play';
      this.gallery.play();
      this.music.nativeElement.volume = 1;
      this.music.nativeElement.play();
    } else if (!isLast && this._play && nextItemType === this.videoText) {
      this.scrollTextState = 'play';
      this.gallery.play();
      this.music.nativeElement.pause();
    } else {
      this.slideEnded.emit(true);
      if (!this.isLastPlace) {
        this.scrollTextState = 'play';
        this.music.nativeElement.volume = 1;
        this.music.nativeElement.play();
      } else {
        this.music.nativeElement.pause();
        this._play = false;
        this.scrollTextState = 'pause';
        this.shiftDescUp = true;
      }
    }
    this.isVideoShow = false;
    this.shiftDescUp = true;
  }

  handlePauseVideo() {
    this.isPaused = true;
    if (this._play) {
      this.isPaused = false;
      this.scrollTextState = 'play';
      this.gallery.play();
    }
  }

  handleToggleSlideSpeedBtn(event: { checked: boolean; originalEvent: PointerEvent }) {
    if (event.checked) this.slideSpeed = '2x';
    else this.slideSpeed = '1x';
    this.cdr.detectChanges();
  }

  handleMiniMapDbClick(event: MouseEvent) {
    event.stopPropagation();
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  handleSkipPlace(type: skipMedia, e: Event) {
    this.isSkippedPlace = true;
    this.cancelTimer$.next();
    e.stopPropagation();
    this.skipMedia.emit(type);
    this.cdr.detectChanges();
  }
  toggleMusicVolume(mute: boolean, e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.music.nativeElement.muted = mute;
    this.mute = mute;
  }
  getline() {}

  private handleVolumeDown() {
    const volumeSteps = 9;
    const initialVolume = 1;
    const targetVolume = 0.1;
    const time = this.transitionTime[this.slideSpeed];

    const decrement = (initialVolume - targetVolume) / volumeSteps;
    let currentVolume = initialVolume;

    const volumeInterval = setInterval(() => {
      currentVolume -= decrement;
      this.music.nativeElement.volume = currentVolume;

      if (currentVolume <= targetVolume) {
        clearInterval(volumeInterval);
      }
    }, time / volumeSteps);
  }

  isVideoNext(src: string): boolean {
    const currentIndex = this.galleries.findIndex((x) => x.data?.src === src);
    const nextItemType = this.galleries[currentIndex + 1]?.type;
    const isVideoNext = nextItemType === this.videoText ? true : false;

    return isVideoNext;
  }
}
