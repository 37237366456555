<div class="flex flex-col h-full">
  <div class="flex-auto overflow-y-auto" cdkScrollable>
    <div class="bg-contain t-col-tour">
      <div
        class="h-full bg-white border-r border-gray-200"
        [ngClass]="[
          openMapService.open || showQuickView || showFilter || showRecPlaces
            ? 'hidden md:block'
            : '',
          sidebarService.showSidebar ? 'md:col-span-5' : 'md:col-span-4'
        ]"
      >
        <router-outlet></router-outlet>
      </div>
      <div
        class="relative md:h-full"
        [ngClass]="sidebarService.showSidebar ? 'md:col-span-7' : 'md:col-span-8'"
      >
        <div
          class="md:w-1/3 md:sticky"
          [ngClass]="[showQuickView ? 'block' : 'hidden', handleZIndex ? 'md:z-[100]' : 'md:z-[2]']"
        >
          <!-- Quickview -->
          <app-quick-view (showQuickView)="onShowQuickView($event)">
            <app-overview></app-overview>
          </app-quick-view>
        </div>
        <!-- Advanced Filter -->
        <div
          class="md:w-1/3 md:sticky z-[2]"
          [ngClass]="{ block: showFilter, hidden: !showFilter }"
        >
          <app-advanced-filter (showFilter)="onShowFilter($event)"> </app-advanced-filter>
        </div>
        <!-- Recommended Places -->
        <div
          class="md:sticky md:z-[2]"
          [ngClass]="this.sidebarService.showSidebar ? 'md:w-[30%]' : 'md:w-1/4'"
        >
          <div [ngClass]="showRecPlaces ? 'w-full' : 'hidden'">
            <app-recommended-places
              (showRecPlaces)="onShowRecPlaces($event)"
            ></app-recommended-places>
          </div>
        </div>
        <!-- Map -->
        <div
          *ngIf="openMapService.open"
          class="fixed left-[50%] -translate-x-[50%] z-20 font-medium bottom-6 md:hidden"
        >
          <button
            (click)="openMapService.hide()"
            type="button"
            class="flex items-center justify-center gap-2 px-3 py-1 text-center border rounded-md bg-primary-50 border-primary-600"
          >
            <span class="sctr-icon-map-01 text-primary-700"></span>
            <span class="font-semibold text-primary-700 text-md">
              {{ 'page.create_tour.layout.close_map' | translateAsync }}
            </span>
          </button>
        </div>
        <div
          #mapTour
          id="map-tour"
          class="md:absolute md:inset-0 md:block z-[1] overflow-hidden"
          [ngClass]="
            openMapService.open
              ? 'md:h-[calc(100dvh_-_40px)] h-[calc(100dvh_-_120px)] pt-5 md:pt-0 block'
              : 'hidden'
          "
          [ngStyle]="{ position: openMapService.open ? 'inherit' : 'absolute' }"
        >
          <app-map-tour
            *ngIf="openMapService.isVisible"
            (locationDataCallback)="onCloseLocationDialog($event)"
          ></app-map-tour>
        </div>

        <div
          *ngIf="locationData"
          class="md:h-0 h-[58vh] z-[10] md:hidden block fixed md:w-0 w-full md:bg-inherit bg-black md:opacity-100 opacity-40 top-0"
        ></div>

        <div
          *ngIf="locationData"
          class="md:h-[43vh] h-[45vh] z-[11] md:absolute fixed pl-1 bottom-0 right-0 rounded-t-2xl"
          [ngClass]="
            showQuickView || showFilter
              ? 'md:w-2/3 w-full'
              : showRecPlaces
              ? sidebarService.showSidebar
                ? 'md:w-[70%] w-full'
                : 'md:w-3/4 w-full'
              : 'w-full'
          "
        >
          <app-location-detail
            [location]="locationData"
            (closeModalCallBack)="onCloseModal()"
            (showMediaGallery)="onShowMediaGallery($event)"
          ></app-location-detail>
        </div>
      </div>
    </div>
  </div>
  <app-footer class="sticky bottom-0 z-10 flex-initial hidden shadow-sm xl:block"></app-footer>
</div>

<app-modal
  [showDialog]="showMediaGallery"
  (emitOnCloseDialog)="onCloseMediaGallery()"
  [dialogConfig]="mediaGalleryDialogConfig"
  [showFooterDialog]="false"
>
  <div modal-header class="text-xl font-semibold text-gray-900 font-Inter">
    {{ 'page.create_tour.layout.medias' | translateAsync }}
  </div>
  <div modal-content *ngIf="locationData">
    <app-gallery [images]="locationData.medias" type="custom-1"></app-gallery>
  </div>
</app-modal>
