import { Directive, ViewContainerRef } from '@angular/core';
import { HostViewService } from '@core/services/modal/host-view.service';

@Directive({
  selector: '[hostView]',
  standalone: true,
})
export class HostViewDirective {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private hostViewService: HostViewService
  ) {
    this.hostViewService.hostView = this.viewContainerRef;
  }
}
