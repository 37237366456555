import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AvatarService {
  constructor() {}
  private avatar$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getData() {
    return this.avatar$.asObservable();
  }
  setData(data: string) {
    this.avatar$.next(data);
  }
}
