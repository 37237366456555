<div class="t-media-player">
  <video
    #video
    class="w-full h-full"
    [ngClass]="isCover ? 'object-cover' : ''"
    [controls]="!isPreviewMode && showToolbar"
    [src]="videoSrc"
    playsinside
    preload="auto"
    (stalled)="handleStalled()"
    (seeked)="handleLoaded($event)"
    (error)="handleLoaded($event)"
  ></video>
  <ng-container *ngIf="isPreviewMode">
    <p-button class="t-media-play-button">
      <i class="t-button-icon sctr-icon-solid-play"></i>
    </p-button>
  </ng-container>
  <p-progressSpinner
    *ngIf="isLoading"
    [style]="{ height: '1rem', width: '1rem' }"
    strokeWidth="1"
    animationDuration=".5s"
    class="absolute top-1 left-1"
  ></p-progressSpinner>
</div>
