<div class="flex flex-col w-full bg-gray-100">
  <div class="flex flex-col w-full">
    <!-- <app-header
      [config]="headerConfig"
      class="sticky top-0 z-50 flex-initial shadow-sm"
    ></app-header> -->
    <app-master-header
      class="sticky top-0 z-[1001] flex-initial shadow-sm backdrop-blur-sm"
    ></app-master-header>

    <div [ngClass]="[isHomePage ? '-mt-16' : 'mt-0']" class="flex-auto bg-gray-100">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
