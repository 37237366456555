import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ModalComponent } from '../modal/modal.component';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  standalone: true,
  imports: [
    DialogModule,
    ChipModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextareaModule,
    TranslateModule,
    ModalComponent,
    TranslateAsyncPipe,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent {
  @Input() isShowReport: boolean = false;
  @Input() isReportPost: boolean = false;
  @Input() contentReport: any[];
  @Output() closeModal = new EventEmitter();
  @Output() groupReport = new EventEmitter();
  @Output() postReport = new EventEmitter();
  isDisabled: boolean = false;

  selectedChipIndices: string[] = [];
  textareaValue: string = '';
  dialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 't-report-modal w-full md:max-w-[600px] max-h-[90vh]',
  };
  OTHER_CATEGORY = 'Other';
  constructor() {}

  checkAllFields() {
    if (this.textareaValue) {
      if (!this.selectedChipIndices.includes(this.OTHER_CATEGORY))
        this.selectedChipIndices.push(this.OTHER_CATEGORY);
    } else {
      this.selectedChipIndices = this.selectedChipIndices.filter((i) => i !== this.OTHER_CATEGORY);
    }
    this.isDisabled = !!this.textareaValue || !!this.selectedChipIndices.length;
  }

  toggleChip(category_type: string): void {
    const isSelected = this.selectedChipIndices.includes(category_type);
    if (isSelected) {
      this.selectedChipIndices = this.selectedChipIndices.filter((i) => i !== category_type);
    } else {
      this.selectedChipIndices.push(category_type);
    }
    this.isDisabled = this.selectedChipIndices.length > 0;
  }

  isChipSelected(category_type: string): boolean {
    return this.selectedChipIndices.includes(category_type);
  }

  handleCloseModal() {
    this.closeModal.emit(false);
    this.selectedChipIndices = [];
    this.textareaValue = '';
    this.isDisabled = false;
  }

  handleClearData() {
    this.selectedChipIndices = [];
    this.textareaValue = '';
    this.isDisabled = false;
  }

  handleConfirmReport() {
    if (this.selectedChipIndices.length || this.textareaValue) {
      let param = {
        category_type: this.selectedChipIndices,
        report_reason: this.textareaValue,
      };
      this.groupReport.emit(param);
      this.postReport.emit(param);
    }
  }
}
