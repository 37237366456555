<div class="image-post ">
  <ng-container *ngFor="let image of displayImages; let i = index">
    <ng-container
      *ngTemplateOutlet="
        i === lastImageIndex && remainImages > 0 ? overlay : default;
        context: { image: image, isShowFull: isShowFull(i) }
      "></ng-container>
  </ng-container>
</div>

<ng-template #default let-image="image" let-isShowFull="isShowFull">
  <img [src]="image" alt="" class="image-post__item" [class.image-post__item--100]="isShowFull" />
</ng-template>

<ng-template #overlay let-image="image">
  <div class="image-post__item image-post__item--t-overlay">
    <div class="t-overlay">
      <div class="t-overlay__round">
        <span>+{{ limit ? images.length - limit : 0 }}</span>
      </div>
    </div>
    <img [src]="image" alt="" />
  </div>
</ng-template>
  