<div class="t-progress-container w-[31.25rem] min-h-[12.5rem] h-[12.5rem]">
  <div class="flex items-center justify-between w-full h-full">
    <div
      class="flex flex-col items-center self-stretch w-full h-full gap-4 px-6 py-6 rounded-lg"
      [ngClass]="isShowSpinnerOnly ? 'bg-none' : 'bg-white'"
    >
      <p-progressSpinner
        styleClass="opacity-60 w-4 h-4"
        strokeWidth="4"
        animationDuration=".5s"
        [style]="{
          width: '3rem',
          height: '3rem'
        }"
      ></p-progressSpinner>
      <div *ngIf="!isShowSpinnerOnly">
        <div class="mb-1 text-2xl font-semibold text-center text-gray-900">{{ header }}</div>
        <div class="mb-2 font-normal text-center text-gray-600 text-md">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</div>
