import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AutoComplete, AutoCompleteModule } from 'primeng/autocomplete';
import { SelectItemGroup } from 'primeng/api';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { HighlightSearch } from '@shared/pipes/highlight-search.pipe';
import L from 'leaflet';
import { TranslationService } from '@core/services';

@Component({
  selector: 'app-map-search',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    /* Primeng */
    AutoCompleteModule,
    /* Pipes */
    HighlightSearch,
  ],
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapSearchComponent implements OnDestroy {
  @Input() placeholder: string = this.translationService.getTranslationAsync(
    'common.component.map_search.search_place'
  );
  @Input() isShowSeachBox: boolean;
  @Input() selectedItem: SuggestItems = { value: '', type: 'Place', label: '' };
  @Output() selectItemCallback = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();
  @ViewChild(AutoComplete) autoCompletePrime: AutoComplete;

  constructor(
    private searchPlaceService: SearchingPlaceControllerService,
    private translationService: TranslationService
  ) {}

  suggestions: SelectItemGroup[] = [];
  searchTerm: string;
  isShowClear: boolean = false;
  previousMarker?: L.Marker<any>;
  destroy$ = new Subject<void>();
  myPlace: L.LatLng;

  search(event: AutoCompleteEvent) {
    let query: string;
    query = event.query;
    if (query && typeof query === 'string' && query !== '') {
      let suggestedList: SelectItemGroup[] = [];
      this.searchPlaceService
        .searchLocationV2(query, 10)
        .pipe(debounceTime(600), distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            if (res.data) {
              const places = [...(res.data.data || [])];
              if (places.length > 0) {
                const placeList = places.map((item) => ({
                  label: item.main_text,
                  type: 'Place',
                  value: item.id as string,
                  category: item.category ? item.category[0] : undefined,
                  addr: item.addr,
                  lat: item.lat,
                  lng: item.lon,
                  adt_divisions: item.adt_divisions,
                }));
                if (placeList.length) {
                  suggestedList.push({
                    label: this.translationService.getTranslationAsync(
                      'common.component.map_search.places'
                    ),
                    value: 'places',
                    items: placeList,
                  });
                }
                this.suggestions = suggestedList;
              } else {
                this.suggestions = [];
              }
            }
          },
          error: (error) => {
            console.error('Error fetching location suggestions:', error);
            this.suggestions = [];
          },
        });
    }
  }

  onChange(event) {
    this.isShowClear = true;
    if (typeof event === 'string') {
      this.searchTerm = event.trim();
      if (!this.searchTerm) this.isShowClear = false;
    }
  }

  handleClear() {
    this.isShowClear = false;
    this.autoCompletePrime.focusInput();
    this.clear.emit();
  }

  handleSelect(event: any) {
    this.selectItemCallback.emit(event);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onGetMyLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.myPlace = L.latLng(position.coords.latitude, position.coords.longitude);
      });
    }
  }
}

export interface SuggestItems {
  value: string;
  type?: 'Place';
  label?: string;
  addr?: string;
  category?: string;
  price?: string;
  avatar?: string;
}

interface AutoCompleteEvent {
  originalEvent: Event;
  query: string;
}
