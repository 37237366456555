import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TooltipModule, Tooltip } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    InputTextModule,
    ButtonModule,
    FormsModule,
    TooltipModule,
    TranslateModule,
    TranslateAsyncPipe,
  ],
})
export class ClipboardComponent implements OnInit {
  @Input() title: string = 'Copy';
  @Input() inputLink: string = 'http://localhost:4200/journey123';
  @ViewChild(Tooltip) tooltip!: Tooltip;
  constructor() {}

  ngOnInit() {
    return;
  }

  onClick() {
    this.tooltip.activate();
  }

  onClipboard() {
    navigator.clipboard.writeText(this.inputLink);
    this.tooltip.activate();
  }
}
