import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import {
  ILocation,
  LocationService,
  SoctripMapModule,
  SoctripMapService,
} from '@soctrip-common/map';
import {
  CategoryControllerService,
  CategoryRespV2DTO,
  PlaceControllerService,
  PlaceCreateDTO,
  PlaceDetailDTO,
  PlaceUpdateDTO,
  SearchingPlaceControllerService,
  StorageTupleDTO,
} from '@assistant/angular-map-location-service';
import * as L from 'leaflet';
import {
  MessageService,
  TreeNode,
  ConfirmationService,
  SelectItem,
  SelectItemGroup,
} from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { TreeSelectModule } from 'primeng/treeselect';
import { Subject, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { getImagePath } from 'src/common/utils/getImagePath';
import { ModalComponent } from '../modal/modal.component';
import {
  MultiFileUploaderComponent,
  imageUploadInfor,
} from '../multi-file-uploader/multi-file-uploader.component';
import MultiSelectComponent from '../multi-select/multi-select.component';
import { TruncateStringPipe } from 'src/common/pipe/truncate-string.pipe';
import { DialogConfig } from '@shared/models/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { HighlightSearch } from '@shared/pipes/highlight-search.pipe';
import { ItineraryService } from '@shared/services/itinerary.service';
import { MapSearchComponent } from '../map-search/map-search.component';
import { PlaceService } from '@modules/create-tour/services/place.service';
import { MapRoutingComponent } from '../map-routing/map-routing.component';
import { ToastService, TranslationService } from '@core/services';
import { CheckboxModule } from 'primeng/checkbox';
import { ResizeDirective } from '@shared/directives/resize.directive';
import { TooltipModule } from 'primeng/tooltip';
import { MapTourServices } from '@shared/services/map-tour-service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '@shared/services/user-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
// import {TranslationService} from '@core/services/translation.service';

export interface Media {
  imageUrl: string;
  fileName?: string;
}
@Component({
  selector: 'app-map-tour',
  templateUrl: './map-tour.component.html',
  styleUrls: ['./map-tour.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [MessageService, TruncateStringPipe, TranslateAsyncPipe],
  imports: [
    /* common */
    ReactiveFormsModule,
    FormsModule,
    SoctripMapModule,
    /* primeng */
    ButtonModule,
    CommonModule,
    DialogModule,
    SidebarModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    ToastModule,
    TreeSelectModule,
    MultiSelectModule,
    AutoCompleteModule,
    CheckboxModule,
    TooltipModule,
    /* components */
    MultiSelectComponent,
    MultiFileUploaderComponent,
    ModalComponent,
    ConfirmDialogModule,
    MapSearchComponent,
    MapRoutingComponent,
    /* pipes */
    TruncateStringPipe,
    HighlightSearch,
    TranslateAsyncPipe,
    /* directives */
    ResizeDirective,
    TranslateModule,
  ],
})
export class MapTourComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeSelected: PlaceDetailDTO;
  @Input() hideAction: boolean = false;
  @Input() currentCoordinate: L.LatLng;
  @Output() refreshPlaces = new EventEmitter();
  @Output() deletePlaces = new EventEmitter();
  @Output() editPlaces = new EventEmitter();
  @Output() locationDataCallback = new EventEmitter<ILocation | null>();
  @Output() update = new EventEmitter();
  @Output() mapLayer = new EventEmitter();
  @ViewChild(MultiFileUploaderComponent) multiFileUploaderComponent!: MultiFileUploaderComponent;
  @ViewChild(MapRoutingComponent) mapRouting!: MapRoutingComponent;
  dialogConfig: DialogConfig = {
    closeFromOutSide: false,
    resizable: false,
    scrollable: true,
    displayCloseButton: true,
    styleClass: 't-create-place-modal',
    appendTo: 'body',
  };
  nodes!: TreeNode[];
  isResetImageSelect: boolean = false;
  selectedNodes: any;
  // filteredCategorys: any;
  // groupedCategorys: SelectItemGroup[];
  uploadedFiles: any[] = [];

  isCreatePlace: boolean = false;
  currentCoorFocus: L.LatLng;
  lng: number;
  lat: number;

  selectedFiles!: FormData | undefined;

  storageFile: any[] = [];
  uploadFiles: File[] = [];
  destroy$ = new Subject<void>();
  placeDTO: PlaceUpdateDTO | PlaceCreateDTO = {
    lat: 0,
    lon: 0,
    place_name: '',
    desc: '',
    category: [],
    addr: '',
    is_published: false,
    is_partner_data: false,
  };
  placeDTOInitialState: PlaceUpdateDTO | PlaceCreateDTO = {
    lat: 0,
    lon: 0,
    place_name: '',
    desc: '',
    category: [],
    addr: '',
  };
  listMarkers: L.Marker[] = [];
  marker: L.Marker;
  placesData: any[] = [];
  listImageUrl: imageUploadInfor[] = [];
  oldMainText: string;
  oldCategory: string;
  oldAddr: string;
  oldSecdText: string;
  oldDesc: string;
  position: string;
  previousMarker?: L.Marker<any>;
  isShowSeachBox: boolean = false;
  isShowDirectionBox: boolean = false;
  avatar: string = '/assets/images/default-user-avatar.webp';
  markerLatLng: L.LatLng;
  dataValue: boolean;
  actionValue: any;
  addedMarker: L.Marker;
  placeCreateData: object;
  isDesktopView: boolean = window.innerWidth >= 768;
  rate = 1000;
  lastClick = Date.now() - this.rate;
  placeEditData: any;

  editPlaceId = '';

  isDropdownOpen = false;
  oldStoregeFile: any[];
  newPlaceDataFromItinerary: object;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private mapServices: SoctripMapService,
    private confirmationService: ConfirmationService,
    private locationService: LocationService,
    private itineraryService: ItineraryService,
    private truncateString: TruncateStringPipe,
    private placeCustomService: PlaceService,
    private toastServive: ToastService,
    public mapTourService: MapTourServices,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private userProfileService: UserProfileService,
    private translationService: TranslationService
  ) {}
  private searchPlaceService = inject(SearchingPlaceControllerService);
  private placeService = inject(PlaceControllerService);
  private categoryService = inject(CategoryControllerService);
  public map!: L.Map;
  public action: 'create' | 'edit' = 'create';
  placesDataByZoom: any[] = [];
  public addPlaceForm = this.formBuilder.group({
    name_text: ['', Validators.required],
    addr: ['', Validators.required],
    secd_text: [''],
    _lat: [''],
    _long: [''],
    desc: [''],
    website: [''],
    category: [
      {
        data: '',
        key: '',
        label: '',
        type: '',
        selectable: false,
        children: [],
      },
      Validators.required,
    ],
    tags: [''],
    o_time: [''],
    banner: [''],
    medias: [''],
    publish: [false],
  });
  selectedCategoriesPlace: any[] = [];
  private curPlaceData: PlaceDetailDTO[] = [];
  initCoordinate = {
    // Ho Chi Minh City
    lat: 10.769398998600156,
    lon: 106.69836044311525,
  };
  ngOnInit(): void {
    // this.avatarService.getData().subscribe((data) => {
    //   this.avatar = data;
    // });
    if (this.currentCoordinate) {
      this.initCoordinate = {
        lat: this.currentCoordinate.lat,
        lon: this.currentCoordinate.lng,
      };
    }
    this.categoryService
      .getV2()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (data.success) {
            let data$ = data.data as CategoryRespV2DTO[];
            this.nodes = data$.map((rs: CategoryRespV2DTO, index) => {
              return {
                key: `${index}`,
                label: `${this.translationService.getTranslationAsync(
                  'map.categories.' + rs.i18n_code
                )}`,
                data: `${rs.name}`,
                type: `${rs.name}`,
                selectable: !rs?.sub_categories,
                children: rs.sub_categories?.map((item, index2) => {
                  return {
                    key: `${index}-${index2}`,
                    label: `${this.translationService.getTranslationAsync(
                      'map.categories.' + item.i18n_code
                    )}`,
                    data: `${rs.name}`,
                    type: `${item.name}`,
                  };
                }),
              };
            });
          } else {
            console.log('error');
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    if (
      this.activeRoute.snapshot.queryParams['lat'] &&
      this.activeRoute.snapshot.queryParams['lon']
    ) {
      this.initCoordinate = {
        lat: this.activeRoute.snapshot.queryParams['lat'] || 38.57964280859044,
        lon: this.activeRoute.snapshot.queryParams['lon'] || -121.46745892147494,
      };
    }
  }

  onTouch: Function = () => {};

  private mapEvent() {
    this.map.on('contextmenu', (e) => {
      if (!this.hideAction) {
        this.showActionPlacePopup(e.latlng, 'map');
        this.currentCoorFocus = e.latlng;
      }
    });

    let prevCenter = this.map.getCenter();
    this.mapServices.onMoveMapEnd(() => {
      const currCenter = this.map.getCenter();
      const distance = prevCenter.distanceTo(currCenter);

      if (distance > 500) {
        this.searchPlace();
        prevCenter = currCenter;
      }

      // const navigationExtras: NavigationExtras = {
      //   queryParams: { lat: currCenter.lat, lon: currCenter.lng },
      //   queryParamsHandling: 'merge',
      // };
      // this.router.navigate([], navigationExtras);
    });
    this.mapServices.onZoomMapEnd(() => {
      if (this.placesDataByZoom.length > 0) {
        this.mapServices.loadPlacesByZoom(this.placesDataByZoom, 15);
      } else {
        this.searchPlace();
      }
    });
    this.handleViewChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['placeSelected']) {
      if (
        this.placeSelected &&
        !this.placesData.find((item) => item?.id === this.placeSelected.id)
      ) {
        this.placesData.push(this.placeSelected);
      }
      const mk = this.mapServices.listMarkers.find((item) => {
        return (
          item.getLatLng().lat === this.placeSelected.lat &&
          item.getLatLng().lng === this.placeSelected.lon
        );
      });
      if (mk) {
        mk.on('contextmenu', () => {
          this.showActionPlacePopup(mk.getLatLng(), 'marker');
        });
      }
    }
  }

  initMapLayer(map: L.Map) {
    this.mapLayer.emit(map);
    this.map = map;
    this.mapEvent();
    this.mapTourService
      .getCategoriesPlaceSelected$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.selectedCategoriesPlace = data;
        this.searchPlace();
      });
  }
  handleViewChange() {
    if (this.isDesktopView) {
      this.placeCustomService.isCreatePlace$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
        this.dataValue = val['data'];
        this.actionValue = val['action'];
        this.placeCreateData = val;
        this.getMarkerLatLng();
      });
    } else {
      this.placeCreateData = this.placeCustomService.getIsCreatePlaceData();
      this.dataValue = this.placeCreateData['data'];
      this.actionValue = this.placeCreateData['action'];
      this.getMarkerLatLng();
    }
  }

  getMarkerLatLng() {
    if (this.addedMarker) this.mapServices.removeMarker(this.addedMarker);
    if (this.dataValue) {
      this.addedMarker = this.mapServices.addMarker(
        this.mapServices.getCoorCenterMap(),
        true,
        this.markerIcon
      );
      this.mapServices.onMarkerDragEnd(this.addedMarker, () => {
        this.isCreatePlace = this.dataValue;
        this.action = this.actionValue;
        this.markerLatLng = this.addedMarker.getLatLng();
        this.addPlace(this.markerLatLng);

        this.newPlaceDataFromItinerary =
          JSON.parse(localStorage.getItem('newPlaceDataFromItinerary')!) || {};
        this.addPlaceForm
          .get('name_text')
          ?.patchValue(this.newPlaceDataFromItinerary['placeItem']?.name || '');
      });
    }
  }

  removeAddedMarker() {
    this.mapServices.removeMarker(this.addedMarker);
  }
  public getListZoom(): number[] {
    const curZoom = this.mapServices.getMap().getZoom();
    return Array.from({ length: curZoom }, (_, i) => i + 1);
  }

  searchPlace() {
    const listCategories = this.selectedCategoriesPlace.map((item) => item.name);
    if (listCategories.length === 0 || this.mapServices.getMap().getZoom() < 10) {
      this.mapServices.loadPlacesByZoom([]);
    } else {
      this.searchPlaceService
        .searchPlaceWithinDestinationByLatLon(
          this.mapServices.getMap().getCenter().lat,
          this.mapServices.getMap().getCenter().lng,
          Math.round(this.mapServices.getCurrentMapRadius()),
          listCategories,
          this.getListZoom(),
          undefined,
          undefined,
          false,
          false
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.success && res.data && res.data.data) {
            this.curPlaceData = res.data.data;
            const curMarkers = this.mapServices.loadPlacesByZoom(res.data.data, 15);
            curMarkers?.forEach((marker) => {
              marker.on('contextmenu', () => {
                this.showActionPlacePopup(marker.getLatLng(), 'marker-ex');
                this.currentCoorFocus = marker.getLatLng();
              });
            });
          }
        });
    }
  }

  mappingBanner(bannerStorage: string) {
    return getImagePath(bannerStorage, 'web');
  }

  mappingMediaList(medias: StorageTupleDTO[]) {
    return medias.map((media) => {
      return getImagePath(media.id as string, 'web');
    });
  }

  onCloseModal() {
    this.locationDataCallback.emit(null);
  }

  showActionPlacePopup(latlng: L.LatLng, type: string) {
    let content = '';
    let placeName: string | undefined;
    let placeId = '';

    const oBtnAddPlace = document.getElementById('map-layer-popup');
    if (oBtnAddPlace) {
      const parent = oBtnAddPlace.parentNode;
      if (parent) {
        parent.removeChild(oBtnAddPlace);
      }
    }

    if (type === 'marker') {
      this.placesData.map((place) => {
        if (place.lat === latlng.lat && place.lon === latlng.lng) {
          placeId = place.id;
          this.placeService
            .getPlaceInfo([placeId])
            .pipe(
              map((response) => response.data?.[0]),
              takeUntil(this.destroy$)
            )
            .subscribe({
              next: (data) => {
                if (data) placeName = data?.main_text;
              },
              error: (error) => {
                console.log(error);
              },
            });
        }
      });

      content = `<div id="map-layer-popup">
                   <div> <button id="editButton" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
                     'common.component.map_tour.edit_place'
                   )}</button></div>
                  <div> <button id="deleteButton" class="p-button p-button-text h-6	text-red-400">${this.translationService.getTranslationAsync(
                    'common.component.map_tour.delete_place'
                  )}</button></div>
                  <div> <button id="directionFrom" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
                    'common.component.map_tour.direction_from_here'
                  )}</button></div>
                  <div><button id="directionTo" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
                    'common.component.map_tour.direction_to_here'
                  )}</button></div>
                </div>`;
    } else if (type === 'marker-ex') {
      this.curPlaceData.find((place) => {
        if (place.lat === latlng.lat && place.lon === latlng.lng) {
          placeName = place.main_text;
        }
      });
      content = `<div id="map-layer-popup">
        <div> <button id="directionFrom" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
          'common.component.map_tour.direction_from_here'
        )}</button></div>
         <div><button id="directionTo" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
           'common.component.map_tour.direction_to_here'
         )}</button></div>
       </div>`;
    } else {
      content = `<div id="map-layer-popup"><button id="addPlaceButton" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
        'common.component.map_tour.create_place'
      )}</button></div>
       <div> <button id="directionFrom" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
         'common.component.map_tour.direction_from_here'
       )}</button></div>
        <div><button id="directionTo" class="p-button p-button-text h-6">${this.translationService.getTranslationAsync(
          'common.component.map_tour.direction_to_here'
        )}</button></div>`;
    }

    const popup = L.popup().setLatLng(latlng).setContent(`${content}`).openOn(this.map);
    const btnAddPlace = document.getElementById('addPlaceButton');
    if (btnAddPlace) {
      btnAddPlace.addEventListener('click', () => {
        this.userProfileService.isLogged.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.action = 'create';
            this.addPlace(latlng);
            popup.remove();
          } else {
            popup.remove();
            this.toastServive.error(
              this.translationService.getTranslationAsync(
                'common.component.map_tour.you_have_to_loggin_to_create_a_new_place_message'
              )
            );
          }
        });
      });
    }
    const btnDirectFrom = document.getElementById('directionFrom');
    if (btnDirectFrom) {
      btnDirectFrom.addEventListener('click', () => {
        this.isShowDirectionBox = true;
        this.isShowSeachBox = false;
        this.mapRouting.selectedItemFrom = {
          value: '',
          type: 'Place',
          label: placeName ? placeName : latlng.toString(),
        };
        this.mapRouting.StartRouteSub.next(L.latLng(latlng));
        popup.remove();
      });
    }
    const btnDirectTo = document.getElementById('directionTo');
    if (btnDirectTo) {
      btnDirectTo.addEventListener('click', () => {
        this.isShowDirectionBox = true;
        this.isShowSeachBox = false;
        this.mapRouting.selectedItemTo = {
          value: '',
          type: 'Place',
          label: placeName ? placeName : latlng.toString(),
        };
        this.mapRouting.EndRouteSub.next(L.latLng(latlng));
        popup.remove();
      });
    }
    const btnEditPlace = document.getElementById('editButton');
    if (btnEditPlace) {
      btnEditPlace.addEventListener('click', () => {
        // prevent duplicate images after fetch place info
        if (Date.now() - this.lastClick >= this.rate) {
          this.action = 'edit';
          this.selectedFiles = undefined;
          this.listImageUrl = [];
          this.editPlace(latlng);
          popup.remove();
        }
      });
    }

    const btnDeletePlace = document.getElementById('deleteButton');
    if (btnDeletePlace) {
      btnDeletePlace.addEventListener('click', () => {
        this.confirmationService.confirm({
          message: this.translationService.getTranslationAsync(
            'common.component.map_tour.confirm_delete_place_message',
            { param: `<b>${placeName ? placeName : ''}</b>` }
          ),
          header: this.translationService.getTranslationAsync(
            'common.component.map_tour.delete_place'
          ),
          acceptLabel: this.translationService.getTranslationAsync('common.term.delete'),
          rejectLabel: this.translationService.getTranslationAsync('common.term.cancel'),
          acceptButtonStyleClass:
            '!bg-[var(--light-color-scheme-error-600)] rounded-lg h-11 py-2.5 px-[18px] text-base font-semibold border border-solid !border-[var(--light-color-scheme-error-600)] !shadow-none',
          rejectButtonStyleClass:
            'rounded-lg h-11 py-2.5 px-[18px] text-base font-semibold border border-solid !border-[var(--light-color-scheme-gray-300)] !text-[var(--light-color-scheme-gray-700)] !bg-[var(--light-color-scheme-base-1)] !shadow-none',
          accept: () => {
            this.placeService.deletePlace(placeId).subscribe({
              next: (data) => {
                if (data.success) {
                  this.listMarkers.map((item) => {
                    if (item.getLatLng().equals(latlng)) {
                      item.remove();
                    }
                  });
                  this.toastServive.success(
                    this.translationService.getTranslationAsync(
                      'common.component.map_tour.deleting_place_message',
                      { param: placeName }
                    ),
                    3000,
                    undefined,
                    '',
                    'sctr-icon-refresh-cw-03'
                  );
                  const rmMarker = this.mapServices.listMarkers.find((marker) => {
                    return (
                      marker.getLatLng().lat === latlng.lat && marker.getLatLng().lng === latlng.lng
                    );
                  });
                  if (rmMarker) {
                    this.mapServices.removeMarker(rmMarker);
                  }
                  setTimeout(() => {
                    this.toastServive.success(
                      this.translationService.getTranslationAsync(
                        'common.component.map_tour.deleted_place_successfully_message',
                        { param: placeName }
                      )
                    );
                    this.refreshPlaces.emit();
                    this.deletePlaces.emit(placeId);
                  }, 3000);
                  this.onCloseModal();
                } else {
                  this.toastServive.error(
                    this.translationService.getTranslationAsync(
                      'common.component.map_tour.deleted_place_failed_message'
                    )
                  );
                }
              },
              error: (error) => {
                this.toastServive.error(
                  this.translationService.getTranslationAsync(
                    'common.component.map_tour.deleted_place_failed_message'
                  )
                );
                console.log(error);
              },
            });
          },
          reject: () => {},
        });

        popup.remove();
      });
    }
  }

  addPlace(latlng: L.LatLng) {
    this.selectedFiles = undefined;
    this.uploadFiles = [];
    this.listImageUrl = [];
    this.isResetImageSelect = true;
    this.lat = latlng.lat;
    this.lng = latlng.lng;
    this.isCreatePlace = true;
    this.selectedNodes = this.nodes[0].children ? this.nodes[0].children[0] : this.nodes[0];
  }

  editPlace(latlng: L.LatLng) {
    let validCategory = false;
    this.placesData.map((place) => {
      if (place.lat === latlng.lat && place.lon === latlng.lng) {
        this.editPlaceId = place.id;
        this.placeService.getPlaceInfo([place.id]).subscribe({
          next: async (data) => {
            if (data.success && data.data) {
              const placeData: any = data.data[0];
              this.placeEditData = placeData;
              this.storageFile = placeData.storages;
              this.oldStoregeFile = placeData.storages;
              if (this.storageFile) {
                placeData.storages?.map((item: { id: any }) => {
                  let stamp: imageUploadInfor = {
                    imageUrl: `${environment.baseURL}/storage/files/thumbnail/${item.id}`,
                    fileName: `${item.id}`,
                  };
                  this.listImageUrl.push(stamp);
                });
              }

              this.nodes.map((node) => {
                if (node.type === placeData.category[0]) {
                  this.selectedNodes = node;
                  validCategory = true;
                } else if (node.children) {
                  node.children.map((child) => {
                    if (child.type === placeData.category[0]) {
                      this.selectedNodes = child;
                      validCategory = true;
                    }
                  });
                }
              });
              // this.groupedCategorys.map((node) => {
              //   if (node.label === placeData.category[0]) {
              //     this.SelectedCategory = node;
              //     validCategory = true;
              //   } else if (node.items) {
              //     node.items.map((child) => {
              //       if (child.label === placeData.category[0]) {
              //         this.SelectedCategory = child;
              //         validCategory = true;
              //       }
              //     });
              //   }
              // });

              this.isCreatePlace = true;
              this.lat = placeData.lat;
              this.lng = placeData.lon;
              this.addPlaceForm.patchValue({
                name_text: placeData.main_text,
                addr: placeData.addr ? placeData.addr : '',
                secd_text: placeData.secd_text ? placeData.secd_text : '',
                desc: placeData.desc,
                category: placeData.category && validCategory ? this.selectedNodes : '',
                website: placeData.website ? placeData.website : '',
                _lat: placeData.lat ? placeData.lat.toString() : '',
                _long: placeData.lon ? placeData.lon.toString() : '',
                tags: placeData.tags ? placeData.tags : '',
                o_time: placeData.o_time ? placeData.o_time : '',
                banner: placeData.banner ? placeData.banner : '',
                medias: placeData.storages ? placeData.storages : [],
                publish: placeData.is_published ? placeData.is_published : false,
              });

              this.oldMainText = placeData.main_text ? placeData.main_text : '';
              this.oldCategory = placeData.category ? placeData.category[0] : '';
              this.oldAddr = placeData.addr ? placeData.addr : '';
              this.oldSecdText = placeData.secd_text ? placeData.secd_text : '';
              this.oldDesc = placeData.desc ? placeData.desc : '';
            }
          },
          error: (error) => {
            console.log('error', error);
          },
        });
      }
    });
  }

  async onSubmit() {
    const lat = this.lat;
    const lng = this.lng;
    this.placeDTO = {
      lat: lat,
      lon: lng,
      place_name: this.addPlaceForm.value.name_text ? this.addPlaceForm.value.name_text : '',
      s_desc: this.addPlaceForm.value.secd_text ? this.addPlaceForm.value.secd_text : '',
      desc: this.addPlaceForm.value.desc ? this.addPlaceForm.value.desc : '',
      category: this.addPlaceForm.value.category ? [this.addPlaceForm.value.category?.type] : [''],
      addr: this.addPlaceForm.value.addr ? this.addPlaceForm.value.addr : '',
      storages: this.storageFile,
      is_published: this.addPlaceForm.value.publish || false,
      is_partner_data: false,
    };

    // let category = this.addPlaceForm.value.category as unknown as SelectItem;
    const apiUrl = `${environment.baseURL}/storage/files/upload-files`;
    if (this.action === 'edit') {
      if (this.selectedFiles && this.selectedFiles.getAll('files').length > 0) {
        this.httpClient.post<any>(apiUrl, this.selectedFiles).subscribe({
          next: (data) => {
            if (data.success) {
              const newStoreData = this.getStorageData(data.data);
              newStoreData.map((item: any) => {
                if (this.storageFile === null) {
                  this.storageFile = [];
                }
                this.storageFile.push(item);
              });
              this.placeDTO.storages = this.storageFile;
              this.isResetImageSelect = true;
              this.updatePlaceApi();
              this.storageFile = [];
              this.placeDTO.storages = [];
              this.placeDTO = this.placeDTOInitialState;
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
      } else {
        this.updatePlaceApi();
      }
    } else if ((this.action = 'create')) {
      if (this.selectedFiles && this.selectedFiles.getAll('files').length > 0) {
        this.httpClient.post<any>(apiUrl, this.selectedFiles).subscribe({
          next: (data) => {
            if (data.success) {
              this.storageFile = this.getStorageData(data.data);
              this.placeDTO.storages = this.storageFile;
              this.createPlace();
              this.isResetImageSelect = true;
              this.storageFile = [];
              this.placeDTO.storages = [];
              this.placeDTO = this.placeDTOInitialState;
            } else {
              console.log('error', data);
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
      } else {
        this.placeDTO.storages = [];
        this.createPlace();
      }
    }
    this.placeCustomService.setIsCreatePlace(false, 'create');
    this.isCreatePlace = false;
    this.isResetImageSelect = false;
  }

  private updatePlaceApi() {
    let category = this.placeDTO.category as unknown as SelectItem;
    this.placeService.updatePlace(this.placeDTO as PlaceUpdateDTO, this.editPlaceId).subscribe({
      next: (data) => {
        if (data.success) {
          let imageUrl = '';
          if (data.data?.storages) {
            if (data.data.storages.length > 0)
              imageUrl = `${environment.baseURL}/storage/files/thumbnail/${data.data.storages[0].id}`;
          }

          const rmMarker = this.mapServices.listMarkers.find((marker) => {
            return (
              marker.getLatLng().lat === this.placeDTO.lat &&
              marker.getLatLng().lng === this.placeDTO.lon
            );
          });
          if (rmMarker) {
            this.mapServices.removeMarker(rmMarker);
          }
          const listMarkers = this.mapServices
            .addMarkers([data.data as PlaceDetailDTO], false)[0]
            .on('contextmenu', () => {
              this.showActionPlacePopup(listMarkers.getLatLng(), 'marker');
            });

          this.toastServive.success(
            this.translationService.getTranslationAsync('common.component.map_tour.updating_place'),
            5000,
            undefined,
            '',
            'sctr-icon-refresh-cw-03'
          );
          setTimeout(() => {
            this.toastServive.success(
              this.translationService.getTranslationAsync(
                'common.component.map_tour.updated_place_successfully_message'
              )
            );
            this.refreshPlaces.emit();
            this.editPlaces.emit(this.editPlaceId);
            this.update.emit(data.data);
          }, 5000);
        } else {
          this.toastServive.error(
            this.translationService.getTranslationAsync(
              'common.component.map_tour.updated_place_failed_message'
            )
          );
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
    this.listImageUrl = [];
  }

  createPlace() {
    this.placeService
      .createPlace(this.placeDTO as PlaceCreateDTO)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (data.success) {
            this.placesData.push(data.data);
            let imageUrl = '';
            if (data.data?.storages) {
              if (data.data?.storages.length > 0)
                imageUrl = `${environment.baseURL}/storage/files/thumbnail/${data.data?.storages[0].id}`;
            }
            this.toastServive.success(
              this.translationService.getTranslationAsync(
                'common.component.map_tour.creating_place'
              ),
              3000,
              undefined,
              '',
              'sctr-icon-refresh-cw-03'
            );
            const isSetFromItinerary =
              this.newPlaceDataFromItinerary['isSetFromItinerary'] || false;
            if (isSetFromItinerary) {
              this.newPlaceDataFromItinerary = {
                ...this.newPlaceDataFromItinerary,
                placeInfo: data?.data!,
              };
              localStorage.setItem(
                'newPlaceDataFromItinerary',
                JSON.stringify(this.newPlaceDataFromItinerary)
              );
            } else {
              const marker = this.mapServices
                .addMarkers([data.data as PlaceDetailDTO], true)[0]
                .on('contextmenu', () => {
                  this.showActionPlacePopup(marker.getLatLng(), 'marker');
                  this.currentCoorFocus = marker.getLatLng();
                });
              this.listMarkers.push(marker);
              setTimeout(() => {
                this.toastServive.success(
                  this.translationService.getTranslationAsync(
                    'common.component.map_tour.created_place_successfully_message'
                  )
                );
                this.refreshPlaces.emit();
              }, 3000);
            }
          } else {
            this.toastServive.error(
              this.translationService.getTranslationAsync(
                'common.component.map_tour.created_place_failed_message'
              )
            );
          }
        },
        error: (error) => {
          this.toastServive.error(
            this.translationService.getTranslationAsync(
              'common.component.map_tour.created_place_failed_message'
            )
          );
          console.log(error);
        },
      });
  }

  onCancel() {
    localStorage.removeItem('newPlaceDataFromItinerary');
    this.selectedFiles = undefined;
    this.isCreatePlace = false;
    this.isResetImageSelect = false;
    this.addPlaceForm.reset();
    this.placeCustomService.setIsCreatePlace(false, 'create');
  }
  //upload file
  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.locationService.setPlaceModalData(null);
  }
  onImageSelected(files: File[]) {
    const formData = new FormData();
    if (files.length > 0) {
      files.map((file) => {
        formData.append('files', file);
      });
      this.selectedFiles = formData;
    } else {
      this.selectedFiles = undefined;
    }
  }
  getStorageData(data: any[]) {
    if (data) {
      return data.map((item) => {
        return {
          id: item.id,
          extension: item.extension,
          type: item.type,
          signature: item.signature,
        };
      });
    }
    return [];
  }
  clearImageSelected(image: imageUploadInfor) {
    if (this.listImageUrl) {
      let idRemoved = image.imageUrl.split('/');
      this.storageFile = this.storageFile.filter(
        (item) => item.id !== idRemoved[idRemoved.length - 1]
      );
    }
    if (this.storageFile.length === 0) {
      this.listImageUrl = [];
    }
  }

  onLoadHoverMarker(lat: number, lng: number) {
    let result = '';
    this.placesData.map((place) => {
      if (place.lat === lat && place.lon === lng) {
        let category = place.category ? place.category[0] : '';
        let imageUrl = place.storages
          ? `${environment.baseURL}/storage/files/thumbnail/${place.storages[0].id}`
          : '';
        result = this.onHoverMarker(category, place.main_text, imageUrl);
      }
    });
    return result;
  }

  onLoadHoverMarkerById(id: string, lat: number, lng: number) {
    this.placeService
      .getPlaceInfo([id])
      .pipe(
        map((response) => response.data?.[0]),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          if (data?.lat === lat && data.lon === lng) {
            let category = data?.category ? data?.category[0] : '';
            let imageUrl = data?.storages
              ? `${environment.baseURL}/storage/files/thumbnail/${data?.storages[0].id}`
              : '';
            const tooltipContent = this.onHoverMarker(category, data?.main_text, imageUrl);

            // Set the tooltip content when data is available
            this.marker.setTooltipContent(tooltipContent);
            this.marker.openTooltip(); // Open the tooltip when hovered
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  onHoverMarker(
    category: string | undefined,
    nameText: string | undefined | null,
    imageUrl?: string
  ) {
    if (!imageUrl) {
      imageUrl = 'https://primefaces.org/cdn/primeng/images/demo/product/bamboo-watch.jpg';
    }
    return `<div class="t-tooltip-marker rounded-lg">
      <div class="flex flex-col">
        <img src=${imageUrl} class="thumbnail w-36 rounded-t-lg" alt="card-thumbnail"/>
        <button class="btn-icon absolute right-2 top-2 w-7 h-7 rounded-full">
          <i class="sctr-icon-bookmark text-white text-base"></i>
        </button>
      </div>
      <div class="p-2">
        <div class="info-wrapper flex flex-row justify-between">
          <h4 class="h-auto title text-sm text-gray-700 font-semibold">${
            nameText ? this.truncateString.transform(nameText, 15) : ''
          }</h4>
          <i class="sctr-icon-copy-06 text-gray-500"></i>
        </div>
        <div>
          <span class="text-gray-500 text-xs font-normal">(510 ratting)</span>
          <li class="text-gray-700 text-xs font-normal">${
            category ? this.truncateString.transform(category, 20) : ''
          }</li>
          <li class="text-gray-700 text-xs font-normal">Open 09:00 - 22:00</li>
        </div>
      </div>
    </div>`;
  }

  disableBtnSave() {
    if (this.listImageUrl.length !== 0) {
      return (
        (!this.addPlaceForm.valid
          ? !this.addPlaceForm.valid
          : this.oldMainText === this.addPlaceForm.value.name_text &&
            this.oldCategory === this.addPlaceForm.value.category?.label &&
            this.oldAddr === this.addPlaceForm.value.addr &&
            this.oldSecdText === this.addPlaceForm.value.secd_text &&
            this.oldDesc === this.addPlaceForm.value.desc &&
            !this.addPlaceForm.dirty &&
            this.selectedFiles === undefined &&
            this.oldStoregeFile === this.storageFile) ||
        this.listImageUrl.length === 0 ||
        this.invalidType('name_text') ||
        this.invalidType('addr')
      );
    } else {
      return (
        (!this.addPlaceForm.valid
          ? !this.addPlaceForm.valid
          : this.oldMainText === this.addPlaceForm.value.name_text &&
            this.oldCategory === this.addPlaceForm.value.category?.label &&
            this.oldAddr === this.addPlaceForm.value.addr &&
            this.oldSecdText === this.addPlaceForm.value.secd_text &&
            this.oldDesc === this.addPlaceForm.value.desc &&
            !this.addPlaceForm.dirty) ||
        this.selectedFiles === undefined ||
        this.invalidType('name_text') ||
        this.invalidType('addr')
      );
    }
  }

  invalidType(form: string): any {
    if (this.addPlaceForm.get(form)?.value !== '') {
      return this.addPlaceForm.get(form)?.value?.trim() === '';
    }
  }
  hintMarkerText: string = this.translationService.getTranslationAsync(
    'common.component.map_tour.hint_drag_marker'
  );

  markerIcon: L.DivIcon = L.divIcon({
    className: 'sctr-icon-solid-marker-pin-01',
    html: `
    <div class="marker-add-place flex flex-col">
      <div class="absolute mt-[-5.313rem] w-[15rem] flex justify-center items-center font-Inter">
        <span class="text-md bg-white rounded-lg p-1 font-sans relative inline-block -top-4">
          ${this.hintMarkerText}
          <span class="absolute bottom-[-0.625rem] left-5 transform -translate-x-1/2 w-0 h-0 border-t-[0.625rem] border-t-white border-x-[0.625rem] border-x-transparent"></span>
        </span>
      </div>
    </div>`,
    iconSize: [120, 42],
    iconAnchor: [15, 42],
  });

  avatarIcon: L.DivIcon = L.divIcon({
    className: 'map-tour-marker',
    html: `<img class="marker-avatar-icon" src = ${this.avatar}> </img>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42],
  });
  onSelectItem(event: any) {
    let itineraryMarkers: L.Marker<any>[] = [];
    const removePreviousMarker = (previousMarker: L.Marker<any>) => {
      const existedOnItineraryList = itineraryMarkers.findIndex((marker) => {
        return (
          marker.getLatLng().lat === previousMarker?.getLatLng().lat &&
          marker.getLatLng().lng === previousMarker?.getLatLng().lng
        );
      });
      if (existedOnItineraryList < 0) {
        this.mapServices.removeMarker(previousMarker);
        this.listMarkers = this.listMarkers.filter((marker) => marker !== previousMarker);
      }
    };

    this.itineraryService.markersAction$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (data && this.previousMarker) {
          itineraryMarkers = data;
          removePreviousMarker(this.previousMarker);
        }
      },
    });
    if (event.value === 'my-location') {
      this.previousMarker = this.mapServices.addMarkers(
        [L.latLng(event.lat, event.lng)],
        true,
        this.avatarIcon
      )[0];
      return;
    }
    this.placeService
      .getPlaceInfo([event.value])
      .pipe(debounceTime(600), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          const placeData = data.data?.[0];
          if (placeData) {
            const existingPlaceIndex = this.placesData.findIndex(
              (place) => place.id === placeData.id
            );

            if (this.previousMarker) {
              const { lat, lng } = this.previousMarker.getLatLng();
              if (placeData.lat === lat && placeData.lon === lng) {
                const newMarker = this.mapServices
                  .addMarkers([placeData as PlaceDetailDTO], true)[0]
                  .on('contextmenu', () => {
                    this.showActionPlacePopup(newMarker.getLatLng(), 'marker');
                    this.currentCoorFocus = newMarker.getLatLng();
                  });
                this.mapServices.hightLightMarker(
                  placeData,
                  this.mapServices.getMap().getZoom(),
                  true
                );
                this.listMarkers.push(newMarker);
                this.previousMarker = newMarker;
                return;
              } else {
                // remove previous marker
                removePreviousMarker(this.previousMarker);
              }
            }

            // prevent duplicate images after searching this place again
            if (existingPlaceIndex !== -1) {
              this.placesData.splice(existingPlaceIndex, 1);
            }
            this.placesData.push(placeData);

            const newMarker = this.mapServices
              .addMarkers([placeData as PlaceDetailDTO], true)[0]
              .on('contextmenu', () => {
                this.showActionPlacePopup(newMarker.getLatLng(), 'marker');
                this.currentCoorFocus = newMarker.getLatLng();
              });
            this.mapServices.hightLightMarker(placeData, this.mapServices.getMap().getZoom(), true);
            this.listMarkers.push(newMarker);
            this.previousMarker = newMarker;
          }
        },
      });
  }

  handleToggleSearchBox() {
    this.isShowSeachBox = !this.isShowSeachBox;
    this.isShowDirectionBox = false;
  }
  handleToggleDirectionBox() {
    this.isShowDirectionBox = !this.isShowDirectionBox;
    this.isShowSeachBox = false;
  }

  hideDirectionBox() {
    this.isShowDirectionBox = false;
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  selectedValues: string[] = [];

  onSelectedCategoriesPlaceChange(): void {
    const curHlMarker = this.mapServices.getCurHlMarker()?.getIcon() as L.DivIcon;
    if (curHlMarker) {
      const htmlAlt = curHlMarker.options?.html?.toString();
      const altValue = htmlAlt?.match(/alt="([^"]+)"/)?.[1];
      if (altValue) {
        const matchCategory = this.selectedCategoriesPlace.find((item) => item.name === altValue);
        if (!matchCategory) {
          this.mapServices.unHLMarker();
        }
      }
    }
    this.searchPlace();
  }
  onSelectAllLayer(): void {
    this.selectedCategoriesPlace = this.mapTourService.categoriesPlace;
    this.searchPlace();
  }
  onClearAllLayer(): void {
    this.mapServices.unHLMarker();
    this.selectedCategoriesPlace = [];
    this.searchPlace();
  }

  onIsDesktopViewChange(isDesktopView: boolean): void {
    if (isDesktopView) {
      this.position = 'center';
    } else {
      this.position = 'bottom';
    }
  }
  closePopup() {
    const tooltip = document.querySelector('.leaflet-popup');
    if (tooltip) {
      tooltip.remove();
    }
  }
}

export interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
