<div class="t-multi-file-upload">
  <div *ngIf="isExist" class="text-yellow-500 sctr-icon-alert-triangle mb-1.5">
    {{ 'common.component.multi_file_uploader.file_upload_is_existing' | translateAsync }}
  </div>
  <div *ngIf="errorMessage" class="text-error-500 sctr-icon-alert-triangle mb-1.5">
    {{ errorMessage }}
  </div>

  <label
    *ngIf="listImageUrl.length === 0; else haveUploadedFile"
    class="flex h-32 w-full cursor-pointer appearance-none justify-center rounded-xl border border-gray-300 bg-white px-6 py-4 transition hover:border-gray-400 focus:outline-none"
    (click)="inputFile.click()"
  >
    <span class="flex items-center space-x-2">
      <span class="font-medium text-gray-600">
        <div
          class="w-[46px] h-[46px] rounded-[50%] bg-gray-50 flex justify-center items-center mx-auto"
        >
          <div class="w-[34px] h-[34px] rounded-[50%] bg-gray-100 flex justify-center items-center">
            <span class="sctr-icon-upload-cloud-02" style="font-size: 20px"></span>
          </div>
        </div>

        <div class="mt-2 text-center text-md">
          <span class="text-blue-600 font-semibold">{{
            'common.component.multi_file_uploader.click_to_upload' | translateAsync
          }}</span>
          <span class="text-gray-600 font-Inter font-normal">
            {{ 'common.component.multi_file_uploader.or_drag_and_drop' | translateAsync }}</span
          >
        </div>
        <div class="text-center text-gray-600 font-normal text-sm mt-1">
          {{ acceptedFileTypesText }}
        </div>
      </span>
    </span>
  </label>
  <ng-template #haveUploadedFile>
    <div class="grid grid-cols-5 gap-1.5">
      <div *ngFor="let item of listImageUrl" class="w-full aspect-square rounded-md relative">
        <img [src]="item.imageUrl" alt="" class="w-full h-full rounded-md object-cover" />
        <button
          class="t-remove-button absolute top-2 right-2 flex items-center justify-center w-5 h-5"
          (click)="clearFile(item)"
        >
          <i class="sctr-icon-x text-base"></i>
        </button>
      </div>
      <div
        class="w-full aspect-square rounded-md relative grid border border-gray-300 bg-gray-50 border-dashed"
        (click)="inputFile.click()"
      >
        <button>
          <i class="sctr-icon-plus text-display-xs"></i>
        </button>
      </div>
    </div>
  </ng-template>
</div>
<input
  #inputFile
  type="file"
  multiple
  accept="image/*"
  (change)="onUploadFile($event)"
  class="hidden"
/>
