import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { ScrollTopModule } from 'primeng/scrolltop';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { quickViewAnimation } from '@modules/tour-list/components/quick-view/quick-view.animations';
import { OverviewComponent } from '@modules/tour-list/components/overview/overview.component';
import { AdvancedFilterComponent } from '@modules/tour-list/components/advanced-filter/advanced-filter.component';
import { ListSuggestionService } from '@modules/tour-list/tabs/list-suggestion/list-suggestion.service';
import { OpenMapService } from '@modules/create-tour/services/open-map.service';
import { Action } from '@shared/enum/action';
import { AppRoutes } from 'src/app/config/routes.config';
import { TourListService } from '@shared/services/tour-list.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-quick-view',
  standalone: true,
  imports: [
    CommonModule,
    PanelModule,
    CardModule,
    RouterModule,
    ScrollTopModule,
    OverlayscrollbarsModule,
    OverviewComponent,
    AdvancedFilterComponent,
  ],
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss'],
  animations: [quickViewAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class QuickViewComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private listSuggestionService: ListSuggestionService,
    public openMapService: OpenMapService,
    private tourListService: TourListService
  ) {}
  @Input() class: string;
  @Output() showQuickView = new EventEmitter<boolean>();
  previewVisible: boolean = false;
  lang: string;
  isPreviewMode: boolean;
  action: string;
  @Input() tourId?: string;
  @Input() isModal: boolean = false;
  destinationId: string | undefined;
  destination: string | undefined;
  destroy$ = new Subject<void>();
  @Input() quickViewVisible: boolean = false;
  isEnableScroll: boolean = false;
  // @Input() header: string;
  // @Input() headerStyle: string;

  ngOnInit(): void {
    if (this.tourId && this.isModal) {
      this.lang = 'en';
      this.isPreviewMode = true;
      this.action = 'update';
    } else {
      this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
        this.quickViewVisible = params['openQuickView'] === 'true';
        this.destinationId = params['destinationId'];
        this.destination = params['destination'];
        this.showQuickView.emit(this.quickViewVisible);

        if (params['previewMode'] === 'true') {
          this.lang = params['lang'];
          this.isPreviewMode = params['previewMode'];
          this.action = params['action'];
          this.tourId = params['id'];
        }
      });
    }
  }

  hidePanel() {
    this.showQuickView.emit(false);
    if (this.isModal) {
      this.quickViewVisible = false;
    } else if (this.isPreviewMode) {
      if (this.action === Action.CREATE) {
        this.route.navigate([`${environment.prefix}/${Action.CREATE}`]);
      } else if (this.action === Action.UPDATE) {
        this.route.navigate([`${environment.prefix}/${this.tourId}/${Action.UPDATE}`], {
          queryParams: {
            openQuickView: null,
            id: null,
            previewMode: null,
            action: null,
            lang: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    } else {
      this.route.navigate([`${environment.prefix}/${AppRoutes.TOUR_LIST}`], {
        queryParams: { openQuickView: null, id: null },
        queryParamsHandling: 'merge',
      });
      this.listSuggestionService.reviewSubject.next(0);
    }
  }
  handleCloseModal(event: any) {
    this.isEnableScroll = event;
    this.tourListService.handleModalShareTour.next(this.isEnableScroll);
  }
  handleOpenModal(event: any) {
    this.isEnableScroll = event;
    this.tourListService.handleModalShareTour.next(this.isEnableScroll);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
