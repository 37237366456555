import { MenuItem } from 'primeng/api';
import { environment } from '@env/environment';
import { AppRoutes } from './routes.config';
export const AGENCY_TOP_BAR_MENU: Array<MenuItem> = [
  {
    label: 'Branch',
    url: AppRoutes.BRANCH,
    icon: 'sctr-icon-users-01',
    target: '_self',
  },
  {
    label: 'Contracts',
    url: AppRoutes.CONTRACTS,
    icon: 'sctr-icon-file-attachment-04',
    target: '_self',
  },
  {
    label: 'Policies',
    url: AppRoutes.POLICIES,
    icon: 'sctr-icon-file-check-03',
    target: '_self',
  },
  {
    label: 'Contacts',
    url: AppRoutes.CONTACTS,
    icon: 'sctr-icon-phone-call-01',
    target: '_self',
  },
  {
    label: 'Users',
    url: AppRoutes.USERS,
    icon: 'sctr-icon-users-01',
    target: '_self',
  },
];
