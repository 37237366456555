import { DateTime } from 'luxon';
import { Injectable, inject } from '@angular/core';
import { TranslationService } from '@core/services/translation/translation.service';

import { DateTimeFormat } from '@shared/enum/date-time-format.enum';
import { DateFormat } from '@shared/enum/date-format.enum';
import { TimeFormat } from '@shared/enum/time-format.enum';

@Injectable({
  providedIn: 'root',
})
export class CustomDateTimeService {
  private translationService = inject(TranslationService);
  private localeString = this.translationService.getLocale();

  formatDateTime(value: any, format?: DateTimeFormat, tz?: string): string {
    const dateTimeValue = new Date(value).toISOString();
    const dateTimeFormat =
      format && (Object.values(DateTimeFormat) as string[]).includes(format)
        ? format
        : DateTimeFormat.DATETIME_SHORT__24H;
    const formatSplited: string[] = dateTimeFormat.split('__');

    const dateTime = tz
      ? DateTime.fromISO(dateTimeValue).setZone(tz)
      : DateTime.fromISO(dateTimeValue);

    return dateTime.setLocale(this.localeString).toLocaleString(
      formatSplited[1]
        ? {
            ...DateTime[`${formatSplited[0]}`],
            hourCycle: 'h23',
            hour: '2-digit',
          }
        : DateTime[formatSplited[0]]
    );
  }

  formatTzOnly(tz?: string, tzFormat: 'OFFSET' | 'OFFSET_AND_ABB' = 'OFFSET'): string {
    try {
      const offset = DateTime.now().setZone(tz);
      if (tzFormat === DateTimeFormat.OFFSET) {
        return `(UTC${offset.toFormat('ZZ')})`;
      }
      if (tzFormat === DateTimeFormat.OFFSET_AND_ABB) {
        return `(UTC${offset.toFormat('ZZ')} ${offset.toFormat('ZZZZ')})`;
      }
      return `(UTC${offset.toFormat('ZZ')})`;
    } catch (error) {
      return 'Invalid timezone';
    }
  }

  formatDate(value: any, format?: DateFormat, tz?: string): string {
    const dateValue = new Date(value).toISOString();
    const dateFormat =
      format && (Object.values(DateFormat) as string[]).includes(format) ? format : 'DATE_SHORT';
    if (tz) {
      return DateTime.fromISO(dateValue)
        .setZone(tz)
        .setLocale(this.localeString)
        .toLocaleString(DateTime[`${dateFormat}`]);
    }
    return DateTime.fromISO(dateValue)
      .setLocale(this.localeString)
      .toLocaleString(DateTime[`${dateFormat}`]);
  }

  formatTime(value: any, format?: TimeFormat): string {
    const timeValue = new Date(value).toISOString();
    const timeFormat =
      format && (Object.values(TimeFormat) as string[]).includes(format) ? format : 'TIME_SIMPLE';

    return DateTime.fromISO(timeValue)
      .setLocale(this.localeString)
      .toLocaleString(DateTime[`${timeFormat}`]);
  }

  convertToDateTime(datetimeString: any, type: string = 'DATETIME_SHORT') {
    const result = DateTime.fromFormat(datetimeString, type);
    if (result.isValid) {
      return result;
    }
    return null;
  }

  formatToISODate(value: any) {
    const dateValue = new Date(value).toISOString();
    return DateTime.fromISO(dateValue).toISODate();
  }

  convertDateToDateTime(datetime: Date) {
    return DateTime.fromJSDate(datetime);
  }
}
