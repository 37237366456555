<p-toast
  #toast
  key="confirm"
  (onClose)="onReject()"
  [baseZIndex]="9999"
  [breakpoints]="{ '768px': { width: '90%', bottom: '1.375rem' } }"
  class="t-icon-close-toast-bookmark"
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-col" style="flex: 1">
      <div class="flex pt-4 gap-3">
        <i
          class="font-semibold pl-4"
          [ngClass]="[
            message.severity === 'success' ? 'sctr-icon-check-circle' : 'sctr-icon-alert-circle'
          ]"
          style="font-size: 20px"
        ></i>
        <p
          class="pb-2 text-black font-medium text-md"
          [ngClass]="[changeVisible ? 'pb-2' : 'pb-4']"
        >
          {{ message.summary }}
        </p>
      </div>
      <div *ngIf="changeVisible" class="pl-9 pb-4">
        <button
          class="px-3 py-1 hover:border-solid hover:bg-[#EFF8FF] hover:rounded-lg"
          (click)="onChange()"
        >
          <p class="font-semibold text-md text-primary-600">
            {{ 'common.component.bookmark_modal.change' | translateAsync }}
          </p>
        </button>
      </div>
    </div>
  </ng-template>
</p-toast>
