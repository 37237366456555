import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { TimelineModule } from 'primeng/timeline';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { GalleryComponent } from '@shared/components/gallery/gallery.component';
import { MediaPlayerComponent } from '@shared/components/media-player/media-player.component';

import { SoctripMapService } from '@soctrip-common/map';
import { Media, PlaceVisitDto } from '@assistant/angular-tour-builder-service';
import { PlaceControllerService } from '@assistant/angular-map-location-service';
import { MediaService } from '@modules/tour-guide/services/media.service';
import { ItineraryService } from '@shared/services/itinerary.service';

import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { CustomTimeFormatPipe } from '@shared/pipes/time-format.pipe';
import { getMediaPath, IMAGE_EXTENSION, VIDEO_EXTENSION } from '@shared/utils/getMediaPath';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { CurrencySymbolPipe } from 'src/common/pipe/currency-symbol.pipe';
import { LocalizedLanguageStringPipe } from '@shared/pipes/localized-language-string.pipe';

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [
    /* @angular */
    CommonModule,
    TimelineModule,
    TranslateModule,
    /* components */
    ModalComponent,
    GalleryComponent,
    MediaPlayerComponent,
    /* pipes */
    CurrencyConversionPipe,
    SafeHtmlPipe,
    CustomTimeFormatPipe,
    TranslateAsyncPipe,
    CurrencyConversionPipe,
    CurrencySymbolPipe,
    LocalizedLanguageStringPipe,
  ],
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() showCost: boolean = true;
  @Input() events: PlaceVisitDto[];
  @Input() isPreviewMode: boolean;
  @Input() activeDay: number;
  @Input() action: string;
  @Input() isQuickView: boolean = false;
  @Input() isItineraryTimeline: boolean = false;
  @Input() isTourGuide: boolean = false;
  @Input() isShowPlaceOnTop: boolean = false;
  @Input() isConvertPrice: boolean;
  @Output() emitOnPlayPreview: EventEmitter<boolean> = new EventEmitter();

  activeImageGalleryIndex: number = 0;
  primaryColor = 'var(--light-color-scheme-primary-600)';
  textColor = 'var(--light-color-scheme-gray-600)';
  previousOrder: number;
  previousLengthMarker = 0;
  currentPlace: PlaceVisitDto;
  unsubscribe$: Subject<void> = new Subject();
  currentDay: number;
  currentOrder: number;

  constructor(
    private mapService: SoctripMapService,
    private itineraryService: ItineraryService,
    private placeControllerService: PlaceControllerService,
    public mediaService: MediaService
  ) {}

  ngOnInit() {
    this.events = this.events?.sort((a, b) =>
      a.order === b.order
        ? Number(a.end_time?.toString().slice(0, 2)) - Number(b.start_time?.toString().slice(0, 2))
        : Number(a.order) - Number(b.order)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeDay']?.currentValue) {
      this.itineraryService?.placeVisitsSubject.next(changes['activeDay'].currentValue);
    }
  }

  ngAfterViewInit(): void {
    if (this.isTourGuide) {
      this.mediaService.currentTabIndex$.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
        if (val) {
          this.currentDay = val.day_no;
          this.currentOrder = val.order;
        }
        setTimeout(() => {
          this.onPlayPreview();
        }, 600);
      });
      this.mediaService.endMedia$.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
        if (val) {
          const last = document.querySelector<HTMLElement>(
            '.tour-guide-timeline .p-timeline-event:last-child .custom-separator'
          )!;
          const marker = document.querySelectorAll<HTMLElement>('.tour-guide-timeline .p-marker')!;
          last.style.backgroundImage = this.percentColor(100);
          marker[marker.length - 1].style.color = this.primaryColor;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePlayPreview(e) {
    const defaultId = '00000000-0000-0000-0000-000000000000';
    if (e?.name !== '' && e?.place_id !== defaultId) {
      this.emitOnPlayPreview.emit(true);
      this.mediaService.currentPlace$.next(e);
      this.itineraryService.setPlaceSaved(false);
    }
  }

  getAllGalleriesPath(medias: Media[]): string[] {
    return medias.map((item: Media) =>
      item.type?.includes('video')
        ? getMediaPath(item.id as string, 'video')
        : getMediaPath(item.id as string, 'image')
    );
  }

  checkMediaType = (url: string) => {
    const ext = url.split('.').pop() as string;
    if (IMAGE_EXTENSION.includes(ext)) return 'image';
    else if (VIDEO_EXTENSION.includes(ext)) return 'video';
    return 'other';
  };

  handleClickHyperlink(placeId: string) {
    this.placeControllerService.getPlaceInfo([placeId]).subscribe((data) => {
      if (data.data)
        this.mapService.hightLightMarker(data.data[0], this.mapService.getMap()?.getZoom(), true);
    });
  }

  // Handle gallery modal
  isShowGalleriesDialog: boolean = false;
  images: string[] = [];

  dialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 't-gallery-images-modal',
    appendTo: 'body',
  };

  onShowGalleriesDialog(images: string[], index: number) {
    this.images = images;
    this.activeImageGalleryIndex = index;
    this.isShowGalleriesDialog = true;
  }

  onCloseGalleriesDialog() {
    this.isShowGalleriesDialog = false;
  }

  percentColor(percent: number): string {
    return `-webkit-linear-gradient(top, var(--light-color-scheme-primary-600), var(--light-color-scheme-primary-600) ${percent}%, transparent ${percent}%, transparent 100%)`;
  }

  onPlayPreview() {
    const marker = document.querySelectorAll<HTMLElement>('.tour-guide-timeline .p-marker')!;
    const timelineConnector = document.querySelectorAll<HTMLElement>(
      '.tour-guide-timeline .p-timeline-event-connector'
    )!;
    const timelineSeparator = document.querySelectorAll<HTMLElement>(
      '.tour-guide-timeline .p-timeline-event-separator'
    )!;
    if (marker && marker[this.currentOrder - 1]) {
      marker[this.currentOrder - 1].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      this.clearColorPlayReview(marker, timelineConnector, timelineSeparator);
      this.addColorPlayReview(marker, timelineConnector);
    }
  }

  clearColorPlayReview(
    marker: NodeListOf<HTMLElement>,
    timelineConnector: NodeListOf<HTMLElement>,
    timelineSeparator: NodeListOf<HTMLElement>
  ) {
    const customSeparatorEle = document.querySelector<HTMLElement>(
      '.tour-guide-timeline .custom-separator'
    )!;
    const removeChild = document.querySelector<HTMLElement>(
      '.tour-guide-timeline .custom-separator-child'
    )!;
    if (!timelineSeparator[timelineSeparator.length - 1].contains(customSeparatorEle)) {
      const child = document.createElement('div');
      child.classList.add('custom-separator');
      timelineSeparator[timelineSeparator.length - 1].classList.add('custom-separator-after');
      timelineSeparator[timelineSeparator.length - 1].appendChild(child);
    }
    const lastChild = document.querySelector<HTMLElement>(
      '.tour-guide-timeline .custom-separator'
    )!;
    lastChild.style.backgroundImage = this.percentColor(0);
    if (lastChild.contains(removeChild)) {
      lastChild.removeChild(removeChild);
    }
    for (let i = 0; i < marker.length; i++) {
      marker[i].style.color = this.textColor;
      if (i < timelineConnector.length) {
        timelineConnector[i].style.backgroundImage = this.percentColor(0);
        if (timelineConnector[i].contains(removeChild)) {
          timelineConnector[i].removeChild(removeChild);
        }
      }
    }
  }

  addColorPlayReview(marker: NodeListOf<HTMLElement>, timelineConnector: NodeListOf<HTMLElement>) {
    let order;
    if (this.activeDay + 1 < this.currentDay) {
      order = marker.length - 1;

      const last = document.querySelector<HTMLElement>(
        '.tour-guide-timeline .p-timeline-event:last-child .custom-separator'
      )!;
      if (last) {
        last.style.backgroundImage = this.percentColor(100);
      }
    } else if (this.activeDay + 1 === this.currentDay) {
      order = this.currentOrder - 1;
    } else {
      return;
    }
    for (let i = 0; i < order; i++) {
      marker[i].style.color = this.primaryColor;
      timelineConnector[i].style.backgroundImage = this.percentColor(100);
    }
    marker[order].style.color = this.primaryColor;
  }
}
