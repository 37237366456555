<p-dialog
  header="Save to"
  [(visible)]="visible"
  [style]="{ width: '384px' }"
  [modal]="true"
  [draggable]="false"
  class="t-close-collection"
>
  <ng-template pTemplate="header">
    <div class="flex items-center w-full justify-between">
      <span class="text-xl font-semibold">
        {{ 'common.component.bookmark_modal.save_to' | translateAsync }}
      </span>
      <i
        class="sctr-icon-x-close cursor-pointer text-[#344054]"
        (click)="cancelBtn.emit(); onClose()"
      ></i>
    </div>
  </ng-template>
  <div class="gap-2">
    <div class="max-h-[14.375rem] overflow-y-scroll t-collection-panel flex flex-col gap-4">
      <div *ngFor="let point of listSave; let i = index" class="t-checkbox flex">
        <p-checkbox
          name="group"
          [value]="point"
          [(ngModel)]="selectedSave"
          [inputId]="point.id"
          (onChange)="onChange(point.id, point.name)"
        ></p-checkbox>
        <label
          #label
          [for]="point.id"
          class="gap-4 pl-3 md:text-lg text-md font-regular font-Inter text-gray-700 max-w-full truncate block"
          (mouseenter)="fullCollectionName.toggle($event); $event.stopPropagation()"
          (mouseleave)="fullCollectionName.hide()"
        >
          {{ point.name }}
        </label>
        <p-overlayPanel #fullCollectionName styleClass="t-more-categories-panel">
          <label
            [for]="point.id"
            class="gap-4 pl-3 md:text-lg text-md font-regular font-Inter text-gray-700"
          >
            {{ point.name }}
          </label>
        </p-overlayPanel>
      </div>
    </div>
    <div class="t-divider my-4">
      <p-divider type="dashed" class=""></p-divider>
    </div>
    <div class="t-checkbox">
      <p-checkbox
        [value]="newSave[0]"
        [(ngModel)]="selectedSave"
        [inputId]="newSave[0].id"
        (onChange)="onChange(newSave[0].id, newSave[0].name)"
      ></p-checkbox>
      <label
        [for]="newSave[0].id"
        class="gap-4 pl-3 md:text-lg text-md font-regular font-Inter text-gray-700"
      >
        <span>{{ getTranslationAsync('common.component.bookmark_modal.new_collection') }}</span>
      </label>
    </div>
    <div *ngIf="openInput">
      <div class="flex pt-4 pb-2">
        <p>{{ 'common.component.bookmark_modal.collection_name' | translateAsync }}</p>
        <p class="text-red-500 pl-1">*</p>
      </div>
      <input
        [placeholder]="'common.component.bookmark_modal.enter_collection_name' | translateAsync"
        class="p-3 border border-gray-200 bg-white focus:border-primary-300 focus:border-solid focus:shadow-[0_0px_0px_4px_#eaf3ff] rounded-lg w-full focus-visible:outline-none"
        [(ngModel)]="inputValue"
        (ngModelChange)="checkValidinput(inputValue)"
        (blur)="blurInput(inputValue)"
      />
      <div *ngIf="spaceValid" class="flex pt-4 pb-2">
        <p class="text-red-500">{{ 'page.saved_list.invalid_collection_name_type' | translateAsync }}</p>
      </div>
      <div *ngIf="emptyValid" class="flex pt-4 pb-2">
        <p class="text-red-500">{{ 'page.saved_list.collection_name_is_required' | translateAsync }}</p>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row-reverse gap-3">
      <button
        (click)="checkUpdateOrAddNew()"
        class="border-solid rounded-lg"
        [ngClass]="[
          inputValue ||
          (selectedSave[0] !== undefined &&
            selectedSave[0].id !== 'new collection' &&
            selectedSave !== previousSave)
            ? 'bg-primary-700'
            : 'bg-primary-100 disabled:bg-primary-100 pointer-events-none'
        ]"
      >
        <p class="py-3 px-5 font-semibold text-white text-[14px] leading-5">
          {{ 'common.term.confirm' | translateAsync }}
        </p>
      </button>
      <button (click)="cancelBtn.emit(); onClose()" class="border border-gray-200 rounded-lg">
        <p class="py-3 px-5 font-semibold text-[14px] leading-5">
          {{ 'common.term.cancel' | translateAsync }}
        </p>
      </button>
    </div>
  </ng-template>
</p-dialog>
