import { BehaviorSubject, Observable, Subject, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslationService } from '@core/services';

import { AgencyControllerService, ResponseModel } from '@assistant/angular-tour-ota-service';
import { HeaderLibModule } from '@soctrip-common/master-menu';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';

import { UserProfileService } from '@shared/services/user-profile.service';

import { environment } from '@env/environment';
import { AppRoutes } from 'src/app/config/routes.config';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { TimezoneService } from '@shared/services/timezone.service';

@Component({
  selector: 'app-master-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    /* components */
    HeaderLibModule,
    /* pipes */
    TranslateAsyncPipe,
    SocialLoginModule,
  ],
  providers: [
    AgencyControllerService,
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.socialAuthenIds.google, {
              oneTapEnabled: false, // <===== default is true
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.socialAuthenIds.facebook),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  templateUrl: './master-header.component.html',
  styleUrls: ['./master-header.component.scss'],
})
export class MasterHeaderComponent implements OnInit, OnDestroy {
  routes = AppRoutes;
  environment = environment;
  myBookingUrl = `${environment.TRAVEL_ASSIST_URL}/${AppRoutes.MY_BOOKING}`;
  pageTitle = environment.PAGE_TITLE;
  bannerUrl = environment.BANNER_URL;
  isSearching: boolean = false;
  userInfo$: any;
  agency$: Observable<ResponseModel | null> = of({
    data: { agency_id: sessionStorage.getItem('agencyId') ?? 'agencyId' },
    success: true,
    error: null,
  });
  unsubscribe$ = new Subject<void>();
  isFirstCheckToken = new BehaviorSubject<boolean>(true);
  adminRole: string = '';
  megaVisible = false;
  offHeader: boolean = false;
  isLogged = false;
  countryCode: string;
  currentTimeZone: string;
  stickyBannerClass = '';
  anonymousUserId: string;
  remindPositionSetting: string =
    'flex flex-col gap-1 items-end fixed md:mt-[-4.25rem] md:top-[5.625rem] md:right-5 md:mx-0 mx-2';

  constructor(
    private userProfileService: UserProfileService,
    private agencyControllerService: AgencyControllerService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private timezoneService: TimezoneService
  ) {}

  ngOnInit(): void {
    this.anonymousUserId = localStorage.getItem('device_id')! || '';
    let currentCountryCode = localStorage.getItem('currentCountryCode');
    if (currentCountryCode) {
      this.countryCode = currentCountryCode;
    } else {
      this.countryCode = 'US';
    }
    this.currentTimeZone = this.timezoneService.getTimezoneMapping(
      this.countryCode.toLocaleUpperCase()
    );

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params['hOff']) {
        this.offHeader = Boolean(params['hOff']);
      }
    });
    this.userInfo$ = this.userProfileService.getData();
    this.userProfileService.isLogged.subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          this.isLogged = true;
        }
      },
    });
    this.userProfileService
      .getData()
      .pipe(
        tap((userData) => {
          if (!userData && !this.isFirstCheckToken.getValue()) {
            this.agency$ = of(null);
          }
          this.isFirstCheckToken.next(false);
        }),
        filter((userData) => !!userData),
        switchMap((userData) => {
          return this.agencyControllerService.getAgencyIdRegistered(userData.id!);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (res) => {
          // console.log('res', res);
          this.agency$ = of(res);
        },
        error: (err) => {
          // console.error(err.message);
          this.agency$ = of(null);
        },
      });
  }

  updateLanguage(event: any) {
    this.translationService.setLanguage(event);
    window.location.reload();
  }

  navigateToBanner(url: string, option: string) {
    window.open(url, option, 'noopener');
    this.megaVisible = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isAllowedToShowRemindOrder(): boolean {
    const currentPath = window.location.pathname;

    return (currentPath === '/' ||
      currentPath === '/tour' ||
      currentPath.startsWith('/tour/tour-detail') ||
      currentPath.startsWith('/tour/search-result')) &&
      !environment.production
      ? true
      : false;
  }
}
