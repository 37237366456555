import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  /**
   * Badge type
   */
  @Input() type: 'text' | 'hashtag' = 'text';
  /**
   * Badge label
   */
  @Input() label: string = 'Badge';
  /**
   * Badge label
   */
  @Input() size?: 'small' | 'medium' = 'medium';
  /**
   * Badge color (default will be primary color)
   */
  @Input() color: 'primary' | 'rose' | 'green' | 'yellow' | 'red' = 'primary';
  /**
   * Badge color (default will be primary color)
   */
  @Input() removable: boolean = false;
  /**
   * Badge color (default will be primary color)
   */
  @Input() tooltip: string;
  /**
   * Click handler (optional)
   */
  @Output() handleOnClick = new EventEmitter();
  /**
   * Click on remove icon handler (optional)
   */
  @Output() handleOnRemove = new EventEmitter();

  tooltipOptions = {
    autoHide: false,
    tooltipEvent: 'hover',
  };

  ngOnInit(): void {
    if (this.type === 'hashtag')
      this.label = this.label.startsWith('#') ? this.label : `#${this.label}`;
  }
}
