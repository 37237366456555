<div class="t-map-search">
  <span
    class="sctr-icon-search-lg t-search-icon t-icon--white {{
      isShowSeachBox ? 'visible pointer-events-none opacity-60' : 'invisible opacity-0'
    }}"
  ></span>

  <p-autoComplete
    [(ngModel)]="selectedItem"
    [suggestions]="suggestions"
    [showClear]="isShowClear"
    [group]="true"
    [showEmptyMessage]="true"
    (completeMethod)="search($event)"
    (ngModelChange)="onChange($event)"
    (onClear)="handleClear()"
    (onSelect)="handleSelect($event)"
    (keydown.enter)="handleSelect(selectedItem)"
    field="label"
    emptyMessage="No result for &quot;{{ selectedItem }}&quot;"
    [placeholder]="placeholder"
    scrollHeight="500px"
    styleClass="t-map-search-input-wrapper {{ isShowSeachBox ? 'active' : '' }}"
    inputStyleClass="t-map-search-input {{ isShowSeachBox ? 'active' : 'w-0' }}"
    panelStyleClass="t-map-search-overlay"
  >
    <ng-template let-group pTemplate="group">
      <div *ngIf="group.label">{{ group.label }}</div>
    </ng-template>
    <ng-template let-suggestedItem pTemplate="item">
      <div *ngIf="suggestedItem.type === 'Place'" class="flex items-center py-2">
        <div class="flex items-center justify-center">
          <i class="t-item-icon sctr-icon-marker-pin-01 p-2.5 rounded-full bg-primary-100"></i>
        </div>
        <div class="flex-grow overflow-hidden pl-3">
          <div
            class="overflow-hidden text-md text-gray-700"
            style="white-space: nowrap; text-overflow: ellipsis"
            [innerHTML]="suggestedItem.label | highlight: searchTerm"
          ></div>
          <div
            *ngIf="suggestedItem.addr"
            class="mt-1 overflow-hidden text-md text-gray-500"
            style="white-space: nowrap; text-overflow: ellipsis"
            [innerHTML]="suggestedItem.addr | highlight: searchTerm"
          ></div>
        </div>
        <div
          *ngIf="suggestedItem.category"
          class="px-2 text-md text-gray-500"
          [innerHTML]="suggestedItem.category | highlight: searchTerm"
        ></div>
      </div>
    </ng-template>
    <ng-template pTemplate="loadingicon">
      <i class="t-icon--white pi pi-spin pi-spinner"></i>
    </ng-template>
    <ng-template pTemplate="clearicon">
      <i class="t-icon--white sctr-icon-x-close"></i>
    </ng-template>
  </p-autoComplete>
</div>
