import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-media-player',
  standalone: true,
  imports: [CommonModule, ButtonModule, ProgressSpinnerModule],
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaPlayerComponent implements OnInit {
  @Input() url: string;
  @Input() type: string;
  @Input() isCover: boolean = false;
  @Input() showToolbar: boolean = true;
  @Input() isPreviewMode: boolean = false;
  @Input() isCustomPlayButtonHandler: boolean = false;
  @Output() customPlayButtonHandler = new EventEmitter();

  @ViewChild('video', { static: true }) video: ElementRef;

  videoSrc: string;
  isLoading: boolean = true;

  ngOnInit() {
    this.type = this.url.split('.').pop() as string;
    this.videoSrc = this.url + '#t=1';
  }

  handleStalled() {
    this.isLoading = true;
    if (this.videoSrc.includes('.webm')) {
      let iframeEle = document.createElement('iframe');
      iframeEle.style.display = 'none';
      iframeEle.setAttribute('src', this.videoSrc);
      document.body.appendChild(iframeEle);
      iframeEle.addEventListener('load', () => {
        document.body.removeChild(iframeEle);
      });
    }
  }

  handleLoaded(event: Event) {
    this.isLoading = false;
    if (event.type === 'error' && this.videoSrc.includes('.webm')) {
      this.videoSrc = this.videoSrc.replace('.webm', '.mp4');
    } else if (event.type === 'error' && this.videoSrc.includes('.mp4')) {
      this.videoSrc = this.videoSrc.replace('.mp4', '.web');
      console.error('Fail to loading from server');
    }
  }
}
