import { Injectable } from '@angular/core';
import {
  AgencyRoleDTO,
  TourUserControllerService,
  UserRoleDetailDTO,
} from '@assistant/angular-tour-ota-service';
import { UserService } from './user.service';
import { BehaviorSubject, Observable, filter, map, of, switchMap } from 'rxjs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionService } from '../permission/permission.service';
import { BrowseAccount } from '@shared/enum/browse-account.enum';

@Injectable({
  providedIn: 'root',
})
export class UserRolesResolverService {
  adminPermission: string =
    'TOUR_DASHBOARD_RESERVATION_RO; TOUR_DASHBOARD_ADVERTISING_RO; TOUR_GROUP_MGNT_RW; TOUR_MGNT_RW; TOUR_AVAILABILITY_RW; TOUR_RESERVATION_MGNT_RW; TOUR_USER_MGNT_RW; TOUR_DELETE_USER; TOUR_PROMOTION_RW; TOUR_CONTRACT_RW; TOUR_BUILDER_RW; TOUR_VOUCHER_RW; TOUR_POLICY_RW; TOUR_CONTACT_RW; TOUR_PAYMENT_RW; TOUR_DETAILS_RO';
  otaOwnerRole: string = 'OTA_OWNER';

  constructor(
    private tourUserControllerService: TourUserControllerService,
    private userService: UserService,
    private permissionService: PermissionService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAdmin = localStorage.getItem(BrowseAccount.PERMISSION) === BrowseAccount.ADMIN;

    if (isAdmin) {
      this.permissionService.nextPermissions(this.adminPermission);
      this.permissionService.nextUserRoleOfTourGrp(this.otaOwnerRole);
      return;
    }

    const id = this.userService.getUserInfoFromLocalStorage()?.id;
    return this.tourUserControllerService.getUserRoles(id).pipe(
      map((res) => {
        if (res.success) {
          let agency: AgencyRoleDTO | null = null;
          if (res.success && res.data?.agencies?.length! > 0) {
            agency = res.data?.agencies?.find((item) => state.url.includes(item.agency_id!))!;
            const role = agency?.roles?.find((role) => state.url.includes(role.tour_group_id!));
            this.permissionService.nextPermissions(role?.permission!);
            this.permissionService.nextUserRoleOfTourGrp(role?.role!);
          }
        }
      })
    );
  }
}
