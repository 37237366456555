import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ReviewComponent } from '@shared/components/review/review.component';
import { PluralizePipe } from '@shared/pipes/pluralize.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@core/services';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { GetBloomHelpfulService } from '@shared/services/get-bloom-helpful.service';

export interface Review {
  id: string;
  content: string;
  rating: number;
  created_at: string;
  medias?: any[];
  created_by: {
    id: string;
    full_name: string;
    avatar_thumbnail_url: string;
    frame_url: string;
    username: string;
    city_code: string;
  };
  object_id: string;
}

@Component({
  selector: 'app-review-panel',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ReviewComponent,
    PluralizePipe,
    TranslateModule,
    TranslateAsyncPipe,
  ],
  templateUrl: './review-panel.component.html',
  styleUrls: ['./review-panel.component.scss'],
})
export class ReviewPanelComponent {
  @Input() reviews: Review[] = [];
  @Input() total_reviews: number = 0;
  @Input() rating: number;
  @Input() disable: boolean = true;
  @Output() handleLoadMoreReviews = new EventEmitter();
  @Output() emitOpenReportDialog = new EventEmitter<Review>();

  constructor(
    private getBloomHelpfulService: GetBloomHelpfulService,
    private translationService: TranslationService
  ) {
    this.getBloomHelpfulService.getBloomlFilterString();
  }

  getRatingString(rating: number): string {
    return `${rating}/10`;
  }

  getTranslationAsync(key: string, interpolateParams?: Object): string {
    return this.translationService.getTranslationAsync(key, interpolateParams);
  }
}
