import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { LatLng, latLngBounds } from 'leaflet';
import { IPlace, LocationService, SoctripMapService } from '@soctrip-common/map';
import { PlaceControllerService, PlaceDetailDTO } from '@assistant/angular-map-location-service';
import { ItineraryDto, PlaceVisitDto } from '@assistant/angular-tour-builder-service';
import { colors } from '../constant';
@Injectable({
  providedIn: 'root',
})
export class ItineraryService implements OnDestroy {
  public placeVisitsSubject = new BehaviorSubject<number>(0);
  private isPlaceSaved = new BehaviorSubject<boolean>(true);
  isPlaceSaved$: Observable<boolean> = this.isPlaceSaved.asObservable();
  public markersSubject = new BehaviorSubject<any[]>([]);
  public allCoordinates = new BehaviorSubject<any[]>([]);
  public markersAction$ = this.markersSubject.asObservable();
  private curMarkers: any[] = [];
  private randomColor: string;
  private colorList: string[] = [
    '#1570EF',
    '#875BF7',
    '#66C61C',
    '#EE46BC',
    '#0BA5EC',
    '#FDB022',
    '#FF692E',
    '#15B79E',
  ];
  private destroy$ = new Subject();
  private totalPlaceIds: string[] = [];
  public iShowQuickView = false;
  currTourId: string = '';
  defaultId = '00000000-0000-0000-0000-000000000000';
  public isShowMarkersCalled: boolean = false;
  onRouting: boolean = false;
  constructor(
    private mapService: SoctripMapService,
    private placeControllerService: PlaceControllerService,
    private locationService: LocationService
  ) {}

  setPlaceSaved(value: boolean): void {
    this.isPlaceSaved.next(value);
  }

  async addPlaceMarkers(
    placeVisits: PlaceVisitDto[],
    day: number,
    colorMarker?: boolean,
    setView?: boolean,
    singleDay?: boolean,
    tourId?: string
  ): Promise<void> {
    if (tourId) {
      const id = tourId;
      let currentId: string = '';
      currentId = !currentId ? id! : currentId!;
      this.totalPlaceIds = currentId !== id ? [] : this.totalPlaceIds;
    } else if (singleDay) {
      this.totalPlaceIds = [];
    }
    const placeIds = placeVisits?.map((place) => place?.place_id) as string[];
    const setOfPlaceIds = placeIds.filter(
      (id) => id !== this.defaultId && id !== '' && id !== null
    );
    this.totalPlaceIds = this.totalPlaceIds.concat(setOfPlaceIds);
    if (setOfPlaceIds.length > 0) {
      await this.handleAddMarkerBySetOfPlaceIds(
        setOfPlaceIds,
        day,
        colorMarker ? true : false,
        placeVisits
      );
    }
  }

  async handleAddMarkerBySetOfPlaceIds(
    setOfPlaceIds: string[],
    day: number,
    colorMarker: boolean,
    placeVisits: PlaceVisitDto[] = []
  ): Promise<L.Marker[]> {
    return new Promise<L.Marker[]>((resolve, reject) => {
      this.placeControllerService.getPlaceInfoWithoutCache(setOfPlaceIds).subscribe((data) => {
        if (data.data) {
          if (day !== 0) {
            const color: string = colorMarker ? this.getColorByDay(day) : '#FF0000';
            this.addMarkers(data.data, placeVisits, undefined, color);
          } else {
            const color: string = colorMarker ? this.getColorByDay(day) : '#FF0000';
            this.addMarkers(data.data, placeVisits, undefined, color);
          }
          this.markersSubject.next(this.curMarkers);
          resolve(this.curMarkers);
        }
      });
    });
  }

  getColorByDay(day: number): string {
    if (day > colors.length) return '#0ba5ec';
    return colors[day - 1];
  }
  addMarkers(places: PlaceDetailDTO[], placeVisits: PlaceVisitDto[], day?: number, color?: string) {
    try {
      const latLngs: LatLng[] = [];
      const markIcon = day ? this.mapService.MarkerNumIcon(day, color || '') : undefined;
      let listMarkers: any[] = [];
      places.forEach(async (place: PlaceDetailDTO, index) => {
        if (place?.id) {
          listMarkers.push(
            ...this.mapService.addMarkers(
              [place],
              false,
              markIcon ? markIcon : (this.mapService.MarkerNumIcon(index + 1, color!) as Node)
            )
          );
        } else {
          latLngs.push(
            new LatLng(placeVisits[index].latitude || 0, placeVisits[index].longitude || 0)
          );
        }
      });

      const allMarkers = [...listMarkers, ...this.mapService.addMarkers(latLngs, false, markIcon)];

      this.curMarkers.push(...allMarkers);
    } catch (e) {
      console.error('error add markers', e);
    }
  }

  fitBoundsMarkers(isShowQuickView?: boolean, itineraries?: any) {
    if (this.mapService.listMarkers.length === 0) return;
    if (this.mapService.typeMap === 'google') {
      setTimeout(() => {
        const bounds = new google.maps.LatLngBounds();
        this.mapService.listMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
          bounds.extend(marker.position!);
        });
        let map = this.mapService.getMap() as google.maps.Map;
        if (map) {
          map.fitBounds(bounds, 100);
          const currentZoom = map.getZoom();
          if (currentZoom && currentZoom > 13) {
            map.setZoom(13);
          }
          const sizeMap = Math.round(map.getDiv().offsetWidth);
          const distantmove = Math.round(sizeMap / 2 - sizeMap / 3);

          this.mapService.updateMapViewTo(map.getCenter()!, 'left', distantmove);
        }
      }, 1000);
    } else {
      try {
        let map = this.mapService.getMap() as L.Map;
        const markerLatLngs = this.curMarkers.map((marker) => marker?.getLatLng());
        if (markerLatLngs.length === 0) return;
        const bounds = latLngBounds(markerLatLngs);
        if (!bounds) return;
        if (!isShowQuickView) {
          map?.flyToBounds(bounds, { padding: [100, 100], maxZoom: 14 });
        } else {
          const oOfThirdsMapWidth = (1 / 3) * map.getSize().x + 50;
          map?.flyToBounds(bounds, {
            paddingTopLeft: [oOfThirdsMapWidth, 50],
            paddingBottomRight: [50, 50],
            maxZoom: 14,
          });
        }
      } catch (e) {
        console.error('error fit bound', e);
      }
    }
  }

  highlightMarker(placeId: string, place?: IPlace) {
    this.placeControllerService.getPlaceInfoWithoutCache([placeId]).subscribe((data) => {
      if (data.data && data.data[0]?.id) {
        this.mapService.hightLightMarker(data.data[0], this.mapService.getMap()?.getZoom(), true);
      } else {
        const marker = this.curMarkers.find((m) => {
          let latLngInfo = m.getLatLng();
          return latLngInfo.lat === place?.latitude && latLngInfo.lng === place?.longitude;
        });
        if (marker) {
          this.mapService.hightLightMarker(marker, this.mapService.getMap()?.getZoom(), true);
          this.locationService.setLocationData(null);
        }
      }
    });
  }

  clearAllMarkers() {
    this.markersSubject.getValue()?.forEach((marker) => {
      this.mapService.removeMarker(marker);
    });
    this.markersSubject.next([]);
    this.totalPlaceIds = [];
    // Hide marker routing
    this.onShowRouting([]);
  }

  getRandomColor() {
    if (this.colorList.length === 0) {
      this.colorList = [
        '#1570EF',
        '#875BF7',
        '#66C61C',
        '#EE46BC',
        '#0BA5EC',
        '#FDB022',
        '#FF692E',
        '#15B79E',
      ];
    }
    const randomIndex: number = Math.floor(Math.random() * this.colorList.length);
    this.randomColor = this.colorList[randomIndex];
    this.colorList.splice(randomIndex, 1);
    return this.randomColor;
  }

  onShowRouting(
    latLngList: [number, number][],
    showMovingMarkers: boolean = false,
    time?: number,
    color?: string
  ) {
    if (this.onRouting) return;
    setTimeout(() => {
      this.mapService.birdDirection(latLngList!, color);
    }, 500);
  }

  getLatLngList(itineraries: ItineraryDto[], showRouting?: boolean, zoom: boolean = false) {
    const latLngList: any[] = [];
    itineraries?.forEach((itinerary) => {
      itinerary.place_visits?.forEach((place) => {
        if (place.latitude && place.longitude) {
          const latLng = [place.latitude, place.longitude];
          latLngList.push(latLng);
        }
      });
    });
    if (showRouting) {
      this.onShowRouting(latLngList);
    }
  }
  previousItineraries: any[] = [];

  checkPreviousItineraries(itineraries: any[]): boolean {
    if (JSON.stringify(this.previousItineraries) === JSON.stringify(itineraries)) {
      return true;
    } else {
      this.previousItineraries = itineraries;
      return false;
    }
  }

  async showMarkers(
    itineraries: ItineraryDto[],
    numberedMarkers?: boolean,
    zoom: boolean = false,
    tourId?: string,
    isShowQuickView?: boolean
  ): Promise<void> {
    if (itineraries?.length === 0 || !itineraries) return;
    try {
      if (!this.isShowMarkersCalled) {
        this.isShowMarkersCalled = true;
        if (numberedMarkers) {
          this.removeOlderMarkers();
          setTimeout(async () => {
            if (itineraries?.length === 1) {
              const { place_visits, day_no } = itineraries[0];
              await this.addPlaceMarkers(
                place_visits as PlaceVisitDto[],
                day_no!,
                true,
                zoom,
                true,
                tourId
              );
              this.resetShowMarkersCalled();
              this.fitBoundsMarkers(isShowQuickView, itineraries);
            } else {
              let currentDay = 1;
              const addMarkersForNextDay = async () => {
                const itinerary = itineraries?.find((itinerary) => itinerary.day_no === currentDay);
                if (itinerary) {
                  const { place_visits, day_no } = itinerary;
                  await this.addPlaceMarkers(
                    place_visits as PlaceVisitDto[],
                    day_no!,
                    true,
                    zoom,
                    itineraries?.length === 1,
                    tourId
                  );
                  currentDay++;
                  addMarkersForNextDay();
                  this.resetShowMarkersCalled();
                } else {
                  this.markersSubject.next(this.curMarkers);
                  this.fitBoundsMarkers(isShowQuickView, itineraries);
                  this.resetShowMarkersCalled();
                }
              };
              addMarkersForNextDay();
            }
          }, 500);
        } else {
          this.placeVisitsSubject
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (value: number) => {
              if (value) {
                const placeVisits: PlaceVisitDto[] =
                  value === 0
                    ? (itineraries.flatMap(
                        (itinerary) => itinerary.place_visits
                      ) as PlaceVisitDto[])
                    : (itineraries[value - 1]?.place_visits as PlaceVisitDto[]);
                if (placeVisits) {
                  await this.addPlaceMarkers(
                    placeVisits,
                    value,
                    zoom,
                    undefined,
                    undefined,
                    tourId
                  );
                }
                this.resetShowMarkersCalled();
                this.fitBoundsMarkers(isShowQuickView, itineraries);
              } else {
                this.resetShowMarkersCalled();
              }
            });
          this.markersSubject.next(this.curMarkers);
          this.fitBoundsMarkers(isShowQuickView, itineraries);
        }
      }
    } catch (e) {
      this.resetShowMarkersCalled();
      console.error('error show markers', e);
    }
  }

  private resetShowMarkersCalled() {
    setTimeout(() => {
      this.isShowMarkersCalled = false;
    }, 200);
  }
  removeOlderMarkers() {
    if (this.mapService.listMarkers.length > 0) {
      this.mapService.listMarkers.forEach((marker) => {
        this.mapService.removeMarker(marker);
      });
    }
    this.curMarkers = [];
    this.markersSubject.next([]);
  }

  ngOnDestroy(): void {
    this.destroy$.next([]);
    this.destroy$.complete();
  }
}
