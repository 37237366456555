import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedLanguageString',
  standalone: true,
})
export class LocalizedLanguageStringPipe implements PipeTransform {
  transform(value: string | { [key: string]: string }, lang?: string): string {
    lang = lang ? lang.toLowerCase() : localStorage.getItem('lang')?.toLowerCase() || 'en';

    if (typeof value === 'string') {
      if (value.trim().startsWith('{') && value.trim().endsWith('}')) {
        try {
          const parsedValue = JSON.parse(value);
          if (typeof parsedValue === 'object' && parsedValue !== null) {
            value = parsedValue;
          }
        } catch {
          return value as string;
        }
      } else {
        return value;
      }
    }

    if (typeof value === 'object') {
      if (value[lang] !== null && value[lang] !== undefined) {
        return value[lang];
      } else if (value['en'] !== null && value['en'] !== undefined) {
        return value['en'];
      } else {
        const firstNonNullKey = Object.keys(value).find((key) => value[key] !== null);
        return firstNonNullKey ? value[firstNonNullKey] : '';
      }
    }

    return value;
  }
}
