import { environment } from '@env/environment';

export const getImagePath = (
  file: string,
  option: 'web' | 'svg' | 'thumbnail' | 'mobile' = 'web',
  extension = 'webp'
) => {
  if (option && option === 'svg') {
    return `${environment.baseURL}/storage/files/download/${file}.svg`;
  }
  return `${environment.baseURL}/storage/files/${option}/${file}.${extension}`;
};
