export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];
export const colors = [
  '#ff692e', // Red-Orange
  '#66c61c', // Green
  '#fdb022', // Yellow
  '#ee46bc', // Pink
  '#344054', // Dark Blue
  '#875bf7', // Purple
  '#1570ef', // Blue
  '#15b79e', // Teal
  '#164c63', // Dark Teal
  '#a15c07', // Brown
  '#d32f2f', // Red
  '#1976d2', // Blue
  '#388e3c', // Green
  '#0288d1', // Light Blue
  '#8e24aa', // Purple
  '#ff5722', // Deep Orange
  '#5c6bc0', // Indigo
  '#7b1fa2', // Purple
  '#f57c00', // Orange
  '#c2185b', // Pink
  '#43a047', // Green
  '#ab47bc', // Purple
  '#7e57c2', // Violet
  '#e91e63', // Pink
  '#9c27b0', // Purple
  '#00bcd4', // Cyan
  '#ff9800', // Orange
  '#607d8b', // Blue Grey
];
