import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LOCALSTORAGE_ITEMS } from '@shared/enum';
import { ResponseModel } from '@soctrip/angular-regional-service';
import { TranslationService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class LanguagePreferenceService {
  private readonly userApi: string = `${environment.BASE_API_URL}/user`;
  private readonly defaultLanguagePreference = {
    language: 'en',
    currency: 'USD',
    country_code: 'US',
  };

  constructor(
    private httpClient: HttpClient,
    private translationService: TranslationService
  ) {}

  private createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }

  private getUserAPI(userId: string) {
    const options = { headers: this.createHeader() };
    const userAPI = `${this.userApi}/users/${userId}`;
    return this.httpClient.get(userAPI, options);
  }

  retrieveLanguagePreference(userId: string) {
    this.getUserAPI(userId).subscribe((response: ResponseModel) => {
      if (response.success) {
        const { language, currency, country_code } = response.data;
        const languagePreference = {
          language: language,
          currency: currency,
          // To Do: Set all country_code to US. Will improve when API is fixed
          // country_code: country_code,
          country_code: 'US',
        };
        localStorage.setItem(
          LOCALSTORAGE_ITEMS.LANGUAGE_PREFERENCE,
          JSON.stringify(languagePreference)
        );
      } else {
        // Set default value
        localStorage.setItem(
          LOCALSTORAGE_ITEMS.LANGUAGE_PREFERENCE,
          JSON.stringify(this.defaultLanguagePreference)
        );
      }
    });
  }

  getLanguagePreference() {
    const languagePreference = localStorage.getItem(LOCALSTORAGE_ITEMS.LANGUAGE_PREFERENCE);
    return languagePreference ? JSON.parse(languagePreference) : this.defaultLanguagePreference;
  }
}
