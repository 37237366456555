import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ResponseModel } from '@soctrip/angular-regional-service';

@Injectable({
  providedIn: 'root',
})
export class CurrentLocationService {
  private readonly regionalApi: string = `${environment.BASE_API_URL}/regional`;
  private readonly defaultCountryCode: string = 'US';
  private readonly CURRENT_COUNTRY_CODE_KEY: string = 'currentCountryCode';
  private readonly IS_AUTO_DETECT_LOCATION: string = 'isAutoDetectLocation';
  private currentCountryCode: string = '';
  private targetSearchLocation$ = new BehaviorSubject<string>('');
  private countryCode$ = new BehaviorSubject<string>('');
  private isAutoDetect$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {
    this.retrieveCurrentCountryCode();
  }

  private createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }

  getCurrentLocationAPI() {
    const options = { headers: this.createHeader() };
    const regionalApi = `${this.regionalApi}/me`;
    return this.httpClient.get(regionalApi, options);
  }

  retrieveCurrentCountryCode() {
    const countryCode = localStorage.getItem(this.CURRENT_COUNTRY_CODE_KEY);
    if (countryCode) {
      this.currentCountryCode = countryCode;
      this.storeCurrentCountryCode(countryCode);
    } else {
      this.autoDetectCurrentCountryCode();
    }
  }

  autoDetectCurrentCountryCode() {
    this.getCurrentLocationAPI().subscribe((response: ResponseModel) => {
      if (response.success) {
        const data = response.data[0];
        this.currentCountryCode = data.country_code;
        this.storeCurrentCountryCode(data.country_code);
        this.setIsAutoDetect(true);
      } else {
        this.currentCountryCode = this.defaultCountryCode;
        this.storeCurrentCountryCode(this.defaultCountryCode);
      }
    });
  }

  setCurrentCountryCode(countryCode: string) {
    if (countryCode.toLowerCase() !== this.currentCountryCode.toLowerCase()) {
      this.currentCountryCode = countryCode.toUpperCase() || this.defaultCountryCode;
      this.storeCurrentCountryCode(this.currentCountryCode);
      this.setIsAutoDetect(false);
    }
  }

  getCurrentCountryCode(): string {
    return localStorage.getItem(this.CURRENT_COUNTRY_CODE_KEY) || this.defaultCountryCode;
  }

  storeCurrentCountryCode(countryCode: string) {
    localStorage.setItem(this.CURRENT_COUNTRY_CODE_KEY, countryCode);
    this.countryCode$.next(countryCode);
  }

  getCurrentCountryCodeObservable(): Observable<string> {
    return this.countryCode$;
  }

  getTargetSearchLocation(): Observable<string> {
    return this.targetSearchLocation$;
  }

  setTargetSearchLocation(value: string) {
    this.targetSearchLocation$.next(value);
  }

  getIsAutoDetect(): boolean {
    return JSON.parse(localStorage.getItem(this.IS_AUTO_DETECT_LOCATION) ?? '');
  }

  getIsAutoDetectObservable(): Observable<boolean> {
    return this.isAutoDetect$;
  }

  setIsAutoDetect(value: boolean) {
    localStorage.setItem(this.IS_AUTO_DETECT_LOCATION, JSON.stringify(value));
    this.isAutoDetect$.next(value);
  }
}
