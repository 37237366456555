import { MapSearchComponent } from './../map-search/map-search.component';
import { ButtonModule } from 'primeng/button';
import {
  Component,
  EventEmitter,
  Input,
  NO_ERRORS_SCHEMA,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import * as L from 'leaflet';
import { SoctripMapService, LocationService } from '@soctrip-common/map';
import { SuggestItems } from './../map-search/map-search.component';
import { Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';

@Component({
  selector: 'app-map-routing',
  standalone: true,
  imports: [
    CommonModule,
    SidebarModule,
    ButtonModule,
    MapSearchComponent,
    TranslateModule,
    TranslateAsyncPipe,
  ],
  templateUrl: './map-routing.component.html',
  styleUrls: ['./map-routing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [NO_ERRORS_SCHEMA],
})
export class MapRoutingComponent implements OnInit, OnChanges {
  @Input() sidebarVisible = false;
  @Output() hideSidebar = new EventEmitter<boolean>();
  StartRouteSub = new Subject<L.LatLng | null>();
  StartRouteOb = this.StartRouteSub.asObservable();
  EndRouteSub = new Subject<L.LatLng | null>();
  EndRouteOb = this.EndRouteSub.asObservable();
  routeFrom: L.LatLng | null = null;
  routeTo: L.LatLng | null = null;
  markerFrom: L.Marker | null = null;
  markerTo: L.Marker | null = null;
  routeData: any;
  routeLayer: L.Polyline | null;
  maxHeight: string;
  constructor(
    private mapServices: SoctripMapService,
    private locService: LocationService
  ) {}

  selectedItemFrom: SuggestItems = { value: '', type: 'Place', label: '' };
  selectedItemTo: SuggestItems = { value: '', type: 'Place', label: '' };
  ngOnInit(): void {
    this.locService.isOpenDirection$
      .pipe(
        switchMap((isOpen) => {
          if (isOpen) {
            this.sidebarVisible = true;
            return this.locService.LocationDataObservable$;
          } else {
            return of(null);
          }
        })
      )
      .subscribe((data) => {
        if (data) {
          this.EndRouteSub.next(L.latLng(data.lat || 0, data.lon || 0));
          this.selectedItemTo = {
            value: data?.main_text ? data.main_text : '',
            type: 'Place',
            label: data.main_text || '',
          };
          this.mapServices.unHLMarker();
        }
      });
    this.StartRouteOb.subscribe((data) => {
      if (data) {
        this.routeFrom = data;
        this.mapServices.unHLMarker();
        if (this.markerFrom) this.mapServices.removeMarker(this.markerFrom);
        this.markerFrom = L.marker(data, {
          icon: this.mapServices.startRouteIcon,
        }).addTo(this.mapServices.getMap());
        this.direction();
      }
    });
    this.EndRouteOb.subscribe((data) => {
      if (data) {
        this.routeTo = data;
        this.mapServices.unHLMarker();
        if (this.markerTo) this.mapServices.removeMarker(this.markerTo);
        this.markerTo = L.marker(data, {
          icon: this.mapServices.endRouteIcon,
        }).addTo(this.mapServices.getMap());
        // }

        this.direction();
      }
    });
  }
  private direction() {
    if (this.routeTo !== null && this.routeFrom !== null) {
      const element = document.querySelector('.t-map-routing-container') as HTMLElement;
      const height = element.offsetHeight;
      if (height > 700) {
        this.maxHeight = '530px';
      } else {
        this.maxHeight = '360px';
      }
      this.mapServices.directionStreet([this.routeFrom, this.routeTo]).subscribe(
        (data) => {
          this.routeData = data;
          this.routeLayer = this.mapServices.routeLayer;
        },
        (error) => {
          console.error('Error:', error);
          this.routeData = error;
        }
      );
    }
  }

  onSelectItemFrom(event: any) {
    this.StartRouteSub.next(L.latLng(event.lat, event.lng));
  }
  onSelectItemTo(event: any) {
    this.EndRouteSub.next(L.latLng(event.lat, event.lng));
  }
  formatDuration(duration: number): string {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
    return ` ${hours !== 0 ? hours.toString().padStart(2, '0') + 'hours' : ''}${minutes
      .toString()
      .padStart(2, '0')} min ${seconds.toString().padStart(2, '0')} seconds`;
  }
  onHide() {
    this.ClearDirection();
    this.sidebarVisible = false;
    this.hideSidebar.emit(false);
    this.locService.setIsOpenDirection(false);
  }
  ClearDirection() {
    this.StartRouteSub.next(null);
    this.EndRouteSub.next(null);
    this.selectedItemFrom = { value: '', type: 'Place', label: '' };
    this.selectedItemTo = { value: '', type: 'Place', label: '' };
    if (this.routeLayer) this.mapServices.removePolyLine(this.routeLayer!);
    if (this.routeLayer) this.mapServices.removePolyLine(this.routeLayer!);
    if (this.markerFrom) this.mapServices.removeMarker(this.markerFrom);
    if (this.markerTo) this.mapServices.removeMarker(this.markerTo);
    this.routeLayer = null;
    this.markerFrom = null;
    this.markerTo = null;
    this.routeData = null;
    this.routeTo = null;
    this.routeFrom = null;
  }
  clearFrom() {
    this.StartRouteSub.next(null);
    this.selectedItemFrom = { value: '', type: 'Place', label: '' };
    if (this.routeLayer) this.mapServices.removePolyLine(this.routeLayer!);
    if (this.markerFrom) this.mapServices.removeMarker(this.markerFrom);
    this.routeLayer = null;
    this.routeData = null;
    this.markerFrom = null;
    this.routeFrom = null;
  }
  clearTo() {
    this.EndRouteSub.next(null);
    this.selectedItemTo = { value: '', type: 'Place', label: '' };
    if (this.routeLayer) this.mapServices.removePolyLine(this.routeLayer!);
    if (this.markerTo) this.mapServices.removeMarker(this.markerTo);
    this.routeLayer = null;
    this.routeData = null;
    this.markerTo = null;
    this.routeTo = null;
  }
  isDisableClear(): boolean {
    if (this.routeTo !== null || this.routeFrom !== null) {
      return false;
    }
    return true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sidebarVisible']) {
      if (changes['sidebarVisible'].currentValue === false) {
        if (this.routeLayer) this.mapServices.removePolyLine(this.routeLayer!);
        if (this.markerFrom) this.mapServices.removeMarker(this.markerFrom);
        if (this.markerTo) this.mapServices.removeMarker(this.markerTo);
      } else {
        if (this.routeLayer) this.mapServices.getMap().addLayer(this.routeLayer!);
        if (this.markerFrom) this.mapServices.getMap().addLayer(this.markerFrom);
        if (this.markerTo) this.mapServices.getMap().addLayer(this.markerTo);
      }
    }
  }
}
