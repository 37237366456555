<div
  class="t-badge t-badge-{{ type }} t-badge-{{ color }}"
  [ngClass]="size==='medium' ? 'text-md' : 'text-sm'"
  (click)="handleOnClick.emit()"
  [pTooltip]="tooltip"
  [tooltipOptions]="tooltipOptions"

>
  {{ label }}
  <i *ngIf="removable" class="t-close-icon sctr-icon-x" (click)="handleOnRemove.emit()"></i>
</div>
