import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { IDay } from '@modules/create-tour/components/explore/explore.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';

@Component({
  selector: 'app-card2',
  templateUrl: './card2.component.html',
  styleUrls: ['./card2.component.scss'],
  standalone: true,
  imports: [ButtonModule, OverlayPanelModule, CommonModule, TranslateModule, TranslateAsyncPipe],
})
export class Card2Component implements OnChanges {
  /**
   * The image link to display on card @requires
   */
  @Input() imageUrl: string;
  /**
   * Card title @requires
   */
  @Input() title: string;
  /**
   * Click handler (optional)
   */
  @Output() handleOnClick = new EventEmitter<IDay>();
  @Output() selectedCard = new EventEmitter<MouseEvent>();

  @Input() days: Array<IDay> = [];
  @Input() isActivated: boolean = false;
  @Input() placeId: string;
  extraDay: IDay = { no: 2 };
  destroy$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['days']) {
      this.extraDay['no'] = this.days.length + 1;
    }
  }

  handleClickAddBtn(event: MouseEvent) {
    if (event.target === event.currentTarget) this.selectedCard.emit(event);
  }

  isDayAdded(day: IDay, placeId: string): boolean {
    return day.addedPlaces?.includes(placeId) || false;
  }
}
