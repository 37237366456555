import { Injectable } from '@angular/core';
import { GetApiService, postTranslate } from './get-api.service';
import { BehaviorSubject } from 'rxjs';
import { autoTranslateDb, IAutoTranslate } from '@shared/indexeddb-databases/translate-db';
import { AutoTranslateService } from './auto-translate.service';
import { generateHash, normalizeText } from '@shared/utils/normalize-text';
import { TranslationService } from '@core/services';

export interface translateResult {
  translatedText: string;
  detectedLanguage: {
    confidence: number;
    language: string;
  };
  originalText: string;
}
@Injectable({
  providedIn: 'root',
})
export class TranslateHubService {
  private debounceTimer: any = null;
  public translateResult: translateResult[] = [];
  public translateDetection$ = new BehaviorSubject<number>(0);
  public isLoadMore$ = new BehaviorSubject<boolean>(false);

  target: string = '';
  listText: postTranslate[] = [];
  newListText: postTranslate[] = [];
  listTextResult: IAutoTranslate[] = [];

  translationList: IAutoTranslate[] = [];
  constructor(
    private getApiService: GetApiService,
    private autoTranslateService: AutoTranslateService
  ) {
    this.target = localStorage.getItem('lang')!;
    if (sessionStorage.getItem('translateDetection') === 'true') {
      this.getTranslationFromCache();
    } else {
      autoTranslateDb[this.target]?.toArray().then((data) => {
        this.listTextResult = data;
      });
    }
  }

  async getTranslationFromCache() {
    if (this.target !== localStorage.getItem('lang')) {
      this.target = localStorage.getItem('lang')!;
    }
    await autoTranslateDb[this.target].toArray().then((data) => {
      this.listTextResult = data;
      this.toggleTranslateDetection(false);
    });
  }
  postTranslate(body: postTranslate[]) {
    if (!body.length && sessionStorage.getItem('translateDetection') === 'false') {
      this.getTranslationFromCache();
      return;
    }
    if (!body.length && !this.translateDetection$.value) {
      this.toggleTranslateDetection(false);
      return;
    }
    if (!body.length && this.translateDetection$.value && this.isLoadMore$) {
      this.toggleTranslateDetection(false);
      return;
    }
    this.getApiService.translate(body).subscribe({
      next: (value: any) => {
        if (value) {
          this.translateResult = value.data;
          if (this.translateResult) {
            this.translateResult.map((translate) => {
              this.listTextResult.push({
                original: translate.originalText,
                translated: translate.translatedText,
                to: this.target,
              });
            });
            this.autoTranslateService.addtranslated(this.listTextResult).then(() => {
              if (this.isLoadMore$.value && this.translateDetection$.value) {
                this.toggleTranslateDetection(false);
                this.isLoadMore$.next(false);
              } else {
                this.toggleTranslateDetection(false);
              }
            });
          }
        }
      },
      error: () => {},
      complete: async () => {
        this.newListText = [];
      },
    });
  }

  toggleTranslateDetection(reset: boolean) {
    this.translateDetection$.next(reset ? 0 : this.translateDetection$.value + 1);
    sessionStorage.setItem('translateDetection', String(reset ? false : true));
  }
  getTranslationList() {
    return this.listTextResult;
  }
  async toggleTranslate(reset?: boolean) {
    if (this.target !== localStorage.getItem('lang')) {
      this.target = localStorage.getItem('lang')!;
    }
    if (reset) {
      this.toggleTranslateDetection(true);
    } else {
      this.postTranslate(
        await this.autoTranslateService.getTranslationNotIncludeDB(this.newListText)
      );
    }
  }
  isSameText(text: string): boolean {
    return this.listText.some(
      (obj) => generateHash(normalizeText(obj['q'])) === generateHash(normalizeText(text))
    );
  }

  async addTextList(text: string) {
    if (!this.isSameText(text)) {
      const input = {
        q: text,
        source: 'auto',
        target: this.target,
        format: 'text',
        limited: true,
      };
      this.listText.push(input);
      this.newListText.push(input);
    }
    if (this.translateDetection$.value) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        const translation = await this.autoTranslateService.getTranslationNotIncludeDB(
          this.newListText
        );
        this.postTranslate(translation);
      }, 200);
    }
  }
}
